import React from 'react';
import { useParams } from "react-router"
import { ContractAlertList } from '../features/contractList/ContractAlertList';
import { useContractLookupQuery } from '../../../services/contractService/contract-api';
import { Loading } from '../../shared';

function AlertList(){
    const {type} = useParams();

    const {isLoading} = useContractLookupQuery(type!);

    return <React.Fragment>
        {isLoading ? <Loading/> :
            <div>
                <ContractAlertList/>
            </div>
        }
    </React.Fragment>
}

export {AlertList}