import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { IncidentCommentAddCommand } from "../../../../../services/varianceService/commands/IncidentCommentAddCommand";
import { useIncidentCommentAddMutation } from "../../../../../services/varianceService/variance-api";
import { toggleIncidentRejectCommentModal } from "../../../../../app/slices/VarianceModuleSlice";
import { useNavigate } from "react-router-dom";

function IncidentRejectCommentDialog(){
    const dispatch = useAppDispatch();
    const modalOpen = useAppSelector((state)=>state.varianceModule.IncidentRejectCommentModalOpen);
    const varianceId = useAppSelector((state)=>state.varianceRequest.varianceRequest.id);
    const [comment,setComment] = React.useState("");
    const [save] = useIncidentCommentAddMutation();
    const navigator = useNavigate();
    const saveComment = ()=>{
        let command = new IncidentCommentAddCommand(varianceId!,comment);
        save(command);
        setTimeout(() => {
            navigator(-1);
          }, 1000);
    }

    return <React.Fragment>
        <Dialog open={modalOpen} onClose={()=>setComment('')}>
            <DialogTitle>Reason for Rejection</DialogTitle>
            <DialogContent>
                <TextField label='Comment' value={comment} onChange={(e)=>setComment(e.target.value)} multiline fullWidth
                   sx={{width:'500px',marginTop:'10px'}} minRows='3'/>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{dispatch(toggleIncidentRejectCommentModal());setComment('');}}>Cancel</Button>
                <Button onClick={()=>{saveComment();dispatch(toggleIncidentRejectCommentModal())}}>Save</Button>
            </DialogActions>            
        </Dialog>
    </React.Fragment>
}

export {IncidentRejectCommentDialog}