import { Grid, GridCellProps, GridColumn as Column } from "@progress/kendo-react-grid";
import React, { useState } from "react"
import { Profile } from "../../../../services/profileService";
import { useMyProfileQuery } from "../../../../services/profileService/profile-api";
import { CenterAccessRemoveCommand } from "./CenterAccessRemoveCommand";

type ownProps = {
    profile:Profile;
}
function CenterAccessList(props:ownProps){
    const {data} = useMyProfileQuery();
    const [profile] = useState(props.profile);
    const canAccess=():boolean=>{
        if(data?.role === 'Admin' || data?.role === 'RevCycle Admin'|| data?.role==='Incident Admin'){
            return true;
        }
        return false;
    }
    const customRemove = (props:GridCellProps) => (<CenterAccessRemoveCommand {...props} profile={profile}/>)
    return <React.Fragment>
        <Grid data={props.profile?.centerAccess} style={{height:'250px'}}>
            {canAccess() && <Column cell={customRemove} width='100px'/>}
            <Column title='Center' field='code' width='150px'/>
            <Column title='Name' field = 'name' width='500px'/>
        </Grid>
    </React.Fragment>
}

export {CenterAccessList}