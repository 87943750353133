import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { Alert, Button, Grid, TextField } from "@mui/material";
import { Procedure } from "../../../../../services/scheduleService/model/schedule/Procedure";
import { formatDate } from "@progress/kendo-intl";
import { toggleProcedureDetailDialog } from "../../../../../app/slices/ScheduleModuleSlice";
import { ContainsAtLeastOneService } from "../../../specs";

function ProcedureList(){
    const dispatch = useAppDispatch();
    const schedule = useAppSelector((state)=>state.schedule.schedule)
    const mapProcedures=()=>{
        let procs:Procedure[]=schedule.procedures===undefined?[]:
        schedule.procedures.map(i => ({...i, 
            startTime:new Date(i.startTime ?? new Date('01/01/1900')),
            endTime:new Date(i.endTime??new Date('01/01/1900'))
        }))
        return procs;
    }
    
    return <React.Fragment>
        {!new ContainsAtLeastOneService().IsSatisfiedBy(schedule) &&
            <Alert color='info'>Case must contain at least one service.</Alert>
        }
        {schedule.procedures.length > 0 &&
        <Grid container spacing={1} marginLeft='5px' marginTop='10px'>
            <Grid item sm={1}></Grid>
            <Grid item sm={.75}><h6>Priority</h6></Grid>
            <Grid item sm={1}><h6>Room</h6></Grid>
            <Grid item sm={2}><h6>Service</h6></Grid>
            <Grid item sm={2}><h6>Start Time</h6></Grid>
            <Grid item sm={2}><h6>End Time</h6></Grid>
        </Grid>
        }
        {mapProcedures().map(i => (
            <Grid container spacing={1} marginLeft='5px' marginBottom='5px'>
                <Grid item sm={1}><Button size='large' variant="outlined" onClick={()=>dispatch(toggleProcedureDetailDialog(i.id))}>Details</Button></Grid>
                <Grid item sm={.75}><TextField value={i.priority} disabled/></Grid>
                <Grid item sm={1}><TextField value={i.room} disabled/></Grid>
                <Grid item sm={2}><TextField value={i.serviceCode} disabled/></Grid>
                <Grid item sm={2}><TextField value={formatDate(i.startTime!,'g')} disabled/></Grid>
                <Grid item sm={2}><TextField value={formatDate(i.endTime!,'g')} disabled/></Grid>
            </Grid>
        ))}
    </React.Fragment>
}

export {ProcedureList}