import React from "react"
import { useAppSelector } from "../../../../../../app/hooks"
import { useMyProfileQuery } from "../../../../../../services/profileService/profile-api";
import { useRefundRejectMutation, useRefundSaveMutation } from "../../../../../../services/refundService/refund-api";
import { RefundRejectPostCommand } from "../../../../../../services/refundService/commands/RefundRejectPostCommand";
import { Alert, Button, Snackbar } from "@mui/material";
import { ThumbDown } from "@mui/icons-material";
import { RefundPostCommand } from "../../../../../../services/refundService/commands";

type ownProps ={
    setNotify:Function;
    setMessage:Function;}

function RefundRejectBtn(props:ownProps){
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const [refundSave] = useRefundSaveMutation();
    const [refundReject, result] = useRefundRejectMutation();
    const profile = useMyProfileQuery();
    const handleReject=async ()=>{
        await refundSave(new RefundPostCommand(refund));
        await refundReject(new RefundRejectPostCommand(refund.caseId,refund.id,profile.data?.identity!))
        .unwrap()
        .then(()=>{props.setNotify(true);props.setMessage("Refund Rejected")}) 
    }
    return <React.Fragment>
        <Button variant='outlined' color='error' startIcon={<ThumbDown/>}
            onClick={()=>handleReject()}>Reject</Button>

        <Snackbar open={result.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(result.error)}</Alert>
        </Snackbar>
    </React.Fragment>
}

export {RefundRejectBtn}