import { Add, Upload } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react"
import { useParams } from "react-router";
import { useAppDispatch } from "../../../../../app/hooks"
import { toggleCarveOutAddModal, toggleCarveOutUploadModal } from "../../../../../app/slices/ContractModuleSlice";
import { useCarveOutListQuery } from "../../../../../services/contractService/contract-api";
import { CarveOutEdit } from "./CarveOutEdit";
import { CarveOutsAdd } from "./CarveOutsAdd";
import { CarveOutsList } from "./CarveOutsList"
import { CarveOutUpload } from "./CarveOutUpload";

function CarveOuts(){
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const {data} = useCarveOutListQuery(id!);
    
    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' alignItems='center' gap={1} margin='5px'>
            <Typography className='psn-green' variant="h6">{'Count of CarveOuts: ' + data?.length}</Typography>
            <div>
            <Button className="me-2" startIcon={<Add/>} variant='outlined' onClick={()=>dispatch(toggleCarveOutAddModal())}>Add Carve Out</Button>
            <Button startIcon={<Upload/>} variant='outlined' onClick={()=> dispatch(toggleCarveOutUploadModal())}>Upload Carve Out</Button>
            </div>
        </Stack>
        <CarveOutsList/>
        <CarveOutsAdd/>
        <CarveOutEdit/>
        <CarveOutUpload/>
    </React.Fragment>
}

export {CarveOuts}