import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import { Stack } from "@mui/system";
import { Switch} from "@progress/kendo-react-inputs";
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleCallDetailDialog } from "../../../../../app/slices/RadModuleSlice";
import { formatDate } from "@progress/kendo-intl";


function CallDetail(){
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.radModule.CallDetailDialog)
    const call = useAppSelector((state) => state.radRequest.radRequest.callLog.find(p => p.id === state.radModule.SelectedCall));

    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogTitle>
                <Stack direction='row' justifyContent='space-between'>
                    <h5>Call Detail</h5>
                    <h6>{formatDate(new Date(call?.callDate ?? new Date()),'MM/dd/yyyy')}</h6>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack direction='row'>
                    <div>
                        <Typography>Patient Reached</Typography>
                        <Switch value={call?.patientReached} checked={call?.patientReached} disabled/>
                    </div>
                </Stack>
                <Stack direction='row' marginTop='15px'>
                    <TextField label='Note' multiline value={call?.callNotes}
                        sx={{width:'400px',maxWidth:'100%'}} InputLabelProps={{shrink:true}} disabled/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction='row'>
                    <Button variant='outlined' onClick={()=>dispatch(toggleCallDetailDialog(''))}>Close</Button>
                </Stack>
            </DialogActions>    
        </Dialog>    
    </React.Fragment>
}

export {CallDetail}