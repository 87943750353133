import React, { useEffect, useState } from "react"
import { useParams } from "react-router";
import { useAppDispatch } from "../../../../../../app/hooks";
import { contractApi, useRuleDeleteMutation, useRuleSaveMutation } from "../../../../../../services/contractService/contract-api";
import { RuleDeleteCommand, RulePostCommand } from "../../../../../../services/contractService/commands";
import { Stack, TextField } from "@mui/material";
import { RuleCommands } from "./RuleCommands";


type ownProps={
    ruleId:string;
    ruleString?:string;
    ruleDescription?:string;
}

function StatusBasedComprehensive(props:ownProps){
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const [rule,setRule] = useState({category:'J1'})
    const [saveRule] = useRuleSaveMutation();
    const [deleteRule] = useRuleDeleteMutation();
    const save=()=>{
        let command = new RulePostCommand(id!,props.ruleId,'StatusBasedComprehensive',0, JSON.stringify(rule),props.ruleDescription ?? '');
        saveRule(command).unwrap().then(()=>{dispatch(contractApi.util.invalidateTags(['ruleList']))});
    }
    const ruleDelete=()=>{deleteRule(new RuleDeleteCommand(props.ruleId))};
    useEffect(() => {
        if(props.ruleString !== ''){
            let r:{category:string} = JSON.parse(props.ruleString!);
            setRule(r);
        }},[props.ruleString, props.ruleId]
    )

    return <React.Fragment>
        <Stack direction='row' gap={1}>
            <TextField label='Category' value={rule.category} sx={{width:'150px'}}
                onChange={(e)=>setRule({...rule, category:e.target.value})}/>
        </Stack>
        <RuleCommands saveCommand={save} deleteCommand={ruleDelete} isNewRule={props.ruleId === '0' ? true:false}/>
    </React.Fragment>
}

export {StatusBasedComprehensive}