import React from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { TextField } from "@mui/material";
import { formatNumber } from "@progress/kendo-intl";

function AccountPatientPaymentsView(){
    const account = useAppSelector((state) => state.account.account);
    return <React.Fragment>
        <TextField label='Patient Payments' 
            value={formatNumber(account.transactionHeader.patientPayments,'c')}
            disabled
            />
    </React.Fragment>
}
export {AccountPatientPaymentsView}