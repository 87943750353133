import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Add } from "@mui/icons-material"
import { Button, Stack, Typography } from "@mui/material"
import React from "react"
import { useAppDispatch } from "../../../../../app/hooks"
import { toggleCenterLookupModal } from "../../../../../app/slices/ContractModuleSlice"
import { CenterList } from "./CenterList"
import { CenterLookup } from "./CenterLookup"

function Centers(){
    const dispatch = useAppDispatch();
    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' alignItems='center' margin='5px'>
            <Typography variant='h6' className="ms-2 psn-green">Centers associated with this contract</Typography>
            <Button className="me-2" variant='outlined' color="primary" onClick={()=> dispatch(toggleCenterLookupModal())}><Add/>Add Centers</Button>
        </Stack>
        <CenterList/>

        <CenterLookup/>
    </React.Fragment>
}

export{Centers}