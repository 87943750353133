import { Alert } from "@mui/material"
import React from "react"
import { useAppSelector } from "../../../../../app/hooks"
import { FeeScheduleAdd } from "../feeSchedules/FeeScheduleAdd"
import { FeeScheduleList } from "../feeSchedules/FeeScheduleList"
import { CptHeader } from "./CptHeader"

function Cpt(){
    const contract = useAppSelector((state)=>state.contract.contract);
    return <React.Fragment>
        {contract.medicareBasedConfig.basedOnMedicare === true &&
            <Alert color='info'>This contract has fee schedules based on Medicare rates. </Alert>
        }
        {contract.medicareBasedConfig.basedOnMedicare === false &&
            <div>
                <CptHeader/>
                <FeeScheduleList feeScheduleType="CPT"/>
                <FeeScheduleAdd feeScheduleType="CPT"/>
            </div>
        }
    </React.Fragment>
}

export {Cpt}