import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {Center, DefaultCenter} from '../center/Center';
import {orgApi} from '../../org-api';
import { ReduxUpdate } from '../../../../utils/ReduxUpdateCommands';

interface CenterState{
    center:Center;
}

const initialState: CenterState = {center: DefaultCenter()}
export const centerSlice = createSlice({
    name:'center',
    initialState,
    reducers:{
        editCenter:(state, action:PayloadAction<Center>) =>{
            state.center = action.payload
        },
        editMedicareConfig:(state,action:PayloadAction<React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>>) =>{
            const {name,value} = action.payload.target;
            //@ts-ignore
            state.center.medicareConfig[name] = value == '' ? null : value;
        },
        editAppConfig:(state, action:PayloadAction<ReduxUpdate>) =>{
            //@ts-ignore
            state.center[action.payload.name] = action.payload.value;
        },
        editCenterV2:(state, action:PayloadAction<ReduxUpdate>) =>{
            //@ts-ignore
            state.center[action.payload.name] = action.payload.value;
        }

    },
    extraReducers:(builder)=>{
        builder.addMatcher(
            orgApi.endpoints.center.matchFulfilled,
            (state,{payload}) => {
                state.center = payload
            }
        ).addMatcher(orgApi.endpoints.saveMedicareRate.matchFulfilled,
            (state,{payload}) => {
                var existing = state.center.medicareRates.find(p => p.id == payload.id);
                if(!existing){state.center.medicareRates.push(payload)}
                else{
                    existing.wageIndex = payload.wageIndex;
                    existing.lrop = payload.lrop;
                    existing.nlop = payload.nlop;
                    existing.federalCapitalWeight = payload.federalCapitalWeight;
                    existing.geographicAdjustmentFactor = payload.geographicAdjustmentFactor;
                    existing.inpatientHospitalDeductible = payload.inpatientHospitalDeductible;
                }
            })
    }
})

export const {editCenter, editMedicareConfig, editAppConfig, editCenterV2} = centerSlice.actions;
export default centerSlice.reducer;