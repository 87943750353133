import React, { useState } from "react"
import { Profile } from "../../../../services/profileService";
import { useMyProfileQuery } from "../../../../services/profileService/profile-api";
import { Grid, GridCellProps, GridColumn as Column } from "@progress/kendo-react-grid";
import { ClaimsAccessRemoveCommand } from "./ClaimsAccessRemoveCommand";

type ownProps ={
    profile: Profile;
}

function ClaimsAccessList(props:ownProps){
    const {data} = useMyProfileQuery();
    const [profile] = useState(props.profile);
    const customRemove = (props:GridCellProps) => (<ClaimsAccessRemoveCommand{...props} profile={profile}/>)
    const canAccess=():boolean=>{
        if(data?.role === 'Admin' || data?.role === 'RevCycle Admin'|| data?.role==='Incident Admin'){
            return true;
        }
        return false;
    }

    return <React.Fragment>
        <Grid className='embedded-grid' data={props.profile?.claimsAccess} style={{height:'250px'}}>
            {canAccess() && <Column cell={customRemove} width='100px'/>}
            <Column title='Code' field='code' width='150px'/>
            <Column title='Name' field='name' width='500px'/>
        </Grid>
    </React.Fragment>
}

export {ClaimsAccessList}