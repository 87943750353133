import { Stack } from "@mui/material"
import React from "react"
import { IncidentCommentList } from "./IncidentCommentList"
import { IncidentCommentAddDialog } from "./IncidentCommentAddDialog"

function IncidentComments(){
    return <React.Fragment>
        <Stack direction='row' justifyContent='flex-end' margin='20px'>
            <IncidentCommentAddDialog/>
        </Stack>
        <IncidentCommentList/>
    </React.Fragment>
}
export {IncidentComments}