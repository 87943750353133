export interface MedicareRate{
    id:string;
    year:number;
    cbsa:string;
    wageIndex:number;
    lrop:number;
    nlop:number;
    baseRate:number;
    federalCapitalWeight:number;
    geographicAdjustmentFactor:number;
    inpatientHospitalDeductible:number;
}

export function DefaultMedicareRate():MedicareRate{
    return {id:'',year:new Date().getFullYear(),cbsa:'',wageIndex:0,lrop:0,nlop:0
        ,baseRate:0, federalCapitalWeight:0,geographicAdjustmentFactor:0,
        inpatientHospitalDeductible:0}
}