import {process, State } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import React, { useState } from "react"
import { useAppSelector } from "../../../../../app/hooks";
import { Approval } from "../../../../../services/refundService/model/refundRequest/Approval";
import { ApprovalStatusIndicator } from "./ApprovalStatusIndicator";
import { Alert } from "@mui/material";


function Approvals(){
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const mapApprovals=():Approval[]=>{
        let approvals:Approval[]=refund?.approvals===undefined ? [] : refund?.approvals?.map(i => ({...i, approvedDate: i.approvedDate===null?undefined: new Date(i.approvedDate??''),
             rejectDate:i.rejectDate===null?undefined: new Date(i.rejectDate??'')}))
        return approvals;
    }
    const defaultGridState:State = {sort:[{field:'priority',dir:'asc'}]};
    const [dataState, setDataState] = useState(defaultGridState);
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };

    return <React.Fragment>
        {mapApprovals().length === 0 && <Alert>Approvers will be assigned on submission</Alert>}
        {mapApprovals().length > 0 &&
        <Grid data={process(mapApprovals(),dataState)} style={{height:'750px'}}
         onDataStateChange={dataStateChange}>
            <Column field='priority' title='Priority' width='100px'/>
            <Column field='approver' title='Approver' width='200px'/>
            <Column cell={ApprovalStatusIndicator} title='Status' width='150px'/>
            <Column field='approvedDate' title='Approval Date' width='200px' format='{0:MM/dd/yyyy}'/>
        </Grid>}
    </React.Fragment>
}

export {Approvals}