import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { GridButton } from "../../../../../components";
import { EstimatedProcedure } from "../../../../../services/verifyService/model/verification/EstimatedProcedure";
import {addProcedure} from "../../../../../services/verifyService/model/verification/VerificationSlice";

function ProcedureAddCommandCell(props: GridCellProps){
    const dispatch = useAppDispatch();
    const add = (code:string, description:string)=>{
        var estimatedProc = new EstimatedProcedure(code,description);
        dispatch(addProcedure(estimatedProc));
    }
    return <React.Fragment>
        <GridButton variant='outlined' size='small' color='primary'
            onClick={()=>add(props.dataItem.code,props.dataItem.description)}>Add To Case</GridButton>
    </React.Fragment>
}

export {ProcedureAddCommandCell}