export const PhysicianBehaviorOptions = [
    {
        description: "Aggression to Patient/Family",
        name: "AggressionPatientFamily",
    },
    {
        description: "Aggression to Medical Staff",
        name: "AggressionMedicalStaff",
    },
    {
        description: "Aggression to Staff",
        name: "AggressionStaff",
    },
    {
        description: "Communication Issue",
        name: "CommunicationIssue",
    },
    {
        description: "Death Threat",
        name: "DeathThreat",
    },
    {
        description: "Destructive Behavior",
        name: "DestructiveBehavior",
    },
    {
        description: "Discrimination",
        name: "Discrimination",
    },
    {
        description: "Harassment",
        name: "Harassment",
    },
    {
        description: "Inconsiderate/Rude/Hostile/Inappropriate",
        name: "InconsiderateRudeHostileInappropriate",
    },
    {
        description: "Intimidation/Verbal Abuse",
        name: "IntimidationVerbalAbuse",
    },
    {
        description: "Noncompliance/Uncooperative/Obstructive",
        name: "NoncomplianceUncooperativeObstructive",
    },
    {
        description: "Physical Abuse/Assault",
        name: "PhysicalAbuseAssault",
    },
    {
        description: "Responsiveness  - untimely",
        name: "ResponsivenessUntimely",
    },
    {
        description: "Responsiveness- No response",
        name: "ResponsivenessNoResponse",
    },
    {
        description: "Retaliation/Reckless/Dangerous",
        name: "RetaliationRecklessDangerous",
    },
    {
        description: "Sexual Harassment",
        name: "SexualHarassment",
    },
];