import React, { useState } from "react"
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { ColumnMenu } from "../../../../../utils/ColumnMenu";
import { useDrgListQuery } from "../../../../../services/contractService/contract-api";
import { useParams } from "react-router";
import { DrgItemListCommandCell } from "./DrgItemListCommandCell";
import { DrgRateCalcCell } from "./DrgRateCalcCell";
import { process, State } from "@progress/kendo-data-query";


function DrgItemList(){
    const {feeScheduleId} = useParams();
    const {data} = useDrgListQuery(feeScheduleId!);
    const [dataState, setDataState] = useState({});
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };
      
    return <React.Fragment>
        <Grid data={process(data === undefined ?[]:data,dataState)} 
            style={{height:'500px'}} onDataStateChange={dataStateChange}>
            <Column cell={DrgItemListCommandCell} width='75px'/> 
            <Column title="DRG #" field='code' columnMenu={ColumnMenu} width='125px'/>       
            <Column title='Description'field='title' columnMenu={ColumnMenu} width='500px'/>
            <Column title='Weight' field='weight' columnMenu={ColumnMenu} width='125px'/>
            <Column title='ALOS' field='arithmeticMeanLOS' columnMenu={ColumnMenu} width='125px'/>
            <Column title='Base Rate Pointer' field='grouperCode' columnMenu={ColumnMenu} width='175px'/>
            <Column title='Rate' cell={DrgRateCalcCell}width='100px' format="{0:c}"/>
        </Grid>
    </React.Fragment>
}

export {DrgItemList}