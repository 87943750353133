import {
  Stack,
  Box,
  IconButton,
  Autocomplete,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import SaveIcon from '@mui/icons-material/Save';
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { editVariance } from "../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";
import { Departments } from "../../details/Header/Department/Departments";
import { toggleIncidentReportingDepartmentDialog, toggleIndividualTypeModal } from "../../../../../app/slices/VarianceModuleSlice";
import { Cancel } from "@mui/icons-material";


function ReportingDepartmentSelection(props: { canEdit: boolean }) {
  const dispatch = useAppDispatch();
  const incident = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  const [incidentEdit, setIncidentEdit] = useState(incident.reportingDepartment === null || incident.reportingDepartment === "");
  return (
    <React.Fragment>
      <Box>       
          <Stack direction="row" margin={'20px'}>
            <Autocomplete
              disabled={!props.canEdit}
              sx={{ width: 300 }}
              onChange={(_event, value) => {
                dispatch(editVariance({ name: "reportingDepartment", value: value?.department! }));
              }}
              options={Departments.filter((p) => p.centerCode === incident.center)}
              getOptionLabel={(option) => option.department}
              renderInput={(params) => (
                <TextField {...params} label="Department Reporting Incident?" />
              )}
            /><IconButton
            disabled={!props.canEdit}
            size="small"
            onClick={() => {
              setIncidentEdit(!incidentEdit);
              dispatch(toggleIncidentReportingDepartmentDialog());
              dispatch(toggleIndividualTypeModal());              
            }}
            >
            <SaveIcon fontSize="medium" />
            </IconButton>
            <IconButton
            size="small"
            onClick={() => {
              dispatch(toggleIncidentReportingDepartmentDialog());
            }}
            >
              <Cancel fontSize="medium" />
            </IconButton>                  
          </Stack>
      </Box>
    </React.Fragment>
  );
}

export { ReportingDepartmentSelection };
