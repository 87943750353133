export class Authorization{
    authorizationNumber: string = '';
    referralNumber:string = '';
    dateRange:string = '';
    method:string = '';
    phoneRep:string = '';
    authRequired:string = '';
    authStep:string = '';
    stayTypeApproved:string = ''
    ipDischargeRequired:Boolean = false;
}