export class CallCreateCommand{
    requestId:string;
    patientReached:boolean;
    notes:string;

    constructor(requestId:string, patientReached:boolean, notes:string){
        this.requestId = requestId;
        this.patientReached = patientReached;
        this.notes = notes;
    }
}