import { AccountAttributes } from "./AccountAttributes";
import { AccountEncounterInfo } from "./AccountEncounterInfo";
import { AccountNote } from "./AccountNote";
import { AccountPatient } from "./AccountPatient";
import { AccountPrimaryPayer } from "./AccountPrimaryPayer";
import { AccountTransactionHeader } from "./AccountTransactionHeader";
import { BookedProcedure } from "./BookedProcedure";
import { ExpectedProcedure } from "./ExpectedProcedures";
import { FinalProcedure } from "./FinalProcedure";
import { NonSurgicalLine } from "./NonSurgicalLine";
import { OutOfPeriodAdjustment } from "./OutOfPeriodAdjustment";

export interface Account{
    id:string;
    centerId:string;
    accountNumber:string;
    visitNumber:number;
    visitKey:string;
    accountServiceType:string;
    encounterInfo: AccountEncounterInfo;
    patient: AccountPatient;
    primaryPayerInfo: AccountPrimaryPayer;
    attributes:AccountAttributes
    expectedProcedures: ExpectedProcedure[]
    finalProcedures: FinalProcedure[];
    transactionHeader: AccountTransactionHeader;
    nonSurgical: NonSurgicalLine[];
    outOfPeriodAdjustments: OutOfPeriodAdjustment[];
    notes: AccountNote[];
    bookedProcedures: BookedProcedure;
}

export function DefaultAccount():Account{
    return {id:'', centerId:'', accountNumber:'', visitNumber:0, visitKey:'', accountServiceType:'',
    encounterInfo: {visitNumber: 0, admissionType:'', admissionSource:'',visitType:'',serviceCode:'',status:''}, 
    patient: {lastName:'', firstName:'', middleName:''}, 
    primaryPayerInfo: {payerId:'', payerName:'', arPayer:'', calcPayer:'', payerGroup:''},
    attributes:{revenueVarianceNote:'', accountServiceType:'Not Set', approvalStatus:'NotApproved', approvedBy:''},
    expectedProcedures: [], finalProcedures:[], nonSurgical:[], outOfPeriodAdjustments:[], notes:[],
    bookedProcedures:{bookedRevenue:0,bookedCharges:0,bookedImplantCost:0,bookedImplantCharges:0,lines:[]},
    transactionHeader:{charges:0,roomCharges:0,contractualAdjustments:0,adjustments:0,insurancePayments:0,patientPayments:0,implantCosts:0,bookedRevenue:0}}
}