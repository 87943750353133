import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { toggleClaimAccessModal } from "../../../../app/slices/ProfileModuleSlice";
import { GridButton } from "../../../../components";
import { ClaimAddCommand } from "../../../../services/profileService/commands";
import { Profile } from "../../../../services/profileService/model/profile/Profile";
import { useAddClaimMutation } from "../../../../services/profileService/profile-api";

interface ownProps extends GridCellProps{
    profile:Profile;
}

function ClaimAccessEditCommandCell(props:ownProps){
    const dispatch = useAppDispatch();
    const [addClaim] = useAddClaimMutation()
    const handleSave=()=>{
        const command = new ClaimAddCommand(props.profile?.identity,props.dataItem.claimValue);
        addClaim(command);
        dispatch(toggleClaimAccessModal());
    }

    return<React.Fragment>
        <GridButton className='ms-1 mt-1' variant="outlined" color="primary" onClick={()=>handleSave()}>Select</GridButton>
    </React.Fragment>
}

export {ClaimAccessEditCommandCell}

