import { Stack } from "@mui/material"
import React from "react"

function ScheduledCases(){
    return <React.Fragment>
        <Stack justifyContent='center' alignItems='center'>
            <iframe title="ScheduledCases" width="1350" height="800" 
                src="https://app.powerbi.com/reportEmbed?reportId=b8755657-08e9-410a-bc62-e25eb8adfebc&autoAuth=true&ctid=49423d5d-8d49-4128-b53d-59537808f6ac&navContentPaneEnabled=true"
                allowFullScreen={true}></iframe>
        </Stack>
    </React.Fragment>
}

export {ScheduledCases}