import React from "react"
import { useMyProfileQuery } from "../../../../services/profileService/profile-api"
import { Card, CardActions, CardContent, Grid } from "@mui/material";
import CaseManagementIcon from '../../../../images/appicons/CaseManagement.png';

function CaseManagementCard(){
    const {data} = useMyProfileQuery();
    const canAccess=():boolean=>{
        if(data?.role === 'Admin'){return true;}
        if(data?.claimsAccess.find(p => p.code === 'CaseManagement') != undefined){return true;}
        return false;
    }

    return <React.Fragment>
        {canAccess() &&
            <Grid item sm={3}>
                <Card className='card-img-body center-align'>
                    <CardContent className='card-img-main'>
                        <a className='btn img-button' href={'/CaseManagement'}><img className='card-img' src={CaseManagementIcon} alt=''/></a>
                    </CardContent>
                    <CardActions className='card-img-footer'><h6>Case Management</h6></CardActions>
                </Card>
            </Grid>
        }
    </React.Fragment>
}

export{CaseManagementCard}