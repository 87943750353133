export const BloodProductOptions = [
  {
    description: "Adverse Reaction",
    name: "AdverseReaction",
  },
  {
    description: "Consent Issue",
    name: "ConsentIssue",
  },
  {
    description: "Contraindication",
    name: "Contraindication",
  },
  {
    description:
      "Device Failure/Malfunction Dislodgement/Misconnection/Removal",
    name: "DeviceFailure",
  },
  {
    description: "Documentation incomplete/incorrect",
    name: "DocumentationIncorrect",
  },
  {
    description: "Expired product",
    name: "ExpiredProduct",
  },
  {
    description: "Incorrect collection- (wrong tube, wrong sequence, etc)",
    name: "IncorrectCollection",
  },
  {
    description: "Incorrect IV Fluid",
    name: "IncorrectIVFluid",
  },
  {
    description: "Incorrect Rate",
    name: "IncorrectRate",
  },
  {
    description: "Incorrect tubing",
    name: "IncorrectTubing",
  },
  {
    description: "Incorrect Volume",
    name: "IncorrectVolume",
  },
  {
    description: "Product requested for wrong patient",
    name: "ProductRequestedForWrongPatient",
  },
  {
    description: "Special Product Needed Not Available",
    name: "SpecialProductNotAvailable",
  },
  {
    description: "Storage Incorrect",
    name: "StorageIncorrect",
  },
  {
    description:
      "Transfusion Issue – Pre-Transfusion Checks Not Done Prior to Release",
    name: "TransfusionIssue",
  },
  {
    description: "Transfusion Reaction",
    name: "TransfusionReaction",
  },
  {
    description: "Transfusion Time Issue- not started timely",
    name: "TransfusionTimeIssue",
  },
  {
    description: "Transfusion Time Issue- transfusion more than 4 hours",
    name: "TransfusionTimeIssueMoreThan4Hours",
  },
  {
    description: "Unit Mismatched",
    name: "UnitMismatched",
  },
  {
    description: "Unknown",
    name: "Unknown",
  },
  {
    description: "Unused product, Not returned",
    name: "UnusedProductNotReturned",
  },
  {
    description: "User Error",
    name: "UserError",
  },
  {
    description: "Wrong Patient Collected (T&C)",
    name: "WrongPatientCollected",
  },
  {
    description: "Wrong patient transfused",
    name: "WrongPatientTransfused",
  },
  {
    description: "Wrong product ordered",
    name: "WrongProductOrdered",
  },
  {
    description: "Wrong Product Issued (i.e., PRBC v FFP)",
    name: "WrongProductIssued",
  },
  {
    description: "Wrong Product Requested (i.e., PRBC v FFP)",
    name: "WrongProductRequested",
  },
];
