import { Alert, Button, Snackbar, Stack } from "@mui/material";
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { useAppSelector } from "../../../../../app/hooks";
import { VerificationSaveCommand } from "../../../../../services/verifyService/commands";
import { useSaveVerificationMutation } from "../../../../../services/verifyService/verify-api";

function CommandBar(){
    const navigate = useNavigate();
    const verification = useAppSelector((state) => state.verification.verification);
    const [saveVerification, status] = useSaveVerificationMutation();
    const [notify, setNotify] = useState(false)
    useEffect(()=>{setNotify(status.isSuccess)},[status.isSuccess]);

    return <React.Fragment>
        <Stack direction='row' justifyContent='flex-end' gap={1}>
            <Button variant='outlined' color="primary" onClick={()=> saveVerification(new VerificationSaveCommand(verification))}>Save</Button>
            <Button variant="outlined" color='success' onClick={()=>navigate(-1)}>Go Back</Button>
        </Stack>
        <Snackbar open={notify} onClose={()=>setNotify(false)} color='success' autoHideDuration={6000} message='Case successfully saved'/>
        <Snackbar open={status.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(status.error)}</Alert>
        </Snackbar>
    </React.Fragment>
}

export {CommandBar}