export interface RefundDetail{
    refundCategory:string;
    amount:number;
    openBalances: boolean;
    openClaims: boolean;
    reason:string;
    creditCardRefund:boolean;
}

export function DefaultRefundDetail():RefundDetail{
    return {refundCategory:'', amount:0, creditCardRefund:false, 
    openBalances:false, openClaims:false, reason:''}
}