import { CaseManDiagnosisCodes } from "./CaseManDiagnosisCodes";
import { CaseManProcedureCodes } from "./CaseManProcedureCodes";

export interface CaseManCodingDetails {
    diagnosisCodes: CaseManDiagnosisCodes;
    cptCodes: CaseManProcedureCodes[];
    medNecAlerts:[];
    medNecError:string;
    abnLocator:string;
}

export function DefaultCaseManCodingDetails():CaseManCodingDetails{
    return {diagnosisCodes: {diagnosis1:'', diagnosis2:'', diagnosis3:'', diagnosis4:'', dx1CoverageStatus:'', dx2CoverageStatus:'', dx3CoverageStatus:'', dx4CoverageStatus:''},
        cptCodes:[], medNecAlerts:[], medNecError:'', abnLocator:''};
}