import { Alert, Snackbar, Stack, Table } from "@mui/material";
import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { CallLog, CommandBar, StatusIndicator, VisitDetails } from "../features";
import { PatientDetails } from "../features/radDetail/patientDetails/PatientDetails"
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout"
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setTabStripPosition } from "../../../app/slices/RadModuleSlice";
import { useFindRadRequestQuery, usePostRadRequestMutation } from "../../../services/radiologyService/radiology-api";
import { PostRadRequestCommand } from "../../../services/radiologyService/commands";

function EditRadRequest(){
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const [notify, setNotify] = useState(false)
    const toggleTab = (e: TabStripSelectEventArguments) => dispatch(setTabStripPosition(e.selected));
    const [saveRadRequest,status] = usePostRadRequestMutation();
    useEffect(()=>{setNotify(status.isSuccess)},[status.isSuccess]);
    const {data} = useFindRadRequestQuery(id!);
    const tabPosition = useAppSelector((state) => state.radModule.TabStripPosition);
    const radRequest = useAppSelector((state) =>state.radRequest.radRequest);
    const save=()=>{
        let request = radRequest;
        let command = new PostRadRequestCommand(request);
        saveRadRequest(command);
    };
    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' marginBottom='10px'>
            <StatusIndicator/>
            <CommandBar save={save}/>        
        </Stack>

        <PatientDetails/>

        <TabStrip selected={tabPosition} onSelect={toggleTab}>
            <TabStripTab title='Request Details'>
                <Table/>
                <VisitDetails/>
            </TabStripTab>
            <TabStripTab title ='Call Log'>
                <Table/>
                <CallLog/>
            </TabStripTab>
        </TabStrip>
        
        <Snackbar open={notify} onClose={()=>setNotify(false)} color='success' autoHideDuration={6000} message='Case successfully saved'/>
        <Snackbar open={status.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(status.error)}</Alert>
        </Snackbar>
    </React.Fragment>
}

export {EditRadRequest}