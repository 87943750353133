import React, { useState } from "react"
import { useParams } from "react-router";
import { useCaseFindQuery} from "../../../../../services/scheduleService/schedule-api";
import { formatDate } from "@telerik/kendo-intl";
import { Box, Button, Card, CardContent, Popover, Popper, Tooltip } from "@mui/material";
import { Contacts } from "@mui/icons-material";


function PatientDetails(){
    const {id} = useParams();
    const schedule = useCaseFindQuery(id!);
    const[popout, setPopout] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    return <React.Fragment>
        <Tooltip title='Open patient details'>
                    <Button id='popover1' className="popoutButton" 
                        onClick={(e)=> {setPopout(!popout); setAnchorEl(anchorEl?null:e.currentTarget)}}
                    sx={{paddingLeft:'0px'}} size='large'><Contacts/></Button>
        </Tooltip>
        <Popper open={popout} placement='left-start' anchorEl={anchorEl}  disablePortal sx={{zIndex:100}}>
            <Card>
                <CardContent>
            <div>{schedule.data?.lastName + ', ' + schedule.data?.firstName}</div>
            <div>
                    <h6>Address</h6>
                    <address><div>{schedule.data?.streetAddress1}</div>
                    {schedule.data?.streetAddress2 != '' && <div>{schedule.data?.streetAddress2}</div>}
                    <div>{schedule.data?.city + ', ' + schedule.data?.state + '  ' + schedule.data?.zipCode}</div></address>
                
                {/* <div>{'Birth Date: ' +formatDate(new Date(schedule.data?.birthDate ?? ''),'MM/dd/yyy')}</div><br/> */}

                    <h6>Contact Info</h6>
                    <address><div>{'Home Phone: ' + schedule.data?.homePhone}</div>
                    <div>{'Mobile Phone: ' + schedule.data?.mobilePhone}</div>
                    <div>{'Email: ' + schedule.data?.email}</div></address>

                    <h6>Demographics</h6>
                    <address>
                    <div>{'Birth Date: ' +formatDate(new Date(schedule.data?.birthDate ?? ''),'MM/dd/yyy')}</div>
                    </address>
            </div>
            </CardContent>
            </Card>
        </Popper>
    </React.Fragment>
}

export {PatientDetails}