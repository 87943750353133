import React from "react"
import { useAppSelector } from "../../../../../../app/hooks"
import { Stack, Typography } from "@mui/material";

function RefundPatient(){
    const schedule = useAppSelector((state)=>state.schedule.schedule);

    return <React.Fragment>
        <Stack direction='row'>
            <Typography variant='h5' className='psn-green'>{schedule.lastName + ', ' + schedule.firstName + ' (' + schedule.accountNumber + ')' }</Typography>    
        </Stack>    
    </React.Fragment>
}

export {RefundPatient}