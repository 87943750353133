import {
  Box,
  FormGroup,
} from "@mui/material";
import React from "react";
import { FallPrecautionOptions } from "../FallPrecautionsOptions";
import { FallActivityOrderedOptions } from "../FallActivityOrderedOptions";
import { FallMedicatedOptions } from "../FallMedicatedOptions";
import { FallOtherPriorDetailOptions } from "./FallOtherPriorDetailOptions";
import { IncidentSubTypes } from "../../IncidentSubTypes";

function FallPriorTo(props: { canEdit: boolean }) { 
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", ml: 1 }}>      
        <FormGroup>
        <IncidentSubTypes canEdit={props.canEdit} formName="Prior To Fall" freeformName="FallRiskAssessment_Score" freeformLabel="Fall Risk Assessment Score" options={[{description: "Fall Risk Assessment Complete", name: "FallRiskAssessComplete"}]} />    
        <IncidentSubTypes canEdit={props.canEdit} formName="Fall Precautions in Place" options={FallPrecautionOptions} />
        <IncidentSubTypes canEdit={props.canEdit} formName="Surface Conditions" helperText={1} options={[{description: "Wet", name: "Wet"}, {description: "Dry", name: "Dry"}]} freeformName="FallObstacles" freeformLabel="Obstacles present"/>
        </FormGroup>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", ml: 1 }}>
        <IncidentSubTypes canEdit={props.canEdit} formName="Other Prior Details" options={FallOtherPriorDetailOptions} freeformName="FallRestraintsInUse" freeformLabel="Restraints in use: Type" />
        <IncidentSubTypes canEdit={props.canEdit} formName="If Medicated within the past 4 hours" options={FallMedicatedOptions} helperText={2} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", ml: 1 }}>
        <IncidentSubTypes canEdit={props.canEdit} formName="Activity Ordered" options={FallActivityOrderedOptions} />
        </Box>
    </React.Fragment>
  );
}

export { FallPriorTo };
