import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { Button, Stack, TextField } from "@mui/material";
import { formatNumber } from "@progress/kendo-intl";
import { useMyProfileQuery } from "../../../../../services/profileService/profile-api";
import { accountApi, useSaveAccountVarianceApprovalMutation } from "../../../../../services/accountService/account-api";
import { AccountApproveVarianceCommand } from "../../../../../services/accountService/commands";

function AccountVarianceView(){
    const dispatch = useAppDispatch();
    const profile = useMyProfileQuery();
    const account = useAppSelector((state)=>state.account.account);
    const variance = Math.abs(account.bookedProcedures.bookedRevenue - account.expectedProcedures.reduce((acc, p) => acc + p.reimbursement, 0));
    const [save] = useSaveAccountVarianceApprovalMutation();
    const canApprove = profile.data?.claimsAccess.find(p => p.code === 'OopAdjApprove') != undefined;
    const handleApprove=(status:string)=>{
        let command = new AccountApproveVarianceCommand(account.id,status);
        save(command);
        dispatch(accountApi.util.invalidateTags(['AccountSummary']));
    }


    return <React.Fragment>
        <Stack direction='row' gap={1}>
            <TextField label='Variance from Projected' 
                sx={{input: {color:Math.abs(variance) > 5000 ? 'red' : ''}}}
                value={formatNumber(variance,'c')}/>
            {variance > 5000 && canApprove &&
                <Stack direction='row' gap={1}>
                {account.attributes.approvalStatus != 'Rejected' &&
                <Button variant='outlined' color={'success'}
                    onClick={()=>handleApprove(account.attributes.approvalStatus=='NotApproved'?'Approved':'NotApproved')}>
                    {account.attributes.approvalStatus=='Approved'?'UnApprove':"Approve"}</Button>}
                {account.attributes.approvalStatus != 'Approved' &&    
                <Button variant='outlined'color='error'
                    onClick={()=>handleApprove(account.attributes.approvalStatus =='Rejected'?'NotApproved':'Rejected')}>
                    {account.attributes.approvalStatus == 'Rejected' ? 'UnReject' :'Reject'}</Button>}
                </Stack>}
        </Stack>
    </React.Fragment>
}
export {AccountVarianceView}