export const FallPostMentalStatusOptions = [
    {
        description: "Oriented",
        name: "Oriented",
    },
    {
        description: "Disoriented",
        name: "Disoriented",
    },
    {
        description: "Alert",
        name: "Alert",
    },
    {
        description: "Sedated",
        name: "Sedated",
    },
    {
        description: "Unconscious",
        name: "Unconscious",
    },
    {
        description: "Confused",
        name: "Confused",
    },
];