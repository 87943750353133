import { MenuItem, TextField } from "@mui/material"
import React from "react"
import { useAppDispatch } from "../../../../../../app/hooks";
import { ReduxUpdate } from "../../../../../../utils/ReduxUpdateCommands";
import { editCaseManDiagnosisCodes } from "../../../../../../services/caseManService/model/caseMan/CaseManagementSlice";

type ownProps= {
    value: string;
    name: string;
}
function DiagnosisSupportEdit(props:ownProps){
    const dispatch = useAppDispatch();
    return <React.Fragment>
        <TextField select label="Dx Supported By" sx={{width:'222px'}} defaultValue='Not Supported'
            value={props.value} onChange={(e)=> dispatch(editCaseManDiagnosisCodes(new ReduxUpdate(props.name,e.target.value )))}>
            <MenuItem value='Not Supported'>Not Supported</MenuItem>
            <MenuItem value='Clinical Records'>Clinical Records</MenuItem>
            <MenuItem value='History and Physical'>History and Physical</MenuItem>
            <MenuItem value='Imaging Report'>Imaging Report</MenuItem>
            <MenuItem value='Pathology'>Pathology</MenuItem>
            <MenuItem value='Physical Therapy Notes'>Physical Therapy Notes</MenuItem>
            <MenuItem value='Psychological Evaluation'>Psychological Evaluation</MenuItem>
            <MenuItem value='Other'>Other</MenuItem>
        </TextField>
    </React.Fragment>
}

export {DiagnosisSupportEdit}