import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { TextField } from "@mui/material";
import { editAccountEncounterInfo } from "../../../../../services/accountService/model/account/AccountSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";

function AccountVisitTypeAttr(){
    const account = useAppSelector((state) => state.account.account);
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <TextField label='Visit Type' value={account.encounterInfo.visitType}
            onChange={(e) => dispatch(editAccountEncounterInfo(new ReduxUpdate('visitType',e.target.value)))}/>
    </React.Fragment>
}
export{AccountVisitTypeAttr}