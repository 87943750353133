import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { Button, Grid, TextField } from "@mui/material";
import { toggleInsuranceDetailDialog } from "../../../../../app/slices/ScheduleModuleSlice";
import { useParams } from "react-router";
import { usePayerListQuery } from "../../../../../services/orgService/org-api";

type ownProps ={
    center:string;
}
function InsuranceList(props:ownProps){
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const schedule = useAppSelector((state) => state.schedule.schedule);
    const payers = usePayerListQuery(props.center);
    const payerCopy= payers.data === undefined?[]:[...payers.data];

    return <React.Fragment>
        {schedule.insurances.length > 0 &&
        <Grid container spacing={1} marginLeft='5px' marginTop='10px'>
            <Grid item sm={1}></Grid>
            <Grid item sm={.75}><h6>Priority</h6></Grid>
            <Grid item sm={1}><h6>Payer Code</h6></Grid>
            <Grid item sm={3}><h6>Payer Name</h6></Grid>
            <Grid item sm={2}><h6>Policy</h6></Grid>
            <Grid item sm={2}><h6>Group</h6></Grid>
        </Grid>
        }
        {schedule.insurances.map(i => (
            <Grid container spacing={1} marginLeft='5px' marginBottom='5px'>
                <Grid item sm={1}><Button size='large' variant="outlined" onClick={()=>dispatch(toggleInsuranceDetailDialog(i.id))}>Details</Button> </Grid>
                <Grid item sm={.75}><TextField value={i.priority} disabled/></Grid>
                <Grid item sm={1}><TextField value={i.payerCode} disabled/></Grid>
                <Grid item sm={3}><TextField value={payerCopy.find(p => p.code == i.payerCode)?.name} fullWidth disabled/></Grid>
                <Grid item sm={2}><TextField value={i.policy} disabled/></Grid>
                <Grid item sm={2}><TextField value={i.groupNumber} disabled/></Grid>
            </Grid>
        ))}
    </React.Fragment>
}

export {InsuranceList}