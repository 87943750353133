import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editAuthorizationStatus } from "../../../../../services/verifyService/model/verification/VerificationSlice";

function AuthorizationRequired(){
    const dispatch = useAppDispatch();
    const verification = useAppSelector((state) => state.verification.verification);

    return <React.Fragment>
        <TextField label='Authorization Required' name='authRequired' value={verification.authorization?.authRequired} size='small'
                onChange={(e) => dispatch(editAuthorizationStatus(e))}
                InputLabelProps={{shrink:true}} select>
                    <MenuItem value='Not Set'>Not Set</MenuItem>
                    <MenuItem value='Required'>Required</MenuItem>
                    <MenuItem value='Not Required'>Not Required</MenuItem>
        </TextField>
    </React.Fragment>
}

export {AuthorizationRequired}