export class RefundStartCommand{
    caseId: string;
    centerId:string;
    accountNumber:string;
    lastName:string;
    firstName:string;
    visitKey:string;

    constructor(caseId:string, centerId: string, accountNumber:string, lastName:string, firstName:string, visitKey:string){
        this.caseId = caseId;
        this.centerId = centerId;
        this.accountNumber = accountNumber;
        this.lastName = lastName;
        this.firstName = firstName;
        this.visitKey = visitKey;
    }
}