export const AdverseDrugDetailsOptions = [
   {
         description: "Arrhythmia",
         name: "Arrhythmia",
    },
    {
         description: "Dizziness",
         name: "Dizziness",
    },
    {
         description: "Edema",
         name: "Edema",
    },
    {
         description: "Hematologic problem, Nephrotoxicity",
         name: "HematologicProblem",
    },
    {
         description: "Hypotension",
         name: "Hypotension",
    },
    {
         description: "Mental status change",
         name: "MentalStatusChange",
    },
    {
         description: "Skin reaction (rash, blistering, itching, hives)",
         name: "SkinReaction",
    },
    {
         description: "Shortness of Breath",
         name: "ShortnessOfBreath",
    },
];

