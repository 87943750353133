import React from "react"
import { Stack, Typography } from "@mui/material";
import { useCaseFindQuery } from "../../../services/scheduleService/schedule-api";
import { useParams } from "react-router";
import { formatDate } from "@progress/kendo-intl";

function EncounterHeaderView(){
    const {id} = useParams();
    const schedule = useCaseFindQuery(id!);

    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between'>
            <Typography variant='h5' className="psn-green">{schedule.data?.lastName + ', ' + schedule.data?.firstName + '  (' + schedule.data?.accountNumber + ')'}</Typography>
            <Typography variant="h5" className='psn-green'>{"DOB: " + formatDate(new Date(schedule.data?.birthDate??''),'MM/dd/yyyy')}</Typography>
            <Typography variant='h5' className='psn-green'>{'Service Date: ' + formatDate(new Date(schedule.data?.serviceDate ??''),'MM/dd/yyyy')}</Typography>
            <div></div>
        </Stack>
    </React.Fragment>
}

export {EncounterHeaderView}