import React from "react"
import {SchedulerForm, SchedulerFormProps} from "@progress/kendo-react-scheduler";
import { ScheduleFormEditor } from "./ScheduleFormEditor";
import { ScheduleDialog } from "./ScheduleDialog";

function ScheduleForm(props:SchedulerFormProps){
    return <React.Fragment>
        <SchedulerForm {...props}
        editor={ScheduleFormEditor} dialog={ScheduleDialog}/>
    </React.Fragment>
}

export {ScheduleForm}