export const ImagingOptions = [
    {
        description: "Adverse Reaction",
        name: "AdverseReaction",
    },
    {
        description: "Allergic Reaction",
        name: "AllergicReaction",
    },
    {
        description: "Contrast - Allergic Reaction",
        name: "ContrastAllergicReaction",
    },
    {
        description: "Contrast - Extravasation",
        name: "ContrastExtravasation",
    },
    {
        description: "Equipment Issue",
        name: "EquipmentIssue",
    },
    {
        description: "Exposure - Overexposure",
        name: "ExposureOverexposure",
    },
    {
        description: "Exposure-Prolonged Fluoro Time",
        name: "ExposureProlongedFluoroTime",
    },
    {
        description: "Exposure - Unanticipated Radiation Exposure",
        name: "ExposureUnanticipatedRadiationExposure",
    },
    {
        description: "Image - Destroyed/Lost",
        name: "ImageDestroyedLost",
    },
    {
        description: "Image - Mislabeled/Incorrect",
        name: "ImageMislabeledIncorrect",
    },
    {
        description: "Image - Over/Underexposed",
        name: "ImageOverUnderexposed",
    },
    {
        description: "Incorrect Patient",
        name: "IncorrectPatient",
    },
    {
        description: "Infiltration Event",
        name: "InfiltrationEvent",
    },
    {
        description: "MRI Safety Issue",
        name: "MRISafetyIssue",
    },
    {
        description: "Requisition Issue",
        name: "RequisitionIssue",
    },
    {
        description: "Results - Incorrect Reading",
        name: "ResultsIncorrectReading",
    },
    {
        description: "Results Unavailable/Delayed",
        name: "ResultsUnavailableDelayed",
    },
    {
        description: "Supplies Issue",
        name: "SuppliesIssue",
    },
    {
        description: "Test - Delay in performing",
        name: "TestDelayInPerforming",
    },
    {
        description: "Test - Incomplete",
        name: "TestIncomplete",
    },
    {
        description: "Test - Incorrect",
        name: "TestIncorrect",
    },
    {
        description: "Test - Incorrectly performed",
        name: "TestIncorrectlyPerformed",
    },
    {
        description: "Test - Ordered, Not Performed",
        name: "TestOrderedNotPerformed",
    },
    {
        description: "Test - Performed, Not Ordered",
        name: "TestPerformedNotOrdered",
    },
    {
        description: "Test - Wrong Procedure",
        name: "TestWrongProcedure",
    },
    {
        description: "Test - Wrong Side (L vs R)",
        name: "TestWrongSide",
    },
    {
        description: "Test - Wrong Site",
        name: "TestWrongSite",
    },
    {
        description: "Unclean/Unsterile Environment",
        name: "UncleanUnsterileEnvironment",
    },
];