import { Schedule } from "../../../services/scheduleService/model/schedule/Schedule";
import { CompositeSpecification } from "../../../utils/Specification";
import { ContainsAtLeastOneService } from "./ContainsAtLeastOneService";
import { EncounterMustBePopulated } from "./EncounterMustBePopulated";

export class IsSavingDisabled extends CompositeSpecification<Schedule>{
    IsSatisfiedBy(candidate: Schedule): boolean {
        if(!new ContainsAtLeastOneService().IsSatisfiedBy(candidate))
            return true;
        if(!new EncounterMustBePopulated().IsSatisfiedBy(candidate))
            return true;

        return false;
    }
    
}