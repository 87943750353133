export const SafetyOptions = [
    {
        description: "Abandonment",
        name: "Abandonment",
    },
    {
        description: "Abduction",
        name: "Abduction",
    },
    {
        description: "Abuse/Assault (Physical)",
        name: "AbuseAssaultPhysical",
    },
    {
        description: "Abuse/Assault (Verbal)",
        name: "AbuseAssaultVerbal",
    },
    {
        description: "Accidental Injury of Unknown Origin",
        name: "AccidentalInjuryOfUnknownOrigin",
    },
    {
        description: "Auto Accident",
        name: "AutoAccident",
    },
    {
        description: "Bomb Threat",
        name: "BombThreat",
    },
    {
        description: "Breach of Confidentiality",
        name: "BreachOfConfidentiality",
    },
    {
        description: "Breach of Privacy",
        name: "BreachOfPrivacy",
    },
    {
        description: "Chemical Spill",
        name: "ChemicalSpill",
    },
    {
        description: "Disaster Code",
        name: "DisasterCode",
    },
    {
        description: "Disorderly Person",
        name: "DisorderlyPerson",
    },
    {
        description: "Domestic Quarrel",
        name: "DomesticQuarrel",
    },
    {
        description: "Elopement",
        name: "Elopement",
    },
    {
        description: "Entrapment",
        name: "Entrapment",
    },
    {
        description: "Evacuation Code",
        name: "EvacuationCode",
    },
    {
        description: "Fire/Fire Alarm",
        name: "FireFireAlarm",
    },
    {
        description: "Hostage Taking",
        name: "HostageTaking",
    },
    {
        description: "Infant/Child Discharged to Wrong Person",
        name: "InfantChildDischargedToWrongPerson",
    },
    {
        description: "Lost Health Record/Chart",
        name: "LostHealthRecordChart",
    },
    {
        description: "Missing/Wandering Patient/Resident",
        name: "MissingWanderingPatientResident",
    },
    {
        description: "Property Damage/Vandalism",
        name: "PropertyDamageVandalism",
    },
    {
        description: "Property Loss",
        name: "PropertyLoss",
    },
    {
        description: "Safety Policy Violation",
        name: "SafetyPolicyViolation",
    },
    {
        description: "Self-Injury",
        name: "SelfInjury",
    },
    {
        description: "Smoking",
        name: "Smoking",
    },
    {
        description: "Stalking",
        name: "Stalking",
    },
    {
        description: "Suicide/Suicide Attempt",
        name: "Suicide",
    },
    {
        description: "Suspicious Package",
        name: "Sus",
    },
    {
        description: "Theft/Suspected Theft",
        name: "Theft",
    },
    {
        description: "Threat of Violence",
        name: "Violence",
    },
];