import { Card, CardActions, CardContent, Grid } from '@mui/material';
import React from 'react';
import IVLogo from '../../../../images/appicons/IVToolIcon.png';
import { useMyProfileQuery } from '../../../../services/profileService/profile-api';


function IVCard(){
    const {data} = useMyProfileQuery();
    const canAccess=():boolean=>{
        if(data?.role === 'Admin' || data?.role === 'RevCycle Admin'|| data?.role === 'IVVerify'){return true;}
        if(data?.claimsAccess.find(p => p.code === 'InsuranceVerify') != undefined){return true;}
        return false;
    }

    
    return (
        <React.Fragment>
            {canAccess() &&
                <Grid item sm={3}>
                <Card className='card-img-body'>
                    <CardContent className='card-img-main center-align'>
                        <a className='btn img-button' href={'/VerifyHome'}><img className='card-img' src={IVLogo} alt=''/></a>
                    </CardContent>
                    <CardActions className='card-img-footer'><h6>Insurance Verification</h6></CardActions>
                </Card>
                </Grid>
            }
        </React.Fragment>
    )
}

export {IVCard}