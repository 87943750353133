import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material"
import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { toggleRefundApproverEditDialog } from "../../../../app/slices/OrgModuleSlice";
import { UserSelector } from "../../../../components/profileComponents";
import { useRefundApproverSaveMutation } from "../../../../services/orgService/org-api";
import { DefaultRefundApprover } from "../../../../services/orgService/model/refundApprover/RefundApprover";
import { RefundApproverPostCommand } from "../../../../services/orgService/commands";
import { Guid } from "js-guid";

function RefundApproversAdd(){
    const dispatch = useAppDispatch();
    const center = useAppSelector((state)=>state.center.center);
    const isOpen = useAppSelector((state)=>state.orgModule.refundApproverEditDialog);
    const [approval,setApproval] = useState(DefaultRefundApprover());
    const [addRefund] = useRefundApproverSaveMutation();
    const setApprover=(e:string)=>{setApproval({...approval,approver:e})};
    const save = ()=>{
        let newApproval = {...approval};
        newApproval.centerId = center.code;
        newApproval.id = Guid.newGuid().toString();
        let command = new RefundApproverPostCommand(newApproval);
        addRefund(command); 
        dispatch(toggleRefundApproverEditDialog());
    }
    return <React.Fragment>
        <Button variant='outlined'
            onClick={()=>dispatch(toggleRefundApproverEditDialog())}>Add Approver</Button>

        <Dialog open={isOpen}>
            <DialogTitle>Edit Approvers</DialogTitle>
            <DialogContent>
                <Stack direction='row' marginTop='10px' gap={1}>
                    <TextField value={approval.priority} label='Priority' type='number' sx={{width:'100px'}}
                        onChange={(e)=>setApproval({...approval,priority: parseInt(e.target.value)})}/>
                    <UserSelector value={approval.approver} updateFunc={setApprover}/>
                    <TextField label='Approve Greater Than' type='number' value={approval.approveGreaterThan}
                        InputProps={{startAdornment:'$'}}
                        onChange={(e)=>setApproval({...approval,approveGreaterThan: parseInt(e.target.value)})}/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='secondary' onClick={()=>dispatch(toggleRefundApproverEditDialog())}>Cancel</Button>
                <Button variant='outlined' onClick={()=>save()} >Save</Button>
            </DialogActions>
        </Dialog>

    </React.Fragment>
}

export {RefundApproversAdd}