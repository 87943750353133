import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useAppDispatch } from "../../../../../app/hooks"
import { toggleFeeScheduleItemEditModal } from "../../../../../app/slices/ContractModuleSlice";
import { GridButton } from "../../../../../components"

function DrgItemListCommandCell(props:GridCellProps){
    const dispatch = useAppDispatch();


    return <React.Fragment>
        <GridButton size='small' onClick={()=> dispatch(toggleFeeScheduleItemEditModal(props.dataItem.id))}>View</GridButton>
    </React.Fragment>
}

export {DrgItemListCommandCell}