export const PatientIDOptions = [
    {
        description: "Armband Incorrect - Patient Name Incorrect",
        name: "ArmbandIncorrectPatientNameIncorrect",
    },
    {
        description: "Armband Incorrect - Patient DOB Incorrect",
        name: "ArmbandIncorrectPatientDOBIncorrect",
    },
    {
        description: "Armband Incorrect- Wrong Patient",
        name: "ArmbandIncorrectWrongPatient",
    },
    {
        description: "Armband Missing",
        name: "ArmbandMissing",
    },
    {
        description: "HIPAA/PHI Breach",
        name: "HIPAAPHIBreach",
    },
    {
        description: "Patient Identification not Verified",
        name: "PatientIdentificationNotVerified",
    },
    {
        description: "Wrong Patient Information in Chart",
        name: "WrongPatientInformationInChart",
    },
];