import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { useSaveServiceMutation } from "../../../../services/orgService/org-api";
import { DefaultService } from "../../../../services/orgService/model/service/Service";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { toggleServiceAddModal } from "../../../../app/slices/OrgModuleSlice";
import { Add } from "@mui/icons-material";
import { ServicePostCommand } from "../../../../services/orgService/commands";

type ownProps ={
    center:string;
}
function ServiceAdd(props:ownProps){
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.orgModule.serviceAddModal);
    const [saveService] = useSaveServiceMutation();
    const [service,setService] = useState(DefaultService());
    const save=()=>{
        let command = new ServicePostCommand(service);
        command.service.centerId = props.center;
        saveService(command);
        dispatch(toggleServiceAddModal());
        setService(DefaultService());
    }
    return <React.Fragment>
        <Button startIcon={<Add/>} variant="outlined"
            onClick={()=>dispatch(toggleServiceAddModal())}>
            Add Service
        </Button>
        <Dialog open={isOpen} sx={{maxWidth:'100%'}}>
            <DialogTitle>Add new service</DialogTitle>
            <DialogContent>
                <Stack direction='row' marginTop='5px'>
                    <TextField label='Code' value={service.code} 
                        onChange={(e)=>setService({...service,code:e.target.value})}/>
                </Stack>
                <Stack direction='row' marginTop='10px'>
                    <TextField label='Description' value={service.description} sx={{width:'500px', maxWidth:'100%'}}
                        onChange={(e)=>setService({...service,description:e.target.value})}/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction='row' justifyContent='space-between'>
                    <Button color='secondary' onClick={()=>dispatch(toggleServiceAddModal())}>Close</Button>
                    <Button onClick={()=>save()}>Save</Button>
                </Stack>
            </DialogActions>
        </Dialog>    
    </React.Fragment>
}

export {ServiceAdd}