export const ConsentOptions = [
    {
        description: "Abbreviations on Consent",
        name: "AbbreviationsOnConsent",
    },
    {
        description: "Consent Not Complete Before Surgery",
        name: "ConsentNotCompleteBeforeSurgery",
    },
    {
        description: "Consent Incomplete (missing date, time, risks, etc)",
        name: "ConsentIncomplete",
    },
    {
        description: "Procedures added before patient in operating room, consents re-done",
        name: "ProceduresAddedBeforePatientInOperatingRoom",
    },
    {
        description: "Procedures Not On Consent, added after patient in operating room",
        name: "ProceduresNotOnConsent",
    },
    {
        description: "Signature Missing- Patient/Physician/Witness",
        name: "SignatureMissing",
    },
    {
        description: "Wrong Procedure Listed",
        name: "WrongProcedureListed",
    },
];

