import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { useSaveApprovalMutation, verifyApi } from "../../../../../../services/verifyService/verify-api";
import { ApprovalUpdateCommand } from "../../../../../../services/verifyService/commands";
import { useParams } from "react-router";
import { Button, Dialog, Stack, TextField, Typography } from "@mui/material";
import { useMyProfileQuery } from "../../../../../../services/profileService/profile-api";

function ApprovalNote(){
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const verification = useAppSelector((state) => state.verification.verification);
    const profile = useMyProfileQuery();
    const [isOpen, setIsOpen] = React.useState(false);
    const [note, setNote] = React.useState('');
    const [approve] = useSaveApprovalMutation();
    const canApprove=()=>{
        if(profile.data?.claimsAccess.find(p => p.code === 'VobApprover') != undefined){
            return true; }
            return false;
    }
    const save=(isApproved:boolean)=>{
        let command = new ApprovalUpdateCommand(id!, isApproved,note);
        approve(command);
        dispatch(verifyApi.util.invalidateTags(['worklist']));
    }
    return <React.Fragment>
        {verification.approval.isApproved &&
            <Stack direction = 'row' gap={5} alignItems='center'>
                {canApprove() &&<Button variant='outlined' onClick={()=>save(false)}>UnApprove</Button>}
            </Stack> 
        }
        {verification.approval.isApproved === false &&
            <Stack direction='row' gap={5} alignItems='center'>
                {canApprove() &&<Button variant="outlined" onClick={()=>setIsOpen(true)}>Approve</Button>}
            </Stack> 
        }

        <Dialog open={isOpen} onClose={()=>setIsOpen(false)}>
            <Stack gap={1} alignItems='center' marginLeft='10px' marginRight='10px'>
                <Typography variant='h6'>Please indicate why you are approving this case.</Typography>
                <TextField multiline minRows={5} value={note} onChange={(e)=>setNote(e.target.value)} fullWidth/>
                <Stack direction='row' gap={1} justifyContent='flex-end' alignItems='flex-end'>
                    <Button color='success' variant="outlined" onClick={()=>save(true)} disabled={note.length === 0}>Approve</Button>
                    <Button variant="outlined" onClick={()=>setIsOpen(false)}>Cancel</Button>
                </Stack>
            </Stack>
        </Dialog>
    </React.Fragment>
}
export {ApprovalNote}