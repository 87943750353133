import React from "react"
import { useAppSelector } from "../../../../../app/hooks";
import { InputAdornment, TextField } from "@mui/material";

function CheckAmountView(){
    var refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    return <React.Fragment>
        <TextField label='Check amount' value={refund.refundDetail.amount} disabled
            InputProps={{startAdornment:<InputAdornment position="start">$</InputAdornment> }}/>
    </React.Fragment>
}

export {CheckAmountView}