import React, { useState } from "react"
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { ColumnMenu } from "../../../../../utils/ColumnMenu";
import { useAppSelector } from "../../../../../app/hooks";
import { CenterRemoveCommandCell } from "./CenterRemoveCommandCell";
import { process, State } from "@progress/kendo-data-query";

function CenterList(){
    const contract = useAppSelector((state)=> state.contract.contract);
    const [dataState, setDataState] = useState({});
        const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };



    return <React.Fragment>
        <Grid data = {process(contract.centers.slice(0),dataState)} 
            style={{height:'500px'}} onDataStateChange={dataStateChange}>
            <Column cell={CenterRemoveCommandCell} width='75px'/>
            <Column title='Code' field='code' columnMenu={ColumnMenu} width='125px'/>
            <Column title='Name' field='name' columnMenu={ColumnMenu}/>
        </Grid>
    </React.Fragment>
}

export {CenterList}