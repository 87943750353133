export class Individual {
  varianceId: string = "";
  id: string = "";
  mrn: string = "";
  firstName: string = "";
  lastName: string = "";
  age: number = 0;
  sex: string = "";
  homePhone: string = "";
  mobilePhone: string = "";
  email: string = "";
  individualType: string = "";
}
export function DefaultIndividual(): Individual {
  return {
    varianceId: "",
    id: "",
    mrn: "",
    firstName: "",
    lastName: "",
    age: 0,
    sex: "",
    homePhone: "",
    mobilePhone: "",
    email: "",
    individualType: "",
  };
}
