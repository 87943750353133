import React from "react"
import { useCenterFindQuery } from "../../services/orgService/org-api";
import { Chip } from "@mui/material";


type ownProps={
    centerId:string;
}

function CenterNameChipView(props:ownProps){
    const {data} = useCenterFindQuery(props.centerId);
    
    return <React.Fragment>
        <Chip label={data?.shortName} variant='outlined' color='success' sx={{color:'#62BCBD', fontWeight:'bold'}}/>
    </React.Fragment>
}

export {CenterNameChipView}