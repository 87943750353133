import { Stack, Typography } from "@mui/material"
import { formatNumber } from "@progress/kendo-intl"
import React from "react"
import { AccountSummaryRequest } from "../../../../services/accountService/commands";
import { useFindAccountByPeriodQuery } from "../../../../services/accountService/account-api";
import { useParams } from "react-router";

function FinalRevenueCard(){
    const {center, month, year} = useParams();
    const {data} = useFindAccountByPeriodQuery(new AccountSummaryRequest(center!, Number(month!), Number(year!)));
    const finalRev = data === undefined ? 0 : data.reduce((acc, item)=>acc+item.finalRevenue!,0);

    return <React.Fragment>
        <Stack alignItems='center'>
            <Typography variant='subtitle1' className='psn-green'>Final Revenue</Typography>
            <Typography variant='subtitle1' className='psn-green'>{formatNumber(finalRev,'c')}</Typography>
        </Stack>
    </React.Fragment>
}
export{FinalRevenueCard}