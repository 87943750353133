import {process, State } from '@progress/kendo-data-query';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useAppDispatch } from "../../../../app/hooks"
import { toggleProcedureAddModal } from "../../../../app/slices/OrgModuleSlice";
import { useProcedureListQuery } from "../../../../services/orgService/org-api";
import { ProcedureAdd } from "./ProcedureAdd";
import { Grid, GridColumn as Column, GridDataStateChangeEvent} from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../../utils/ColumnMenu';
import { EditProcedureCell } from './EditProcedureCell';
import { ProcedureEdit } from './ProcedureEdit';
import { Button, Stack, Tooltip } from '@mui/material';
import { Add } from '@mui/icons-material';
import { ProcedureExport } from './ProcedureExport';
import { ProcedureImport } from './ProcedureImport';

type ownProps = {
    center:string;
}

function ProcedureList(props:ownProps){
    const dispatch = useAppDispatch();
    const {data} = useProcedureListQuery(props.center)
    const [dataState, setDataState] = useState({});

    const createDataState = (dataState: State) =>{
        return {dataState: dataState === undefined ? {} : dataState,};
    }
    const dataStateChange = (event:GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
    }

    return <React.Fragment>
            <Stack justifyContent='space-between' direction='row'  sx={{marginRight:'10px',marginTop:'5px', width:'100%', backgroundColor:'white'}}>
                <Stack direction='row' gap={1}>
                    <ProcedureExport center={props.center}/>
                    <ProcedureImport center={props.center}/>
                </Stack>
                <Tooltip title='Add a procedure'>   
                    <Button variant='outlined' startIcon={<Add/>} onClick={()=>dispatch(toggleProcedureAddModal())}>Add Procedure</Button>
                </Tooltip>
            </Stack>
        <Grid className='center-list-grid card-body-grid' data={process(data === undefined ? [] : data.slice(0),dataState)}
            style={{height:'500px'}} onDataStateChange={dataStateChange}>
                <Column cell={EditProcedureCell} width='75px'/>
                <Column title='Code' field='code' columnMenu={ColumnMenu} width='125x'/>
                <Column title='Type' field='codeType' columnMenu={ColumnMenu} width='100px'/>
                <Column title='Description' field='description' columnMenu={ColumnMenu} width='500px'/>
        </Grid>

        <ProcedureAdd center = {props.center}/>
        <ProcedureEdit center = {props.center}/>
    </React.Fragment>
}

export {ProcedureList}