import {TextField } from "@mui/material"
import React from "react"
import { useAppSelector } from "../../../../../app/hooks"
import { formatNumber } from "@progress/kendo-intl"

function AccountFinalImplantCostEdit(){
    const account = useAppSelector((e)=>e.account.account)

    return <React.Fragment>
        <TextField label='Total Implant Cost' value={formatNumber(account.transactionHeader.implantCosts,'c')}disabled/>
    </React.Fragment>
}
export {AccountFinalImplantCostEdit}