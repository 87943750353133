import { Add, Upload } from "@mui/icons-material";
import { Button } from "@mui/material"
import { Stack } from "@mui/system"
import React from "react"
import { useAppDispatch } from "../../../../../app/hooks"
import { toggleDrgUploadModal, toggleFeeScheduleItemAddModal } from "../../../../../app/slices/ContractModuleSlice";
import { DrgItemEdit } from "./DrgItemEdit";
import { DrgItemList } from "./DrgItemList";
import { DrgUpload } from "./DrgUpload";

function DrgCodes(){
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <Stack direction='row' justifyContent='flex-end' marginRight='10px' gap={1}>
            <Button variant="outlined" startIcon={<Add/>} onClick={()=>dispatch(toggleFeeScheduleItemAddModal())}>Add Code</Button>
            <Button variant="outlined" startIcon={<Upload/>} onClick={()=>dispatch(toggleDrgUploadModal())}>Upload Codes</Button>
        </Stack>
        <DrgItemList/>
        <DrgItemEdit/>
        <DrgUpload/>
    </React.Fragment>
}

export {DrgCodes}