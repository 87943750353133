import React from "react"
import { useAppSelector } from "../../app/hooks"
import { TextField } from "@mui/material";

function PhysicianDisplay(){
    const encounter = useAppSelector((state)=>state.schedule.schedule);
    return <React.Fragment>
        <TextField label='Physician' value={encounter.physician.lastName + ', '+ encounter.physician.firstName} disabled/>
    </React.Fragment>
}

export {PhysicianDisplay}