import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from "@mui/material";
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "../../../../app/hooks"
import { toggleProcedureAddModal } from "../../../../app/slices/OrgModuleSlice";
import { ProcedureSaveCommand } from "../../../../services/orgService/commands";
import { Procedure } from "../../../../services/orgService/model";
import { useSaveProcedureMutation } from "../../../../services/orgService/org-api";

type ownProps = {
    center : string;
}

function ProcedureAdd(props:ownProps){
    const dispatch = useDispatch();
    const isOpen = useAppSelector((state) => state.orgModule.procedureAddModal)
    const [saveProcedure] = useSaveProcedureMutation();
    const [proc,setProc] = useState(new Procedure());
    const reset =()=> setProc(new Procedure());
    const handleChange = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>|SelectChangeEvent<string>) =>{
        const {name,value} = e.target;
        let newProc = proc;
        newProc=({...newProc,[name]:value});
        newProc.centerId = props.center;
        setProc(newProc);
    }
    
    return <React.Fragment>
        <Dialog open={isOpen} sx={{maxWidth:'100%'}}>
            <DialogTitle>Add a new code</DialogTitle>
            <DialogContent>
                <Stack direction='row' sx={{marginTop:'5px', marginBottom:'10px'}} gap={2}>
                    <TextField label='Code' name="code" value={proc.code} onChange={(e)=>handleChange(e)} size='small' inputProps={{maxlength:6}} InputLabelProps={{shrink:true}}/>
                    <TextField label='Code Type' select name='codeType' value={proc.codeType} onChange={(e)=>handleChange(e)}>
                        <MenuItem value='DRG'>DRG</MenuItem>
                        <MenuItem value='CPT'>CPT</MenuItem>
                    </TextField>
                </Stack>
                <Stack>
                    <TextField label='Description' name='description' value={proc.description} onChange={(e)=>handleChange(e)} sx={{width:'500px', maxWidth:'100%'}} size='small' InputLabelProps={{shrink:true}}/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={()=>dispatch(toggleProcedureAddModal())}>Cancel</Button>
                <Button onClick={()=>{saveProcedure(new ProcedureSaveCommand(proc)); dispatch(toggleProcedureAddModal());}}>Save</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export {ProcedureAdd}