export class NotePostCommand{
    caseId: string;
    refundId: string;
    noteId:string;
    text:string;

    constructor(caseId:string,refundId:string,noteId:string,text:string){
        this.caseId = caseId;
        this.refundId = refundId;
        this.noteId = noteId;
        this.text = text;
    }
}