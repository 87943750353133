import { TextField } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../../../app/hooks";

function SageStatusView(){
    const refund = useAppSelector((state)=> state.refundRequest.refundRequest);
    return <React.Fragment>
        <TextField label='Sage Status' value={refund.sageStatus}
            disabled />
    </React.Fragment>
}

export {SageStatusView}