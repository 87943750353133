import React from "react"

function CancelledCases(){
    return <React.Fragment>
        <iframe title="Cancelled Cases" width="1400" height="800" src="https://app.powerbi.com/reportEmbed?reportId=316dc8f6-ad3c-4dfe-a8a8-dbb637df3e53&autoAuth=true&ctid=49423d5d-8d49-4128-b53d-59537808f6ac" allowFullScreen={true}></iframe>
    </React.Fragment>
}

export{CancelledCases}

