export interface Procedure{
    id:string;
    priority:number;
    description:string;
    serviceCode:string;
    serviceDescription:string ;
    startTime:Date|null;
    endTime:Date|null;
    room:string;
    modifier:string;
}

export function DefaultProcedure():Procedure{
    return {
        id:'',priority:0,description:'',serviceCode:'',serviceDescription:''
        ,startTime:null,endTime:null,room:'',modifier:''
    }
}