import { Button, Stack } from "@mui/material"
import React from "react"
import {Headset} from '@mui/icons-material';
import { toggleCallEntryDialog } from "../../../../../app/slices/RadModuleSlice";
import { useAppDispatch } from "../../../../../app/hooks";
import { CallEntry } from "./CallEntry";
import { CallList } from "./CallList";
import { CallDetail } from "./CallDetail";

function CallLog(){
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <Stack direction='row' justifyContent='flex-end' marginTop='10px' marginRight='10px'>
            <Button startIcon={<Headset/>} variant='outlined'
                onClick={()=>dispatch(toggleCallEntryDialog())}>Log a Call</Button>
        </Stack>
        <Stack marginTop='10px'>
            <CallList/>
        </Stack>
        <CallEntry/>
        <CallDetail/>
    </React.Fragment>
}

export {CallLog}