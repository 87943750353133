import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { TextField } from "@mui/material";
import { editCenterV2 } from "../../../../services/orgService/model/center/CenterSlice";
import { ReduxUpdate } from "../../../../utils/ReduxUpdateCommands";

function SageLocationEdit(){
    const center = useAppSelector((state) => state.center.center);
    const dispatch = useAppDispatch();
    return <React.Fragment>
            <TextField label='Sage Location' value={center.sageLocation} 
            onChange={(e)=>dispatch(editCenterV2(new ReduxUpdate("sageLocation",e.target.value)))}/>
    </React.Fragment>
}
export {SageLocationEdit}