export class VarianceRejectPostCommand{
    Id:string;
    rejecter:string;
    rejecterEmail:string;

    constructor(varianceId:string, rejecter:string, rejecterEmail:string){
        this.Id = varianceId;
        this.rejecter = rejecter;
        this.rejecterEmail = rejecterEmail;
    }
}