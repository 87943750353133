export const FacilitiesOptions = [
    {
        description: "Blocked/Obstructed Hallway or Stairway",
        name: "BlockedObstructedHallwayStairway",
    },
    {
        description: "Blocked/Obstructed Sidewalk",
        name: "BlockedObstructedSidewalk",
    },
    {
        description: "Call light/Code Button Malfunction",
        name: "CallLightCodeButtonMalfunction",
    },
    {
        description: "Cleanliness of Facility",
        name: "CleanlinessOfFacility",
    },
    {
        description: "Communications Down",
        name: "CommunicationsDown",
    },
    {
        description: "Construction Dust Issue",
        name: "ConstructionDustIssue",
    },
    {
        description: "Construction Issue",
        name: "ConstructionIssue",
    },
    {
        description: "Disaster – External",
        name: "DisasterExternal",
    },
    {
        description: "Disaster – Internal",
        name: "DisasterInternal",
    },
    {
        description: "Elevator Malfunction",
        name: "ElevatorMalfunction",
    },
    {
        description: "Emergency Exits Blocked",
        name: "EmergencyExitsBlocked",
    },
    {
        description: "Fire",
        name: "Fire",
    },
    {
        description: "Hazardous Material and Waste",
        name: "HazardousMaterialWaste",
    },
    {
        description: "Improper Storage",
        name: "ImproperStorage",
    },
    {
        description: "Lighting of Facility/Parking Area",
        name: "LightingOfFacilityParkingArea",
    },
    {
        description: "Noise Level of Facility",
        name: "NoiseLevelOfFacility",
    },
    {
        description: "OR Temperature/Humidity Issue",
        name: "ORTemperatureHumidityIssue",
    },
    {
        description: "Overhead/Paging System Malfunction",
        name: "OverheadPagingSystemMalfunction",
    },
    {
        description: "Provision of Handicapped Access",
        name: "ProvisionOfHandicappedAccess",
    },
    {
        description: "Spill",
        name: "Spill",
    },
    {
        description: "Temperature of Facility",
        name: "TemperatureOfFacility",
    },
    {
        description: "Unsafe Condition Not Posted",
        name: "UnsafeConditionNotPosted",
    },
    {
        description: "Utility Failure",
        name: "UtilityFailure",
    },
    {
        description: "Vandalism",
        name: "Vandalism",
    },
    {
        description: "Walking Surface Issue",
        name: "WalkingSurfaceIssue",
    },
    {
        description: "Water Leak/Flood",
        name: "WaterLeakFlood",
    },
];