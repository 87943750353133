import React from "react";
import { Grid, GridColumn as Column} from '@progress/kendo-react-grid';
import { Profile } from "../../../../services/profileService";
import { LookupUserGridCommand } from "./LookupUserGridCommand";

type ownProps={
    searchResults:Profile[];
}

function LookupUserSearchResults(props:ownProps){
    return <React.Fragment>
        <Grid className="card-body-grid" data={props.searchResults} style={{height:'500px'}}>
            <Column cell={LookupUserGridCommand} width={'75px'}/>
            <Column title='Name' field='identity' width={'300px'}/>
            <Column title='Role' field='roleDescription'/>
        </Grid>
    </React.Fragment>
}

export {LookupUserSearchResults}