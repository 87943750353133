import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardContent, Stack, TextField, CardHeader, Typography,Button } from "@mui/material";
import React from "react"
import { useParams } from "react-router";
import { useAppDispatch } from "../../../../../app/hooks";
import { toggleInsuranceDetail, toggleWaystarModal } from "../../../../../app/slices/VerificationModuleSlice";
import { PayerForCenterRequest } from "../../../../../services/verifyService/commands";
import { useCaseFindQuery } from "../../../../../services/scheduleService/schedule-api";
import { InsuranceDetail } from "./InsuranceDetail";
import WaystarLogo from '../../../../../images/WaystarLogo.png';
import { WaystarBenefitsSummary } from "./WaystarBenefitsSummary";

type ownProps ={
    visitId:string;
    centerId:string;
}

function Insurances(props:ownProps){
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const schedule = useCaseFindQuery(id!);
    let insurances = [...schedule.data!.insurances];
    return <React.Fragment>
        <Card className="mt-0">
            <CardHeader sx={{marginBottom:'0px', paddingBottom:'3px',paddingTop:'3px', borderBottom:'solid', borderColor:'gray'}} title ={<Typography variant='subtitle1' color='gray'>Insurance</Typography>}/>
            <CardContent>
                <Stack gap={1}>
                {insurances?.sort((a,b) => a.priority - b.priority).map(i => (
                    
                    <Stack direction='row' gap={2} key={i.priority}>
                        <TextField label='Priority' value={i.priority} disabled size='small' InputLabelProps={{shrink:true}}/>
                        <TextField label='Payer' value={i.payer} disabled size='small' InputLabelProps={{shrink:true}} style={{width:400}}/>
                        <TextField label='Policy'value={i.policy} disabled size='small' InputLabelProps={{shrink:true}}/>
                        <TextField label='Group' value={i.groupNumber} disabled size='small' InputLabelProps={{shrink:true}}/>
                        <Button variant='outlined' size='large' 
                            onClick={()=>dispatch(toggleInsuranceDetail(new PayerForCenterRequest(schedule.data?.center!,i.payerCode)))}>
                            <FontAwesomeIcon icon='id-card'/>
                        </Button>
                        <Button variant='outlined' size='large'
                            onClick={()=>dispatch(toggleWaystarModal(new PayerForCenterRequest(schedule.data?.center!,i.payerCode)))}>
                                <img src={WaystarLogo} width='20px' height='20px'/>
                        </Button>
                    </Stack> 
                ))}
                </Stack>
            </CardContent>
        </Card>
        <InsuranceDetail/>
        <WaystarBenefitsSummary/>
    </React.Fragment>
}

export {Insurances}