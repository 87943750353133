import { Card, CardActions, CardContent, Grid } from '@mui/material';
import React from 'react';
import ReportsLogo from '../../../../images/appicons/ReportingIcon.png';
import { useMyProfileQuery } from '../../../../services/profileService/profile-api'



function ReportsCard(){
    const {data} = useMyProfileQuery();
    const canAccess=():boolean=>{
        if(data?.role === 'Admin'){return true;}
        if(data?.role === 'RevCycle Admin'){return true;}
        if(data?.role === 'IVVerify'){return true;}
        if(data?.role === 'Incident Admin'){return true;}
        if(data?.claimsAccess.find(p => p.code === 'Reports') != undefined){return true;}
        return false;
    }
    
    return (
        <React.Fragment>
            {canAccess() && 
                <Grid item sm={3}>
                <Card className='card-img-body center-align'>
                    <CardContent className='card-img-main'>
                        <a className='btn img-button' href={'/reports'}><img className='card-img' src={ReportsLogo} alt=''/></a>
                    </CardContent>
                    <CardActions className='card-img-footer'><h6>Reporting</h6></CardActions>
                </Card>
                </Grid>
            }
        </React.Fragment>
    )
}

export {ReportsCard}