import { Box, Stack } from "@mui/material";
import React from "react";
import { MedicationEventDetails } from "./MedicationEventDetails";
import { MedicationEventMedDetails } from "./MedicationEventMedDetails";

function MedicationEvent(props: {canEdit: boolean}) {
  return (
    <React.Fragment>
      <Box mt={2} mb={2} sx={{ display: "flex", flexDirection: "column", ml: 3, mr: 3 }}>
        <Stack
          direction="column"
          spacing={3}
          justifyContent="space-between"
          alignItems="top"
        >
          <MedicationEventMedDetails />
          <MedicationEventDetails />
        </Stack>
      </Box>
    </React.Fragment>
  );
}

export { MedicationEvent };