import { Button, Stack, TextField } from "@mui/material";
import React from "react";
import {
  scheduleApi,
  useScheduleSearchQuery,
} from "../../../../../services/scheduleService/schedule-api";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { ScheduleSearchCommand } from "../../../../../services/scheduleService/commands";
import { VarianceSchedule } from "../../../../../services/varianceService/model/varianceRequest/extensions/Schedule";
import { VarianceInitiatePatientResults } from "./VarianceInitiatePatientResults";
import { VarianceInitiatePatientManualEntry } from "./VarianceInitiatePatientManualEntry";

function VarianceInitiatePatientSearch() {
  const { center } = useParams();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = React.useState("");
  const [skip, setSkip] = React.useState(true);
  const { data, isFetching } = useScheduleSearchQuery(
    new ScheduleSearchCommand(center!, searchText),
    { skip }
  );

  return (
    <React.Fragment>
      <Stack direction="row" gap={1} marginTop="10px" marginBottom="10px">
        <TextField
          autoFocus
          label="Patient name or account"
          sx={{ width: "300px", maxWidth: "100%" }}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            setSkip(true);
          }}
        />

        <Button
          variant="outlined"
          onClick={() => {
            setSkip(false);
            dispatch(scheduleApi.util.invalidateTags(["scheduleLookup"]));
          }}
          disabled={!searchText.length}
        >
          {isFetching ? "Searching" : "Search"}
        </Button>
        <VarianceInitiatePatientManualEntry/>
      </Stack>
      <VarianceInitiatePatientResults
        data={(data as VarianceSchedule[]) ?? []}
      />
    </React.Fragment>
  );
}

export { VarianceInitiatePatientSearch };
