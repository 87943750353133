import React from "react"
import { RefundRequestCommands } from "../features/detail/commands/RefundRequestCommands";
import { VisitDetails } from "../features/detail/visitDetails/VisitDetails";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Table } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setTabStripPosition } from "../../../app/slices/RefundModuleSlice";
import { RefundDetails } from "../features/detail/refundDetails/RefundDetails";
import { useFindRefundRequestQuery} from "../../../services/refundService/refund-api";
import { useParams } from "react-router";
import { Loading } from "../../shared";
import { useCaseFindQuery } from "../../../services/scheduleService/schedule-api";
import { Approvals, CheckInfo, RefundNotes, RefundUploads } from "../features/detail";
import { RefundDuplicateCheck } from "../features/detail/alerts/RefundDuplicateCheck";

function RefundRequestDetail(){
    const dispatch = useAppDispatch();
    const {refundId,caseId} = useParams();
    const tabPosition = useAppSelector((state)=>state.refundModule.TabStripPosition);
    const {isLoading} = useFindRefundRequestQuery( refundId!)
    const schedule = useCaseFindQuery(caseId!);
    return <React.Fragment>
        {(isLoading || schedule.isLoading) && <h5><Loading/></h5>}
        {!isLoading && !schedule.isLoading &&
        <div>
        <RefundRequestCommands/>
        <VisitDetails/>
        <RefundDuplicateCheck/>
        <TabStrip selected={tabPosition} onSelect={(e)=>dispatch(setTabStripPosition(e.selected))}>
            <TabStripTab title='Refund Details'>
                <Table/>
                <RefundDetails/>
            </TabStripTab>
            <TabStripTab title='Check Info'>
                <Table/>
                <CheckInfo/>
            </TabStripTab>
            <TabStripTab title='Notes'>
                <Table/>
                <RefundNotes/>
            </TabStripTab>
            <TabStripTab title='Documents'>
                <Table/>
                <RefundUploads/>
            </TabStripTab>
            <TabStripTab title='Approvals'>
                <Table/>
                <Approvals/>
            </TabStripTab>
        </TabStrip>
        </div>}
    </React.Fragment>
}

export {RefundRequestDetail}