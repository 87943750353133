export const FallPrecautionOptions = [
    {
        description: "Bed in low position",
        name: "BedInLowPosition",
    },
    {
        description: "Non-slip footwear",
        name: "NonSlipFootwear",
    },
    {
        description: "Bed/Chair alarm activated",
        name: "BedChairAlarmActivated",
    },
    {
        description: "Fall Risk Identifiers Room Sign Utilized",
        name: "FallRiskIdentifiersRoomSignUtilized",
    },
    {
        description: "Fall Risk Identifier Wrist Band Utilized",
        name: "FallRiskIdentifierWristBandUtilized",
    },
    {
        description: "Fall Risk Identifier Chart Sign Utilized",
        name: "FallRiskIdentifierChartSignUtilized",
    },
];