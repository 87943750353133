export interface Policy{
    id:string;
    policyNumber:string;
    policyName:string;
    effectiveDate?:Date;
    reviewDate?:Date;
    category:string;
    effectiveProcedureCodes:string[];
    effectiveInsurances:string[];
    policySummary:string;
}

export function DefaultPolicy():Policy{
    return {id:'0', policyNumber:'', policyName:'', category:'', effectiveProcedureCodes:[], effectiveInsurances:[], policySummary:''}
}