import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleNoteAddModal } from "../../../../../app/slices/VerificationModuleSlice";
import { NotePostCommand } from "../../../../../services/verifyService/commands";
import { Note } from "../../../../../services/verifyService/model/verification/Note";
import { addNote } from "../../../../../services/verifyService/model/verification/VerificationSlice";
import { useSaveNoteMutation } from "../../../../../services/verifyService/verify-api";

function NoteAdd(){
    const dispatch = useAppDispatch();
    const modalOpen = useAppSelector((state) => state.verificationModule.NoteAddModal);
    const verificationId = useAppSelector((state) => state.verification.verification.id)
    const [note,setNote] = useState('');
    const[saveNoteToServer] = useSaveNoteMutation();
    const saveNote=()=>{
        let newNote = new Note();
        newNote.noteText = note; newNote.changeBy='You'; newNote.changeDate = new Date();
        dispatch(addNote(newNote));
        saveNoteToServer(new NotePostCommand(verificationId,newNote.noteText));
        setNote('');
    }

    return <React.Fragment>
        <Dialog open={modalOpen} onClose={()=>setNote('')}>
            <DialogTitle>Add Note</DialogTitle>
            <DialogContent>
                <TextField label='Note' value={note} onChange={(e)=>setNote(e.target.value)} multiline 
                    InputLabelProps={{shrink:true}} sx={{width:'400px',marginTop:'10px'}} minRows='3'/>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{dispatch(toggleNoteAddModal());setNote('');}}>Close</Button>
                <Button onClick={()=>{saveNote();dispatch(toggleNoteAddModal())}}>Submit</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export {NoteAdd}