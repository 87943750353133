import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react"
import { useAppSelector, useAppDispatch } from "../../../../../../../app/hooks";
import { useProfileLookupQuery, profileApi } from "../../../../../../../services/profileService/profile-api";
import { toggleIncidentAssignUserDialog } from "../../../../../../../app/slices/VarianceModuleSlice";
import { LookupIncidentAssignUserSearchResults } from "./LookupIncidentAssignUserSearchResults";


function LookupIncidentAssignUser(){
    const modalOpen = useAppSelector((state)=>state.varianceModule.IncidentAssignUserModalOpen);
    const dispatch = useAppDispatch();
    const [searchTerm,setSearchTerm] = useState('');
    const [skip, setSkip] = React.useState(true)
    const {data} = useProfileLookupQuery(searchTerm,{skip,});
 
    
    return <React.Fragment>
        <Dialog open={modalOpen} maxWidth='lg'>
            <DialogTitle>Assign User Lookup</DialogTitle>
            <DialogContent>
                <Stack direction='row' gap={1}>
                    <TextField placeholder="Search" size='small' value={searchTerm} onChange={(e) => {setSearchTerm(e.target.value); setSkip(true)}}/>
                    <Button variant="outlined" disabled={searchTerm === ''} 
                        onClick={()=>{dispatch(profileApi.util.invalidateTags(['profileLookup']));setSkip(false);}}>
                            <FontAwesomeIcon icon='search'/>
                    </Button>
                </Stack> 
                <br/>
                <LookupIncidentAssignUserSearchResults searchResults={data === undefined ? [] : data}/>   
            </DialogContent>
            <DialogActions>
                <Stack direction='row' justifyContent='flex-end'>
                    <Button variant='outlined' onClick={()=>{dispatch(toggleIncidentAssignUserDialog()); setSkip(true)}}>Close</Button>
                </Stack>
            </DialogActions>
        </Dialog>
        
    </React.Fragment>
}

export {LookupIncidentAssignUser}