import { Button, Dialog, DialogContent, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react"
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../app/hooks"
import { toggleCodeLookup } from "../../../../../app/slices/VerificationModuleSlice";
import { ProcedureLookupRequest } from "../../../../../services/verifyService/commands";
import { orgApi, useProcedureLookupQuery } from "../../../../../services/orgService/org-api";
import { ApptNote } from "./ApptNote";
import { CodeLookupResults } from "./CodeLookupResults";

function CodeLookup(){
    const verification = useAppSelector((state) => state.verification.verification);
    const dispatch = useDispatch();
    const isOpen = useAppSelector((state) => state.verificationModule.CodeLookupModal);
    const [searchTerm,setSearchTerm] = useState('');
    const [skip, setSkip] = React.useState(true);
    const {data} = useProcedureLookupQuery(new ProcedureLookupRequest(verification.center,searchTerm), {skip,});

    return <React.Fragment>
        <Dialog open={isOpen} maxWidth='xl'>
            <DialogContent>
                <ApptNote/>
                <Stack direction='row' gap={1}>
                    <TextField value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value); setSkip(true)}} size='small'
                        onKeyDown={(e)=>{if(e.key==='Enter') setSkip(false)}}
                        InputLabelProps={{shrink:false}} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}}/>
                    <Button variant='outlined' disabled={searchTerm===''} onClick={()=>{dispatch(orgApi.util.invalidateTags(['lookup'])); setSkip(false);}}>Search</Button>
                </Stack>
                <Stack direction='row'>
                    <CodeLookupResults procedures={data === undefined ? [] : data}/>
                </Stack>
                <Stack direction='row'justifyContent='flex-end' marginTop='10px'>
                    <Button variant='outlined' onClick={()=>dispatch(toggleCodeLookup())}>Close</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    </React.Fragment>
}

export {CodeLookup}