import { Button, Grid, Stack, Typography } from "@mui/material"
import React from "react"
import { PolicyGroupCard } from "../components/PolicyGroupCard"
import { Add } from "@mui/icons-material"
import { useNavigate } from "react-router"
import { useAppDispatch } from "../../../app/hooks"
import { resetPolicyDetails } from "../../../services/caseManService/model/policy/PolicySlice"


function PolicyHome(){
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    return <React.Fragment>
        <Stack direction='row' gap={2} marginTop='10px' justifyContent='space-between'>
            <Typography variant='h5' textAlign='left' className="psn-green" sx={{marginLeft:'10px'}}>Policy Groups</Typography>
            <Button startIcon={<Add/>} variant='outlined' sx={{marginRight:'10px'}} onClick={()=>{navigate('/PolicyDetail/new');dispatch(resetPolicyDetails())}}>Add Policy</Button>
        </Stack>
        <Stack direction='row' justifyContent='center' gap={2}>
            <PolicyGroupCard title='Arthroscopy' queryParam='Arthroscopy'/>
            <PolicyGroupCard title='Cochlear Implant' queryParam='Cochlear'/>
            <PolicyGroupCard title='Inspire Procedure' queryParam='Inspire'/>
            <PolicyGroupCard title='MILD Procedure' queryParam='Mild'/>
            <PolicyGroupCard title='Pain Pump' queryParam='PainPump'/>
        </Stack>
        <Stack direction='row' gap={2} justifyContent='center'>
            <PolicyGroupCard title='Computer Assisted' queryParam='ComputerAssisted'/>
            <PolicyGroupCard title='Spine' queryParam='Spine'/>
            <PolicyGroupCard title='Stimulators' queryParam="Stimulators"/>
            <PolicyGroupCard title='Total Joints' queryParam='TotalJoint'/>
        </Stack>
    </React.Fragment>
}
export {PolicyHome}