import React from "react"
import { useAppSelector } from "../../../../../app/hooks";
import { Alert, Grid, Stack, TextField, Typography } from "@mui/material";
import { formatNumber } from "@progress/kendo-intl";
import { MedNecProcDetailModal } from "./MedNecProcDetailModal";

function CaseManCptList(){
    const caseManage = useAppSelector((state)=>state.caseManagement.caseManagement);

    return <React.Fragment>
        <Stack marginLeft='10px' marginTop='10px'>
            <Typography variant='h5' className='psn-green' textAlign='left'>Procedure Codes</Typography>
        </Stack>
        <Stack>
        {caseManage.codingDetails.cptCodes.length === 0 && 
            <Alert className="mt-2" color='warning'>No Codes have been added to this case</Alert>
        }
        {caseManage.codingDetails.cptCodes.length > 0 &&
        <div>
        <Grid container spacing={1} marginLeft={'5px'}>
            <Grid item sm={1}><Typography variant='body1' color='gray'></Typography></Grid>
            <Grid item sm={.75}><Typography variant='body1' color='gray'>Priority</Typography></Grid>
            <Grid item sm={1}><Typography variant='body1' color='gray'>Code</Typography></Grid>
            <Grid item sm={4}><Typography variant='body1' color='gray'>Description</Typography></Grid>
            <Grid item sm={1.5}><Typography variant='body1' color='gray'>Status</Typography></Grid>
            <Grid item sm={3}><Typography variant='body1' color='gray'>Orig. Dx</Typography></Grid>
        </Grid>
        {caseManage.codingDetails.cptCodes.map(i => (
        <Grid container spacing={1} marginLeft={'5px'} marginBottom={'8px'} key={i.priority}>
            <Grid item sm={1}><MedNecProcDetailModal priority={i.priority} status={i.medNecReview}/></Grid>
            <Grid item sm={.75}><TextField value={i.priority} fullWidth disabled/></Grid>
            <Grid item sm={1}><TextField value={i.code} fullWidth disabled/></Grid>
            <Grid item sm={4}><TextField value={i.description} fullWidth disabled/></Grid>
            <Grid item sm={1.5}><TextField value={i.medNecReview===''?'No Info':i.medNecReview} fullWidth disabled/></Grid>
            <Grid item sm={3}><TextField value={i.diagnosisCodes} fullWidth disabled/></Grid>
        </Grid>
        ))}
        </div>
        }
        </Stack>
    </React.Fragment>
}
export{CaseManCptList}