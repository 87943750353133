export const OtherOptions = [
    {
        description: "Break Scrub for Non-Patient Care Needs",
        name: "BreakScrubForNonPatientCareNeeds",
    },
    {
        description: "Operating Room Fire",
        name: "OperatingRoomFire",
    },
    {
        description: "Procedure Delayed",
        name: "ProcedureDelayed",
    },
    {
        description: "Prophylactic Antibiotics Delayed/Not Given",
        name: "ProphylacticAntibioticsDelayedNotGiven",
    },
];