import { Stack, Typography} from "@mui/material";
import { formatDate } from "@telerik/kendo-intl";
import React from "react"
import { useParams } from "react-router";
import { useCaseFindQuery } from "../../../../../services/scheduleService/schedule-api";
import './Header.css'
import { PatientDetails } from "./PatientDetails";

type ownProps ={
    visitId:string;
    centerId:string;
}
function Header(props:ownProps){
    const {id} = useParams();
    const schedule = useCaseFindQuery(id!);
    
    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{marginBottom:'5px'}}>
            <Typography variant='h6' color='gray'>{schedule.data?.lastName + ', ' + schedule.data?.firstName}
                <PatientDetails/>        
            </Typography>
            <Typography variant='h6' color='gray'>{schedule.data?.accountNumber}</Typography>
            <Typography variant='h6' color='gray'>{'Birth Date: ' + formatDate(new Date(schedule.data?.birthDate ?? ''),'MM/dd/yyyy')}</Typography>
            <Typography variant='h6' color='gray'>{'Service Date: ' + formatDate(new Date(schedule.data?.serviceDate ?? ''),'MM/dd/yyyy')}</Typography>
            {/* <CommandBar/> */}
        </Stack>
        

    </React.Fragment>
}

export {Header}