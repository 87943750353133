import { Switch } from "@progress/kendo-react-inputs"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editCaseManDocumentRequest } from "../../../../../services/caseManService/model/caseMan/CaseManagementSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";

function PsychEvalRequest(){
    const dispatch = useAppDispatch();
    const caseMan = useAppSelector((state) => state.caseManagement.caseManagement);

    return <React.Fragment>
        <div>
            <label>Psych Eval</label><br/>
            <Switch value={caseMan.documentRequest?.psychiatricEvaluation}
                checked={caseMan.documentRequest?.psychiatricEvaluation}
                onChange={(e)=>dispatch(editCaseManDocumentRequest(new ReduxUpdate('psychiatricEvaluation',e.target.value)))}/>
        </div>
    </React.Fragment>
}
export {PsychEvalRequest}