import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { CallPostCommand, EligibilitySummaryRequest, NotePostCommand, VerificationLookupRequest, VerificationSaveCommand, WorklistRequest,EligibilitySummaryResponse, ApprovalUpdateCommand, VerificationDocRemoveCommand, EligibilityDataRequest } from './commands';
import { SameDayScheduledView, Verification, Worklist } from './model';
import { CallLog } from './model/verification/CallLog';
import {getToken} from '../../utils/AdalConfig';
import {VerifyService} from '../../utils/ServiceLocator'
import { Approval } from './model/verification/Approval';
import { VerificationDocument } from './model/verification/VerificationDocument';
import { SameDayScheduledCommand } from './commands/SameDayScheduledCommand';

export const verifyApi = createApi({
    reducerPath:'verifyApi',
    baseQuery: fetchBaseQuery({
        baseUrl: VerifyService,
        prepareHeaders:(headers,{getState}) => {
            const token = getToken();
            headers.set('Authorization', 'Bearer ' + token);
            headers.set('content-type', 'application/json')
            return headers;
        }
    }),
    tagTypes:['worklist','verification','lookup','eligibilitySummary'],
    endpoints:(builder) => ({
        worklist:builder.query<Worklist[],WorklistRequest>({
            query:(request:WorklistRequest) => 'worklist?type=' + request.type + '&center=' + request.center,
            providesTags:['worklist']
        }),
        verificationFind:builder.query<Verification,string>({
            query:(id) => 'verification?request=' + id,
            providesTags:['verification']
        }),
        verificationLookup:builder.query<Worklist[],VerificationLookupRequest>({
            query:(request:VerificationLookupRequest) => 'verificationLookup?centerId=' + request.centerId + '&searchCriteria='+request.searchCriteria,
            providesTags:['lookup']
        }),
        eligibilitySummary:builder.query<EligibilitySummaryResponse,EligibilitySummaryRequest>({
            query:(request) => 'eligibilitySummary?partitionKey=' + request.partitionKey + '&payerId=' + request.payerId,
        }),
        sameDayScheduledFind:builder.query<SameDayScheduledView[],SameDayScheduledCommand>({
            query:(command) => 'scheduledSameDay?centerId=' + command.centerId + '&serviceDate=' + command.serviceDate + '&physicianName=' + command.physicianName,
        }),
        eligibilityData:builder.mutation<string,EligibilityDataRequest>({
            query:(body) => 
                {return {url:'eligibilityData', method:'POST', body:body,}}
        }),
        saveVerification:builder.mutation<Verification,VerificationSaveCommand>({
            query:(body) =>{
                return {url:'Verification', method:'POST', body:body,}
            },
            invalidatesTags:['worklist','verification']
        }),
        saveNote:builder.mutation<void,NotePostCommand>({
          query:(body) => {
            return {url:'Note',method:'POST',body:body,}
          },        
        }),
        saveCallLog:builder.mutation<CallLog, CallPostCommand>({
            query:(body) => {
                return {url:'CallAdd',method:'POST',body:body,}
            },
        }),
        saveApproval:builder.mutation<Approval,ApprovalUpdateCommand>({
            query:(body) =>{
                return {url:'Approval',method:'POST',body:body,}
            },
        }),
        verifyDocRemove:builder.mutation<VerificationDocument,VerificationDocRemoveCommand>({
            query:(body) =>{
                return {url:'documentRemove',method:'POST',body:body,}
            }
        }),
    })
})

export const {useWorklistQuery, useVerificationFindQuery, useVerificationLookupQuery,
     useSaveVerificationMutation, useSaveNoteMutation, useSaveCallLogMutation,
     useEligibilitySummaryQuery,useSaveApprovalMutation, useVerifyDocRemoveMutation,
     useEligibilityDataMutation, useSameDayScheduledFindQuery} = verifyApi;