import { MenuItem, TextField } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editAccountPrimaryPayer } from "../../../../../services/accountService/model/account/AccountSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";

function AccountCalcTypeAttr(){
    const dispatch = useAppDispatch();
    const account = useAppSelector((state) => state.account.account);

    return <React.Fragment>
        <TextField label='Calc Type' select value={account.primaryPayerInfo.calcPayer} sx={{width:'225px'}}
            onChange={(e)=>dispatch(editAccountPrimaryPayer(new ReduxUpdate('calcPayer',e.target.value)))}>
            <MenuItem value='Unknown'>Unknown</MenuItem>
            <MenuItem value='Aetna'>Aetna</MenuItem>
            <MenuItem value='Aetna Medicare'>Aetna Medicare</MenuItem>
            <MenuItem value='Aetna Medicaid'>Aetna Medicaid</MenuItem>
            <MenuItem value='Amerigroup'>Amerigroup</MenuItem>
            <MenuItem value='BCBS BAV'>BCBS BAV</MenuItem>
            <MenuItem value='BCBS Blue Essential'>BCBS Blue Essential</MenuItem>
            <MenuItem value='BCBS HMO'>BCBS HMO</MenuItem>
            <MenuItem value='BCBS Medicaid'>BCBS Medicaid</MenuItem>
            <MenuItem value='BCBS Medicare'>BCBS Medicare</MenuItem>
            <MenuItem value='BCBS NWB'>BCBS NWB</MenuItem>
            <MenuItem value='BCBS PPO'>BCBS PPO</MenuItem>
            <MenuItem value='BCBS Trad'>BCBS Trad</MenuItem>
            <MenuItem value='Bundled'>Bundled</MenuItem>
            <MenuItem value='Care N Care'>Care N Care</MenuItem>
            <MenuItem value='Cigna'>Cigna</MenuItem>
            <MenuItem value='Cigna Healthspring'>Cigna Healthspring</MenuItem>
            <MenuItem value='Cigna Medicare'>Cigna Medicare</MenuItem>
            <MenuItem value='Corvel'>Corvel</MenuItem>
            <MenuItem value='Department of Labor'>Department of Labor</MenuItem>
            <MenuItem value='Endeavor'>Endeavor</MenuItem>
            <MenuItem value='HD Research'>HD Research</MenuItem>
            <MenuItem value='Humana'>Humana</MenuItem>
            <MenuItem value='Humana Medicare'>Humana Medicare</MenuItem>
            <MenuItem value='LOP'>LOP</MenuItem>
            <MenuItem value='Medicaid'>Medicaid</MenuItem>
            <MenuItem value='Medicare'>Medicare</MenuItem>
            <MenuItem value='MPower'>MPower/Triple AIM</MenuItem>
            <MenuItem value='Multiplan'>Multiplan</MenuItem>
            <MenuItem value='Multiplan Comm'>Multiplan Comm</MenuItem>
            <MenuItem value='Multiplan WC'>Multiplan WC</MenuItem>
            <MenuItem value='Oscar'>Oscar</MenuItem>
            <MenuItem value='Other Commercial'>Other Commercial</MenuItem>
            <MenuItem value='Research Source'>Research Source</MenuItem>
            <MenuItem value='Superior Health Plan'>Superior Health Plan</MenuItem>
            <MenuItem value='Surgery Plus'>Surgery Plus</MenuItem>
            <MenuItem value='Self-Pay'>Self-Pay</MenuItem>
            <MenuItem value='SWHP Comm'>SWHP Comm</MenuItem>
            <MenuItem value='SWHP Managed Medicare'>SWHP Managed Medicare</MenuItem>
            <MenuItem value ='SWHR'>SWHR</MenuItem>
            <MenuItem value='Three Rivers'>Three Rivers</MenuItem>
            <MenuItem value='Tricare'>Tricare</MenuItem>
            <MenuItem value='TriWest'>TriWest</MenuItem>
            <MenuItem value='UHC'>UHC</MenuItem>
            <MenuItem value='UHC Medicaid'>UHC Medicaid</MenuItem>
            <MenuItem value='UHC Medicare'>UHC Medicare</MenuItem>
            <MenuItem value='Wellcare'>WellCare</MenuItem>
            <MenuItem value='Wellmed'>Wellmed</MenuItem>
            <MenuItem value='Workers Comp'>Workers Comp</MenuItem>
        </TextField>
    </React.Fragment>
}
export {AccountCalcTypeAttr}