import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { toggleCenterAccessModal } from "../../../../app/slices/ProfileModuleSlice";
import { AccessAddCommand } from "../../../../services/profileService/commands";
import { Profile } from "../../../../services/profileService";
import { useAddAccessMutation } from "../../../../services/profileService/profile-api"; 
import { GridButton } from "../../../../components";

interface ownProps extends GridCellProps{
    profile:Profile;
}

function CenterAccessEditCommandCell(props:ownProps){
    const dispatch = useAppDispatch();
    const [addAccess] = useAddAccessMutation();
    const handleSave=()=>{
        const command = new AccessAddCommand(props.profile?.identity, props.dataItem.code, props.dataItem.name)
        addAccess(command);
        dispatch(toggleCenterAccessModal());
    }
    return <React.Fragment>
        <GridButton className='ms-1 mt-1' variant="outlined" color='primary' onClick={()=>handleSave()}>Select</GridButton>
    </React.Fragment>
}

export {CenterAccessEditCommandCell}