import { Alert, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { toggleVerifyLookupModal } from "../../../../app/slices/VerificationModuleSlice";
import { VerificationLookupRequest } from "../../../../services/verifyService/commands";
import { useVerificationLookupQuery } from "../../../../services/verifyService/verify-api";
import { Loading } from "../../../shared/features/Loading";
import { LookupResultsGrid } from "./LookupResultsGrid";
import { useParams } from "react-router";


type ownProps = {
    searchText: string;
    skip: boolean;
}
function LookupResults(props: ownProps){
    const [skip, setSkip] = React.useState(props.skip)
    const {center} = useParams();
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.verificationModule.VerifyLookupModal);
    const {data, isFetching} = useVerificationLookupQuery(new VerificationLookupRequest(center!,props.searchText),{skip,})
    useEffect(()=>setSkip(props.skip),[props.skip]);


    return <React.Fragment>
        <Dialog open={isOpen} maxWidth='xl' /* onClosed={()=>setSkip(true)} onOpened={()=>setSkip(false)} */>
            <DialogContent>
                {(isFetching === false && data?.length === 0) &&
                    <Alert>No results found</Alert>
                }
                {isFetching === false ?
                    <LookupResultsGrid data={data ?? []}/>:
                    <Loading/>
                }

            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={()=>{dispatch(toggleVerifyLookupModal()); setSkip(true);}}>Close</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export {LookupResults}