import moment from "moment";
import { RadRequest } from '../model/radRequest/RadRequest';

export class PostRadRequestCommand{
    requestView : RadRequest;

    constructor(radRequest:RadRequest){
        this.requestView = radRequest;
        if(!moment(this.requestView.patient.birthDate).isValid)
            this.requestView.patient.birthDate = null;
        if(!moment(this.requestView.requestByDate).isValid)
            this.requestView.requestByDate = null;
    }
}