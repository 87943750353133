import React, { useState } from "react"
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { ColumnMenu } from "../../../../utils/ColumnMenu";
import { process, State } from "@progress/kendo-data-query";
import { Schedule } from "../../../../services/scheduleService";
import { RefundInitiateCommand } from "./RefundInitiateCommand";

type ownProps = {
    data:Schedule[];
}
function RefundInitiateResults(props:ownProps){
    const [dataState, setDataState] = useState<State>({});
    const mapCases=():Schedule[]=>{return props.data.map(i => ({...i, serviceDate:new Date(i.serviceDate ?? '01/01/1900')}))}
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };


    return <React.Fragment>
        <Grid data={process(mapCases()??[].slice(0),dataState)}{...dataState} 
            onDataStateChange={dataStateChange} style={{height:'500px'}}>
                <Column cell={RefundInitiateCommand} columnMenu={ColumnMenu} width='100px'/>
                <Column title='Account' field='accountNumber' columnMenu={ColumnMenu} width='175px'/>
                <Column title='Center' field='center' columnMenu={ColumnMenu} width='150px'/>
                <Column title='Last Name' field='lastName' columnMenu={ColumnMenu} width='200px'/>
                <Column title='First Name' field='firstName' columnMenu={ColumnMenu} width='200px'/>
                <Column title='Service Date' field='serviceDate' columnMenu={ColumnMenu} width='200px' format='{0:d}'/>
        </Grid>
    </React.Fragment>
}

export {RefundInitiateResults}