import React, { useState } from "react";
import {
  Editor,
  EditorChangeEvent,
  EditorTools,
} from "@progress/kendo-react-editor";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { editVariance } from "../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";
import { DirectorResponseInstructions } from "./DirectorResponseInstuctions";


function DirectorResponseEditor(props: { canEdit: boolean }) {
  const dispatch = useAppDispatch();
  const description = useAppSelector(
    (state) => state.varianceRequest.varianceRequest.directorResponse
  );
  const [value, setValue] = useState(description);
  const onChange = (e: EditorChangeEvent) => {
    setValue(e.html);
    dispatch(
      editVariance(new ReduxUpdate('directorResponse', e.html))
    );
  };
  const {
    Bold,
    Italic,
    Underline,
    AlignLeft,
    AlignRight,
    AlignCenter,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    InsertTable,
    Undo,
    Redo,
  } = EditorTools;
  return (
    <React.Fragment>
      <Editor
        tools={[
          [Bold, Italic, Underline],
          [Undo, Redo],
          [AlignLeft, AlignCenter, AlignRight],
          [InsertTable],
          [OrderedList, UnorderedList, Indent, Outdent],
          [DirectorResponseInstructions],
        ]}
        contentStyle={{
          height: 500,
        }}
        value={value}
        onChange={onChange}
      />
    </React.Fragment>
  );
}

export { DirectorResponseEditor };
