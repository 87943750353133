import React from "react"
import { CallList } from "./CallList"
import { CallLog } from "./CallLog"
import { PatientContact } from "./PatientContact"

function FinancialOrientation(){
    return <React.Fragment>
        <hr/>
        <PatientContact/>
        <hr/>
        <CallList/>
        <CallLog/>
    </React.Fragment>
}

export {FinancialOrientation}