export class DrgEditCommand{
    feeScheduleId:string;
    code:string;
    type:string;
    title:string;
    weight:number;
    arithmeticMeanLOS: number;
    grouperCode:string;

    constructor(feeScheduleId:string, code:string, type:string, title:string, weight:number, arithmeticMeanLOS:number, grouperCode:string){
        this.feeScheduleId = feeScheduleId;
        this.code = code;
        this.type = type;
        this.title = title;
        this.weight = weight;
        this.arithmeticMeanLOS = arithmeticMeanLOS;
        this.grouperCode = grouperCode;
    }
}