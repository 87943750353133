import React from "react"
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { scheduleApi, useScheduleSaveMutation } from "../../../../../services/scheduleService/schedule-api";
import { ProcedureMapper } from "./ProcedureMapper";
import { SchedulePostCommand } from "../../../../../services/scheduleService/commands";
import { Button, Stack } from "@mui/material";
import { Cancel, KeyboardDoubleArrowLeft, Save } from "@mui/icons-material";
import { ContainsAtLeastOneService, IsSavingDisabled } from "../../../specs";
import { useCenterFindQuery } from "../../../../../services/orgService/org-api";
import { CancelCase } from "./CancelCase";

type ownProps={
    center:string;
}

function UpdateCommandBar(props:ownProps){
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const schedule = useAppSelector((state)=>state.schedule.schedule);
    const readonly = !useCenterFindQuery(props.center).data?.allowManualScheduling;
    const [saveSchedule] = useScheduleSaveMutation();
    const save=()=>{
        let newSched = {...schedule};
        newSched.center = props.center;
        newSched.procedures =  ProcedureMapper(newSched.procedures);
        let command = new SchedulePostCommand(newSched);
        saveSchedule(command).unwrap()
        .then(i => {dispatch(scheduleApi.util.invalidateTags(['scheduledCases']))})
        .then(i => {navigate(-1)});
    }

    return <React.Fragment>
        <Stack direction='row' gap={1}>
            <Button variant='outlined' onClick={()=>navigate(-1)} color='success' startIcon={<KeyboardDoubleArrowLeft/>}>Go Back</Button>
            {!readonly &&
            <Stack direction='row' gap={1}>
                <Button variant='outlined' onClick={()=>save()} startIcon={<Save/>} disabled={new IsSavingDisabled().IsSatisfiedBy(schedule)}>Save</Button>
                <CancelCase/>
            </Stack>
            }
        </Stack>
    </React.Fragment>
}

export {UpdateCommandBar}