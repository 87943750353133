import { Box, Stack } from "@mui/material";
import React from "react";
import { IncidentSubTypes } from "../IncidentSubTypes";
import { FallOptions } from "./FallOptions";
import { FallPriorTo } from "./FallPriorTo/FallPriorTo";
import { FallPostAssessment } from "./FallPostAssessment/FallPostAssessment";

function FallDetails(props: {canEdit: boolean}) {
  return (
    <React.Fragment>
      <Box mt={2} mb={2} sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
        <Stack
          direction="row"
          spacing={3}
          justifyContent="space-between"
          alignItems="top"
        >
          <IncidentSubTypes options={FallOptions} formName="Fall Details" canEdit={props.canEdit}/> 
          <FallPriorTo canEdit={props.canEdit} />
          <FallPostAssessment canEdit={props.canEdit}/>
        </Stack>
      </Box>
    </React.Fragment>
  );
}

export { FallDetails };
