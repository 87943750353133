import { Button } from "@mui/material"
import { Stack } from "@mui/system"
import React from "react"
import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "../../../../../app/hooks";
import { radiologyApi } from "../../../../../services/radiologyService/radiology-api";

type ownProps = {
    save:Function;
}
function CommandBar(props:ownProps){
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {center} = useParams();
    return <React.Fragment>
        <Stack direction='row' justifyContent='flex-end' marginBottom='10px' gap={2}>
            <Button color='success' variant='outlined' 
                onClick={()=>{navigate('/RadHome/' + center); dispatch(radiologyApi.util.invalidateTags(['radWorklist']))}}>Back</Button>
            <Button variant="outlined" onClick={()=>props.save()}>Save</Button>
        </Stack>
    </React.Fragment>
}

export {CommandBar}