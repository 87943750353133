import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react"
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleFeeScheduleItemAddModal } from "../../../../../app/slices/ContractModuleSlice";
import { DrgAddCommand } from "../../../../../services/contractService/commands";
import { Drg } from "../../../../../services/contractService/model";
import { useDrgAddMutation } from "../../../../../services/contractService/contract-api";

function DrgItemAdd(){
    const isOpen = useAppSelector((state) => state.contractModule.feeScheduleItemAddModal)
    const {feeScheduleId} = useParams();
    const dispatch = useAppDispatch();
    const [drg,setDrg] = useState(new Drg());
    const [saveDrg] = useDrgAddMutation();
    const handleChange=(e:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>)=>{
        const {name,value} = e.target;
        let newdrg:Drg = drg;
        newdrg = ({...newdrg,[name]:value});
        setDrg(newdrg);
    }
    const save=()=>{
        let command = new DrgAddCommand(feeScheduleId!,drg.code,drg.type,drg.title,drg.weight,drg.arithmeticMeanLOS,drg.grouperCode);
        saveDrg(command);
    }
    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogTitle><Typography className="pen-green">Add A Drg Code</Typography></DialogTitle>
            <DialogContent>
                <Stack direction='row' gap={1} marginTop='10px'>
                    <TextField label='DRG #' name='code' value={drg.code} onChange={(e)=>handleChange(e)}
                        size='small' InputLabelProps={{shrink:true}}/>
                </Stack>
                <Stack direction='row' marginTop='10px'>
                    <TextField label='Description' name='title' value={drg.title} onChange={(e) => handleChange(e)}
                        size='small' InputLabelProps={{shrink:true}} fullWidth multiline/>
                </Stack>
                <Stack direction='row' marginTop='10px' gap={1}>
                    <TextField label='Weight' name='weight' value={drg.weight} onChange={(e)=> handleChange(e)}
                        size='small' InputLabelProps={{shrink:true}}/>
                    <TextField label='ALOS' name='arithmeticMeanLOS' value={drg.arithmeticMeanLOS} onChange={(e)=>handleChange(e)}
                        size='small' InputLabelProps={{shrink:true}}/>
                    <TextField label='Base Rate Pointer' name='grouperCode' value={drg.grouperCode} onChange={(e)=>handleChange(e)}
                        size='small' InputLabelProps={{shrink:true}}/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={()=>dispatch(toggleFeeScheduleItemAddModal())}>Cancel</Button>
                <Button onClick={()=>{save(); dispatch(toggleFeeScheduleItemAddModal());}}>Save</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export {DrgItemAdd}