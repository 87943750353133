import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { CenterAddCommand, CenterRemoveCommand, ContractQuickAddCommand, ContractUpdateCommand, FeeScheduleAddCommand, 
    DrgFeeScheduleEditCommand, DrgFeeScheduleRemoveCommand, GrouperAddCommand, GrouperEditCommand, GrouperDeleteCommand,
    DrgRequest, DrgEditCommand, DrgAddCommand, CarveOutAddCommand, CarveOutEditCommand, CptAddCommand, CptEditCommand, 
    CarveOutDeleteCommand, CptSearchRequest, FeeScheduleDeleteCommand, FeeSchedulePostCommand, RulePostCommand, RuleDeleteCommand } from './commands';
import { CarveOut, Center, Contract, Cpt, Drg, Groupers,FeeSchedule, BaseRule } from '../contractService/model';
import { getToken } from '../../utils/AdalConfig';
import { ContractService } from '../../utils/ServiceLocator';

export const contractApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: ContractService,
        prepareHeaders:(headers, {getState}) => {
            const token = getToken();
            headers.set('Authorization', 'Bearer ' + token);
            headers.set('content-type', 'application/json');
            return headers;           
        }
    }),
    tagTypes:['contract','contractList','feeSchedule','feeScheduleList','drgList','drg', 'cptList', 
        'cpt', 'carveOutList','carveOut','cptSearch','feeScheduleItemCount','contractsForCalculator',
        'rule','ruleList','contractLookup'],
    endpoints:(builder) => ({
        quickAdd:builder.mutation<Contract,ContractQuickAddCommand>({
            query:(body) => {
                return {url:'QuickAdd',method:'POST',body:body,}
            },
            invalidatesTags:['contractList']
        }),
        contractList:builder.query<Contract[],void>({
            query:() => 'contractList',
            providesTags:['contractList']
        }),
        contractFind:builder.query<Contract,string>({
            query:(id) => 'contract?request=' + id,
            providesTags:['contract']
        }),
        contractsForCalculator:builder.query<Contract[],string>({
            query:(id) => 'contractsForCalculator?request=' + id,
            providesTags:['contractsForCalculator']
        }),
        contractLookup:builder.query<Contract[],string>({
            query:(id) => 'contractLookup?type=' + id,
            providesTags:['contractLookup']
        }),
        feeScheduleFind:builder.query<FeeSchedule,string>({
            query:(id) => 'feeschedule?id=' + id,
            providesTags:['feeSchedule']
        }),
        feeScheduleListFind:builder.query<FeeSchedule[],string>({
            query:(id) => 'feeschedulelist?request=' + id,
            providesTags:['feeScheduleList']
        }),
        feeScheduleItemCount:builder.query<number,string>({
            query:(id) =>'feeScheduleItemCount?request=' + id,
            providesTags:['feeScheduleItemCount']
        }),
        drgList:builder.query<Drg[],string>({
            query:(id)=> 'drgList?feeScheduleId=' + id,
            providesTags:['drgList']
        }),
        drgFind:builder.query<Drg,DrgRequest>({
            query:(id)=> 'drg?feeScheduleId=' + id.feeScheduleId + '&code=' + id.code,
            providesTags:['drg']
        }),
        cptList:builder.query<Cpt[],string>({
            query:(id) => 'cptList?feeScheduleId=' + id,
            providesTags:['cptList']
        }),
        cptFind:builder.query<Cpt,string>({
            query:(id)=> 'cpt?code=' + id,
            providesTags:['cpt']
        }),
        cptSearch:builder.query<Cpt[],CptSearchRequest>({
            query:(id)=>'cptSearch?feeScheduleId=' + id.feeScheduleId + '&searchCriteria=' + id.searchCriteria,
            providesTags:['cptSearch']
        }),
        carveOutList:builder.query<CarveOut[],string>({
            query:(id)=>'carveOutList?request=' + id,
            providesTags:['carveOutList']
        }),
        carveOutFind:builder.query<CarveOut,string>({
            query:(id)=>'carveOut?request=' + id,
            providesTags:['carveOut']
        }),
        contractUpdate:builder.mutation<Contract,ContractUpdateCommand>({
            query:(body) =>{
                return {url:'Contract',method:'POST',body:body,}
            }
        }),
        centerAttach:builder.mutation<Center,CenterAddCommand>({
            query:(body) => {
                return {url:'CenterAttach',method:'POST',body:body,}
            }
        }),
        centerDetach: builder.mutation<Center,CenterRemoveCommand>({
            query:(body) => {
                return {url:'CenterDetach',method:'POST',body:body,}
            }
        }),
        FeeScheduleAdd:builder.mutation<FeeSchedule,FeeScheduleAddCommand>({
            query:(body) => {
                return {url:'FeeScheduleAdd',method:'POST',body:body,}
            }, invalidatesTags:['feeScheduleList']
        }),
        drgFeeScheduleRemove:builder.mutation<FeeSchedule,DrgFeeScheduleRemoveCommand>({
            query:(body) => {
                return {url:'DrgFeeScheduleRemove',method:'POST',body:body,}
            }
        }),
        drgFeeScheduleEdit:builder.mutation<FeeSchedule,DrgFeeScheduleEditCommand>({
            query:(body)=>{
                return {url:'DrgFeeScheduleEdit',method:'POST',body:body,}
            }
        }),
        grouperAdd:builder.mutation<Groupers,GrouperAddCommand>({
            query:(body)=>{
                return {url:'grouperAdd',method:'POST',body:body,}
            }
        }),
        grouperEdit:builder.mutation<Groupers,GrouperEditCommand>({
            query:(body) =>{
                return {url:'grouperEdit',method:'POST',body:body,}
            }
        }),
        grouperDelete:builder.mutation<Groupers,GrouperDeleteCommand>({
            query:(body) => {
                return {url:"grouperDelete",method:'POST',body:body,}
            }
        }),
        drgEdit:builder.mutation<Drg,DrgEditCommand>({
            query:(body) => {
                return {url:'DrgEdit',method:'POST', body:body,}
            },invalidatesTags:['drgList']
        }),
        drgAdd:builder.mutation<Drg,DrgAddCommand>({
            query:(body)=>{
                return {url:'DrgAdd',method:'POST',body:body,}
            },invalidatesTags:['drgList']        
        }),
        cptEdit:builder.mutation<Cpt,CptEditCommand>({
            query:(body) =>{
                return {url:'cptEdit',method:'POST',body:body,}
            },invalidatesTags:['cptList','cptSearch']
        }),
        cptAdd:builder.mutation<Cpt,CptAddCommand>({
            query:(body) => {
                return{url:'cptAdd',method:'POST',body:body,}
            },invalidatesTags:['cptList','cptSearch']
        }),
        cptDelete:builder.mutation<Cpt,string>({
            query:(body) => {
                return{url:'cptDelete?request=' + body, method:'POST'}
            },invalidatesTags:['cptList','cptSearch']
        }),
        carveOutAdd:builder.mutation<CarveOut,CarveOutAddCommand>({
            query:(body)=>{
                return {url:'CarveOutAdd',method:'POST',body:body,}
            },invalidatesTags:['carveOutList','carveOut']
        }),
        carveOutEdit:builder.mutation<CarveOut,CarveOutEditCommand>({
            query:(body)=>{
                return {url:'CarveOutEdit',method:'POST',body:body,}
            },invalidatesTags:['carveOutList','carveOut']
        }),
        carveOutDelete:builder.mutation<CarveOut, CarveOutDeleteCommand>({
            query:(body) => {
                return{url:'carveOutDelete',method:'POST',body:body,}
            }, invalidatesTags:['carveOutList']
        }),
        feeScheduleDelete:builder.mutation<FeeSchedule,FeeScheduleDeleteCommand>({
            query:(body) => {
                return {url:'feeScheduleDelete',method:'POST',body:body,}
            },invalidatesTags:['feeScheduleList']
        }),
        feeScheduleUpdate:builder.mutation<FeeSchedule,FeeSchedulePostCommand>({
            query:(body) =>{
                return{url:'FeeSchedule',method:'POST',body:body,}
            }, invalidatesTags:['feeScheduleList','feeSchedule']
        }),
        ruleSave:builder.mutation<string,RulePostCommand>({
            query:(body) => {
                return{url:'rule',method:'POST',body:body,}
            }, invalidatesTags:['rule']
        }),
        ruleList:builder.query<BaseRule[],string>({
            query:(id) => 'rulesList?contractId=' + id,
            providesTags:['ruleList']
        }),
        ruleFind:builder.query<BaseRule,string>({
            query:(id) => 'rule?ruleId=' + id,
            providesTags:['rule']
        }),
        ruleDelete:builder.mutation<BaseRule,RuleDeleteCommand>({
            query:(body) =>{
                return{url:'ruleDelete',method:'POST',body:body,}
            },invalidatesTags:['ruleList','rule']
        })
    })
})

export const {useQuickAddMutation, useContractListQuery, useContractFindQuery, 
    useCenterAttachMutation, useCenterDetachMutation, useContractUpdateMutation,
    useFeeScheduleAddMutation,useDrgFeeScheduleEditMutation,useDrgFeeScheduleRemoveMutation,
    useFeeScheduleListFindQuery, useFeeScheduleFindQuery, useGrouperAddMutation,
    useGrouperEditMutation, useGrouperDeleteMutation,
    useDrgFindQuery, useDrgListQuery, useDrgAddMutation, useDrgEditMutation,
    useCptListQuery, useCptFindQuery, useCptAddMutation, useCptEditMutation,
    useCptDeleteMutation, useCarveOutAddMutation, useCarveOutEditMutation,
    useCarveOutFindQuery, useCarveOutListQuery, useCarveOutDeleteMutation,
    useCptSearchQuery, useFeeScheduleItemCountQuery,useFeeScheduleDeleteMutation,
    useFeeScheduleUpdateMutation,useContractsForCalculatorQuery, useRuleSaveMutation,
    useRuleListQuery, useRuleFindQuery, useRuleDeleteMutation, useContractLookupQuery} = contractApi;