import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import React from "react"
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { toggleEncounterTab } from "../../../app/slices/ScheduleModuleSlice";
import { useCaseFindQuery } from "../../../services/scheduleService/schedule-api";
import { Header } from "../features/detail/Header"
import { Table } from "@mui/material";
import { Procedures } from "../features/detail/procedures/Procedures";
import { Insurances } from "../features/detail/insurances/Insurances";
import { ContactInfo } from "../features/detail/contact/ContactInfo";
import { ProcedureDetail } from "../features/detail/procedures/ProcedureDetail";
import { InsuranceDetail } from "../features/detail/insurances/InsuranceDetail";
import { InsuranceCreate } from "../features/detail/insurances/InsuranceCreate";
import { Loading } from "../../shared";
import { ApptNote } from "../features/detail/apptNote/ApptNote";

function ScheduleDetail(){
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const {isLoading} = useCaseFindQuery(id!);
    const tabLocation = useAppSelector((state) => state.scheduleModule.EncounterTab);
    const toggleTab = (e: TabStripSelectEventArguments) => dispatch(toggleEncounterTab(e.selected));

    
    return <React.Fragment>
        
        {isLoading ? <Loading/> : <div>
        <Header center={id!.split('-')[0]} isCreate={false}/>
        <TabStrip selected={tabLocation} onSelect={toggleTab}>
                    <TabStripTab title='Services'>
                        <Table/>
                        <Procedures center={id!.split('-')[0]}/>
                    </TabStripTab>
                    <TabStripTab title='Insurance'>
                        <Table/>
                        <Insurances center={id!.split('-')[0]}/>
                    </TabStripTab>
                    <TabStripTab title='Contact'>
                        <Table/>
                        <ContactInfo center={id!.split('-')[0]}/>
                    </TabStripTab>
                    <TabStripTab title='Notes'>
                        <Table/>
                        <ApptNote center={id!.split('-')[0]}/>
                    </TabStripTab>
                </TabStrip>
                <ProcedureDetail center={id!.split('-')[0]}/>
                <InsuranceDetail center={id!.split('-')[0]}/>
                <InsuranceCreate center={id!.split('-')[0]}/>
        </div> }
    </React.Fragment>
}

export {ScheduleDetail}