import { Button } from "@mui/material"
import React from "react"
import { useAppSelector } from "../../../../../app/hooks"
import { ExternalService } from "../../../../../utils/ServiceLocator";
import { useFindCaseManQuery } from "../../../../../services/caseManService/caseManage-api";
import { useParams } from "react-router";

function AbnGenerator(){
    const {id} = useParams();
    const caseManage = useFindCaseManQuery(id!);
    // let prepareImage = "data:application/pdf;base64," + pdf;
    const [generate, setGenerate] = React.useState(false);
    const handleDownload = () => {
        setGenerate(true);
        fetch(ExternalService + 'PdfLookupEndpoint/PdfDownload/' + caseManage.data?.codingDetails.abnLocator, { method: 'POST' })
            .then((response) => response.text())
            .then((data) => {
                let pdfWindow = window.open("")
                pdfWindow!.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(data) + "'></iframe>"
                )
            }).finally(() => {setGenerate(false)});
    }

    return <React.Fragment>
        {caseManage.data?.codingDetails?.abnLocator != '' &&
            <Button variant='contained' sx={{backgroundColor:'#62BCBD'}} size='small' onClick={()=>{handleDownload();}}>{generate===true?'Generating':'ABN Available'}</Button>
        }
    </React.Fragment>
}

export {AbnGenerator}