import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { AccountService } from "../../utils/ServiceLocator";
import { getToken } from "../../utils/AdalConfig";
import { AccountSummary } from './model/AccountSummary';
import { AccountSummaryRequest } from './commands/AccountSummaryRequest';
import { Account } from './model/account/Account';
import { AccountSaveCommand } from './commands/AccountSaveCommand';
import { AccountExport } from './model/AccountExport';
import { AccountCenterSummary } from './model/AccountCenterSummary';
import { AccountCenterSummaryRequest } from './commands/AccountCenterSummaryRequest';
import { AccountLookupRequest } from './commands/AccountLookupRequest';
import { AccountingServiceSummary, AdjustmentReasons } from './model';
import { AccountOutOfPeriodAdjustmentPostCommand } from './commands/AccountOutOfPeriodAdjustmentPostCommand';
import { AdjustmentSummary } from './model/AdjustmentSummary';
import { AccountApproveAdjustmentCommand, AccountApproveVarianceCommand, AccountingServiceSummaryRequest, AccountNoteAddCommand, AccountStatusChangeCommand } from './commands';
import { OutOfPeriodAdjustment } from './model/account/OutOfPeriodAdjustment';
import { AccountNote } from './model/account/AccountNote';

export const accountApi = createApi({
    reducerPath: 'accountApi',
    baseQuery: fetchBaseQuery({
        baseUrl: AccountService,
        prepareHeaders: (headers, { getState }) => {
            const token = getToken();
            headers.set('Authorization', 'Bearer ' + token);
            headers.set('content-type', 'application/json')
            return headers;
        }
    }),
    tagTypes: ['Account','AccountSummary','AccountExport','AccountCenterSummary','AccountLookup', 'AdjustmentReasons',
    'AdjustmentSummary', 'AccountingServiceSummary'
    ],
    endpoints: (builder) => ({
        findAccountByPeriod: builder.query<AccountSummary[],AccountSummaryRequest>({
            query: (request: AccountSummaryRequest) => 'AccountsForPeriod?center=' + request.center + '&month=' + request.month + '&year=' + request.year,
            providesTags: ['AccountSummary']    
        }),
        findAdjustmentsByPeriod: builder.query<AdjustmentSummary[],AccountSummaryRequest>({
            query: (request: AccountSummaryRequest) => 'adjustments-for-period?center=' + request.center + '&month=' + request.month + '&year=' + request.year,
            providesTags: ['AdjustmentSummary']
        }),
        findAccountCenterSummary: builder.query<AccountCenterSummary[],AccountCenterSummaryRequest>({
            query: (request: AccountCenterSummaryRequest) => 'center-summary?period=' + request.period + '&year=' + request.year,
            providesTags: ['AccountCenterSummary']
        }),
        findAccountingServiceSummary: builder.query<AccountingServiceSummary[],AccountingServiceSummaryRequest>({
            query: (request: AccountingServiceSummaryRequest) => 'accounting-service-summary?center=' + request.center + '&period=' + request.period + '&year=' + request.year,
            providesTags: ['AccountingServiceSummary']
        }),
        findAccount:builder.query<Account,string>({
            query:(id:string) => 'AccountFind?request=' + id,
            providesTags: ['Account']
        }),
        AccountLookup:builder.query<AccountSummary[],AccountLookupRequest>({
            query:(request:AccountLookupRequest) => 'account-lookup?centerId=' + request.centerId + '&searchCriteria=' + request.searchCriteria,
            providesTags: ['AccountLookup']
        }),
        saveAccount:builder.mutation<Account,AccountSaveCommand>({
            query:(body:AccountSaveCommand) => ({url: 'AccountSave',method: 'POST',body: body}),
            invalidatesTags: ['Account']
        }),
        saveAccountNote:builder.mutation<AccountNote,AccountNoteAddCommand>({
            query:(body:AccountNoteAddCommand) => ({url: 'NoteAdd',method: 'POST',body: body})
        }),
        saveOutOfPeriodAdjustment:builder.mutation<Account,AccountOutOfPeriodAdjustmentPostCommand>({
            query:(body:AccountOutOfPeriodAdjustmentPostCommand) => ({url: 'OutOfPeriodAdjustment',method: 'POST',body: body}),
            invalidatesTags: ['Account']
        }),
        saveAccountStatus:builder.mutation<Account,AccountStatusChangeCommand>({
            query:(body:AccountStatusChangeCommand) => ({url: 'AccountStatus',method: 'POST',body: body})
        }),
        saveAdjustmentApproval:builder.mutation<OutOfPeriodAdjustment,AccountApproveAdjustmentCommand>({
            query:(body:AccountApproveAdjustmentCommand) => ({url: 'adjustmentApprove',method: 'POST',body: body})
        }),
        saveAccountVarianceApproval:builder.mutation<AccountApproveVarianceCommand,AccountApproveVarianceCommand>({
            query:(body:AccountApproveVarianceCommand) => ({url: 'variance-approve',method: 'POST',body: body})
        }),
        findAccountExport:builder.query<AccountExport[],AccountSummaryRequest>({
            query:(request:AccountSummaryRequest) => 'AccountExport?center=' + request.center + '&month=' + request.month + '&year=' + request.year,
            providesTags: ['AccountExport']
        }),
        findAdjustmentReasons:builder.query<AdjustmentReasons[],void>({
            query:() => 'adjustment-reasons',
            providesTags: ['AdjustmentReasons']
        }),
        })

    })


export const { useFindAccountByPeriodQuery, useFindAccountQuery, useSaveAccountMutation,
    useFindAccountExportQuery, useFindAccountCenterSummaryQuery, useAccountLookupQuery, 
    useFindAdjustmentReasonsQuery, useSaveOutOfPeriodAdjustmentMutation, useFindAdjustmentsByPeriodQuery,
    useSaveAdjustmentApprovalMutation, useSaveAccountStatusMutation, useSaveAccountNoteMutation,
    useSaveAccountVarianceApprovalMutation, useFindAccountingServiceSummaryQuery} = accountApi;