export const IncorrectProcedureOptions = [
    {
        description: "Procedure Performed Incorrectly",
        name: "ProcedurePerformedIncorrectly",
    },
    {
        description: "Procedure Not Completed",
        name: "ProcedureNotCompleted",
    },
    {
        description: "Omission of Procedure",
        name: "OmissionOfProcedure",
    },
    {
        description: "Preparation Incorrect",
        name: "PreparationIncorrect",
    },
    {
        description: "Unexpected Return to the OR",
        name: "UnexpectedReturnToTheOR",
    },
    {
        description: "Wrong Body Part",
        name: "WrongBodyPart",
    },
    {
        description: "Wrong Side",
        name: "WrongSide",
    },
    {
        description: "Wrong Site (L vs R)",
        name: "WrongSiteLvsR",
    },
];