import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from "@mui/material";
import React from "react";
import { editVariance } from "../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { SeverityItems } from "./SeverityItems";

function SeverityGroup(props: { canEdit: boolean, severityGroup: {value: string, displayName: string} }) {
  const dispatch = useAppDispatch();
  const [severity, setSeverity] = React.useState({ value: "", description: "" });
  const incident = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  return (
    <React.Fragment>
      <Stack direction="row" margin={'20px'}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{props.severityGroup.displayName}</FormLabel>
          <RadioGroup
            value={incident.severity}
            onChange={(event) => {
              const value = event.target.value;
              const selectedSeverity = SeverityItems.find((item) => item.value === value);
              setSeverity(selectedSeverity || { value: "", description: "" });
              dispatch(editVariance({ name: "severity", value: value }));
            }}
          >
            {SeverityItems.filter(p=>p.group==props.severityGroup.value).map((item) => (
              <FormControlLabel
                disabled={!props.canEdit}
                key={item.value}
                value={item.value}
                control={<Radio />}
                label={item.description}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Stack>
    </React.Fragment>
  );
}

export {SeverityGroup};
