import React from "react"
import { CSVLink } from "react-csv";
import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import { usePhysicianListQuery } from "../../../../services/orgService/org-api";

type ownProps={
    center:string;
}
function PhysicianExport(props:ownProps){
    const {data} = usePhysicianListQuery(props.center);

    const headers=[
        {label:'CenterId',key:'centerId'},
        {label:'Code',key:'code'},
        {label:'LastName',key:'lastName'},
        {label:'FirstName',key:'firstName'},
        {label:'MiddleName',key:'middleName'},
        {label:'Npi',key:'npi'},
        {label:'Specialty',key:'specialty'}
    ]

    return <React.Fragment>
<       CSVLink data={data ?? []} headers={headers} filename={'physician-export.csv'} className='btn' style={{margin:'0px', padding:'0px'}}>
            <Button startIcon={<Download/>} variant='outlined'>Export</Button>
        </CSVLink>
    </React.Fragment>
}

export {PhysicianExport}