import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";

function FeeScheduleMessageCell(props:GridCellProps){
    const warningStyle = {
        color:'rgb(255, 204, 0)',
        fontWeight:'bold'
    }
    const errorStyle = {
        color:'red',
        fontWeight:'bold'
    }
    return <React.Fragment>
        <td>
            {props.dataItem.feeSummary.submittedForDelete === true &&
                <div style={errorStyle}>Submitted for Deletion</div>
            }
            {
                props.dataItem.feeSummary.isLastUploadSuccess === false &&
                <div style={errorStyle}>Upload Error Detected</div>
            }
            {props.dataItem.feeSummary.uploadInProgress === true &&
                <div style={warningStyle}>Update in progress</div>
            }
        </td>
    </React.Fragment>
}
export {FeeScheduleMessageCell};