import React from "react"
import { useParams } from "react-router"
import { WorklistRequest } from "../../../services/verifyService/commands";
import { useWorklistQuery } from "../../../services/verifyService/verify-api";
import { BenefitsWorklist } from "../features";
import { Loading } from "../../shared/features/Loading";

function Worklist(){
    const {type,center} = useParams();

    const {isLoading} = useWorklistQuery(new WorklistRequest(type!,center!));

    return <React.Fragment>
        {isLoading ? <Loading/> :
            <div>
                <BenefitsWorklist/>
            </div>
        }
    </React.Fragment>
}

export{Worklist}