import { Stack, Typography } from "@mui/material"
import React from "react"
import { AuthorizationRequired } from "./AuthorizationRequired"
import { AuthorizationStep } from "./AuthorizationStep"
import { DischargeNotice } from "./DischargeNotice"
import { AuthInitiateBtn } from "./AuthInitiateBtn"
import { ElectronicAuthImages } from "../electronicAuthImages/ElectronicAuthImages"

function AuthorizationStatus(){
    return <React.Fragment>
        <Stack marginRight={'10px'} marginLeft={'10px'}>
        <Stack direction='row' justifyContent='space-between' sx={{borderBottom:'2px solid gray'}} paddingBottom='5px'>
            <Typography variant='h6'   className='psn-green'>Authorization Status</Typography>
            <AuthInitiateBtn/>
        </Stack>
        <Stack marginTop='10px' marginRight='10px' gap={2}>
            <AuthorizationRequired/>
            <AuthorizationStep/>
            <DischargeNotice/>
        </Stack>
        </Stack>
        <ElectronicAuthImages/>
    </React.Fragment>
}

export {AuthorizationStatus}