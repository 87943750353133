import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import React, { useState } from "react"
import { useAccountLookupQuery } from "../../../../../services/accountService/account-api";
import { useParams } from "react-router";
import { AccountLookupRequest } from "../../../../../services/accountService/commands/AccountLookupRequest";
import { AccountLookupResults } from "./AccountLookupResults";

function AccountLookup(){
    const [searchText, setSearchText] = useState('');
    const[open, setOpen] = useState(false)
    const [skip, setSkip] = React.useState(true)
    const {center} = useParams();
    const {data, error, isLoading} = useAccountLookupQuery(new AccountLookupRequest(center!,searchText), {skip: skip});
    
    return <React.Fragment>
        <Stack direction='row'>
            <TextField label='Name or Account #' name='searchText' value={searchText}
             onChange={(e)=>{setSearchText(e.currentTarget.value);setSkip(true);}}
             onKeyDown={(e)=>{if(e.key==='Enter'){setSkip(false);setOpen(true);}}}
             size='small' sx={{backgroundColor:'white'}}/>

            <Button color='primary' variant="outlined" 
                onClick={()=>{setSkip(false);setOpen(true)}} 
                disabled={searchText === ''} size='small'>Search</Button>
        </Stack> 

        <Dialog open={open} onClose={()=>{setOpen(false)}} maxWidth='xl'> 
            <DialogTitle>Account Lookup</DialogTitle>
            <DialogContent>
                {isLoading && <div>Loading...</div>}
                {error && <div>Error: {error}</div>}
                {data && 
                    <AccountLookupResults data={data}/>
                }
            </DialogContent>
        </Dialog>
    </React.Fragment>
}
export {AccountLookup}