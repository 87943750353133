import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CallCreateCommand, CallDeleteCommand,PostRadRequestCommand } from "./commands";
import { RadWorklist } from "./";
import { Call } from "./model/radRequest/Call";
import { RadRequest } from "./model/radRequest/RadRequest";
import { getToken } from '../../utils/AdalConfig';
import { RadiologyService } from "../../utils/ServiceLocator";

export const radiologyApi = createApi({
    reducerPath:'radiologyApi',
    baseQuery: fetchBaseQuery({
        baseUrl:RadiologyService,
        prepareHeaders:(headers,{getState}) =>{
            const token = getToken();
            headers.set('Authorization', 'Bearer ' + token);
            headers.set('content-type', 'application/json')
            return headers;
        }      
    }),
    tagTypes:['radRequest','radWorklist','radLookup'],
    endpoints:(builder)=>({
        postRadRequest:builder.mutation<RadRequest,PostRadRequestCommand>({
            query:(body) => ({url:'RadRequest',method:('POST'),body,}),
            invalidatesTags:['radRequest']
        }),
        findRadWorklist:builder.query<RadWorklist[],string>({
            query:(request:string) => 'radWorklist?centerId=' + request,
            providesTags:['radWorklist']
        }),
        findRadRequest:builder.query<RadRequest,string>({
            query:(request:string) => 'radRequest?id=' + request,
            providesTags:['radRequest']
        }),
        searchRadRequest:builder.query<RadWorklist[],string>({
            query:(request:string) => 'radLookup?request=' + request,
            providesTags:['radLookup']
        }),
        createCall:builder.mutation<Call,CallCreateCommand>({
        query:(body) => ({url:'CallCreate',method:('POST'),body,})
        }),
        deleteCall:builder.mutation<Call,CallDeleteCommand>({
            query:(body) => ({url:'CallDelete',method:('POST'),body,})
        })
    })
})

export const {usePostRadRequestMutation, useFindRadWorklistQuery, 
    useFindRadRequestQuery, useSearchRadRequestQuery,
    useCreateCallMutation, useDeleteCallMutation} = radiologyApi;