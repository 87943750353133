import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../../../app/hooks";
import { GridButton } from "../../../../components";
import { caseManageApi } from "../../../../services/caseManService/caseManage-api";

function PolicyWorklistCommandCell(props:GridCellProps){
    const navigate = useNavigate();
    const dispatch = useAppDispatch(); 
    
    return <React.Fragment>
        <GridButton variant='outlined' size='small' onClick={()=>
            {navigate('/PolicyDetail/'+props.dataItem.id); 
            dispatch(caseManageApi.util.invalidateTags(['Policy']));
            }}>View</GridButton>
    </React.Fragment>
}
export{PolicyWorklistCommandCell}