import React, { useState } from "react"
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { ColumnMenu } from "../../../../../utils/ColumnMenu";
import { useParams } from "react-router";
import { useFeeScheduleFindQuery } from "../../../../../services/contractService/contract-api";
import { CptItemListCommandCell } from "./CptItemListCommandCell";
import { CptRateCalcCell } from "./CptRateCalcCell";
import { Cpt } from "../../../../../services/contractService/model";
import { process, State } from "@progress/kendo-data-query";

type ownProps ={
    data:Cpt[];
}
function CptItemList(props:ownProps){
    const {feeScheduleId} = useParams();
    const feeSchedule = useFeeScheduleFindQuery(feeScheduleId!);
    const [dataState, setDataState] = useState({});
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };
      
    return <React.Fragment>        
        <Grid data={process(props.data === undefined ? []:props.data!,dataState)}
          style={{height:'500px'}} onDataStateChange={dataStateChange}>
            <Column cell={CptItemListCommandCell} width='75px'/>
            <Column title="Cpt" field='code' columnMenu={ColumnMenu} width='100px'/>
            <Column title='Description' field='name' columnMenu={ColumnMenu} width={'400px'}/>
            <Column title='Category' field='category' columnMenu={ColumnMenu} width={'200px'}/>
            <Column title='Apc Code' field='apcCode' columnMenu={ColumnMenu} width={'150px'}/>
            {feeSchedule.data?.feeSubType === 'Grouped' &&
            <Column title='Grouper' field='grouperPointer' columnMenu={ColumnMenu} width={'150px'} />}
            <Column title='Rate' cell={CptRateCalcCell} columnMenu={ColumnMenu} width={'150px'} format="{0:c}"/>
            <Column title='MPD' field='multiProcedureDiscount' columnMenu={ColumnMenu} width='100px'/>
            <Column title='Relative Weight' field='relativeWeight' columnMenu={ColumnMenu} width='200px'/>
        </Grid>
        
    </React.Fragment>
}

export {CptItemList}