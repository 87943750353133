import React from "react"
import { ManualScheduling } from "./ManualScheduling"
import { Stack } from "@mui/material"
import { SageLocationEdit } from "./SageLocationEdit"
import { AuthNotificationEdit } from "./AuthNotificationEdit"

function AppConfig(){
    return<React.Fragment>
        <Stack direction ='row' marginTop='20px' marginLeft='20px' gap={2}>
            <ManualScheduling/>
        </Stack>
        <Stack direction ='row' marginTop='20px' marginLeft='20px' gap={2}> 
            <SageLocationEdit/>
            <AuthNotificationEdit/>
        </Stack>
    </React.Fragment>
}

export {AppConfig}