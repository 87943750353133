export class Drg{
    id:string='';
    contractId:string = '';
    feeScheduleId:string = '';
    code:string = '';
    type:string = '';
    title:string = '';
    weight:number=0;
    arithmeticMeanLOS:number=0;
    grouperCode:string = '';
    rate:number=0;
}