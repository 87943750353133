import { Chip } from "@mui/material";
import React from "react"
import { useParams } from "react-router";
import { useCaseFindQuery } from "../../services/scheduleService/schedule-api";


function PhysicianChipView(){
    const {id} = useParams();
    const schedule = useCaseFindQuery(id!);
    const doctor = 'DR: ' + schedule.data?.physician.lastName + ', ' + schedule.data?.physician.firstName;
    return<React.Fragment>
        {schedule.isLoading == false &&
            <Chip label={doctor} variant='outlined' color='success' sx={{color:'#62BCBD', fontWeight:'bold'}}/>
        }
    </React.Fragment>
}
export {PhysicianChipView}