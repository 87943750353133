import { Stack } from "@mui/material"
import React from "react"
import { RefundApproversAdd } from "./RefundApproversAdd"
import { RefundApproversList } from "./RefundApproversList"

function RefundApprovers(){
    return <React.Fragment>
        <Stack direction='row' justifyContent='flex-end' marginTop='10px' marginRight='5px'>
            <RefundApproversAdd/>
        </Stack>
        <RefundApproversList/>
    </React.Fragment>
}

export {RefundApprovers}