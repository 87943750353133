export class VarianceWorklistRequest{
    center:string;
    queryParam:string;
    isAdmin:boolean;
    user:string;

    constructor(center:string, queryParams:string, isAdmin:boolean, user:string){
        this.center = center;
        this.queryParam = queryParams;
        this.isAdmin = isAdmin;
        this.user = user;
    }
}