import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { ReportingDepartmentSelection } from "./ReportingDepartmentSelection";

function ReportingDepartment() {
 const modalOpen = useAppSelector(
    (state) => state.varianceModule.IncidentReportingDepartmentDialogOpen);
    return (
        <React.Fragment>
      <Dialog open={modalOpen} maxWidth="sm">
        <DialogTitle>Reporting Department</DialogTitle>
        <DialogContent>
            <ReportingDepartmentSelection canEdit={true} />
        </DialogContent>
        </Dialog>
        </React.Fragment>
    )
}

export { ReportingDepartment };