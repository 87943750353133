import React from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { useParams } from "react-router";
import { useFindCaseManQuery } from "../../../services/caseManService/caseManage-api";
import { useCaseFindQuery } from "../../../services/scheduleService/schedule-api";
import { CaseManCommands, CaseManNotes, ClinicalReview, CodingReview, DocumentRequest } from "../features";
import { EncounterHeaderView,  InsuranceViewWithCard } from "../../../components/encounterItems";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import { setTabStripPosition } from "../../../app/slices/CaseManModuleSlice";
import { Loading } from "../../shared";
import { Table } from "@mui/material";
import { useMyProfileQuery } from "../../../services/profileService/profile-api";
import { CaseManCallLog } from "../features/detail/callLog/CaseManCallLog";

function CaseManDetail(){
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const schedule = useCaseFindQuery(id!);
    const profile = useMyProfileQuery();
    const {data, isLoading} = useFindCaseManQuery(id!);
    const tabIndex = useAppSelector((state)=>state.caseManModule.TabStripPosition);
    const toggleTab = (e:TabStripSelectEventArguments) => dispatch(setTabStripPosition(e.selected));
  
    return <React.Fragment>
        {(isLoading || schedule.isLoading ? <Loading/> :
        <div>
            <CaseManCommands/>
            <EncounterHeaderView/>
            <InsuranceViewWithCard visitId={id!}/>

            <TabStrip selected={tabIndex} onSelect={toggleTab}>
                <TabStripTab title='Coding Review'>
                    <Table/>
                    <CodingReview/>
                </TabStripTab>
                <TabStripTab title='Clinical Review'>
                    <Table/>
                    <ClinicalReview/>
                </TabStripTab>
                <TabStripTab title='Notes'>
                    <Table/>
                    <CaseManNotes/>
                </TabStripTab>
                <TabStripTab title='Call Log'>
                    <Table/>
                    <CaseManCallLog/>
                </TabStripTab>
                <TabStripTab title='Document Request'>
                    <Table/>
                    <DocumentRequest/>
                </TabStripTab>
            </TabStrip>
        </div>)}
    </React.Fragment>
}

export {CaseManDetail}