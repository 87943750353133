import { Card, CardContent, CardHeader, Stack, TextField, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers";
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { editPatient } from "../../../../../services/radiologyService/model/radRequest/RadRequestSlice";
import dayjs from "dayjs";


function PatientDetails(){
    const dispatch = useAppDispatch();
    const patient = useAppSelector((state) => state.radRequest.radRequest.patient)
   
   return <React.Fragment>
        <Card sx={{borderRadius:'10px', marginBottom:'10px'}}>
        <CardHeader sx={{marginBottom:'0px', paddingBottom:'3px',paddingTop:'3px', borderBottom:'solid', borderColor:'gray'}} title ={<Typography variant='h6' color='gray'>Patient Details</Typography>}/>
            <CardContent>
                <Stack direction='row' gap={2} marginBottom='10px'>
                    <TextField name='lastName' label='Last Name' value={patient.lastName} onChange={(e)=> dispatch(editPatient({name:e.target.name,value:e.target.value}))}
                         size='small' InputLabelProps={{shrink:true}}/>
                    <TextField name='firstName' label='First Name' value={patient.firstName} onChange={(e)=> dispatch(editPatient({name:e.target.name,value:e.target.value}))}
                         size='small' InputLabelProps={{shrink:true}}/>
                    <TextField name='middleName' label='Middle Name' value={patient.middleName} onChange={(e)=>dispatch(editPatient({name:e.target.name,value:e.target.value}))}
                        size='small' InputLabelProps={{shrink:true}}/>
                    <DatePicker label='Birth Date' value={dayjs(patient.birthDate || null)}
                         onChange={(e)=>dispatch(editPatient({name:'birthDate',value:e!.toDate()}))}/>
                </Stack>
                <Stack direction='row' gap={2}>
                    <TextField name='homePhone' label='Primary Phone' value={patient.homePhone} onChange={(e)=>dispatch(editPatient({name:e.target.name,value:e.target.value}))}
                         size='small' InputLabelProps={{shrink:true}}/>
                    <TextField name='workPhone' label='Secondary Phone' value={patient.workPhone} onChange={(e)=>dispatch(editPatient({name:e.target.name,value:e.target.value}))}
                         size='small' InputLabelProps={{shrink:true}}/>
                    <TextField name='email' type='email' label='Email' value={patient.email} onChange={(e)=>dispatch(editPatient({name:e.target.name,value:e.target.value}))}
                        size='small' sx={{width:'400px'}} InputLabelProps={{shrink:true}}/>
                </Stack>
            </CardContent>
        </Card>
    </React.Fragment>
}

export {PatientDetails}