import {GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useNavigate } from "react-router"
import { useAppDispatch } from "../../../../app/hooks";
import { GridButton } from "../../../../components";
import { verifyApi } from "../../../../services/verifyService/verify-api";

function VerificationViewCell(props:GridCellProps){
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    return <React.Fragment>
        <GridButton variant="outlined" size='small' onClick={()=>{navigate('/Verification/'+props.dataItem.id); dispatch(verifyApi.util.invalidateTags(['verification']));}}>View</GridButton>
    </React.Fragment>
}

export{VerificationViewCell}