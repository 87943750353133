import { Alert } from "@mui/material"
import React from "react"
import { useAppSelector } from "../../../../../app/hooks";

function CheckReissueAlert(){
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    return <React.Fragment>
        {refund.status==='REISSUE' &&
        <Alert severity='warning'>This check is a re issue. Please verify that the address is correct.</Alert>
        }   
    </React.Fragment>

}
export{CheckReissueAlert}