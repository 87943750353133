import { MenuItem, TextField } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editRefundDetails } from "../../../../../services/refundService/model/refundRequest/RefundRequestSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";
import { RefundLocked } from "../../../specs/RefundLocked";

function RefundTypeSelector(){
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <TextField label='Refund Type' select sx={{width:'225px'}}
            disabled={new RefundLocked().IsSatisfiedBy(refund)}
            value={refund.refundDetail.refundCategory}
            onChange={(e)=>dispatch(editRefundDetails(new ReduxUpdate('refundCategory',e.target.value)))}>
                <MenuItem value='NS'>Not Set</MenuItem>
                <MenuItem value='PAT'>Patient</MenuItem>
                <MenuItem value='INS1'>Primary Ins.</MenuItem>
                <MenuItem value='INS2'>Secondary Ins.</MenuItem>
                <MenuItem value='INS3'>Tertiary Ins.</MenuItem>
        </TextField>
    </React.Fragment>
}

export {RefundTypeSelector}