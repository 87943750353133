import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleFeeScheduleAddModal } from "../../../../../app/slices/ContractModuleSlice";
import { FeeScheduleAddCommand } from "../../../../../services/contractService/commands";
import { useFeeScheduleAddMutation } from "../../../../../services/contractService/contract-api";
import dayjs from "dayjs";

type ownProps={
    feeScheduleType:string;
}
function FeeScheduleAdd(props:ownProps){
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state)=>state.contractModule.FeeScheduleAddModal)
    const contract = useAppSelector((state) => state.contract.contract);
    const [FeeScheduleCreate] = useFeeScheduleAddMutation();
    const [description, setDescription] = useState('');
    const [effectiveDate,setEffectiveDate] = useState(new Date());
    const [endDate,setEndDate] = useState(new Date());
    const [subType,setSubType] = useState('Flat Rate');
    const [revCodes, setRevCodes] = useState([]);
    const [category, setCategory] = useState('');
    function adjustForTimezone(date:Date):Date{
        var timeOffsetInMS:number = date.getTimezoneOffset() * 60000;
        date.setTime(date.getTime() + timeOffsetInMS);
        return date
    }
    const saveFeeSchedule=()=>{
        let command = new FeeScheduleAddCommand(contract.id,props.feeScheduleType, description,effectiveDate,endDate, props.feeScheduleType === 'DRG'? 'Grouped': subType,category, revCodes);
        FeeScheduleCreate(command);
    }
    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogTitle>{'Add a ' + props.feeScheduleType + ' fee Schedule'}</DialogTitle>
            <DialogContent>
                <Stack direction='row' marginTop='10px'>
                    <TextField label='Description' name='description' value={description} onChange={(e)=>setDescription(e.target.value)} size='small' InputLabelProps={{shrink:true}} fullWidth/>
                </Stack>
                <Stack direction='row' marginTop='10px' gap={1}>
                <DatePicker label='Effective Date' value={dayjs(effectiveDate)} onChange={(e)=> {setEffectiveDate(e!.toDate())}}/>
                <DatePicker label='Renewal Date' value={dayjs(endDate)} onChange={(e)=> {setEndDate(e!.toDate())}}/>
                </Stack>
                {props.feeScheduleType === 'CPT' && 
                    <Stack marginTop='10px'>
                        <TextField label='Fee Schedule Type' size='small' select defaultValue='Flat Rate' value={subType} onChange={(e)=>setSubType(e.target.value)}>
                            <MenuItem value='Flat Rate'>Flat Rate</MenuItem>
                            <MenuItem value='Grouped'>Grouped</MenuItem>
                        </TextField>
                    </Stack>
                }
                <Stack direction='row' marginTop='10px'>
                    <TextField label='Category' value={category} onChange={(e)=>setCategory(e.target.value)} sx={{width:'250px'}}/>
                </Stack>
                <br/>
                <Typography sx={{color:'gray'}}>Restrict usage to specific revenue codes</Typography>
                <MultiSelect allowCustom placeholder="Enter rev code" required={false} value={revCodes} onChange={(e)=>setRevCodes(e.value)}/>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={()=>dispatch(toggleFeeScheduleAddModal())}>Close</Button>
                <Button onClick={()=>{saveFeeSchedule(); dispatch(toggleFeeScheduleAddModal())}}>Submit</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export {FeeScheduleAdd}