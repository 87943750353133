import { Checkbox, FormControlLabel } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { editVerify } from "../../../../../services/verifyService/model/verification/VerificationSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";

function ImplantCostBypass(){
    const dispatch = useAppDispatch();
    const verify = useAppSelector((state)=> state.verification.verification);
    return <React.Fragment>
        <FormControlLabel sx={{marginLeft:'0px'}}
            control={
                <Checkbox
                    color='primary'
                    value={verify.implantsNotUsed}
                    checked={verify.implantsNotUsed}
                    onChange={(e)=> dispatch(editVerify(new ReduxUpdate('implantsNotUsed',e.target.checked)))}
                />
            }
            label="Check this box if no implants will be used in this procedure."/>
    </React.Fragment>
}
export {ImplantCostBypass}