import { DatePicker } from "@mui/x-date-pickers"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editCheckInfo } from "../../../../../services/refundService/model/refundRequest/RefundRequestSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";
import dayjs from "dayjs";

function CheckDateEdit(){
    const dispatch = useAppDispatch();
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const isHidden=()=>{
        if(refund.status==='STARTED' || refund.status==='APPROVE' || refund.status==='REJECT')
            return true;
        return false;}

    return <React.Fragment>
        {!isHidden() &&
        <DatePicker label='Check date' sx={{width:'240px'}} value={dayjs(refund.checkInfo.checkDate)}
            defaultValue={dayjs(new Date('01/01/1900'))}
            onChange={(e)=>dispatch(editCheckInfo(new ReduxUpdate('checkDate',e!)))}/>}
    </React.Fragment>
}

export {CheckDateEdit}