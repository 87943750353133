import React, { useState } from "react"
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { ColumnMenu } from "../../../../../utils/ColumnMenu";
import { useParams } from "react-router"
import { useCarveOutListQuery } from "../../../../../services/contractService/contract-api";
import { CarveOutEditCommandCell } from "./CarveOutEditCommandCell";
import { process, State } from "@progress/kendo-data-query";

function CarveOutsList(){
    const {id} = useParams();
    const {data} = useCarveOutListQuery(id!);
    const [dataState, setDataState] = useState({});
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };

      
    return <React.Fragment>
        <Grid data={process(data === undefined ? []: data!,dataState)} 
            style={{height:'500px'}} onDataStateChange={dataStateChange}>
            <Column cell={CarveOutEditCommandCell} width='75px'/>
            <Column title='Code' field='code' columnMenu={ColumnMenu} width='200px'/>
            <Column title='Description' field='description' columnMenu={ColumnMenu} width='500px'/>
            <Column title='Rate' field='rate' columnMenu={ColumnMenu} width='200px' format="{0:c}"/>
            <Column title='Apply MPD' field="applyMultiProcedureDisc" columnMenu={ColumnMenu} width='150px'/>
            <Column title='Is Case Rate' field='isCaseRate' columnMenu={ColumnMenu} width='150px'/>
        </Grid>
    </React.Fragment>
}

export {CarveOutsList}