import './App.css';
import Layout from './modules/shared/features/Layout';
import { Route, Routes } from 'react-router';
import { Home } from './modules/home/pages/Home';
import {MyProfile, UserProfile} from './modules/profile/pages';
import { OrgEdit, OrgHome } from './modules/configuration/pages';
import {ContractHome,ContractDetail,FeeScheduleDetail,AlertList} from './modules/contract/pages';
import {Dashboard, CenterIndex, Verification,Worklist} from './modules/verification/pages';
import {ScheduleHome,ScheduleMain,ScheduleDetail,ScheduleCreate} from './modules/schedule/pages';
import {RadHome,RadCenterIndex,CreateRadRequest,EditRadRequest} from './modules/radiology/pages';
import {RefundDashboard, RefundWorklist, RefundRequestDetail, RefundHome} from './modules/refund/pages';
import { CaseManDashboard, CaseManDetail, CaseManHome, CaseManWorklist, PolicyDetail, PolicyHome, PolicyWorklist } from './modules/caseManagement/pages';
import { ReportDashboard } from './modules/reporting/pages';
import {WeeklyProjections, FirstStartsOnTime, CpsiPurchaseOrders, VolumeAndRevenue, CaseDetail, CancelledCases, CpsiORMinutes, CpsiCharges, FacilityScorecard,
    RadiologyAuthOutstanding, VOBStatus, VOBProductivity, RefundsIncoming,PatientCollections, LowContribution,
    ScheduledCases} from './modules/reporting/reports';
import { RevRecCenterDashboard, RevRecDetail, RevRecHome, RevRecOutOfPeriod, RevRecSummary } from './modules/revenueRecognition/pages';
import { VarianceDashboard, VarianceHome, VarianceRequestDetail, VarianceWorklist   } from './modules/variance/pages';
import { IncidentSummary } from './modules/reporting/reports/Incidents/IncidentSummary';

export default () => (
  <Layout>
      <Routes>
        <Route path='/' element ={<Home/>} />
        <Route path='/myProfile' element={<MyProfile/>}/>
        <Route path='/userProfile/:id' element={<UserProfile/>}/>
        <Route path='/orgHome' element={<OrgHome/>}/>
        <Route path ='/OrgEdit/:id' element={<OrgEdit/>}/>
        <Route path='/ContractHome' element={<ContractHome/>}/>
        <Route path='/contractDetail/:id' element={<ContractDetail/>}/>
        <Route path='/AlertList/:type' element={<AlertList/>}/>
        <Route path='/feeSchedule/:contractId/:feeScheduleId' element={<FeeScheduleDetail/>}/>
        <Route path='/VerifyHome' element={<CenterIndex/>}/>
        <Route path='/Dashboard/:center' element={<Dashboard/>}/>
        <Route path='/Worklist/:center/:type' element={<Worklist/>}/>
        <Route path='/Verification/:id' element={<Verification/>}/>
        <Route path='/ScheduleHome' element={<ScheduleHome/>}/>
        <Route path='/ScheduleMain/:id' element={<ScheduleMain/>}/>
        <Route path='/ScheduleDetail/:id' element={<ScheduleDetail/>}/>
        <Route path='/ScheduleCreate/:center' element={<ScheduleCreate/>}/>
        <Route path='/RadMain' element={<RadCenterIndex/>}/>
        <Route path='/RadHome/:center' element={<RadHome/>}/>
        <Route path='/RadCreate/:center' element={<CreateRadRequest/>}/>
        <Route path='/RadEdit/:center/:id' element={<EditRadRequest/>}/>
        <Route path='/reports' element={<ReportDashboard/>}/>
        <Route path='/WeeklyProjections' element={<WeeklyProjections/>}/>
        <Route path ='/FirstStartsOnTime' element={<FirstStartsOnTime/>}/>
        <Route path = '/CpsiPurchaseOrders' element={<CpsiPurchaseOrders/>}/>
        <Route path='/LowContribution' element={<LowContribution/>}/>
        <Route path='/ScheduledCases' element={<ScheduledCases/>}/>
        <Route path='/VolumeAndRevenue' element={<VolumeAndRevenue/>}/>
        <Route path='/RefundsIncoming' element={<RefundsIncoming/>}/>
        <Route path='/CaseDetail' element={<CaseDetail/>}/>
        <Route path='/CancelledCases' element={<CancelledCases/>}/>
        <Route path='/CpsiORMinutes' element={<CpsiORMinutes/>}/>
        <Route path='/CpsiCharges' element={<CpsiCharges/>}/>
        <Route path='/FacilityScorecard' element={<FacilityScorecard/>}/>
        <Route path='/RadAuthOutstanding' element={<RadiologyAuthOutstanding/>}/>
        <Route path='/PatientCollections' element={<PatientCollections/>}/>
        <Route path='/Refunds' element={<RefundHome/>}/>
        <Route path='/RefundDashboard/:center' element={<RefundDashboard/>}/>
        <Route path='/RefundWorklist/:center/:status' element={<RefundWorklist/>}/>
        <Route path='/RefundRequestDetail/:caseId/:refundId' element={<RefundRequestDetail/>}/>
        <Route path='/VOBStatus' element={<VOBStatus/>}/>
        <Route path='/VOBProductivity' element={<VOBProductivity/>}/>
        <Route path='/CaseManagement' element={<CaseManHome/>}/>
        <Route path='/CaseManDashboard/:center' element={<CaseManDashboard/>}/>
        <Route path='/CaseManWorklist/:center/:type' element={<CaseManWorklist/>}/>
        <Route path='/CaseManDetail/:id' element={<CaseManDetail/>}/>
        <Route path='/Policies' element={<PolicyHome/>}/>
        <Route path='/PolicyDetail/:id' element={<PolicyDetail/>}/>
        <Route path='/PolicyWorklist/:queryParam' element={<PolicyWorklist/>}/>
        <Route path='/RevRec' element={<RevRecHome/>}/>
        <Route path='/RevRecDetail/:id' element={<RevRecDetail/>}/>
        <Route path='/RevRecSummary/:center/:month/:year' element={<RevRecSummary/>}/>
        <Route path='/RevRecCenterDashboard/:center/:month/:year' element={<RevRecCenterDashboard/>}/>
        <Route path='/RevRecOutOfPeriod/:center/:month/:year' element={<RevRecOutOfPeriod/>}/>
        <Route path='/VarianceHome' element={<VarianceHome/>}/>
        <Route path='/VarianceDashboard/:center' element={<VarianceDashboard/>}/>
        <Route path='/VarianceWorklist/:center/:status' element={<VarianceWorklist/>}/>
        <Route path='/VarianceRequestDetail/:Id' element={<VarianceRequestDetail/>}/>
        <Route path='/IncidentSummary' element={<IncidentSummary/>}/>
      </Routes>
  </Layout>
);
