export const EquipmentEventOptions = [
    {
        description: "Was Safe Medical Device Report filed?",
        name: "WasSafeMedicalDeviceReportFiled_DR",
    },
    {
        description: "Was substitute equipment available?",
        name: "WasSubstituteEquipmentAvailable_DR",
    },
    {
        description: "Was there a delay in patient care related to this event?",
        name: "WasThereADelayInPatientCareRelatedToThisEvent_DR",
    },
    {
        description: "Was there any patient harm related to Equipment?",
        name: "WasThereAnyPatientHarmRelatedToEquipment_DR",
    },
];
