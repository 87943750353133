export  interface Insurance{
    id:string;
    priority:number;
    payer:string;
    payerCode: string;
    policy:string;
    groupNumber:string;
}

export function DefaultInsurance():Insurance{
    return {
        id:'',priority:0,payer:'',payerCode:'',policy:'',groupNumber:''
    }
}