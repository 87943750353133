import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleDrgUploadModal } from "../../../../../app/slices/ContractModuleSlice";
import { contractApi } from "../../../../../services/contractService/contract-api";
import DrgTemplate from '../../../../../templates/DrgUploadTemplate.png';
import { ContractService } from "../../../../../utils/ServiceLocator";

function DrgUpload(){
    const dispatch = useAppDispatch();
	const feeSchedule = useAppSelector((state)=>state.feeSchedule.feeSchedule);
    const isOpen = useAppSelector((state) => state.contractModule.drgUploadModal)
    const [selectedFile, setSelectedFile] = useState();
	const [uploadComplete, setUploadComplete] = useState(false);
	const [uploadInProgress, setUploadInProgress] = useState(false);

	const changeHandler = (event:any) => {
		setSelectedFile(event.target.files[0]);
	};

	const handleSubmission = () => {
		const formData = new FormData();
		setUploadInProgress(true);
		formData.append('File', selectedFile!);

		fetch(ContractService + 'UploadDrgEndpoint/UploadDrg/'+feeSchedule.id ,{method: 'POST', body: formData,})
			.then((response) => {response.json(); setUploadComplete(true);setUploadInProgress(false);dispatch(contractApi.util.invalidateTags(['drgList']));})
			.then((result) => {console.log('Success:', result);})
			.catch((error) => {console.error('Error:', error);});
	};

    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogTitle>Upload a DRG Fee Schedule</DialogTitle>
            <DialogContent>
	          <Stack gap={2}>
                    <Alert color='info'>Fee Schedules must be uploaded using a specific format and file name.  Please download the template here.
                        <a href={DrgTemplate} download='DrgTemplate.csv'>Download</a>
                    </Alert>
					{uploadInProgress &&
					<Alert color='warning'>We're processing your request.  This may take a minute.</Alert>}
					{uploadComplete === true &&
						<Alert color="success">Upload Completed Successfully</Alert>}
                    <input type="file" name="file" onChange={changeHandler} />
                    <Button variant="outlined" startIcon={uploadInProgress ?'' : ''}  onClick={handleSubmission} disabled={selectedFile === undefined || uploadInProgress}>{!uploadInProgress ? 'Submit': <CircularProgress/>}</Button>
                    <Button variant='outlined' onClick={()=>{dispatch(toggleDrgUploadModal()); setUploadComplete(false)}}>Close</Button>
					
                </Stack>
            
            </DialogContent>
            <DialogActions>
                
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export {DrgUpload}