import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material"
import React from "react"
import { useSaveAccountNoteMutation } from "../../../../../services/accountService/account-api";
import { useParams } from "react-router";
import { AccountNoteAddCommand } from "../../../../../services/accountService/commands";

function AccountNoteAddDialog(){
    const {id} = useParams();
    const [open, setOpen] = React.useState(false);
    const [noteText, setNoteText] = React.useState('');
    const [save] = useSaveAccountNoteMutation();
    const handleSave = (note:string)=>{
        let command = new AccountNoteAddCommand(id!, note);
        save(command);
    }
    return <React.Fragment>
        <Button variant='outlined' onClick={()=>setOpen(true)}>Add Note</Button>

        <Dialog open={open} onClose={()=>setOpen(false)} maxWidth='xl'>
            <DialogTitle>Add Note</DialogTitle>
            <DialogContent>
                <Stack direction='row' marginTop='10px'>
                <TextField label='Note' multiline rows={4} fullWidth sx={{width:'500px'}}
                    value={noteText}
                    onChange={(e)=>setNoteText(e.target.value)}/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={()=>{setOpen(false);setNoteText('')}}>Cancel</Button>
                <Button onClick={()=>{handleSave(noteText); setOpen(false);setNoteText('')}}>Save</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}
export {AccountNoteAddDialog}