
export class AmountDue{
    deductibleDue:number;
    coInsuranceDue:number;
    coPayDue:number;
    totalPatientDue:number;
    changeNote:string;
    changeNoteCategory:string;

    constructor(deductibleDue:number, coInsuranceDue:number,
        coPayDue:number, totalPatientDue:number, changeNote:string, changeNoteCategory:string){
            this.deductibleDue = deductibleDue;
            this.coInsuranceDue = coInsuranceDue;
            this.coPayDue = coPayDue;
            this.totalPatientDue = totalPatientDue;
            this.changeNote = changeNote;
            this.changeNoteCategory = changeNoteCategory;
        }
}