import React, { useState } from "react";
import { useParams } from "react-router";
import {
  Grid,
  GridColumn as Column,
  GridDataStateChangeEvent,
} from "@progress/kendo-react-grid";
import { ColumnMenu } from "../../../../utils/ColumnMenu";
import { process, State } from "@progress/kendo-data-query";
import { VarianceWorklistCommandCell } from "./VarianceWorklistCommandCell";
import { useMyWorklistQuery } from "../../../../services/varianceService/variance-api";
import { VarianceMyWorklistRequest } from "../../../../services/varianceService/commands/VarianceMyWorklistRequest";
import { useMyProfileQuery } from "../../../../services/profileService/profile-api";
import { Card, CardHeader, Typography } from "@mui/material";

function VarianceMyWorklistResults() {
  const { center } = useParams();
  const profile = useMyProfileQuery();
  const [dataState, setDataState] = useState<State>({});
  const { data } = useMyWorklistQuery(
    new VarianceMyWorklistRequest(center!, profile.data?.claimsAccess.find(p => p.code === 'IncidentAdmin') != undefined, profile.data?.identity!)
  );
  const mapCases = () => {
    return data?.map((i) => ({
      ...i,
      varianceStartDate: new Date(i.varianceStartDate),
    }));
  };
  const createDataState = (dataState: State) => {
    return {
      dataState: dataState === undefined ? {} : dataState,
    };
  };
  const dataStateChange = (event: GridDataStateChangeEvent) => {
    let updatedState = createDataState(event.dataState);
    setDataState(updatedState.dataState);
  };

  return (
    <React.Fragment>
      <Card title="My Worklist" sx={{ borderRadius: "10px" }}>
      <CardHeader className='card-header' sx={{textAlign:'center'}} title={<Typography color='#62BCBD' fontWeight={700} variant='body2'>My Worklist</Typography>}/>
      <Grid
        data={process(mapCases() ?? [].slice(0), dataState)}
        {...dataState}
        onDataStateChange={dataStateChange}
        style={{ height: "500px" }}
      >
        <Column cell={VarianceWorklistCommandCell} width="100px" />
        <Column
          title="Last Name"
          field="lastName"
          columnMenu={ColumnMenu}
          width="225px"
        />
        <Column
          title="First Name"
          field="firstName"
          columnMenu={ColumnMenu}
          width="225px"
        />
        <Column
          title="Variance Start Date"
          field="varianceStartDate"
          columnMenu={ColumnMenu}
          width="200px"
          format="{0:d}"
        />
      </Grid>
      </Card>
    </React.Fragment>
  );
}

export { VarianceMyWorklistResults };
