export class CptAddCommand{
    feeScheduleId:string;
    code:string;
    name:string;
    category:string;
    apcCode:string;
    baseRate:number;
    grouperPointer:string;
    multiProcedureDiscount:boolean;
    relativeWeight?: number;

    constructor(feeScheduleId:string, code:string, name:string, category:string, apcCode:string, baseRate:string, grouperPointer:string, multiProcedureDiscount:boolean, relativeWeight?:number){
        this.feeScheduleId = feeScheduleId;
        this.code = code;
        this.name = name;
        this.category = category;
        this.apcCode = apcCode;
        this.baseRate = baseRate === '' ? 0 : Number(baseRate);
        this.grouperPointer = grouperPointer;
        this.multiProcedureDiscount = multiProcedureDiscount ?? false;
        this.relativeWeight = relativeWeight;
    }
}