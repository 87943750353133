import { formatDate } from "@progress/kendo-intl";
import { SchedulerEditSlot, SchedulerEditSlotProps } from "@progress/kendo-react-scheduler"
import React from "react"
import { useNavigate } from "react-router";

function ScheduleSlotEditor(props:SchedulerEditSlotProps){
    const [formItem, setFormItem] = React.useState(null);
    const navigate = useNavigate();

    return <React.Fragment>
        <SchedulerEditSlot style={{...props.style}} {...props} formItem={formItem}/>
        {/* // onClick={()=> navigate('/CreateSchedule/'+ formatDate(new Date(props.start),'MM-dd-yyyy:hh:mm') + '/'+ formatDate(new Date(props.end),'MM-dd-yyyy:hh:mm'))} */}

    </React.Fragment>
}

export {ScheduleSlotEditor}