import React from "react"
import { Card, CardHeader, CardContent, Stack, Grid, Box } from "@mui/material";

function VOBProductivity(){
    return <React.Fragment>
        <Stack justifyContent='center' alignItems='center'>
            <iframe title="VOB Productivity" width="1350" height="800" src="https://app.powerbi.com/reportEmbed?reportId=cfa8f2ab-0f12-4d5a-b2c6-10c9fa208fd9&autoAuth=true&ctid=49423d5d-8d49-4128-b53d-59537808f6ac" allowFullScreen={true}></iframe>
        </Stack>
    </React.Fragment>
}

export{VOBProductivity}