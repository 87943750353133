import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { Alert, Button, Snackbar, Stack } from "@mui/material";
import { caseManageApi, useCaseManageSaveMutation } from "../../../../../services/caseManService/caseManage-api";
import { CaseManPostCommand } from "../../../../../services/caseManService/commands";
import { CenterNameChipView } from "../../../../../components/orgComponents/CenterNameChipView";
import { PhysicianChipView } from "../../../../../components/encounterItems";

function CaseManCommands(){
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const caseManage = useAppSelector((state) => state.caseManagement.caseManagement);
    const [casemanSave,status] = useCaseManageSaveMutation();
    const [notify, setNotify] = useState(false);
    useEffect(()=>{setNotify(status.isSuccess)},[status.isSuccess]);
    
    const handleSave=()=>{
        let command = new CaseManPostCommand(caseManage);
        casemanSave(command).unwrap().then(()=>{});
    }
    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' gap={1}>
            <CenterNameChipView centerId={caseManage?.center??''}/>
            <PhysicianChipView/>
            <Stack direction='row' gap={1}>
                <Button variant='outlined' color="primary" onClick={()=>handleSave()}>Save</Button>
                <Button variant="outlined" color='success' onClick={()=>{navigate(-1);dispatch(caseManageApi.util.invalidateTags(['CaseManWorklist']))}}>Go Back</Button>
            </Stack>
        </Stack>    
        <Snackbar open={notify} onClose={()=>setNotify(false)} color='success' autoHideDuration={6000} message='Case Saved Successfully!'/>
        <Snackbar open={status.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(status.error)}</Alert>
        </Snackbar>
    </React.Fragment>
}

export {CaseManCommands}