import { Autocomplete, MenuItem, TextField } from "@mui/material";
import React from "react"
import { useAllProfilesQuery } from "../../services/profileService/profile-api";

type ownProps={
    value: string;
    updateFunc: Function;
}
function UserSelector(props:ownProps){
    const {data} = useAllProfilesQuery();
    const users = [...data??[]]

    return <React.Fragment>
        <TextField select label='User' value={props.value??''} onChange={(e)=>props.updateFunc(e.target.value)} sx={{width:'200px'}}>
            {users.sort((a,b)=>a.identity > b.identity ? 1 : -1).map((prfile)=>(
                <MenuItem key={prfile.identity} value={prfile.identity}>{prfile.identity}</MenuItem>))??[]
            }
        </TextField>
    </React.Fragment>
}

export {UserSelector}