import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import React, { MouseEventHandler, useState } from 'react';
import { DefaultCenter } from '../../../../services/orgService/model/center/Center';
import {useSaveCenterMutation} from '../../../../services/orgService/org-api';

type OwnProps={
    toggleModal:MouseEventHandler<any>,
    isOpen:boolean
}
type CenterAddModalProps = 
    OwnProps 

function CenterAddModal(props:CenterAddModalProps){
    const [center,setCenter] = useState(DefaultCenter())
    const handleChange = (e:any)=>{
        const {name,value} = e.target;
        setCenter({...center, [name]:value,});
    }
    const [saveCenter] = useSaveCenterMutation();

    return <React.Fragment>
        <Dialog open={props.isOpen}>
            <DialogTitle>Add A Center</DialogTitle>
            <DialogContent>
                <Stack direction='row' gap={2} sx={{marginTop:'10px'}}>
                    <TextField label='Code' name='code' value={center.code} onChange={(e)=>handleChange(e)} size='small'/>
                    <TextField label='Name' name='name' value={center.name} onChange={(e)=> handleChange(e)} size='small' sx={{width:'500px', maxWidth:'100%'}}/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction='row'>
                    <Button onClick={(e)=> props.toggleModal(e)} color='secondary'>Cancel</Button>
                    <Button onClick={(e)=>{props.toggleModal(e); saveCenter(center);}}>Save</Button>
                </Stack>
            </DialogActions>
        </Dialog>
</React.Fragment>
}

export {CenterAddModal};
