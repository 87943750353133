import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  toggleInitiatePatientSearchDialog,
  toggleVarianceSelectedIdentType,
} from "../../../../../app/slices/VarianceModuleSlice";
import { VarianceInitiatePatientSearch } from "./VarianceInitiatePatientSearch";

function VarianceInitiatePatientSearchDialog() {
  const patientSearchDialogOpen = useAppSelector(
    (state) => state.varianceModule.VarianceInitiatePatientSearchDialogOpen
  );
  const dispatch = useAppDispatch();

  return (
    <React.Fragment>
      <Dialog open={patientSearchDialogOpen} maxWidth="xl" fullWidth>
        <DialogTitle>Patient Search</DialogTitle>
        <DialogContent>
          <VarianceInitiatePatientSearch />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              dispatch(toggleInitiatePatientSearchDialog());
              dispatch(toggleVarianceSelectedIdentType(""));
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export { VarianceInitiatePatientSearchDialog };
