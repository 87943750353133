import { Chip} from "@mui/material"
import { Stack } from "@mui/system"
import React from "react"
import { useParams } from "react-router";
import {useCaseFindQuery } from "../../../../../services/scheduleService/schedule-api";
import {Phone, PhoneIphone, Email} from '@mui/icons-material'

function PatientContact(){
    const {id} = useParams()
    const {data} = useCaseFindQuery(id!);
    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' marginRight='10px' marginLeft='10px'>
            <Chip icon={<Phone/>} variant='outlined' color='primary' label={data?.homePhone !== '' ?data?.homePhone : 'Unknown'} />
            <Chip icon={<PhoneIphone/>} variant='outlined' color='primary' label={data?.mobilePhone !== '' ? data?.mobilePhone : 'Unknown'}/>
            <Chip icon={<Email/>} variant='outlined' color='primary' label={(data?.email !=='' && data?.email !== null) ?data?.email:'Unknown'}/>
        </Stack>
    </React.Fragment>
}

export {PatientContact}