export class Benefits{
    deductible:number = 0;
    deductibleMet: number = 0;
    outOfPocket: number=0;
    outOfPocketMet: number = 0;
    coInsurance: number = 0;
    coPay:number = 0;
    dedApplyToOOP:boolean = false;
    coPayApplyToOOP:boolean = false;
    paidToPhysician:number = 0;
    uncoveredAmount:number = 0;
    isComboCase:boolean = false;
}