import { Switch } from "@progress/kendo-react-inputs"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { editSingleCaseAgreement } from "../../../../../services/verifyService/model/verification/VerificationSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";

function ScaFiledEdit(){
    const dispatch = useAppDispatch();
    const verify = useAppSelector((state) => state.verification.verification);
    return <React.Fragment>
        <div style={{marginLeft:'10px',paddingBottom:'2px'}}>
            <label>Filed In EMR</label><br/>
            <Switch name="filedInEmr" value={verify.singleCaseAgreement?.filedInEmr} checked={verify.singleCaseAgreement?.filedInEmr} 
                onChange={(e)=>dispatch(editSingleCaseAgreement(new ReduxUpdate('filedInEmr',e.target.value)))}/>
        </div>
    </React.Fragment>
}
export {ScaFiledEdit}