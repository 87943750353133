import React, { useState } from "react"
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { ColumnMenu } from "../../../../../utils/ColumnMenu";
import { GrouperEditCommandCell } from "./GrouperEditCommandCell";
import { useAppSelector } from "../../../../../app/hooks";
import { process, State } from "@progress/kendo-data-query";

function GrouperList(){
    const feeSchedule = useAppSelector((state)=> state.feeSchedule.feeSchedule);
    const [dataState, setDataState] = useState({});
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };

    return <React.Fragment>
        <Grid data={process(feeSchedule.groupers === undefined ? [] :  feeSchedule.groupers,dataState)} 
            style={{height:'500px'}} onDataStateChange={dataStateChange}>
            <Column cell={GrouperEditCommandCell} width='75px'/>
            <Column title='Grouper Code' field='code' columnMenu={ColumnMenu} width='200px'/>
            <Column title='Grouper Rate' field='rate' columnMenu={ColumnMenu} width='200px' format="{0:c}"/>
            <Column title='Per Diem' field="isPerDiem" columnMenu={ColumnMenu} width='125px'/>
        </Grid>        
    </React.Fragment>
}

export{GrouperList}