import { Stack, Typography } from "@mui/material"
import React from "react"

function CaseManDocRequestHeader(){
    return <React.Fragment>
            <Stack marginLeft='10px' marginTop='20px' marginBottom='20px'>
                <Typography variant='h5' className='psn-green' textAlign='left'>Documents Needed</Typography>
                <Typography variant='body2' textAlign='left'>Indicate any document types missing from the patient's medical record and are necessary to facilitate the comprehensive review of this case.</Typography>
                <Typography variant='body2' textAlign='left'>Deselect document types as you verify their inclusion within the medical record.</Typography>
            </Stack>

    </React.Fragment>
}
export {CaseManDocRequestHeader}