import React from "react"
import { ProcedureList } from "./ProcedureList"
import { ProcedureCreate } from "./ProcedureCreate"
import { useCenterFindQuery } from "../../../../../services/orgService/org-api";

type ownProps = {
    center:string;
}

function Procedures(props:ownProps){
    const readonly = !useCenterFindQuery(props.center).data?.allowManualScheduling;

    return <React.Fragment>
        {!readonly &&
            <ProcedureCreate center={props.center}/>}
        <ProcedureList/>
    </React.Fragment>
}

export {Procedures}