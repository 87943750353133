import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Schedule } from "./model/schedule/Schedule";
import { getToken } from '../../utils/AdalConfig';
import { ScheduleService } from "../../utils/ServiceLocator";
import { ScheduledCases } from "./model/ScheduledCases";
import { CancelCaseCommand, ProcedureUpdateCommand, SchedulePostCommand, ScheduleSearchCommand } from "./commands";


export const scheduleApi = createApi({
    reducerPath:'scheduleApi',
    baseQuery: fetchBaseQuery({
        baseUrl: ScheduleService,
        prepareHeaders:(headers,{getState}) =>{
            const token = getToken();
            headers.set('Authorization', 'Bearer ' + token);
            headers.set('content-type', 'application/json')
            return headers;
        }
    }),
    tagTypes:['caseSearch','case','scheduledCases','scheduleLookup','scheduleSearch'],
    endpoints:(builder)=>({
        caseFind:builder.query<Schedule,string>({
            query:(request: string) => 'schedule?request=' + request,
            providesTags:['case']
        }),
        scheduledCasesFind:builder.query<ScheduledCases[],string>({
            query:(request:string) => 'scheduledCases?centerId=' + request,
            providesTags:['scheduledCases']
        }),
        scheduleLookup:builder.query<Schedule[],string>({
            query:(request:string) => 'schedulebyName?request=' + request,
            providesTags:['scheduleLookup']
        }),
        scheduleSearch:builder.query<Schedule[],ScheduleSearchCommand>({
            query:(request:ScheduleSearchCommand) => 'scheduleSearch?centerId=' + request.centerId +'&searchCriteria=' + request.searchCriteria,
            providesTags:['scheduleSearch']
        }),
        scheduleSave:builder.mutation<Schedule,SchedulePostCommand>({
            query:(body) =>{
                return {url:'ScheduleUpdate',method:'POST', body:body,}
            }
        }),
        scheduleCreate:builder.mutation<Schedule,SchedulePostCommand>({
            query:(body) =>{
                return {url:'ScheduleCreate',method:'POST',body:body,}
            }
        }),
        procedureSave:builder.mutation<void,ProcedureUpdateCommand>({
            query:(body) => {
                return {url:'Procedure', method:'POST', body:body,}
            }
        }),
        cancelCase:builder.mutation<void,CancelCaseCommand>({
            query:(body) =>{
                return {url:'CancelCase', method:'POST',body:body,}
            }
        })
    })
})

export const {useCaseFindQuery,useScheduledCasesFindQuery, useScheduleSaveMutation, useScheduleCreateMutation
    ,useProcedureSaveMutation, useCancelCaseMutation, useScheduleLookupQuery,
    useScheduleSearchQuery} = scheduleApi;