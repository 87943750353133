import 'bootstrap/dist/css/bootstrap.css';
import '@progress/kendo-theme-material';
import './styles/Utility.css';
import './styles/Card.css'
import './styles/Grid.css'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as adal from './utils/AdalConfig';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { AuthenticationContext } from 'react-adal';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowAltCircleLeft, faCheckCircle, faSyncAlt, faComment, faCalculator, faQuestionCircle, faQuestion,
  faStopCircle, faFlag, faPencilRuler, faPlus, faArrowUp, faArrowDown, faArrowRight, faTrash, faBan, faHistory, faCogs, faList, faDollarSign, faExchangeAlt,
  faMoneyBillAlt, faMoneyCheckAlt, faDownload, faUpload, faRegistered, faFileDownload, faSpinner, faMinusCircle, faLock, faLockOpen, faSearch, faIdCard,
  faPrint } from '@fortawesome/free-solid-svg-icons';

library.add(faArrowAltCircleLeft, faCheckCircle, faSyncAlt, faComment, faCalculator, faQuestionCircle, faStopCircle, faFlag, faPencilRuler, faPlus, 
  faArrowUp, faArrowDown, faTrash, faBan, faHistory, faCogs, faList, faDollarSign, faExchangeAlt, faMoneyBillAlt, faMoneyCheckAlt, faDownload, faUpload,
  faRegistered, faQuestion, faArrowRight, faFileDownload, faSpinner, faMinusCircle, faLock, faLockOpen, faSearch, faIdCard,faPrint);


const rootElement = document.getElementById('root');
const DO_NOT_LOGIN = false;
function runWithAdal(authContext: AuthenticationContext, app: Function, donotLogin: boolean ) {
  try {authContext.handleWindowCallback();}
  catch(error) {console.log(error)}
  if (window === window.parent || (window as any).Cypress) {
      if (!authContext.isCallback(window.location.hash)) {
          if (!authContext.getCachedToken(authContext.config.clientId) ||
              (!(window as any).Cypress && !authContext.getCachedUser())) 
          {
              authContext.login();
          } else {
              
              app();
          }
      }
  }
}
runWithAdal(adal.authContext, ()=>{
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    rootElement);
  },DO_NOT_LOGIN)



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
