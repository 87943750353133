import { Approval } from "./Approval";
import { CheckInfo, DefaultCheckInfo } from "./CheckInfo";
import { DefaultRefundDetail, RefundDetail } from "./RefundDetail";
import { RefundDocument } from "./RefundDocuments";
import { RefundNote } from "./RefundNote";

export interface RefundRequest{
    id:string;
    caseId:string;
    accountNumber:string;
    lastName:string;
    firstName:string;
    center:string;
    visitKey:string;
    status:string;
    priority:string;
    sageStatus:string;
    refundDetail: RefundDetail;
    checkInfo: CheckInfo;
    notes:RefundNote[];
    documents:RefundDocument[];
    approvals:Approval[];
}

export function DefaultRefundRequest():RefundRequest{
    return {id:'', caseId:'', accountNumber:'', lastName:'',
     firstName:'', center:'', visitKey:'', status:'', sageStatus:'',
     refundDetail:DefaultRefundDetail(), priority:'Standard',
     checkInfo:DefaultCheckInfo(), notes:[],documents:[],approvals:[]}
}