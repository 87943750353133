import { Center } from "./Center";
import { Implants } from "./Implants";
import { MedicareBasedConfig } from "./MedicareBasedConfig";
import { Multiplier } from "./Multiplier";
import { Unspecified } from "./Unspecified";

export class Contract{
    id:string = '';
    payer:string = '';
    product:string='';
    financialClass:string='Unknown';
    calctype:string='Unknown';
    facilityType:string='Both';
    effectiveDate?:Date;
    endDate?:Date;
    medicareBasedConfig:MedicareBasedConfig = new MedicareBasedConfig();
    centers:Center[] = [];
    multipliers:Multiplier = new Multiplier();
    unspecified:Unspecified = new Unspecified();
    implants:Implants = new Implants();
}