import { Upload } from "@mui/icons-material";
import { Alert, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import React, { useState } from "react"
import { useParams } from "react-router";
import { AccountService } from "../../../../../utils/ServiceLocator";
import { accountApi } from "../../../../../services/accountService/account-api";
import { useAppDispatch } from "../../../../../app/hooks";

function RevRecImport(){
    const dispatch = useAppDispatch();
    const {center,period,year} = useParams();
    const [selectedFile, setSelectedFile] = useState();
	const [uploadComplete, setUploadComplete] = useState(false);
	const [uploadInProgress, setUploadInProgress] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const changeHandler = (event:any) =>{
        setSelectedFile(event.target.files[0]);
    }
    const handleSubmission=()=>{
        const formData = new FormData();
        setUploadInProgress(true);
        formData.append('File', selectedFile!);
        fetch(AccountService + 'UploadRevenueEndpoint/UploadRevenue/'+ center + '/' + period + '/' + year,{method: 'POST', body: formData,})
            .then((response) => {response.json(); setUploadComplete(true);setUploadInProgress(false);})
            .then((result) => {dispatch(accountApi.util.invalidateTags(['AccountSummary']));})
            .catch((error) => {console.error('Error:', error);});
    }

    return <React.Fragment>
        <Button variant='outlined' color='primary' onClick={()=>setIsOpen(true)} startIcon={<Upload/>}>Import</Button>

        <Dialog open={isOpen} onClose={()=>setIsOpen(false)}>
            <DialogTitle>Upload a Revenue Recognition File</DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                {uploadInProgress && <Alert color='warning'>We're processing your request. This may take a few minutes.</Alert>}
                {uploadComplete && <Alert color='success'>Upload Completed Successfully</Alert>}
                <input type='file' name='file' onChange={changeHandler}/>
                    <Button variant='outlined' startIcon={uploadInProgress ? '' : ''} onClick={handleSubmission}  disabled={selectedFile === undefined || uploadInProgress}>{!uploadInProgress ? 'Submit': <CircularProgress/>}</Button>
                    <Button variant='outlined' onClick={()=>{setUploadComplete(false); setIsOpen(false);}}>Close</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    </React.Fragment>
}
export {RevRecImport}