import {
  FormGroup,
  Box,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  DefaultDirectorResponseAction,
  DirectorResponseAction,
} from "../../../../../services/varianceService/model/varianceRequest/DirectorResponseAction";
import { editDirectorResponseActions } from "../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";

function DirectorResponseActions(props: { canEdit: boolean }) {
  const dispatch = useAppDispatch();
  const dirResponseActionState = useAppSelector(
    (state) => state.varianceRequest.varianceRequest.directorResponseAction
  );
  const [dirResponseAction, setDirResponseAction] = useState(
    dirResponseActionState
  );
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let newDirResponseAction: DirectorResponseAction =
      dirResponseAction || DefaultDirectorResponseAction();
    newDirResponseAction = {
      ...newDirResponseAction,
      [e.target.name]:
        (e.target as HTMLInputElement).type === "checkbox"
          ? (e.target as HTMLInputElement).checked
          : (e.target as HTMLInputElement).value,
    };
    setDirResponseAction(newDirResponseAction);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: 3,
        }}
      >
        <FormGroup>
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "10px",
            }}
          >
            <Grid container  direction="row"  justifyContent="center">
              <Grid item xs={3}>
                <FormControlLabel
                  label="Further Action Required"
                  control={
                    <Checkbox
                      disabled={!props.canEdit}
                      checked={dirResponseAction?.isFollowup}
                      name="isFollowup"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(
                          editDirectorResponseActions({
                            name: e.target.name,
                            value: e.target.checked as boolean,
                          })
                        );
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  label="Root Cause Identified"
                  control={
                    <Checkbox
                      disabled={!props.canEdit}
                      checked={dirResponseAction?.hasRootCause}
                      name="hasRootCause"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(
                          editDirectorResponseActions({
                            name: e.target.name,
                            value: e.target.checked as boolean,
                          })
                        );
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  label="Reportable Event"
                  control={
                    <Checkbox
                      disabled={!props.canEdit}
                      checked={dirResponseAction?.isReportable}
                      name="isReportable"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(
                          editDirectorResponseActions({
                            name: e.target.name,
                            value: e.target.checked as boolean,
                          })
                        );
                      }}
                    />
                  }
                />
                {dirResponseAction?.isReportable && (
                  <TextField
                    id="standard-basic"
                    name="reportAgency"
                    label="Agency"
                    variant="standard"
                    disabled={!props.canEdit}
                    value={dirResponseAction?.reportAgency}
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(
                        editDirectorResponseActions({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label="Board Recommendation"
                  control={
                    <Checkbox
                      disabled={!props.canEdit}
                      checked={dirResponseAction?.hasBoardRecommendation}
                      name="hasBoardRecommendation"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(
                          editDirectorResponseActions({
                            name: e.target.name,
                            value: e.target.checked as boolean,
                          })
                        );
                      }}
                    />
                  }
                />
                {dirResponseAction?.hasBoardRecommendation && (              
                <Stack marginTop={"20px"}>
                <label style={{ color: "grey" }}>Recommendation:</label>
                <br />
                <TextField
                  multiline
                  id="standard-basic"
                  name="boardRecommendation"
                  variant="standard"
                  disabled={!props.canEdit}
                  value={dirResponseAction?.boardRecommendation}
                  onChange={(e) => {
                    handleChange(e);
                    dispatch(
                      editDirectorResponseActions({
                        name: e.target.name,
                        value: e.target.value,
                      })
                    );
                  }}
                />
              </Stack>
                )}
              </Grid>
            </Grid>
          </Box>
        </FormGroup>
      </Box>
    </React.Fragment>
  );
}
export { DirectorResponseActions };
