import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { toggleProcedureEditModal } from "../../../../app/slices/OrgModuleSlice";
import { GridButton } from "../../../../components";

function EditProcedureCell(props: GridCellProps){
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <GridButton size='small'color='primary'  onClick={()=>dispatch(toggleProcedureEditModal(props.dataItem.id))}>
            View
        </GridButton>
    </React.Fragment>
}

export {EditProcedureCell}