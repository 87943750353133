import { Stack } from "@mui/material"
import React from "react"

function LowContribution(){
    return <React.Fragment>
        <Stack justifyContent='center' alignItems='center'>
            <iframe title="LowContribution" width="1350" height="800" 
                src="https://app.powerbi.com/reportEmbed?reportId=50cca498-94e7-4790-a7a5-9f363712de63&autoAuth=true&ctid=49423d5d-8d49-4128-b53d-59537808f6ac&navContentPaneEnabled=false"
                allowFullScreen={true}></iframe>
        </Stack>
    </React.Fragment>
}

export {LowContribution}