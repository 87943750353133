
export class FeeScheduleAddCommand{
    contractId:string;
    type:string;
    subType:string;
    description:string;
    effectiveDate:Date;
    endDate:Date;
    category:string;
    revCodeRestrictions:string[];

    constructor(contractId:string, type:string, description:string, effectiveDate:Date, 
        endDate:Date, subType:string, category:string, restrictions:string[]){
        this.contractId = contractId;
        this.description = description;
        this.effectiveDate = effectiveDate;
        this.endDate = endDate;
        this.type = type;
        this.subType = subType;
        this.category = category;
        this.revCodeRestrictions = restrictions;
    }
}