import React from "react"
import { Card, CardContent, CardHeader, Stack, TextField, Typography } from "@mui/material";
import { AccountCalcTypeAttr } from "./AccountCalcTypeAttr";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { editAccountPrimaryPayer } from "../../../../../services/accountService/model/account/AccountSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";


function AccountPayerView(){
    const dispatch = useAppDispatch();
    const account = useAppSelector((state)=>state.account.account);

    return <React.Fragment>
        <Card className="mt-2">
            <CardHeader sx={{marginBottom:'0px', paddingBottom:'3px',paddingTop:'3px', borderBottom:'solid', borderColor:'gray'}} title ={<Typography variant='subtitle1' color='gray'>Insurance</Typography>}/>
            <CardContent>
                <Stack direction='row' gap={2}>
                    <TextField label='Payer Id' value={account?.primaryPayerInfo.payerId} disabled/>
                    <TextField label='Payer Name' value={account?.primaryPayerInfo.payerName} disabled style={{width:400}}/>
                    <AccountCalcTypeAttr/>
                    <TextField label='Payer Group' value={account?.primaryPayerInfo.payerGroup} onChange={(e)=> dispatch(editAccountPrimaryPayer(new ReduxUpdate('payerGroup',e.target.value)))}/>
                </Stack>
                
            </CardContent>
        </Card>
    </React.Fragment>
}
export {AccountPayerView}