import React,{useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {CenterAddModal, CenterListGrid} from '../features';
import { useCenterListQuery } from "../../../services/orgService/org-api";
import { Loading } from '../../shared/features/Loading';
import { Stack} from '@mui/system';
import { Typography,Button as Mbutton, Tooltip, Card, CardContent  } from '@mui/material';

function OrgHome(){
    const {isLoading} = useCenterListQuery();
    const [centerAddModal,setCenterAddModal] = useState(false);
    const toggleModal=()=>{
        setCenterAddModal(centerAddModal === true ? false : true);}

    return <React.Fragment>   
                {isLoading === true ?
                <Loading/> :
                <div>
                        <Stack direction='row' justifyContent='space-between' sx={{marginBottom:'20px'}}>
                            <Typography alignSelf='center' variant="h6">Centers in your Organization</Typography>
                            <Tooltip title='Add a Center'>
                                <Mbutton variant='outlined' onClick={()=>toggleModal()}><FontAwesomeIcon icon='plus'/></Mbutton>
                            </Tooltip>
                        </Stack>
                <CenterListGrid/>
                </div>
            }
  
        <CenterAddModal isOpen={centerAddModal} toggleModal={toggleModal}/>
    </React.Fragment>  
}

export {OrgHome}
