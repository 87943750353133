import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { editVarianceIndividual } from "../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";
import { useMyProfileQuery } from "../../../../services/profileService/profile-api";

function IndividualDetails() {
  const dispatch = useAppDispatch();
  const individual = useAppSelector(
    (state) => state.varianceRequest.varianceRequest.individual
  );
  const incident = useAppSelector(state  => state.varianceRequest.varianceRequest);
  const { data } = useMyProfileQuery();
  const canEdit =():boolean=>{
    if(incident.status === "Complete"){return false;}
    if(data?.role === 'Admin'){return true;}
    if(data?.role === 'Quality Team'){return true;}
    if(data?.claimsAccess.find(p => p.code === 'IncidentQualityTeam') != undefined){return true;}
    if(data?.claimsAccess.find(p => p.code === 'IncidentAdmin') != undefined){return true;}
    if(data?.claimsAccess.find(p => p.code === 'IncidentEdit' && incident.status === "Started") != undefined){return true;}
    if(incident?.assignedUsers.find(p => p.upn === data?.upn) && incident.status === "Assigned"){return true;}
    return false;
}

  return (
    <React.Fragment>
      <Card sx={{ borderRadius: "10px", marginBottom: "10px" }}>
        <CardHeader
          sx={{
            marginBottom: "0px",
            paddingBottom: "3px",
            paddingTop: "3px",
            borderBottom: "solid",
            borderColor: "gray",
          }}
          title={
            <Typography variant="h6" color="gray">
              Individual Details
            </Typography>
          }
        />
        <CardContent>
          <Stack direction="row" gap={2} marginBottom="10px">
            <TextField
              disabled={!canEdit()}
              name="lastName"
              label="Last Name"
              value={individual.lastName}
              onChange={(e) =>
                dispatch(
                  editVarianceIndividual({
                    name: e.target.name,
                    value: e.target.value,
                  })
                )
              }
              size="small"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              disabled={!canEdit()}
              name="firstName"
              label="First Name"
              value={individual.firstName}
              onChange={(e) =>
                dispatch(
                  editVarianceIndividual({
                    name: e.target.name,
                    value: e.target.value,
                  })
                )
              }
              size="small"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              disabled={!canEdit()}
              name="age"
              label="Age"
              type="number"
              value={individual.age || null}
              onChange={(e) =>
                dispatch(
                  editVarianceIndividual({
                    name: e.target.name,
                    value: e.target.value,
                  })
                )
              }
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <TextField
              disabled={!canEdit()}
              name="homePhone"
              label="Home Phone"
              type="tel"
              value={individual.homePhone}
              onChange={(e) =>
                dispatch(
                  editVarianceIndividual({
                    name: e.target.name,
                    value: e.target.value,
                  })
                )
              }
              size="small"             
            />
            <TextField
              disabled={!canEdit()}
              name="mobilePhone"
              label="Mobile Phone"
              type="tel"
              value={individual.mobilePhone}
              onChange={(e) =>
                dispatch(
                  editVarianceIndividual({
                    name: e.target.name,
                    value: e.target.value,
                  })
                )
              }
              size="small"
              InputLabelProps={{ shrink: true }}             
            />
            <TextField
              disabled={!canEdit()}
              name="email"
              type="email"
              label="Email"
              value={individual.email}
              onChange={(e) =>
                dispatch(
                  editVarianceIndividual({
                    name: e.target.name,
                    value: e.target.value,
                  })
                )
              }
              size="small"
              sx={{ width: "400px" }}
              InputLabelProps={{ shrink: true }}             
            />
          </Stack>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export { IndividualDetails };
