import { TextField } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editAccountEncounterInfo } from "../../../../../services/accountService/model/account/AccountSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";

function AccountServiceCodeAttr(){
    const account = useAppSelector((state) => state.account.account);
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <TextField label='Service Code' value={account.encounterInfo.serviceCode}
            onChange={(e)=>dispatch(editAccountEncounterInfo(new ReduxUpdate('serviceCode',e.target.value)))}/>
    </React.Fragment>
}
export {AccountServiceCodeAttr}