import React, { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useRefundApproverSaveMutation, useRefundApproverSingleQuery } from "../../../../services/orgService/org-api";
import { DefaultRefundApprover } from "../../../../services/orgService/model/refundApprover/RefundApprover";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { UserSelector } from "../../../../components/profileComponents";
import { RefundApproverPostCommand } from "../../../../services/orgService/commands";

type ownProps={
    selectedApprover:string;
}
function RefundApproverEdit(props:ownProps){
    const [isOpen, setOpen] = React.useState(false);
    const [skip, setSkip] = React.useState(true);
    const {data} = useRefundApproverSingleQuery(props.selectedApprover,{skip,});
    const [approval,setApproval] = React.useState(DefaultRefundApprover());
    const [editApprover]=useRefundApproverSaveMutation();
    useEffect(()=>setSkip(!isOpen),[isOpen]);
    useEffect(()=>setApproval(data!),[data]);
    const save=()=>{
        let command = new RefundApproverPostCommand(approval);
        editApprover(command);
        setOpen(false);
    }

    return <React.Fragment>
        <Button
            onClick={()=>setOpen(true)}>{<Edit/>}</Button>
        
        <Dialog open={isOpen}>
            <DialogTitle>Edit Approvers</DialogTitle>
            <DialogContent>
                <Stack direction='row' marginTop='10px' gap={1}>
                    <TextField value={approval?.priority??0} label='Priority' type='number' sx={{width:'100px'}}
                        onChange={(e)=>setApproval({...approval,priority: parseInt(e.target.value)})}/>
                    <UserSelector value={approval?.approver} updateFunc={setApproval}/>
                    <TextField label='Approve Greater Than' type='number' value={approval?.approveGreaterThan}
                        InputProps={{startAdornment:'$'}}
                        onChange={(e)=>setApproval({...approval,approveGreaterThan: parseInt(e.target.value)})}/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={()=>setOpen(false)}>Cancel</Button>
                <Button onClick={()=>save()} >Save</Button>
            </DialogActions>
        </Dialog>
        
    </React.Fragment>
}

export {RefundApproverEdit}