import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { Alert, Button, Chip, Grid, TextField } from "@mui/material";
import { formatDate, formatNumber } from "@progress/kendo-intl";
import { accountApi, useSaveAdjustmentApprovalMutation } from "../../../../../services/accountService/account-api";
import { AccountApproveAdjustmentCommand } from "../../../../../services/accountService/commands";
import { useMyProfileQuery } from "../../../../../services/profileService/profile-api";

function AccountAdjustmentList(){
    const dispatch = useAppDispatch();
    const account = useAppSelector((state)=> state.account.account);
    const [save,status] = useSaveAdjustmentApprovalMutation();
    const profile = useMyProfileQuery();
    const canApprove = profile.data?.claimsAccess.find(p => p.code === 'OopAdjApprove') != undefined;
    const handleApprove = (id:string,isApproved:boolean)=>{
        let command = new AccountApproveAdjustmentCommand(account.id,id,isApproved);
        save(command); dispatch(accountApi.util.invalidateTags(['AdjustmentSummary']));
    }

    return <React.Fragment>
        {account.outOfPeriodAdjustments.length === 0 ? 
        <Alert severity='info'>No out of period adjustments found for this case</Alert>: 
        <Grid container spacing={1} marginLeft='5px'>
            <Grid item sm={1}><h6>Amount</h6></Grid>
            <Grid item sm={2}><h6>Period</h6></Grid>
            <Grid item sm={2}><h6>Reason</h6></Grid>
            <Grid item sm={2}><h6>Post Date</h6></Grid>
            <Grid item sm={2}><h6>Posted By</h6></Grid>
        </Grid>}
        {account.outOfPeriodAdjustments.map(i => (
            <Grid container spacing={1} sx={{mb:1}} marginLeft='5px'>
                <Grid item sm={1}><TextField value={formatNumber(i.amount,'c')} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                <Grid item sm={2}><TextField value={i.period +'-' +i.year} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                <Grid item sm={2}><TextField value={i.reason} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                <Grid item sm={2}><TextField value={formatDate(new Date(i.createdDate!),"MM/dd/yyyy")}sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                <Grid item sm={2}><TextField value={i.createdBy} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                <Grid item sm={1}><Chip label={i.isApproved?'Approved':'Pending'} variant='outlined' style={{color:i.isApproved?'green':'orangered'}}/></Grid>
                {canApprove &&
                <Grid item sm={1}><Button variant='outlined' color={i.isApproved?'warning':'primary'} onClick={()=>handleApprove(i.id,!i.isApproved)}>{i.isApproved?'UnApprove':'Approve'}</Button></Grid>}
            </Grid>
        
        ))}
    </React.Fragment>
}
export {AccountAdjustmentList}