import { Button, Card, CardActions, CardContent, CardHeader, MenuItem, Popper, Stack, TextField } from "@mui/material";
import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { QuestionMark } from "@mui/icons-material";
import { editCaseManClinicalReview } from "../../../../../services/caseManService/model/caseMan/CaseManagementSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";

function ImagingReportEdit(){
    const dispatch = useAppDispatch();
    const caseManage = useAppSelector((state)=>state.caseManagement.caseManagement);
    const[popout, setPopout] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    
    return <React.Fragment>
        <Stack direction='row' alignSelf='center'>
        <TextField label='Imaging Report' select defaultValue='Incomplete' sx={{width:'200px'}}
            value={caseManage.clinicalReview?.imagingReport} onChange={(e)=>dispatch(editCaseManClinicalReview(new ReduxUpdate('imagingReport',e.target.value)))}>
            <MenuItem value='Incomplete'>Incomplete</MenuItem>
            <MenuItem value='Pending'>Pending</MenuItem>
            <MenuItem value='Complete'>Complete</MenuItem>
            <MenuItem value='Not Required'>Not Required</MenuItem>
            <MenuItem value='Does Not Support'>Does Not Support</MenuItem>
        </TextField>
        <Button name='questions' startIcon={<QuestionMark/>} variant='text' onClick={(e)=>{setPopout(true);setAnchorEl(e.currentTarget)}}/>
        </Stack>
        <Popper open={popout} placement='right'  sx={{zIndex:100}} anchorEl={anchorEl}>
            <Card>
                <CardHeader title='Imaging Report'/>
                <CardContent>
                <ul>
                    <li>If present, fracture type</li>
                    <li>If present, arthritis and type</li>
                    <li>If present, loosening or mechanical failure of device/implant</li>
                    <li>If present, articular cartilage injury</li>
                    <li>If present, nerve root compression</li>
                    <li>If present, destructive lesions</li>
                </ul>
                </CardContent>
                <CardActions sx={{justifyContent:'center'}}><Button onClick={()=>setPopout(false)}>Close</Button></CardActions>
            </Card>
        </Popper>       
    </React.Fragment>
}
export {ImagingReportEdit}