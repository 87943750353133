import { TextField } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editCaseManDocumentRequest } from "../../../../../services/caseManService/model/caseMan/CaseManagementSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";

function CaseManDocRequestNote(){
    const dispatch = useAppDispatch();
    const caseMan = useAppSelector((state) => state.caseManagement.caseManagement);

    return <React.Fragment>
        <TextField label='Notes' multiline sx={{width:'50%'}} InputProps={{minRows:'5'}}
            value={caseMan.documentRequest?.note} 
            onChange={(e)=>dispatch(editCaseManDocumentRequest(new ReduxUpdate('note',e.target.value)))}/>
    </React.Fragment>
}   
export {CaseManDocRequestNote}