import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import {DatePicker} from '@mui/x-date-pickers';
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { Alert, Button, Card, CardContent, IconButton, MenuItem,  Snackbar, TextField, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useContractUpdateMutation } from "../../../../services/contractService/contract-api";
import { ContractUpdateCommand } from "../../../../services/contractService/commands";
import { editContractDetails, editEffectiveDate, editEndDate } from "../../../../services/contractService/model/contract/ContractSlice";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Save } from "@mui/icons-material";
import dayjs from "dayjs";

function Header(){
    const dispatch = useAppDispatch();
    const contract = useAppSelector((state) => state.contract.contract)
    const navigate = useNavigate();
    function adjustForTimezone(date:Date):Date{
        var timeOffsetInMS:number = date.getTimezoneOffset() * 60000;
        date.setTime(date.getTime() + timeOffsetInMS);
        return date
    }
    const [saveContract, status] = useContractUpdateMutation();
    const save = ()=>{
        let command = new ContractUpdateCommand(contract);
        saveContract(command);
    }
   const [notify, setNotify] = useState(false)
   const [nameEdit, setNameEdit] = useState(false);
   const [productEdit, setProductEdit] = useState(false);
   useEffect(()=>{setNotify(status.isSuccess)},[status.isSuccess]);
    return <React.Fragment>
            <Card>
                <div>
                    <Stack direction='row' justifyContent='space-between' alignItems='center' marginLeft='10px'>
                        <div>
                            {nameEdit === false &&
                            <Stack direction='row'gap={1}>
                                <Typography variant='h6' className='psn-green'>{contract.payer}</Typography>
                                <IconButton size='small' onClick={()=>setNameEdit(!nameEdit)}><ModeEditOutlinedIcon fontSize="small"/></IconButton>
                            </Stack>}
                            {nameEdit===true &&
                            <Stack direction='row' gap={1} marginTop='10px'>
                                <TextField label='Contract Name' name='payer' value={contract.payer} onChange={(e)=>dispatch(editContractDetails(e))}
                                    size='small' InputLabelProps={{shrink:true}}/>
                                 <IconButton size='small' onClick={()=>setNameEdit(!nameEdit)}><CheckCircleOutlineOutlinedIcon fontSize="small"/></IconButton>
                            </Stack>}
                        </div>
                        <div>
                            {productEdit === false &&
                            <Stack direction='row' gap={1}>
                                <Typography variant='h6' className="psn-green">{contract.product}</Typography>
                                <IconButton size='small' onClick={()=>setProductEdit(!productEdit)}><ModeEditOutlinedIcon fontSize="small"/></IconButton>
                            </Stack>}
                            {productEdit === true &&
                            <Stack direction='row' gap={1} marginTop='10px'>
                                <TextField label='Product Name' name='product' value={contract.product} onChange={(e:any)=>dispatch(editContractDetails(e))}
                                    size='small' InputLabelProps={{shrink:true}}/>
                                <IconButton size='small' onClick={()=>setProductEdit(!productEdit)}><CheckCircleOutlineOutlinedIcon fontSize="small"/></IconButton>
                            </Stack>
                            }
                        </div>
                        
                        <div>
                            <Tooltip title='Go Back'>
                            <Button variant="outlined" startIcon={<KeyboardDoubleArrowLeftIcon/>} color='success' onClick={()=>navigate('/ContractHome')} sx={{marginRight:'5px'}}>Back</Button></Tooltip>
                            <Tooltip title='Save'><Button startIcon={<Save/>} variant="outlined" color='primary' onClick={()=>save()} >{!status.isLoading ?'Save': 'Saving'}</Button></Tooltip>
                        </div>
                    </Stack>
                </div>
                <CardContent>
                    <Stack direction='row' gap={1}>
                        <DatePicker label='Effective Date' value={dayjs(contract.effectiveDate!)}
                             onChange={(e)=>dispatch(editEffectiveDate(e!.toDate()))} slotProps={{textField:{}}}/>
                        <DatePicker label='Renewal Date' value={dayjs(contract.endDate!)} 
                            onChange={(e)=> dispatch(editEndDate(e!.toDate()))} />

                        <TextField label='Financial Class' name='financialClass' select sx={{width:'250px'}}value={contract.financialClass} onChange={(e)=>dispatch(editContractDetails(e))}>
                            <MenuItem value='Unknown'>Unknown</MenuItem>
                            <MenuItem value='Commercial/OON'>Commercial/OON</MenuItem>
                            <MenuItem value='Managed Care - Aetna'>Managed Care - Aetna</MenuItem>
                            <MenuItem value='Managed Care - BCBS'>Managed Care - BCBS</MenuItem>
                            <MenuItem value='Managed Care - Cigna'>Managed Care - Cigna</MenuItem>
                            <MenuItem value='Managed Care - Humana'>Managed Care - Humana</MenuItem>
                            <MenuItem value='Managed Care - UHC'>Managed Care - UHC</MenuItem>
                            <MenuItem value='Managed Care - Other'>Managed Care - Other</MenuItem>
                            <MenuItem value='Medicare'>Medicare</MenuItem>
                            <MenuItem value='Managed Medicare'>Managed Medicare</MenuItem>
                            <MenuItem value='Medicaid'>Medicaid</MenuItem>
                            <MenuItem value='Managed Medicaid'>Managed Medicaid</MenuItem>
                            <MenuItem value='Tricare'>Tricare</MenuItem>
                            <MenuItem value='Workers Comp'>Workers Comp</MenuItem>
                            <MenuItem value='LOP'>LOP</MenuItem>
                            <MenuItem value='Bundled Payment'>Bundled Payment</MenuItem>
                            <MenuItem value='Research Cases'>Research Cases</MenuItem>
                            <MenuItem value='Self Pay'>Self Pay</MenuItem>
                        </TextField>

                        <TextField label='Calculator Type' name='calctype' select sx={{width:'250px'}} value={contract.calctype} onChange={(e)=>dispatch(editContractDetails(e))}>
                                <MenuItem value='Unknown'>Unknown</MenuItem>
                                <MenuItem value='Aetna'>Aetna</MenuItem>
                                <MenuItem value='Aetna Medicare'>Aetna Medicare</MenuItem>
                                <MenuItem value='Aetna Medicaid'>Aetna Medicaid</MenuItem>
                                <MenuItem value='Amerigroup'>Amerigroup</MenuItem>
                                <MenuItem value='BCBS BAV'>BCBS BAV</MenuItem>
                                <MenuItem value='BCBS Blue Essential'>BCBS Blue Essential</MenuItem>
                                <MenuItem value='BCBS HMO'>BCBS HMO</MenuItem>
                                <MenuItem value='BCBS Medicaid'>BCBS Medicaid</MenuItem>
                                <MenuItem value='BCBS Medicare'>BCBS Medicare</MenuItem>
                                <MenuItem value='BCBS NWB'>BCBS NWB</MenuItem>
                                <MenuItem value='BCBS PPO'>BCBS PPO</MenuItem>
                                <MenuItem value='BCBS Trad'>BCBS Trad</MenuItem>
                                <MenuItem value='Bundled'>Bundled</MenuItem>
                                <MenuItem value='Care N Care'>Care N Care</MenuItem>
                                <MenuItem value='Cigna'>Cigna</MenuItem>
                                <MenuItem value='Cigna Connect'>Cigna Connect</MenuItem>
                                <MenuItem value='Cigna Healthspring'>Cigna Healthspring</MenuItem>
                                <MenuItem value='Cigna Medicare'>Cigna Medicare</MenuItem>
                                <MenuItem value='Corvel'>Corvel</MenuItem>
                                <MenuItem value='Department of Labor'>Department of Labor</MenuItem>
                                <MenuItem value='Endeavor'>Endeavor</MenuItem>
                                <MenuItem value='GMMI'>GMMI</MenuItem>
                                <MenuItem value='HD Research'>HD Research</MenuItem>
                                <MenuItem value='Humana'>Humana</MenuItem>
                                <MenuItem value='Humana Medicare'>Humana Medicare</MenuItem>
                                <MenuItem value='LOP'>LOP</MenuItem>
                                <MenuItem value='Medicaid'>Medicaid</MenuItem>
                                <MenuItem value='Medicare'>Medicare</MenuItem>
                                <MenuItem value='MPower'>MPower/Triple AIM</MenuItem>
                                <MenuItem value='Multiplan'>Multiplan</MenuItem>
                                <MenuItem value='Multiplan Comm'>Multiplan Comm</MenuItem>
                                <MenuItem value='Multiplan WC'>Multiplan WC</MenuItem>
                                <MenuItem value='Oscar'>Oscar</MenuItem>
                                <MenuItem value='Other Commercial'>Other Commercial</MenuItem>
                                <MenuItem value='Research Source'>Research Source</MenuItem>
                                <MenuItem value='Superior Health Plan'>Superior Health Plan</MenuItem>
                                <MenuItem value='Surgery Plus'>Surgery Plus</MenuItem>
                                <MenuItem value='Self-Pay'>Self-Pay</MenuItem>
                                <MenuItem value='SWHP Comm'>SWHP Comm</MenuItem>
                                <MenuItem value='SWHP Managed Medicare'>SWHP Managed Medicare</MenuItem>
                                <MenuItem value ='SWHR'>SWHR</MenuItem>
                                <MenuItem value='Three Rivers'>Three Rivers</MenuItem>
                                <MenuItem value='Tricare'>Tricare</MenuItem>
                                <MenuItem value='TriWest'>TriWest</MenuItem>
                                <MenuItem value='UHC'>UHC</MenuItem>
                                <MenuItem value='UHC Medicaid'>UHC Medicaid</MenuItem>
                                <MenuItem value='UHC Medicare'>UHC Medicare</MenuItem>
                                <MenuItem value='Wellcare'>WellCare</MenuItem>
                                <MenuItem value='Wellmed'>Wellmed</MenuItem>
                                <MenuItem value='Workers Comp'>Workers Comp</MenuItem>
                            </TextField>

                            <TextField label='Facility Type' name='facilityType' select sx={{width: '150px'}} value={contract.facilityType} onChange={(e)=>dispatch(editContractDetails(e))}>
                                <MenuItem value='Both'>Both</MenuItem>
                                <MenuItem value='ASC'>ASC</MenuItem>
                                <MenuItem value='Hospital'>Hospital</MenuItem>
                            </TextField>

                    </Stack>
                </CardContent>
            </Card>
        <Snackbar open={notify} onClose={()=>setNotify(false)} color='success' autoHideDuration={6000} message='Contract successfully saved'/>
        <Snackbar open={status.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(status.error)}</Alert>
        </Snackbar>
    </React.Fragment>
}

export {Header}