import React from "react"
import { useAppSelector } from "../../app/hooks"
import { TextField } from "@mui/material";
import { formatDate } from "@progress/kendo-intl";

function ServiceDateDisplay(){
    const serviceDate = useAppSelector((state)=>state.schedule.schedule.serviceDate);

    return <React.Fragment>
        <TextField
            label='Service Date' value={serviceDate? formatDate(new Date(serviceDate),'MM/dd/yyyy') : undefined}
             disabled/>    
    </React.Fragment>
}

export {ServiceDateDisplay}