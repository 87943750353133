import React from "react"
import { useAppSelector } from "../../../../../../app/hooks"
import { Alert, Button, Snackbar } from "@mui/material";
import { PostAdd } from "@mui/icons-material";
import { VarianceDetailsValid } from "../../../../specs/VarianceDetailsValid";
import { VariancePostCommand } from "../../../../../../services/varianceService/commands/VariancePostCommand";
import { useVariancePendingMutation, useVarianceSaveMutation } from "../../../../../../services/varianceService/variance-api";
import { useMyProfileQuery } from "../../../../../../services/profileService/profile-api";
import { VariancePendingPostCommand } from "../../../../../../services/varianceService/commands/VariancePendingPostCommand";
import { useNavigate } from "react-router";

type ownProps ={
    setNotify:Function;
    setMessage:Function;}

function VarianceSubmitForApprovalBtn(props:ownProps){
    const profile = useMyProfileQuery();
    const variance = useAppSelector((state)=>state.varianceRequest.varianceRequest);
    const [incidentSave] = useVarianceSaveMutation();
    const [saveStatus, result] = useVariancePendingMutation();
    const [isValid, setIsValid] = React.useState(true);
    const command = new VariancePendingPostCommand(variance?.id, profile.data?.identity!, profile.data?.email!);
    const navigate = useNavigate();
    const handleSave=async ()=>{
        await incidentSave(new VariancePostCommand(variance));
        if(new VarianceDetailsValid().IsSatisfiedBy(variance)){
            setIsValid(true);
            await saveStatus(command).unwrap()
            .then(()=>{props.setNotify(true);
            props.setMessage('Incident Submitted for Approval')}
        ).then(() => navigate(-1));}
        else{setIsValid(false);}
    }
    return <React.Fragment>
        <Button variant='outlined' color='success' startIcon={<PostAdd/>}
            onClick={()=>{handleSave()}}>{result.isLoading?'Submitting':'Submit'}</Button>

        <Snackbar open={result.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(result.error)}</Alert>
        </Snackbar>
        <Snackbar open={!isValid} onClose={()=>setIsValid(true)} color='error' autoHideDuration={6000}>
            <Alert severity='error'>{'Invalid Incident: Confirm Details'}</Alert>
        </Snackbar>
        
    </React.Fragment>
}


export {VarianceSubmitForApprovalBtn}