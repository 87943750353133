import React from "react"
import { useRefundSaveMutation, useRefundStatusUpdateMutation } from "../../../../../../services/refundService/refund-api";
import { useAppSelector } from "../../../../../../app/hooks";
import { RefundPostCommand, RefundStatusPostCommand } from "../../../../../../services/refundService/commands";
import { Button } from "@mui/material";
import { Stop } from "@mui/icons-material";

type ownProps={
    setNotify:Function;
    setMessage:Function;}

function RefundHeldBtn(props:ownProps){
   const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const [refundSave] = useRefundSaveMutation();
    const [saveStatus, result] = useRefundStatusUpdateMutation();
    const handleHeld=async ()=>{
        await refundSave(new RefundPostCommand(refund));
        await saveStatus(new RefundStatusPostCommand(refund?.caseId,refund?.id,"HOLD"))
        .unwrap()
        .then(()=>{props.setNotify(true);props.setMessage("Refund Held")})
    }

    return <React.Fragment>
        <Button variant='outlined' color='warning' startIcon={<Stop/>}
            onClick={()=>handleHeld()}>Hold</Button>
    </React.Fragment>
}

export {RefundHeldBtn}