import React from "react"
import { useServiceListQuery } from "../../../../services/orgService/org-api";
import { CSVLink } from "react-csv";
import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";

type ownProps={
    center:string;
}

function ServiceExport(props:ownProps){
    const {data} = useServiceListQuery(props.center);

    const headers = [
        {label:'CenterId', key:'centerId'},
        {label:'Code',key:'code'},
        {label:'Description',key:'description'}
    ]
    return <React.Fragment>
        <CSVLink data={data ?? []} headers={headers} filename={'service-export.csv'} className='btn' style={{margin:'0px', padding:'0px'}}>
            <Button startIcon={<Download/>} variant='outlined'>Export</Button>
        </CSVLink>
    </React.Fragment>
}

export {ServiceExport}