import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import React from 'react';
import { DefaultSchedule, Schedule } from './schedule/Schedule';
import { scheduleApi } from '../schedule-api';
import { Insurance } from './schedule/Insurance';
import { Guid } from 'js-guid';
import { Procedure } from './schedule/Procedure';
import dayjs from 'dayjs';
import { PayerList } from '../../../modules/configuration/features';
import { ReduxUpdate } from '../../../utils/ReduxUpdateCommands';
import { Physician } from './schedule/Physician';


interface ScheduleState{
    schedule:Schedule;
}

const initialState: ScheduleState = {schedule: DefaultSchedule()}
export const ScheduleSlice = createSlice({
    name:'schedule',
    initialState,
    reducers:{
        resetSchedule:(state)=>{
            state.schedule = DefaultSchedule();
        },
        editSchedule:(state, action:PayloadAction<React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>>) =>{
            const {name, value} = action.payload.target;
            //@ts-ignore
            state.schedule[name]=value;
        },
        editScheduleV2:(state,action:PayloadAction<ReduxUpdate>)=>{
            //@ts-ignore
            state.schedule[action.payload.name] = action.payload.value;
        },
        editInsurance:(state, action:PayloadAction<ScheduleNestedUpdate>) =>{
            const{name, value} = action.payload.evnt.target;
            let newIns = state.schedule.insurances.filter(p => 1===1);
            const index = newIns.findIndex(p => p.id == action.payload.id );
            //@ts-ignore
            newIns[index][name] = value;
            if(newIns){
                state.schedule.insurances = newIns;
            } 
        },
        removeInsurance:(state,action:PayloadAction<string>)=>{
            let newIns = state.schedule.insurances.filter(p => p.id != action.payload);
            if(newIns){
                state.schedule.insurances = newIns;
            }
        },
        createInsurance:(state,action:PayloadAction<Insurance>)=>{
            if(state.schedule.insurances === undefined)
                state.schedule.insurances = [];
                action.payload.id = Guid.newGuid().toString();
                state.schedule.insurances.push(action.payload);
        },
        editProcedure:(state,action:PayloadAction<NestedUpdate>) => {
            const{name,value} = action.payload;
            const newProc = state.schedule.procedures.filter(p => 1===1);
            const index = newProc.findIndex(p => p.id == action.payload.id);
            //@ts-ignore
            newProc[index][name]=value;
            if(newProc)
                state.schedule.procedures = newProc;     
        },
        removeProcedure:(state,action:PayloadAction<string>)=>{
            let newProc = state.schedule.procedures.filter(p => p.id != action.payload);
            if(newProc)
                state.schedule.procedures = newProc;
        },
        createProcedure:(state,action:PayloadAction<Procedure>)=>{
            if(state.schedule.procedures === undefined)
                state.schedule.procedures = [];
            action.payload.id = Guid.newGuid().toString();
            state.schedule.procedures.push(action.payload);
        },
        editApptNote:(state,action:PayloadAction<string>)=>{
            if(state.schedule.visitNotes === undefined)
                state.schedule.visitNotes = [];
            let newNotes = state.schedule.visitNotes.filter(p => 1===1);
            const index = newNotes.findIndex(p => p.code == 'ApptNote');
            if(index > -1)
                {newNotes[index].description = action.payload;newNotes[index].code = 'ApptNote'; state.schedule.visitNotes=newNotes;}     
            else
                {state.schedule.visitNotes.push({code:'ApptNote',description:action.payload})}
        },
        editPhysician:(state, action:PayloadAction<Physician>)=>{
            state.schedule.physician = action.payload;
        }

    },
    extraReducers:(builder)=>{
        builder.addMatcher(
            scheduleApi.endpoints.caseFind.matchFulfilled,
            (state,{payload}) => {state.schedule = payload}
        )
    }
})

export const {resetSchedule, editInsurance, removeInsurance, createInsurance, editSchedule,
    editProcedure,removeProcedure,createProcedure, editApptNote, editScheduleV2, editPhysician} = ScheduleSlice.actions;
export default ScheduleSlice.reducer;

export class ScheduleNestedUpdate{
    id: string;
    evnt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

    constructor(id:string, evnt:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
        this.id = id;
        this.evnt = evnt;
    }
}

export class NestedUpdate{
    id:string;
    name:string;
    value:string |Date |Number|null|dayjs.Dayjs;

    constructor(id:string,name:string,value:string|Date|Number|null|dayjs.Dayjs){
        this.id = id; this.name = name; this.value = value;
    }
}
