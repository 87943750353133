import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ScheduleModuleState{
    EncounterTab:number;
    ProcedureDetailDialog:boolean;
    ProcedureCreateDialog:boolean;
    SelectedProcedure:string;
    InsuranceDetailDialog:boolean;
    InsuranceCreateDialog:boolean;
    SelectedInsurance:string;
}

const initialState:ScheduleModuleState={EncounterTab:0, SelectedInsurance:'',
    InsuranceDetailDialog:false, InsuranceCreateDialog:false, SelectedProcedure:'',
    ProcedureDetailDialog:false, ProcedureCreateDialog:false}

export const ScheduleModuleSlice = createSlice({
    name:'scheduleModule',
    initialState,
    reducers:{
        toggleEncounterTab:(state, action:PayloadAction<number>) =>{
            state.EncounterTab = action.payload;
        },
        toggleProcedureDetailDialog:(state, action:PayloadAction<string>) =>{
            state.ProcedureDetailDialog = !state.ProcedureDetailDialog;
            state.SelectedProcedure = action.payload;
        },
        toggleProcedureCreateDialog:(state)=>{
            state.ProcedureCreateDialog = !state.ProcedureCreateDialog;
        },
        toggleInsuranceDetailDialog:(state, action:PayloadAction<string>)=>{
            state.InsuranceDetailDialog = !state.InsuranceDetailDialog;
            state.SelectedInsurance = action.payload;
        },
        toggleInsuranceCreateDialog:(state)=>{
            state.InsuranceCreateDialog = !state.InsuranceCreateDialog;
        }
    }
})

export const {toggleEncounterTab, toggleProcedureDetailDialog, toggleProcedureCreateDialog,
    toggleInsuranceDetailDialog, toggleInsuranceCreateDialog}
     = ScheduleModuleSlice.actions;
export default ScheduleModuleSlice.reducer;