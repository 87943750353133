import { Grid, Stack, Typography } from "@mui/material"
import React from "react"
import { useAppSelector } from "../../../../../app/hooks";
import { EAI_Dialog } from "./EAI_Dialog";
import { useMyProfileQuery } from "../../../../../services/profileService/profile-api";

function ElectronicAuthImages(){
    const verification = useAppSelector((state)=>state.verification.verification);
    const profile = useMyProfileQuery();
    
    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' sx={{borderBottom:'2px solid gray'}} paddingBottom='5px' marginTop='10px'>
            <Typography variant='h6'   className='psn-green'>Images</Typography>
        </Stack>
        <Grid container>
            {verification?.waystarAuthStatus?.authStatusImages?.imageLocators?.map((image, index) => {
                return <Grid item key={index} sm={3}>
                    <EAI_Dialog pointer={image} index={index}/>
                </Grid>
            })}
        </Grid>
    </React.Fragment>
}
export {ElectronicAuthImages}