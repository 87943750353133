import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editAttributes } from "../../../../../services/verifyService/model/verification/VerificationSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";
import { Switch } from "@progress/kendo-react-inputs";

function ZeroReimbursementAttribute(){
    const dispatch = useAppDispatch();
    const verify = useAppSelector((state)=>state.verification.verification);
    return <React.Fragment>
        <div>
            <label>Zero Reimbursement</label><br/>
            <Switch name='zeroReimbursement' value={verify.verifyAttributes.isZeroReimbursement??false} 
                checked={verify.verifyAttributes.isZeroReimbursement} 
                onChange={(e)=>dispatch(editAttributes(new ReduxUpdate("isZeroReimbursement",e.target.value)))}/>
        </div>
    </React.Fragment>
}
export {ZeroReimbursementAttribute}