import { Button, Dialog, DialogContent, Stack, Typography } from "@mui/material"
import React, { useState } from "react"
import { IsSavingDisabled } from "../../../specs"
import { Cancel } from "@mui/icons-material"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { scheduleApi, useCancelCaseMutation } from "../../../../../services/scheduleService/schedule-api"
import { CancelCaseCommand } from "../../../../../services/scheduleService/commands"
import { useNavigate } from "react-router"

function CancelCase(){
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const schedule = useAppSelector((state)=>state.schedule.schedule);
    const [confirmation, setConfirmation] = useState(false);
    const [cancel] = useCancelCaseMutation();
    const save =()=>{
        let command = new CancelCaseCommand(schedule.center,schedule.visitKey);
        cancel(command).unwrap().then(()=>setConfirmation(false))
        .then(()=>dispatch(scheduleApi.util.invalidateTags(['scheduledCases'])))
        .then(()=>navigate(-1));
    }

    return <React.Fragment>
        <Button variant='outlined' color='error' startIcon={<Cancel/>}
            onClick={()=>setConfirmation(true)}
            disabled={new IsSavingDisabled().IsSatisfiedBy(schedule)}>Cancel Case</Button>

        <Dialog open={confirmation}>
            <DialogContent>
            <Typography>You are about to cancel this case.  Please confirm.</Typography>
            <Stack direction='row' justifyContent='space-between' marginTop='10px'>
                <Button color='secondary' onClick={()=>setConfirmation(false)}>Cancel</Button>
                <Button onClick={()=>save()}>Confirm</Button>
            </Stack> 
            </DialogContent>   
        </Dialog> 
    </React.Fragment>
}

export {CancelCase}