export class ContractQuickAddCommand{
    payer: string;
    product:string;
    effectiveDate?: Date|null;
    endDate?: Date|null;
    isEvergreen: boolean

    constructor(payer:string, product:string, effectiveDate?:Date|null, endDate?:Date|null, isEvergreen?:boolean){
        this.payer = payer;
        this.product = product;
        this.effectiveDate =effectiveDate;
        this.endDate = endDate;
        this.isEvergreen = isEvergreen ?? false;
    }
}