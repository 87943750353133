import { Card, CardActions, CardContent, Grid } from "@mui/material";
import React from "react"
import { useMyProfileQuery } from "../../../../services/profileService/profile-api"
import ScheduleIcon from '../../../../images/appicons/ScheduleIcon.png';

function ScheduleCard(){
    const {data} = useMyProfileQuery();
    const canAccess=():boolean=>{
        if(data?.role === 'Admin'){return true;}
        if(data?.claimsAccess.find(p => p.code === 'ScheduleEdit') != undefined) {return true;}
        return false;
    }


    return <React.Fragment>
        {canAccess() &&
            <Grid item sm={3}>
                <Card className='card-img-body center-align'>
                    <CardContent className='card-img-main'>
                    <a className='btn img-button' href={'/ScheduleHome'}><img className='card-img' src={ScheduleIcon} alt=''/></a>
                    </CardContent>
                    <CardActions className='card-img-footer'><h6>Schedule</h6></CardActions>
                </Card>
            </Grid>
        }
    </React.Fragment>
}

export {ScheduleCard}