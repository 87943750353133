import {
  Grid,
  GridColumn as Column,
  GridDataStateChangeEvent,
} from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { ColumnMenu } from "../../../../../utils/ColumnMenu";
import { process, State } from "@progress/kendo-data-query";
import { WitnessEditDialog } from "./WitnessEditDialog";
import { WitnessDeleteBtn } from "./WitnessDeleteBtn";

function WitnessDetails() {
  const data = useAppSelector(
    (state) => state.varianceRequest.varianceRequest.witnesses
  );
  const [dataState, setDataState] = useState<State>({});
  const createDataState = (dataState: State) => {
    return {
      dataState: dataState === undefined ? {} : dataState,
    };
  };
  const dataStateChange = (event: GridDataStateChangeEvent) => {
    let updatedState = createDataState(event.dataState);
    setDataState(updatedState.dataState);
  };

  return (
    <React.Fragment>
         
          <WitnessEditDialog />
            <Grid
              className="center-list-grid embeded-grid"
              data={process(data === undefined ? [] : data.slice(0), dataState)}
              {...dataState}
              style={{ height: "500px" }}
              onDataStateChange={dataStateChange}
            >
              <Column
                title=""
                width="100px"
                cell={(props) => (
                  <WitnessDeleteBtn {...props} />
                )}
              />
              <Column
                title="First Name"
                field="firstName"
                columnMenu={ColumnMenu}
              />
              <Column
                title="Last Name"
                field="lastName"
                columnMenu={ColumnMenu}
              />
              <Column
                title="Home Phone"
                field="homePhone"
                columnMenu={ColumnMenu}
              />
                            <Column
                title="Mobile Phone"
                field="mobilePhone"
                columnMenu={ColumnMenu}
              />
              <Column
                title="Email"
                field="email"
                columnMenu={ColumnMenu}
              />
            </Grid>
    </React.Fragment>
  );
}

export { WitnessDetails };
