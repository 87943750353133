import { AuthenticationContext, adalFetch, withAdalLogin, adalGetToken } from 'react-adal';

const hostname = window && window.location && window.location.hostname;
let redirectURI;

if (hostname === 'localhost') {
    redirectURI = 'http://localhost:3000';
}
else if (hostname === 'proud-ocean-08249da0f.2.azurestaticapps.net'){
    redirectURI = 'https://proud-ocean-08249da0f.2.azurestaticapps.net';
}
else if (hostname === 'proud-ocean-08249da0f-test.eastus2.2.azurestaticapps.net'){
    redirectURI = 'https://proud-ocean-08249da0f-test.eastus2.2.azurestaticapps.net';
}
else if (hostname === 'refunds-module.wonderfulsky-29006ae5.eastus.azurecontainerapps.io'){
    redirectURI = 'https://refunds-module.wonderfulsky-29006ae5.eastus.azurecontainerapps.io'
}
else {
    redirectURI = 'https://' + hostname;
}


const adalConfig = {
    tenant: 'psnaffiliates.com',
    clientId: '47eafd78-73cb-42ff-8907-3327b38852fc',
    extraQueryParameter: 'nux=1',
    endpoints: {
        api: 'https://psnaffiliates.com/49423d5d-8d49-4128-b53d-59537808f6ac'
    },
    cacheLocation: 'sessionStorage',
    redirectUri: redirectURI,
    popUp: false
};


export const authContext = new AuthenticationContext(adalConfig);


export const adalApiFetch = (fetch, url, options) =>
    adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

export function getToken() {
    adalGetToken(authContext,authContext.config.clientId);
    let tok = authContext.getCachedToken(authContext.config.clientId);
    return tok;
}