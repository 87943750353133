export const HealthCareITOptions = [
    {
        description: "Combination or Interaction of Device Defect and user error",
        name: "CombinationOrInteraction",
    },
    {
        description: "Equipment/Device Function",
        name: "EquipmentDeviceFunction",
    },
    {
        description: "Equipment/Device Maintenance",
        name: "EquipmentDeviceMaintenance",
    },
    {
        description: "Ergonomics, Including Human/Device Interface issue",
        name: "ErgonomicsIncludingHuman",
    },
    {
        description: "Hardware Failure or Problem",
        name: "HardwareFailure",
    },
    {
        description: "Incompatibility Between Devices",
        name: "IncompatibilityBetweenDevices",
    },
    {
        description: "Network Failure or Problem",
        name: "NetworkFailure",
    },
    {
        description: "No Response/Assistance from IT Vendor",
        name: "NoResponseAssistanceFromITVendor",
    },
    {
        description: "Security, Virus or Other Malware Issue",
        name: "SecurityVirusOrOtherMalwareIssue",
    },
    {
        description: "Unable to Access",
        name: "UnableToAccess",
    },
    {
        description: "Unexpected Software Design Issue",
        name: "UnexpectedSoftwareDesignIssue",
    },
    {
        description: "Unknown Device Defect or Failure",
        name: "UnknownDeviceDefectOrFailure",
    },
];