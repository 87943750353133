import React, { useState } from "react"
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { process, State } from "@progress/kendo-data-query";
import { Worklist } from "../../../../services/verifyService/model";
import { ColumnMenu } from "../../../../utils/ColumnMenu";
import { LookupCommandCell } from "./LookupCommandCell";

type ownProps = {
    data: Worklist[]
}
function LookupResultsGrid(props:ownProps){
    const [dataState, setDataState] = useState<State>({});
    const mapCases = ():Worklist[] => {
        return props.data.map(i => ({...i, serviceDate: new Date(i.serviceDate)}))
    }
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };


    return <React.Fragment>
        <Grid data={process(mapCases().slice(0),dataState)}{...dataState}
             style={{height:'500px'}} onDataStateChange={dataStateChange}>
                <Column cell={LookupCommandCell} width='75px'/>
                <Column title='Account' field='account' columnMenu={ColumnMenu}/>
                <Column title='Patient' field = 'patient' columnMenu={ColumnMenu}/>
                <Column title='Service Date' field="serviceDate" format='{0:MM/dd/yyyy}' columnMenu={ColumnMenu}/>
        </Grid>
    </React.Fragment>
}

export{LookupResultsGrid}