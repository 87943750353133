import { AddCircleOutline} from "@mui/icons-material"
import { Button, Dialog, DialogContent, DialogTitle, MenuItem, Stack, TextField } from "@mui/material"
import React from "react"
import { DefaultOutOfPeriodAdjustment, OutOfPeriodAdjustment } from "../../../../../services/accountService/model/account/OutOfPeriodAdjustment"
import { accountApi, useFindAdjustmentReasonsQuery, useSaveOutOfPeriodAdjustmentMutation } from "../../../../../services/accountService/account-api"
import { useAppDispatch } from "../../../../../app/hooks"
import { addOutOfPeriodAdjustment } from "../../../../../services/accountService/model/account/AccountSlice"
import { AccountOutOfPeriodAdjustmentPostCommand } from "../../../../../services/accountService/commands/AccountOutOfPeriodAdjustmentPostCommand"
import { useParams } from "react-router"
import { parseNumber } from "@progress/kendo-intl"

function AccountOPAAddDialog(){
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const [open, setOpen] = React.useState(false)
    const[reason,setReason] = React.useState('OTHER')
    const [amount, setAmount] = React.useState('0')
    const [adjustment, setAdjustment] = React.useState<OutOfPeriodAdjustment>(DefaultOutOfPeriodAdjustment)
    const {data} = useFindAdjustmentReasonsQuery();
    const [saveAdjustment] = useSaveOutOfPeriodAdjustmentMutation();
    const handleSubmit = ()=>{
        var command = new AccountOutOfPeriodAdjustmentPostCommand(id!,adjustment.period,adjustment.year,Number(amount),reason);
        saveAdjustment(command).unwrap().then((data)=>{
            setOpen(false);setAdjustment(DefaultOutOfPeriodAdjustment);
            dispatch(accountApi.util.invalidateTags(['AdjustmentSummary']));
        });
    }


    return <React.Fragment>
        <Button variant='outlined' color='primary' onClick={()=>{setOpen(true)}}><AddCircleOutline/></Button>

        <Dialog open={open} onClose={()=>{setOpen(false)}} maxWidth='xl'>
            <DialogTitle>Add Out of Period Adjustment</DialogTitle>
            <DialogContent>
                <Stack direction='row' gap={1} marginTop='10px'>
                <TextField label='Amount' type='number' value={amount} onChange={(e) => {setAmount(i => (e.target.value))}}/>
                    <TextField select label='Period' value={adjustment.period} onChange={(e)=>{setAdjustment(i => ({...i,period:e.target.value}))}} style={{width:'220px'}}>
                        <MenuItem value='1'>1 - January</MenuItem>
                        <MenuItem value='2'>2 - February</MenuItem>
                        <MenuItem value='3'>3 - March</MenuItem>
                        <MenuItem value='4'>4 - April</MenuItem>
                        <MenuItem value='5'>5 - May</MenuItem>
                        <MenuItem value='6'>6 - June</MenuItem>
                        <MenuItem value='7'>7 - July</MenuItem>
                        <MenuItem value='8'>8 - August</MenuItem>
                        <MenuItem value='9'>9 - September</MenuItem>
                        <MenuItem value='10'>10 - October</MenuItem>
                        <MenuItem value='11'>11 - November</MenuItem>
                        <MenuItem value='12'>12 - December</MenuItem>
                    </TextField>
                    <TextField label='Year' value={adjustment.year} onChange={(e)=>{setAdjustment(i => ({...i,year:e.target.value}))}}/>
                    <TextField label='Reason' value={reason} sx={{width:'300px'}} onChange={(e)=>setReason(e.target.value)}/>
                    <Button variant='outlined' color='primary' size='small' onClick={()=>handleSubmit()}>Add</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    </React.Fragment>
}
export {AccountOPAAddDialog}