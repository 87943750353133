export const MedicalComplicationOptions = [
    {
        description: "Burn",
        name: "Burn",
    },
    {
        description: "Cardiac Arrest During Procedure",
        name: "CardiacArrestDuringProcedure",
    },
    {
        description: "Cardiac or Circulatory Event",
        name: "CardiacOrCirculatoryEvent",
    },
    {
        description: "Central Nervous System Event",
        name: "CentralNervousSystemEvent",
    },
    {
        description: "Complications of Anesthesia",
        name: "ComplicationsOfAnesthesia",
    },
    {
        description: "Complications of Surgery/Procedure",
        name: "ComplicationsOfSurgeryProcedure",
    },
    {
        description: "Laceration/Puncture",
        name: "LacerationPuncture",
    },
    {
        description: "Loss/Impairment of Body Functions",
        name: "LossImpairmentOfBodyFunctions",
    },
    {
        description: "Loss/Delayed Results During Procedure",
        name: "LossDelayedResultsDuringProcedure",
    },
    {
        description: "Perforation",
        name: "Perforation",
    },
    {
        description: "Post-Operative AMI (Unrelated to Cardiac Procedure)",
        name: "PostOperativeAMIUnrelatedToCardiacProcedure",
    },
    {
        description: "Respiratory Failure Requiring Unplanned Respiratory support, Within 24 Hours after the Procedure",
        name: "RespiratoryFailureRequiringUnplannedRespiratorySupportWithin24HoursAfterTheProcedure",
    },
    {
        description: "Tissue Trauma from Procedure",
        name: "TissueTraumaFromProcedure",
    },
];