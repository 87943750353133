import { Stack, TextField, Typography } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editCaseManClinicalReview} from "../../../../../services/caseManService/model/caseMan/CaseManagementSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";

function AdditionalCommentsEdit(){
    const dispatch = useAppDispatch();
    const caseManage = useAppSelector((state)=>state.caseManagement.caseManagement);
    return <React.Fragment>
        <Stack marginBottom='10px'>
            <Typography variant='body2'>Additional Comments: </Typography>
            <TextField fullWidth multiline rows={6} variant='outlined'
                value={caseManage.clinicalReview?.comments}
                onChange={(e)=>dispatch(editCaseManClinicalReview(new ReduxUpdate("comments",e.target.value)))}
                disabled={caseManage.clinicalReviewStatus! === 'APPROVED'}/>
        </Stack>
    </React.Fragment>
}

export {AdditionalCommentsEdit}