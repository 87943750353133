import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid,  Button as Butt, TextField, InputAdornment, Alert, Checkbox } from "@mui/material";
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { ProcedureUpdate } from "../../../../../services/verifyService/commands";
import { demoteProcedure, promoteProcedure, removeProcedure, updateProcedure } from "../../../../../services/verifyService/model/verification/VerificationSlice";
import { ReduxNestedUpdate } from "../../../../../utils/ReduxUpdateCommands";

function CptCodes(){
    const dispatch = useAppDispatch();
    const verification = useAppSelector((state)=> state.verification.verification);

    return <React.Fragment>
        {verification.procedures.length === 0 &&
            <Alert className='ms-2 me-2 mt-2' color="warning">No Procedure Codes have been added to this case.</Alert>
        }
        {verification.procedures.length > 0 && 
        <Grid container spacing={1} marginLeft='5px'>
            <Grid item sm={2}><h6>Actions</h6></Grid>
            <Grid item sm={.75}><h6>Priority</h6></Grid>
            <Grid item sm={1}><h6>Code</h6></Grid>
            <Grid item sm={4}><h6>Description</h6></Grid>
            <Grid item sm={1}><h6>Bill Amount</h6></Grid>
            <Grid item sm={1}><h6>Reimbursement</h6></Grid>
            <Grid item sm={1}><h6>Implant Cost</h6></Grid>
            <Grid item sm={1}><h6>Self Pay</h6></Grid>
        </Grid>
        }
        {verification.procedures.map(i=>(
            <Grid container spacing={1} sx={{mb:1}} marginLeft='5px'>
                <Grid item sm={2}>
                    <Butt color='error' variant='outlined' size='large' sx={{height:'40px',mr:0.5}} onClick={()=>dispatch(removeProcedure(i.priority))}><FontAwesomeIcon icon='minus-circle'/></Butt>
                    <Butt variant='outlined' size='large' sx={{height:'40px',mr:0.5}} onClick={()=>dispatch(promoteProcedure(i.priority))} disabled={i.priority === 1}><FontAwesomeIcon icon='arrow-up'/></Butt>
                    <Butt variant='outlined' size='large' sx={{height:'40px'}} onClick={()=>dispatch(demoteProcedure(i.priority))} disabled={i.priority >= verification.procedures.length}><FontAwesomeIcon icon='arrow-down'/></Butt>
                </Grid>
                <Grid item sm={.75}>
                    <TextField value={i.priority} size='small' InputLabelProps={{shrink:false}} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/>
                </Grid>
                <Grid item sm={1}>
                    <TextField value={i.code} size='small' InputLabelProps={{shrink:false}} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/>
                </Grid>
                <Grid item sm={4}>
                    <TextField value={i.description} size='small' InputLabelProps={{shrink:false}} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/>
                </Grid>
                <Grid item sm={1}>
                    <TextField name='billAmount' value={i.billAmount} size='small' onChange={(e)=>dispatch(updateProcedure(new ReduxNestedUpdate(i.priority.toString(),'billAmount',isNaN(Number(e.target.value)) ? 0 : Number(e.target.value))))}
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth
                        InputProps={{startAdornment:<InputAdornment sx={{backgroundColor:'#E9ECEF'}} position='start'>$</InputAdornment>}}/>
                </Grid>
                <Grid item sm={1}>
                    <TextField name='reimbursement' value={i.reimbursement} size='small' onChange={(e)=>dispatch(updateProcedure(new ReduxNestedUpdate(i.priority.toString(),'reimbursement',isNaN(Number(e.target.value)) ? 0 : Number(e.target.value))))}
                         sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth
                         InputProps={{startAdornment:<InputAdornment sx={{backgroundColor:'#E9ECEF'}} position='start'>$</InputAdornment>}}/>
                </Grid>
                <Grid item sm={1}>
                <TextField name='implantCost' value={i.implantCost} size='small' onChange={(e)=>dispatch(updateProcedure(new ReduxNestedUpdate(i.priority.toString(),'implantCost',isNaN(Number(e.target.value)) ? 0 : Number(e.target.value))))}
                         sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth
                         InputProps={{startAdornment:<InputAdornment sx={{backgroundColor:'#E9ECEF'}} position='start'>$</InputAdornment>}}/>
                </Grid>
                <Grid item sm={1}>
                    <Checkbox size='small' name='selfPay' value={i.selfPay} checked={i.selfPay} onChange={(e)=>dispatch(updateProcedure(new ReduxNestedUpdate(i.priority.toString(),'selfPay',e.target.checked)))}/>
                </Grid>
            </Grid>
        ))}
        
    </React.Fragment> 

}

export {CptCodes}