import { KeyboardDoubleArrowLeft } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react"
import { useNavigate } from "react-router"
import { useAppDispatch } from "../../../../../app/hooks";
import { refundApi } from "../../../../../services/refundService/refund-api";
import { scheduleApi } from "../../../../../services/scheduleService/schedule-api";

function RefundRequestBack(){
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    return <React.Fragment>
        <Button startIcon={<KeyboardDoubleArrowLeft/>} color='success' variant='outlined' 
            onClick={()=>{navigate(-1);
                dispatch(refundApi.util.invalidateTags(['worklist','refund']));
                dispatch(scheduleApi.util.invalidateTags(['case']))}}>Go Back</Button>
    </React.Fragment>
}

export{RefundRequestBack}