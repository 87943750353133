import { Table } from "@mui/material";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import React from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router";
import { useAppSelector } from "../../../app/hooks";
import { toggleFeeScheduleTab } from "../../../app/slices/ContractModuleSlice";
import { useFeeScheduleFindQuery } from "../../../services/contractService/contract-api";
import { FeeScheduleHeader, DrgItemAdd, CptCodes, FeeSummary } from "../features"
import { DrgCodes } from "../features/feeScheduleDetail/drgCodes/DrgCodes";
import { Groupers } from "../features/feeScheduleDetail/groupers/Groupers";

function FeeScheduleDetail(){
    const dispatch = useDispatch();
    const tabIndex = useAppSelector((state) => state.contractModule.feeScheduleTab);
    const toggleTab = (e:TabStripSelectEventArguments) => dispatch(toggleFeeScheduleTab(e.selected));
    const {feeScheduleId} = useParams();
    const {data} = useFeeScheduleFindQuery(feeScheduleId!);

    return <React.Fragment>
        <FeeScheduleHeader/>

        <TabStrip selected={tabIndex} onSelect={toggleTab}>
            {data?.feeType === 'DRG' &&
            <TabStripTab title='Codes'>
                <Table/>
                <DrgCodes/>
            </TabStripTab>}
            {data?.feeType === 'DRG' &&
            <TabStripTab title='Base Rates'>
                <Table/>
                <Groupers/>
            </TabStripTab>}
            {data?.feeType === 'CPT' &&
            <TabStripTab title='Summary'>
                <FeeSummary/>
                <Table/>
            </TabStripTab>
            }
            {data?.feeType === 'CPT' &&
            <TabStripTab title='Code Lookup'>
                <Table/>
                <CptCodes/>
            </TabStripTab>}
            {(data?.feeType === 'CPT' && data?.feeSubType === 'Grouped') &&
            <TabStripTab title='Groupers'>
                <Table/>
                <Groupers/>
            </TabStripTab>}

        </TabStrip>
        <DrgItemAdd/>
    </React.Fragment>
}

export {FeeScheduleDetail}