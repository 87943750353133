import React from "react";
import { useAppSelector } from "../../../../../../app/hooks";
import { useMyProfileQuery } from "../../../../../../services/profileService/profile-api";
import { Alert, Button, Snackbar } from "@mui/material";
import { VariancePostCommand } from "../../../../../../services/varianceService/commands/VariancePostCommand";
import { ApprovalRounded } from "@mui/icons-material";
import {
  useVarianceApproveMutation,
  useVarianceSaveMutation,
} from "../../../../../../services/varianceService/variance-api";
import { useNavigate } from "react-router";
import { VarianceApprovalPostCommand } from "../../../../../../services/varianceService/commands/VarianceApprovalPostCommand";

type ownProps = {
  setNotify: Function;
  setMessage: Function;
};

function VarianceApproveBtn(props: ownProps) {
  const incident = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  const [varianceSave] = useVarianceSaveMutation();
  const [saveStatus, result] = useVarianceApproveMutation();
  const profile = useMyProfileQuery();
  const command = new VarianceApprovalPostCommand(incident?.id, profile.data?.identity!, profile.data?.email!);
  const navigate = useNavigate();
  const handleApprove = async () => {
    await varianceSave(new VariancePostCommand(incident));
    await saveStatus(command)
      .unwrap()
      .then(() => {
        props.setNotify(true);
        props.setMessage("Incident Approved");
      });
      setTimeout(() => {
        navigate(-1);
      }, 3000);
  };
  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="success"
        startIcon={<ApprovalRounded />}
        onClick={() => handleApprove()}
      >
        Approve
      </Button>

      <Snackbar open={result.isError}>
        <Alert color="error">
          {"An error has occured: " + JSON.stringify(result.error)}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export { VarianceApproveBtn };
