import { Stack } from "@mui/material"
import React from "react"
import { ScaAmountEdit } from "./ScaAmountEdit"
import { ScaMethodEdit } from "./ScaMethodEdit"
import { ScaStatusEdit } from "./ScaStatusEdit"
import { ScaFiledEdit } from "./ScaFiledEdit"
import { ScaNotesEdit } from "./ScaNotesEdit"

function SingleCaseAgreement(){
    return <React.Fragment>
        <Stack gap={4} marginTop='20px' marginLeft='10px'>
        <Stack direction='row' gap={1} >
            <ScaAmountEdit/>
            <ScaMethodEdit/>
            <ScaStatusEdit/>
            <ScaFiledEdit/>
        </Stack>
            <ScaNotesEdit/>
        </Stack>
        
    </React.Fragment>
}
export {SingleCaseAgreement}