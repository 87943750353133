import { caseManageApi } from "../../caseManage-api";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CaseManagement, DefaultCaseMan } from "./CaseManagement";
import { ReduxNestedUpdate, ReduxUpdate } from "../../../../utils/ReduxUpdateCommands";

interface CaseManagementState{
    caseManagement:CaseManagement;
}
const initialState: CaseManagementState = {caseManagement: DefaultCaseMan()}

export const CaseManagementSlice = createSlice({
    name:'caseManagement',
    initialState,
    reducers:{
        editClinicalReview:(state, action:PayloadAction<ReduxUpdate>)=>{
            //@ts-ignore
            state.caseManagement.clinicalReviewCheck[action.payload.name] = action.payload.value;
        },
        editClinicalReviewStatus:(state,action:PayloadAction<ReduxUpdate>)=>{
            //@ts-ignore
            state.caseManagement.clinicalReviewStatus = action.payload.value;
        },
        editCaseManDiagnosisCodes:(state,action:PayloadAction<ReduxUpdate>)=>{
            //@ts-ignore
            state.caseManagement.codingDetails.diagnosisCodes[action.payload.name] = action.payload.value;
        },
        editCaseManClinicalReview:(state,action:PayloadAction<ReduxUpdate>)=>{
            //@ts-ignore
            state.caseManagement.clinicalReview[action.payload.name] = action.payload.value;
        },
        editCaseManDocumentRequest:(state,action:PayloadAction<ReduxUpdate>)=>{
            //@ts-ignore
            state.caseManagement.documentRequest[action.payload.name] = action.payload.value;
        },
        editPolicyLinks:(state,action:PayloadAction<ReduxNestedUpdate>)=>{
            let links = state.caseManagement.policyLinks?.filter(p => 1==1);
            const index = links?.findIndex(link => link.policyId === action.payload.index);
            //@ts-ignore
            links[index][action.payload.name] = action.payload.value;
            if(links){state.caseManagement.policyLinks = links;}
        }
    },
    extraReducers: (builder) =>{
        builder.addMatcher(
            caseManageApi.endpoints.findCaseMan.matchFulfilled,
            (state,{payload}) => {state.caseManagement = payload}
        ).addMatcher(
            caseManageApi.endpoints.caseManNoteAdd.matchFulfilled,
            (state,{payload}) => {state.caseManagement.notes.push(payload)}
        ).addMatcher(
            caseManageApi.endpoints.caseManStatusUpdate.matchFulfilled,
            (state,{payload}) => {state.caseManagement = payload}
        ).addMatcher(
            caseManageApi.endpoints.caseManCallAdd.matchFulfilled,
            (state,{payload}) => {state.caseManagement.callLogs.push(payload)}
        )
    }
});

export const { editClinicalReview, editClinicalReviewStatus, editCaseManDiagnosisCodes,
    editCaseManClinicalReview, editPolicyLinks, editCaseManDocumentRequest} = CaseManagementSlice.actions;
export default CaseManagementSlice.reducer;