import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { toggleRefundInitiateDialog } from "../../../../app/slices/RefundModuleSlice";
import { RefundInitiateSearch } from "./RefundInitiateSearch";

function RefundInitiate(){
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state)=>state.refundModule.RefundInitiateDialogOpen);
    return <React.Fragment>
        <Button variant='contained' sx={{background:'#62BCBD'}}
            onClick={()=>dispatch(toggleRefundInitiateDialog())}
            >Initiate Refund</Button>

        <Dialog open={isOpen} maxWidth='xl'>
            <DialogTitle>Initiate Refund</DialogTitle>
            <DialogContent>
                <RefundInitiateSearch/>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={()=>dispatch(toggleRefundInitiateDialog())}>Close</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}
export {RefundInitiate}
