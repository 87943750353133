import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface VarianceModuleState {
  TabStripPosition: number;
  VarianceNoteDialogOpen: boolean;
  VarianceDocumentDialogOpen: boolean;
  VarianceIndividualTypeModalOpen: boolean;
  VarianceSelectedIndividualType: string;
  VarianceInitiatePatientSearchDialogOpen: boolean;
  VarianceIdentificationDetailsDialogOpen: boolean;
  VarianceSelectedIncidentTypes: string;
  VarianceSelectedIncidentTypeDetail: [string, boolean];
  IncidentCommentAddModal:boolean;
  IncidentWitnessModalOpen:boolean;
  IncidentAssignUserModalOpen:boolean;
  IncidentRejectCommentModalOpen:boolean;
  IncidentDocumentDialogOpen:boolean;
  IncidentReportingDepartmentDialogOpen:boolean;
}

const initialState: VarianceModuleState = {
  TabStripPosition: 0,
  VarianceNoteDialogOpen: false,
  VarianceDocumentDialogOpen: false,
  VarianceIndividualTypeModalOpen: false,
  VarianceSelectedIndividualType: "",
  VarianceSelectedIncidentTypes: "",
  VarianceInitiatePatientSearchDialogOpen: false,
  VarianceIdentificationDetailsDialogOpen: false,
  VarianceSelectedIncidentTypeDetail: ["", false],
  IncidentCommentAddModal:false,
  IncidentWitnessModalOpen:false,
  IncidentAssignUserModalOpen:false,
  IncidentRejectCommentModalOpen:false,
  IncidentDocumentDialogOpen:false,
  IncidentReportingDepartmentDialogOpen:false
};

export const VarianceModuleSlice = createSlice({
  name: "VarianceModuleSlice",
  initialState,
  reducers: {
    setTabStripPosition: (state, action: PayloadAction<number>) => {
      state.TabStripPosition = action.payload;
    },
    setVarianceSelectedIncidentTypes: (
      state,
      action: PayloadAction<string>
    ) => {
      state.VarianceSelectedIncidentTypes = action.payload;
    },
    toggleIndividualTypeModal: (state) => {
      state.VarianceIndividualTypeModalOpen =
        !state.VarianceIndividualTypeModalOpen;
    },
    toggleVarianceSelectedIdentType: (state, action: PayloadAction<string>): void => {
      state.VarianceSelectedIndividualType = action.payload;
      state.VarianceInitiatePatientSearchDialogOpen =
      state.VarianceSelectedIndividualType === "Patient";
    state.VarianceIdentificationDetailsDialogOpen =
      state.VarianceSelectedIndividualType === "Visitor" ||
      state.VarianceSelectedIndividualType === "Other";
    },
    toggleInitiatePatientSearchDialog: (state) => {
      state.VarianceInitiatePatientSearchDialogOpen = 
      !state.VarianceInitiatePatientSearchDialogOpen;
    },
    toggleIdentificationDetailsDialog: (state) => {
      state.VarianceIdentificationDetailsDialogOpen =
        !state.VarianceIdentificationDetailsDialogOpen;
    },
    toggleIncidentTypeDetailOption: (state, action: PayloadAction<[string, boolean]>) => {
      state.VarianceSelectedIncidentTypeDetail = action.payload;
    },
    toggleIncidentCommentAddModal:(state) =>{
      state.IncidentCommentAddModal = !state.IncidentCommentAddModal;
    },
    toggleIncidentRejectCommentModal:(state) =>{
      state.IncidentRejectCommentModalOpen = !state.IncidentRejectCommentModalOpen;
    },
    toggleWitnessModal:(state) =>{
      state.IncidentWitnessModalOpen = !state.IncidentWitnessModalOpen;
    },
    toggleIncidentAssignUserDialog: (state) => {
      state.IncidentAssignUserModalOpen = !state.IncidentAssignUserModalOpen;
    },
    toggleIncidentDocumentDialog:(state) =>{
      state.IncidentDocumentDialogOpen = !state.IncidentDocumentDialogOpen;
    },
    toggleIncidentReportingDepartmentDialog:(state) =>{
      state.IncidentReportingDepartmentDialogOpen = !state.IncidentReportingDepartmentDialogOpen;
    }
  },
});

export const {
  setTabStripPosition,
  setVarianceSelectedIncidentTypes,
  toggleIndividualTypeModal,
  toggleVarianceSelectedIdentType,
  toggleInitiatePatientSearchDialog,
  toggleIdentificationDetailsDialog,
  toggleIncidentTypeDetailOption,
  toggleIncidentCommentAddModal,
  toggleWitnessModal,
  toggleIncidentAssignUserDialog,
  toggleIncidentRejectCommentModal,
  toggleIncidentDocumentDialog,
  toggleIncidentReportingDepartmentDialog
} = VarianceModuleSlice.actions;

export default VarianceModuleSlice.reducer;
