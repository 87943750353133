import React from "react"
import { useParams } from "react-router"
import { useCaseFindQuery } from "../../../../../../services/scheduleService/schedule-api";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import { useCalculateEligibilityMutation } from "../../../../../../services/calculatorService/calculator-Api";
import { EligibilityCalcRequest } from "../../../../../../services/calculatorService/model";
import { CompareArrows } from "@mui/icons-material";
import { useMyProfileQuery } from "../../../../../../services/profileService/profile-api";

function EligibilityDataBtn(){
    const {id} = useParams();
    const schedule = useCaseFindQuery(id!);
    const profile = useMyProfileQuery();
    let primaryPayer = [...schedule.data!.insurances].sort((a,b) => a.priority - b.priority)[0];
    const [findEligibility,{isLoading}] = useCalculateEligibilityMutation()
    const [isUpdating, setIsUpdating] = React.useState(false);

    const getData = () => {
        setIsUpdating(true);
        const command = new EligibilityCalcRequest(id!,primaryPayer.payerCode);
        findEligibility(command).unwrap().then(()=>setIsUpdating(false));
    }
    return <React.Fragment>
        <Tooltip title='Populate Eligibility Data'>
        <Button color='info' variant='outlined' startIcon={isUpdating?<CircularProgress size={20}/>:<CompareArrows/>}
            onClick={()=>getData()}></Button>
        </Tooltip>    
    </React.Fragment>
}
export {EligibilityDataBtn}