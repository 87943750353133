// 
export const IVOptions = [
    {
        description: "Broken/Impaired Device",
        name: "BrokenImpairedDevice",
    },
    {
        description: "Circulation Impeded",
        name: "CirculationImpeded",
    },
    {
        description: "Disconnected",
        name: "Disconnected",
    },
    {
        description: "Discontinued",
        name: "Discontinued",
    },
    {
        description: "Dislodgement",
        name: "Dislodgement",
    },
    {
        description: "Infiltration/Extravasation",
        name: "InfiltrationExtravasation",
    },
    {
        description: "Line Not changed",
        name: "LineNotChanged",
    },
    {
        description: "Localized Reaction",
        name: "LocalizedReaction",
    },
    {
        description: "Multiple Attempts",
        name: "MultipleAttempts",
    },
    {
        description: "Occlusion",
        name: "Occlusion",
    },
    {
        description: "Perforation",
        name: "Perforation",
    },
    {
        description: "Phlebitis",
        name: "Phlebitis",
    },
    {
        description: "Severed",
        name: "Severed",
    },
    {
        description: "Site Infection",
        name: "SiteInfection",
    },
    {
        description: "Wrong Insertion Location",
        name: "WrongInsertionLocation",
    },
    {
        description: "Wrong Size Inserted",
        name: "WrongSizeInserted",
    },
    {
        description: "Unable to access",
        name: "UnableToAccess",
    },
];