import React, { useState } from "react"
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import { ColumnMenu } from "../../../../utils/ColumnMenu";
import { AccountCenterSummaryRequest } from "../../../../services/accountService/commands/AccountCenterSummaryRequest";
import { useFindAccountCenterSummaryQuery } from "../../../../services/accountService/account-api";
import { AccountCenterSummaryCommandCell } from "./AccountCenterSummaryCommandCell";
import { useAppSelector } from "../../../../app/hooks";
import { Loading } from "../../../shared";


function AccountCenterSummaryList(){
    var period = useAppSelector((state)=>state.accountModule.Period)
    var year = useAppSelector((state)=>state.accountModule.Year)
    var {data, isFetching, isLoading} = useFindAccountCenterSummaryQuery(new AccountCenterSummaryRequest(Number(period),Number(year)));

    const defaultGridState:State = {sort:[{field:'name',dir:'asc'}]};
    const [dataState, setDataState] = useState(defaultGridState);
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };
      

    return <React.Fragment>
        {isFetching || isLoading ? <Loading/> :
        <Grid data={process(data === undefined ? [] : data, dataState)} style={{height:'750px'}} 
            onDataStateChange={dataStateChange}>
            <Column cell={AccountCenterSummaryCommandCell} width={'75px'}/>
            <Column title='Name' field='name' width={'200px'} columnMenu={ColumnMenu}/>
            <Column title='Month' field='period' width={'150px'} columnMenu={ColumnMenu}/>
            <Column title='Year' field='year' width={'150px'} columnMenu={ColumnMenu}/>
            <Column title='Charges' field='totalCharges' format='{0:c}' width={'200px'} columnMenu={ColumnMenu}/>
            <Column title='Projected Reimb' field='totalExpectedReimbursement' format='{0:c}' width={'200px'} columnMenu={ColumnMenu}/>
            <Column title='Final Reimb.' field='adjustedRevenue' format='{0:c}' width={'200px'} columnMenu={ColumnMenu}/>
            <Column title='Case Count' field='totalCaseCount'  width={'150px'} columnMenu={ColumnMenu}/>
            <Column title='Status' field='' width={'150px'} columnMenu={ColumnMenu}/>

        </Grid>}
    </React.Fragment>
}
export {AccountCenterSummaryList}