import React from "react"
import { InsuranceList } from "./InsuranceList"
import { Button, Stack } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useAppDispatch } from "../../../../../app/hooks";
import { InsuranceCreate } from "./InsuranceCreate";
import { toggleInsuranceCreateDialog } from "../../../../../app/slices/ScheduleModuleSlice";
import { useCenterFindQuery } from "../../../../../services/orgService/org-api";

type ownProps = {
    center:string;
}
function Insurances(props:ownProps){
    const dispatch = useAppDispatch();
    const readonly = !useCenterFindQuery(props.center).data?.allowManualScheduling;
    return <React.Fragment>
        <Stack direction='row' justifyContent='flex-end' marginTop='5px'>
            {!readonly &&
            <Button variant='outlined' startIcon={<Add/>} onClick={()=>dispatch(toggleInsuranceCreateDialog())}>Add Insurance</Button>}
        </Stack>
        <InsuranceList center={props.center}/>
        
    </React.Fragment>
}

export {Insurances}