import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { MenuItem, TextField } from "@mui/material";
import { editScheduleV2 } from "../../../../../services/scheduleService/model/ScheduleSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";
import { useCenterFindQuery } from "../../../../../services/orgService/org-api";

type ownProps={center:string}
function AdmissionTypeSelector(props:ownProps){
    const dispatch = useAppDispatch();
    const schedule = useAppSelector((state)=>state.schedule.schedule);
    const readonly = !useCenterFindQuery(props.center).data?.allowManualScheduling;
    
    return <React.Fragment>
        <TextField label='Admission Type' sx={{width:'225px',maxWidth:'100%'}}
            value={schedule.admissionType} select disabled={readonly}
            onChange={(e)=>dispatch(editScheduleV2(new ReduxUpdate('admissionType',e.target.value)))}>
                <MenuItem value='OP'>Outpatient</MenuItem>
                <MenuItem value='IP'>Inpatient</MenuItem>
            </TextField>
    </React.Fragment>
}

export{AdmissionTypeSelector}