export class SameDayScheduledCommand {
    centerId: string;
    serviceDate: Date;
    physicianName: string;

    constructor(centerId: string, serviceDate: Date, physicianName: string) {
        this.centerId = centerId;
        this.serviceDate = serviceDate;
        this.physicianName = physicianName;
    }
}