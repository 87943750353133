import { Stack } from "@mui/material"
import React from "react"
import { CaseManCallAddModal } from "./CassManCallAddModal"
import { CaseManCallList } from "./callList/CaseManCallList"
import { CaseManNoCallAlert } from "./callList/CaseManNoCallAlert"

function CaseManCallLog(){
    return <React.Fragment>
        <Stack direction='row' justifyContent='flex-end' margin='5px'>
            <CaseManCallAddModal/>
        </Stack>
        <CaseManNoCallAlert/>
        <CaseManCallList/>
    </React.Fragment>
}

export{CaseManCallLog}