export const FallOptions = [
    {
        description: "Witnessed",
        name: "Witnessed",
    },
    {
        description: "UnWitnessed",
        name: "UnWitnessed",
    },
    {
        description: "Found on floor",
        name: "FoundOnFloor",
    },
    {
        description: "While ambulating: Assisted",
        name: "WhileAmbulatingAssisted",
    },
    {
        description: "While ambulating: Unassisted",
        name: "WhileAmbulatingUnassisted",
    },
    {
        description: "Fall from: Chair",
        name: "FallFromChair",
    },
    {
        description: "Fall from: BSC",
        name: "FallFromBSC",
    },
    {
        description: "Fall from: Toilet",
        name: "FallFromToilet",
    },
    {
        description: "Fall from: WC",
        name: "FallFromWC",
    },
    {
        description: "Fall from: Getting in/out of bed",
        name: "FallFromGettingInOutOfBed",
    },
    {
        description: "Fall from: Getting in/out of shower",
        name: "FallFromGettingInOutOfShower",
    },
    {
        description: "Fall from: Sitting at side of bed",
        name: "FallFromSittingAtSideOfBed",
    },
    {
        description: "Fall from: Stretcher",
        name: "FallFromStretcher",
    },
    {
        description: "Fall / slip on floor",
        name: "FallSlipOnFloor",
    },
];