import React, { useState } from "react"
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { VerificationViewCell } from "./VerificationViewCell";
import { useNavigate, useParams } from "react-router";
import { useWorklistQuery } from "../../../../services/verifyService/verify-api";
import { ColumnMenu } from "../../../../utils/ColumnMenu";
import { Worklist } from "../../../../services/verifyService/model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Typography, Button } from "@mui/material";
import { WorklistRequest } from "../../../../services/verifyService/commands";
import { process, State } from "@progress/kendo-data-query";
import { useMyProfileQuery } from "../../../../services/profileService/profile-api";

function BenefitsWorklist(){
    const {type,center} = useParams();
    const {data} = useWorklistQuery(new WorklistRequest(type!,center!));
    const profile = useMyProfileQuery();
    const navigate = useNavigate();
    const mapCases=():Worklist[]=>{
        let cases:Worklist[]=data===undefined ? [] : data?.map(i => ({...i, serviceDate: new Date(i.serviceDate)}))
        return cases;
    }
    
    const defaultGridState:State = {sort:[{field:'serviceDate',dir:'asc'}]};
    const [dataState, setDataState] = useState(defaultGridState);
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };
      const canAccess=():boolean=>{
        if(profile.data?.role === 'Admin'){return true;}
        if(profile.data?.role==='RevCycle Admin'){return true;}
        return false;
    }

      
    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography className="psn-green" variant='h6'>{type + ' Worklist'}</Typography>
            <Button variant='outlined' onClick={()=>navigate(-1)} color='success'><FontAwesomeIcon icon={'arrow-left'}/><span className="ms-1">Go Back</span></Button>
        </Stack>

        <Grid data = {process(mapCases(),dataState)}
         style={{height:'750px'}} onDataStateChange={dataStateChange}>
            <Column cell={VerificationViewCell} width={'75px'}/>
            <Column title='Patient' field='patient' columnMenu={ColumnMenu} width={'200px'}/>
            <Column title='Account' field='account' columnMenu={ColumnMenu} width={'150px'}/>
            <Column title='Service Date' field='serviceDate' columnMenu={ColumnMenu} format='{0:MM/dd/yyyy}' filter="date" width={'140px'}/>
            <Column title='Physician' field='physician' columnMenu={ColumnMenu} width={'150px'}/>
            <Column title='Type' field="admitType" columnMenu={ColumnMenu} width={'100px'}/>
            <Column title='Admit Source' field='admitSource' columnMenu={ColumnMenu} width={'180px'}/>
            <Column title='Payer' field="payerName" columnMenu={ColumnMenu} width={'250x'}/>
            {(type == 'Authorization' || type=='Authorization Denied') &&
            <Column title='Auth Status' field='authStatus' columnMenu={ColumnMenu} width={'200px'}/>}
            {(type == 'Authorization' || type=='Authorization Denied') && 
            <Column title='EAR Status' field='earStatus' columnMenu={ColumnMenu} width={'200px'}/>}
            {type == 'Financial Orientation' &&
            <Column title='Call Count' field='callCount' columnMenu={ColumnMenu} width={'150px'}/>           
            }
        </Grid>
    </React.Fragment>
}

export {BenefitsWorklist}