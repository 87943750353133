import {Button } from "@mui/material";
import {GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useAppDispatch } from "../../../../app/hooks";
import { togglePayerEditModal } from "../../../../app/slices/OrgModuleSlice";

function ViewCommandCell(props:GridCellProps){
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <Button size='small' sx={{alignSelf:'center', marginTop:'5px', marginLeft:'5px', textTransform:'unset'}} onClick={()=>dispatch(togglePayerEditModal(props.dataItem.id))}>
            View
        </Button>
    </React.Fragment>
}

export {ViewCommandCell}