import { Schedule } from "../../../services/scheduleService/model/schedule/Schedule";
import { CompositeSpecification } from "../../../utils/Specification";

export class EncounterMustBePopulated extends CompositeSpecification<Schedule>{
    IsSatisfiedBy(candidate: Schedule): boolean {
        if(candidate.accountNumber != null && candidate.accountNumber != undefined && candidate.accountNumber != '')
            return true;
        
        return false;
    }
    
}