import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { Grid, TextField, Typography } from "@mui/material";

function AccountBookedProceduresList(){
    const account = useAppSelector((state) => state.account.account);
    const sortedBookedProcedures = [...account.bookedProcedures.lines].sort((a, b) => a.priority - b.priority);
    
    return <React.Fragment>
    {account.bookedProcedures.lines.length > 0 && <div>
            <Grid container spacing={1} marginLeft='5px'>
                <Grid item sm={1}><h6>Priority</h6></Grid>
                <Grid item sm={1}><h6>Units</h6></Grid>
                <Grid item sm={1}><h6>Code</h6></Grid>
                <Grid item sm={4}><h6>Description</h6></Grid>
                <Grid item sm={2}></Grid>
            </Grid>
            {sortedBookedProcedures.map(i=>(
                <Grid container spacing={1} sx={{mb:1}} marginLeft='5px'>
                    <Grid item sm={1}><TextField value={i.priority} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                    <Grid item sm={1}><TextField value={i.units} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                    <Grid item sm={1}><TextField value={i.procedureCode} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                    <Grid item sm={4}><TextField value={i.procedureDescription} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                </Grid>
            ))}
            </div>}
    </React.Fragment>
}

export { AccountBookedProceduresList }