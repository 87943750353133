import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Delete from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { CenterRemoveCommand } from "../../../../../services/contractService/commands";
import { useCenterDetachMutation } from "../../../../../services/contractService/contract-api";

function CenterRemoveCommandCell(props:GridCellProps){
    const contract = useAppSelector((state) => state.contract.contract);
    const [remove] = useCenterDetachMutation();
    const detach = () => {
        const command = new CenterRemoveCommand(contract.id,props.dataItem.code, props.dataItem.name);
        remove(command);
    }
    return <React.Fragment>
        <Button className="ms-1 mt-1" variant='outlined' color='error'
            onClick={()=> detach()}><Delete/></Button>
    </React.Fragment>
}

export {CenterRemoveCommandCell}