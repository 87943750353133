export const DischargeOptions = [
    {
        description: "Discharged documents not signed by patient/family",
        name: "DischargedDocuments",
    },
    {
        description: "Discharge medications without time/date of last dose and next dose due",
        name: "DischargeMedications",
    },
    {
        description: "Discharged without Patient Education",
        name: "DischargedWithoutPatientEducation",
    },
    {
        description: "Discharged with Unauthorized Person",
        name: "DischargedWithUnauthorizedPerson",
    },
    {
        description: "Discharged without Instructions",
        name: "DischargedWithoutInstructions",
    },
    {
        description: "Discharged without Orders",
        name: "DischargedWithoutOrders",
    },
    {
        description: "Discharged Wrong Patient",
        name: "DischargedWrongPatient",
    },
    {
        description: "No Responsible Adult Available at Discharge",
        name: "NoResponsibleAdult",
    },
    {
        description: "No Safe Discharge Plan",
        name: "NoSafeDischargePlan",
    },
    ];