import { process, State } from "@progress/kendo-data-query";
import {  Grid, GridColumn as Column,GridCellProps, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { toggleCenterAccessModal } from "../../../../app/slices/ProfileModuleSlice";
import { Profile } from "../../../../services/profileService/model/profile/Profile";
import { useCenterListQuery } from "../../../../services/orgService/org-api";
import { ColumnMenu } from "../../../../utils/ColumnMenu";
import { CenterAccessEditCommandCell } from "./CenterAccessEditCommand";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";

type ownProps = {
    profile:Profile;
}
function CenterAccessEdit(props:ownProps){
    const modalOpen = useAppSelector((state)=> state.profileModule.centerAccessModal);
    const dispatch = useAppDispatch();
    const {data} = useCenterListQuery();
    const [dataState, setDataState] = useState<State>({});
    const [profile] = useState(props.profile);
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };
    const customEdit = (props: GridCellProps) => (<CenterAccessEditCommandCell {...props} profile = {profile}/>)
    return <React.Fragment>
        <Dialog open={modalOpen} maxWidth='xl'>
            <DialogTitle>Edit Access</DialogTitle>
            <DialogContent className="card-body-grid">
                <Grid className='center-list-grid embedded-grid' data={process(data === undefined ? [] :data.slice(0),dataState)}{...dataState} style={{height:'500px'}}
                    onDataStateChange={dataStateChange}>
                    <Column cell={customEdit} width='100px'/>
                    <Column title='Code' field ='code' width='150px' columnMenu={ColumnMenu}/>
                    <Column title='Name' field = 'name' columnMenu={ColumnMenu}/>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Stack direction='row' justifyContent='flex-end'>
                    <Button variant='outlined' onClick={()=>dispatch(toggleCenterAccessModal())}>Close</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export {CenterAccessEdit}