import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { TextField } from "@mui/material";
import { editSingleCaseAgreement } from "../../../../../services/verifyService/model/verification/VerificationSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";

function ScaMethodEdit(){
    const dispatch = useAppDispatch();
    const verify = useAppSelector((state) => state.verification.verification);

    return <React.Fragment>
        <TextField label='Method' value = {verify.singleCaseAgreement?.method} 
            onChange={(e)=>dispatch(editSingleCaseAgreement(new ReduxUpdate('method', e.target.value)))}/>
    </React.Fragment>
}
export {ScaMethodEdit}