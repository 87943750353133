import { Stack, Typography } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { Switch } from "@progress/kendo-react-inputs";
import { editAppConfig } from "../../../../services/orgService/model/center/CenterSlice";
import { ReduxUpdate } from "../../../../utils/ReduxUpdateCommands";

function ManualScheduling(){
    const dispatch = useAppDispatch();
    const center = useAppSelector((state)=>state.center.center);

    return <React.Fragment>
        <Stack marginBottom='5px'>
            <Typography color='gray'>Allow Manual Scheduling</Typography>
            <Switch value={center.allowManualScheduling} checked={center.allowManualScheduling}
                onChange={(e)=>dispatch(editAppConfig(new ReduxUpdate("allowManualScheduling",e.target.value)))}/>  
        </Stack>  
    </React.Fragment>
}

export {ManualScheduling}