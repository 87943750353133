import React, { useState } from "react";
import {
  Grid,
  GridColumn as Column,
  GridDataStateChangeEvent,
} from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import { VarianceSchedule } from "../../../../../services/varianceService/model/varianceRequest/extensions/Schedule";
import { VarianceInitiateCommand } from "./VarianceInitiateCommand";

type ownProps = {
  data: VarianceSchedule[];
};
function VarianceInitiatePatientResults(props: ownProps) {
  const [dataState, setDataState] = useState<State>({});
  const mapCases = (): VarianceSchedule[] => {
    return props.data.map((i) => ({
      ...i,
      age: i.birthDate
        ? new Date().getFullYear() - new Date(i.birthDate).getFullYear()
        : 0,
      mrn: i.accountNumber.split("-")[0],
    }));
  };
  const createDataState = (dataState: State) => {
    return {
      dataState: dataState === undefined ? {} : dataState,
    };
  };
  const dataStateChange = (event: GridDataStateChangeEvent) => {
    let updatedState = createDataState(event.dataState);
    setDataState(updatedState.dataState);
  };

  return (
    <React.Fragment>
      <Grid
        data={process(mapCases() ?? [].slice(0), dataState)}
        {...dataState}
        onDataStateChange={dataStateChange}
        style={{ height: "500px" }}
        groupable={false}
      >
        <Column cell={VarianceInitiateCommand} />
        <Column title="Mrn" field="mrn" />
        <Column title="Center" field="center" />
        <Column title="Last Name" field="lastName" />
        <Column title="First Name" field="firstName" />
        <Column title="Age" field="age" format="{0:d}" />
        <Column title="Gender" field="sex" />
        {false && <Column title="Home Phone" field="homePhone" />}
        {false && <Column title="Mobile Phone" field="mobilePhone" />}
        {false && <Column title="Email" field="email" />}
      </Grid>
    </React.Fragment>
  );
}

export { VarianceInitiatePatientResults };
