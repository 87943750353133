export const SeverityItems = [
    { group: "NO_ERROR", value: "CircumstancesOrEvents", description: "Circumstances or events that have the capacity to cause error" },
    { group: "ERROR_INTERCEPTED", value: "ErrorDidNotReachPatient", description: "An error occurred but did not reach the patient. (An error of omission does reach the patient)" },
    { group: "ERROR_NO_HARM", value: "ErrorReachedPatientNoHarm", description: "An error occurred that reached the patient but did not cause patient harm" },
    { group: "ERROR_NO_HARM", value: "ErrorReachedPatientMonitoring", description: "An error occurred that reached the patient and required monitoring to confirm that it resulted in no harm to the patient and/or required intervention to preclude harm" },
    { group: "ERROR_HARM", value: "ErrorTemporaryHarm", description: "An error occurred that may have contributed to or resulted in temporary harm to the patient and required intervention" },
    { group: "ERROR_HARM", value: "ErrorTemporaryHarmHospitalization", description: "An error occurred that may have contributed to or resulted in temporary harm to the patient and required initial or prolonged hospitalization" },
    { group: "ERROR_HARM", value: "ErrorPermanentHarm", description: "An error occurred that may have contributed to or resulted in permanent patient harm" },
    { group: "ERROR_HARM", value: "ErrorInterventionToSustainLife", description: "An error occurred that required intervention to sustain life" },
    { group: "ERROR_DEATH", value: "ErrorPatientDeath", description: "An error occurred that may have contributed to or resulted in the patient's death" }
];