import { FormControlLabel, MenuItem, Switch, TextField } from "@mui/material"
import { Stack } from "@mui/system"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editMedicareBase } from "../../../../../services/contractService/model/contract/ContractSlice";
import { useContractsForCalculatorQuery } from "../../../../../services/contractService/contract-api";

function MedicareConfig(){
    const dispatch = useAppDispatch();
    const contract = useAppSelector((state)=>state.contract.contract);
    const medicareContracts = useContractsForCalculatorQuery("Medicare");
    return <React.Fragment>
        <Stack justifyContent='flex-start' alignItems='flex-start' gap={2} marginLeft='10px' marginTop='10px'>
            <FormControlLabel sx={{justifyContent:'flex-start', alignItems:'flex-start'}}
                control = {<Switch size="small"  name='basedOnMedicare' value={contract.medicareBasedConfig.basedOnMedicare} checked={contract.medicareBasedConfig.basedOnMedicare} onChange={(e)=>dispatch(editMedicareBase(e))} />}
                label='Contract Based on Medicare' labelPlacement="top"/> 
            <TextField label='Medicare Contract' name='medicareContractId' select size='small' sx={{width:'250px'}} InputLabelProps={{shrink:true}}
                value={contract.medicareBasedConfig.medicareContractId} onChange={(e)=>dispatch(editMedicareBase(e))}> 
                <MenuItem value=''>Unknown</MenuItem>
                {medicareContracts.data?.map(i => (
                    <MenuItem value={i.id}>{i.payer}</MenuItem>
                ))}
            </TextField>
            <Stack direction='row' gap={1}>
                <TextField label='Percent of Medicare' name='percentOfMedicare' value={contract.medicareBasedConfig.percentOfMedicare} onChange={(e)=>dispatch(editMedicareBase(e))} 
                    InputLabelProps={{shrink:true}} size='small'/>
                <TextField label='Inpatient Conversion Factor' name='inpatientConversionFactor' value={contract.medicareBasedConfig.inpatientConversionFactor} onChange={(e)=>dispatch(editMedicareBase(e))} 
                    InputLabelProps={{shrink:true}} size='small'/>
                <TextField label='Outpatient Conversion Factor' name='outpatientConversionFactor' value={contract.medicareBasedConfig.outpatientConversionFactor} onChange={(e)=>dispatch(editMedicareBase(e))} 
                    InputLabelProps={{shrink:true}} size='small'/>

            </Stack>
        </Stack>
    </React.Fragment>
}

export {MedicareConfig}