import { Alert, Stack } from "@mui/material"
import React from "react"
import { useAppSelector } from "../../../../../../app/hooks";

function CaseManNoCallAlert(){
    const caseMan = useAppSelector((state)=> state.caseManagement.caseManagement);
    return <React.Fragment>
        {caseMan?.callLogs.length===0 &&
        <Stack marginLeft='10px' marginRight='10px' marginTop='10px'>
            <Alert color='warning'>No Calls currently logged for this case.</Alert>
        </Stack>
        }
    </React.Fragment>
}

export {CaseManNoCallAlert}