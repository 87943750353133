import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react"
import { Link } from "react-router-dom";
import { useFindPolicyWorklistQuery } from "../../../services/caseManService/caseManage-api";

type ownProps={
    title:string;
    queryParam:string;
}

function PolicyGroupCard(props:ownProps){
    const {data} = useFindPolicyWorklistQuery(props.queryParam);
    return <React.Fragment>
        <Card  className='card' sx={{width:'200px', borderRadius:'5pt'}}>
            <CardHeader className='card-header' title={<Typography color='#62BCBD' variant='body2'>{props.title}</Typography>}/>
                <CardContent>
                    <h2 className="center-align">
                        <Link style={{color:'green'}}
                            to={'/PolicyWorklist/'+ props.queryParam}>{data?.length ?? 0}
                        </Link>
                    </h2>
            </CardContent>
        </Card>
    </React.Fragment>
}

export {PolicyGroupCard}