import { Download } from "@mui/icons-material"
import { Button } from "@mui/material"
import React from "react"
import { CSVLink } from "react-csv";
import { useParams } from "react-router";
import { AccountSummaryRequest } from "../../../../../services/accountService/commands";
import {  useFindAccountExportQuery } from "../../../../../services/accountService/account-api";


function RevRecExport(){
    const {center, month, year} = useParams();
    const {data} = useFindAccountExportQuery(new AccountSummaryRequest(center!, Number(month!), Number(year!)));
    const mapAccounts=()=>{
        let accounts = data === undefined ? [] : data
            .map(i => ({...i, sortDate: new Date(i.serviceDate!)}))
        return accounts;
    }

    const headers = [
        {label: 'Id', key:'id'},
        {label: 'Patient', key:'patient'},
        {label: 'Account', key:'accountNumber'},
        {label: 'Status', key:'status'},
        {label: 'Service Date', key:'serviceDate'},
        {label: 'Admit Date', key:'admitDate'},
        {label: 'Discharge Date', key:'dischargeDate'},
        {label: 'Eom', key:'eom'},
        {label: 'Service Code', key:'serviceCode'},
        {label: 'Service Type', key:'serviceType'},
        {label: 'Accounting Service Type', key:'accountingServiceType'},
        {label: 'IpOp', key:'ipOp'},
        {label: 'System Carrier', key:'systemCarrier'},
        {label: 'Payer Name', key:'payerName'},
        {label: 'Ar Payer', key:'payerGroup'},
        {label: 'Calc Payer', key:'calcPayer'},
        {label: 'Total Charges', key:'totalCharges'},
        {label: "Implant Cost", key:'implantCost'},
        {label: 'Physician Name', key:'physicianName'},
        {label: 'Expected Reimbursement', key:'expectedReimbursement'},
        {label: 'Coding Method', key:'codingMethod'},
        {label: 'Modifiers', key:'modifiers'},
        {label: 'Drg', key:'drg'},
        {label: 'Cpt1', key:'cpt1'},
        {label: 'Cpt2', key:'cpt2'},
        {label: 'Cpt3', key:'cpt3'},
        {label: 'Cpt4', key:'cpt4'},
        {label: 'Cpt5', key:'cpt5'},
        {label: 'Cpt6', key:'cpt6'},
        {label: 'Cpt7', key:'cpt7'},
        {label: 'Cpt8', key:'cpt8'},
        {label: 'Cpt9', key:'cpt9'},
        {label: 'Cpt10', key:'cpt10'},
        {label: 'Cpt1 Qty', key:'cpt1Qty'},
        {label: 'Cpt2 Qty', key:'cpt2Qty'},
        {label: 'Cpt3 Qty', key:'cpt3Qty'},
        {label: 'Cpt4 Qty', key:'cpt4Qty'},
        {label: 'Cpt5 Qty', key:'cpt5Qty'},
        {label: 'Cpt6 Qty', key:'cpt6Qty'},
        {label: 'Cpt7 Qty', key:'cpt7Qty'},
        {label: 'Cpt8 Qty', key:'cpt8Qty'},
        {label: 'Cpt9 Qty', key:'cpt9Qty'},
        {label: 'Cpt10 Qty', key:'cpt10Qty'},
        {label: 'Reimbursement', key:'reimbursement'},
        {label: 'Note', key:'note'}

    ]
    
    return <React.Fragment>
        <CSVLink data={mapAccounts().sort((a,b) => a.sortDate.getTime() - b.sortDate.getTime())??[]} headers={headers} filename={'rev-rec-export.csv'} className='btn' style={{margin:'0px', padding:'0px'}}>
            <Button variant='outlined' color='primary' startIcon={<Download/>} >Export</Button>
        </CSVLink>
    </React.Fragment>
}
export {RevRecExport}