import React, { useState } from "react"
import { useNavigate, useParams } from "react-router";
import { useFindPolicyWorklistQuery } from "../../../../services/caseManService/caseManage-api";
import { Policy } from "../../../../services/caseManService/model/policy/Policy";
import { Button,Stack, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { process, State } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { ColumnMenu } from "../../../../utils/ColumnMenu";
import { PolicyWorklistCommandCell } from "./PolicyWorklistCommandCell";

function PolicyWorklistResults(){
    const {queryParam} = useParams();
    const {data} = useFindPolicyWorklistQuery(queryParam!);
    const navigate = useNavigate();
    const mapPolicies=():Policy[]=>{
        let policies = data===undefined ? [] : data?.map(i => ({...i, effectiveDate: new Date(i.effectiveDate??new Date()),reviewDate:new Date(i.reviewDate??new Date())}))
        return policies;
    }
    const defaultGridState:State = {sort:[{field:'',dir:'asc'}]};
    const [dataState, setDataState] = useState(defaultGridState);
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };


    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' alignItems='center' marginBottom='5px'>
            <Typography className="psn-green" variant='h6'>{queryParam + ' Policies'}</Typography>
            <Button variant='outlined' onClick={()=>navigate(-1)} color='success'><FontAwesomeIcon icon={'arrow-left'}/><span className="ms-1">Go Back</span></Button>
        </Stack>

        <Grid data={process(mapPolicies(),dataState)}
            style={{height:'750px'}} onDataStateChange={dataStateChange}>
            <Column cell={PolicyWorklistCommandCell} width={'100px'}/>
            <Column title='Policy Number' field='policyNumber' columnMenu={ColumnMenu} width={'200px'}/>
            <Column title='Policy Name' field='policyName' columnMenu={ColumnMenu} width={'400px'}/>
            <Column title='Effective' field='effectiveDate' columnMenu={ColumnMenu} format='{0:MM/dd/yyyy}' filter="date" width={'150px'}/>
            <Column title='Review' field='reviewDate' columnMenu={ColumnMenu} format='{0:MM/dd/yyyy}' filter="date" width={'150px'}/>
            <Column title='Category' field='category' columnMenu={ColumnMenu} width={'150px'}/>
        </Grid>
    </React.Fragment>
}   
export {PolicyWorklistResults}
