export class AccessAddCommand{
    identity: string;
    centerCode:string;
    centerName:string;

    constructor(identity:string, centerCode:string, centerName:string){
        this.identity = identity;
        this.centerCode = centerCode;
        this.centerName = centerName;
    }
    
}