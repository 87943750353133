export const ProcedureComplicationOptions = [
    {
        description: "Arrthymia - new onset",
        name: "ArrthymiaNewOnset",
    },
    {
        description: "Aspiration",
        name: "Aspiration",
    },
    {
        description: "Cancellation day of procedure",
        name: "CancellationDayOfProcedure",
    },
    {
        description: "Cardiopulmonary arrest",
        name: "CardiopulmonaryArrest",
    },
    {
        description: "Death",
        name: "Death",
    },
    {
        description: "Deep venous thrombosis",
        name: "DeepVenousThrombosis",
    },
    {
        description: "Myocardial Infarction",
        name: "MyocardialInfarction",
    },
    {
        description: "Pneumothorax",
        name: "Pneumothorax",
    },
    {
        description: "Pulmonary embolism",
        name: "PulmonaryEmbolism",
    },
    {
        description: "Stroke or other neurologic deficit",
        name: "StrokeOrOtherNeurologicDeficit",
    },
    {
        description: "Surgical Site Infection",
        name: "SurgicalSiteInfection",
    },
    {
        description: "Unplanned Return to Surgery",
        name: "UnplannedReturnToSurgery",
    },
    {
        description: "Unplanned transfer to HLOC",
        name: "UnplannedTransferToHLOC",
    },
    {
        description: "Unplanned transfer to ICU",
        name: "UnplannedTransferToICU",
    },
    {
        description: "Unplanned readmission",
        name: "UnplannedReadmission",
    },
];