import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { MenuItem, TextField } from "@mui/material";
import { editSingleCaseAgreement } from "../../../../../services/verifyService/model/verification/VerificationSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";

function ScaStatusEdit(){
    const dispatch = useAppDispatch();
    const verify = useAppSelector((state) => state.verification.verification);

    return <React.Fragment>
        <TextField label='Status' value={verify.singleCaseAgreement?.status} select sx={{width:'220px'}}
            onChange={(e) => dispatch(editSingleCaseAgreement(new ReduxUpdate('status',e.target.value)))}>
            <MenuItem value='Not Applicable'>Not Applicable</MenuItem>
            <MenuItem value='Requested'>Requested</MenuItem>
            <MenuItem value='Approved'>Approved</MenuItem>
            <MenuItem value='Denied'>Denied</MenuItem>
        </TextField>
    </React.Fragment>
}
export {ScaStatusEdit}