import { Stack } from "@mui/material"
import React from "react"
import { PolicySaveBtn } from "./PolicySaveBtn"
import { PolicyGoBackBtn } from "./PolicyGoBackBtn"

function PolicyDetailCommands(){
    return <React.Fragment>
        <Stack direction='row' gap={1}>
            <PolicySaveBtn/>
            <PolicyGoBackBtn/>
        </Stack>
    </React.Fragment>
}
export {PolicyDetailCommands}