import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IncidentNotificationSliceState {
    userProfileId: string | null;
}

const initialState: IncidentNotificationSliceState = {
    userProfileId: null,
};

const incidentNotificationSlice = createSlice({
    name: 'incidentNotification',
    initialState,
    reducers: {
        setUserProfileId: (state, action: PayloadAction<string>) => {
            state.userProfileId = action.payload;
        },
    },
});

export const { setUserProfileId } = incidentNotificationSlice.actions;

export default incidentNotificationSlice.reducer;