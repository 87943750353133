import { Card, CardContent, CardHeader, Stack, TextField, Typography } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editMultipliers } from "../../../../../services/contractService/model/contract/ContractSlice";;


function Multiples(){
    const dispatch = useAppDispatch();
    const data = useAppSelector((state)=> state.contract.contract)

    return <React.Fragment>
        <Card>
            <CardHeader sx={{borderBottom:'1px solid gray'}} title={<Typography className='psn-green' variant='body1'>Multiple Procedure Reduction</Typography>}/>
            <CardContent>
                <Stack direction='row' gap={1}>
                    <TextField label='1' size='small' InputLabelProps={{shrink:true}} sx={{width:'75px'}} name='cpt1' value={data?.multipliers.cpt1} onChange={(e)=>dispatch(editMultipliers(e))}/>
                    <TextField label='2' size='small' InputLabelProps={{shrink:true}} sx={{width:'75px'}} name='cpt2' value={data?.multipliers.cpt2} onChange={(e)=>dispatch(editMultipliers(e))}/>
                    <TextField label='3' size='small' InputLabelProps={{shrink:true}} sx={{width:'75px'}} name='cpt3' value={data?.multipliers.cpt3} onChange={(e)=>dispatch(editMultipliers(e))}/>
                    <TextField label='4' size='small' InputLabelProps={{shrink:true}} sx={{width:'75px'}} name='cpt4' value={data?.multipliers.cpt4} onChange={(e)=>dispatch(editMultipliers(e))}/>
                </Stack>
                <Stack direction='row' gap={1} marginTop='10px'>
                    <TextField label='5' size='small' InputLabelProps={{shrink:true}} sx={{width:'75px'}} name='cpt5' value={data?.multipliers.cpt5} onChange={(e)=>dispatch(editMultipliers(e))}/>
                    <TextField label='6' size='small' InputLabelProps={{shrink:true}} sx={{width:'75px'}} name='cpt6' value={data?.multipliers.cpt6} onChange={(e)=>dispatch(editMultipliers(e))}/>
                    <TextField label='7' size='small' InputLabelProps={{shrink:true}} sx={{width:'75px'}} name='cpt7' value={data?.multipliers.cpt7} onChange={(e)=>dispatch(editMultipliers(e))}/>
                    <TextField label='8' size='small' InputLabelProps={{shrink:true}} sx={{width:'75px'}} name='cpt8' value={data?.multipliers.cpt8} onChange={(e)=>dispatch(editMultipliers(e))}/>
                </Stack>
            </CardContent>
        </Card>
    </React.Fragment>
}
export{Multiples}