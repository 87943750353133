import { TextField } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { editCaseManDiagnosisCodes } from "../../../../../../services/caseManService/model/caseMan/CaseManagementSlice";
import { ReduxUpdate } from "../../../../../../utils/ReduxUpdateCommands";

type ownProps={
    label:string;
    value:string;
    name:string;
}
function DiagnosisView(props:ownProps){
    const caseManage = useAppSelector((state)=>state.caseManagement.caseManagement);
    const dispatch = useAppDispatch();
    
    return <React.Fragment>
        <TextField label={props.label} value={props.value} onChange={(e) => dispatch(editCaseManDiagnosisCodes(new ReduxUpdate(props.name,e.target.value)))}/>
    </React.Fragment>
}
export {DiagnosisView}