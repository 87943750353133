import { createSlice} from '@reduxjs/toolkit'

interface ProfileModuleState{
    centerAccessModal:boolean;
    claimAccessModal:boolean;
    lookupUserModal:boolean;
}

const initialState:ProfileModuleState={centerAccessModal:false, claimAccessModal:false, lookupUserModal:false}
export const profileModuleSlice = createSlice({
    name:'ProfileModule',
    initialState,
    reducers:{
        toggleCenterAccessModal:(state) =>{
            state.centerAccessModal = !state.centerAccessModal;
        },
        toggleClaimAccessModal:(state)=>{
            state.claimAccessModal = !state.claimAccessModal;
        },
        toggleLookupUserModal:(state) =>{
            state.lookupUserModal = !state.lookupUserModal;
        }
    }
})

export const {toggleCenterAccessModal, toggleClaimAccessModal, toggleLookupUserModal} = profileModuleSlice.actions;
export default profileModuleSlice.reducer;