import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface OrgModuleState{
    centerEditModal:boolean;
    centerDetailTab:number;
    physicianEditModal:boolean;
    selectedPhysician:string;
    payerEditModal:boolean;
    selectedPayer:string;
    procedureAddModal:boolean;
    procedureEditModal:boolean;
    selectedProcedure: string;
    payerUploadModal:boolean;
    medicareRateModal:boolean;
    selectedMedicareRate:string;
    serviceAddModal:boolean;
    serviceEditModal:boolean;
    selectedService:string;
    serviceUploadModal:boolean;
    physcianUploadModal:boolean;
    procedureUploadModal:boolean;
    refundApproverEditDialog:boolean;
    refundApproverAddDialog:boolean;
    incidentConfigDepartmentAddDialog:boolean;
    incidentConfigEventAddDialog: boolean;
    incidentConfigLookupUserModal:boolean;
    incidentConfigUserProfile:boolean;
}

const initialState:OrgModuleState={centerEditModal:false, centerDetailTab:0,
     physicianEditModal:false, selectedPhysician:'', payerEditModal: false, selectedPayer:'', 
     procedureAddModal: false, procedureEditModal:false, selectedProcedure:'', payerUploadModal:false, 
     medicareRateModal:false, selectedMedicareRate:'', serviceAddModal:false, serviceEditModal:false,
     selectedService:'', serviceUploadModal:false, physcianUploadModal:false, procedureUploadModal:false,
     refundApproverEditDialog:false, refundApproverAddDialog:false, incidentConfigDepartmentAddDialog:false, 
     incidentConfigEventAddDialog:false, incidentConfigLookupUserModal:false, incidentConfigUserProfile:false}

export const orgModuleSlice = createSlice({
    name:'orgModule',
    initialState,
    reducers:{
        toggleCenterEditModal:(state) =>{
            state.centerEditModal = !state.centerEditModal;
        },
        toggleCenterDetailTab:(state,action:PayloadAction<number>) =>{
            state.centerDetailTab = action.payload;
        },
        togglePhysicianEditModal:(state, action:PayloadAction<string>) => {
            state.physicianEditModal = !state.physicianEditModal;
            state.selectedPhysician = action.payload;
        },
        togglePayerEditModal:(state, action:PayloadAction<string>)=>{
            state.payerEditModal = !state.payerEditModal;
            state.selectedPayer = action.payload;
        },
        toggleProcedureAddModal:(state) => {
            state.procedureAddModal = !state.procedureAddModal;
        },
        toggleProcedureEditModal:(state,action:PayloadAction<string>) =>{
            state.procedureEditModal = !state.procedureEditModal;
            state.selectedProcedure = action.payload;
        },
        togglePayerUpload:(state) =>{
            state.payerUploadModal = !state.payerUploadModal;
        },
        toggleMedicareRateModal:(state, action:PayloadAction<string>)=>{
            state.medicareRateModal = !state.medicareRateModal;
            state.selectedMedicareRate = action.payload;
        },
        toggleServiceAddModal:(state) =>{
            state.serviceAddModal = !state.serviceAddModal;
        },
        toggleServiceEditModal:(state,action:PayloadAction<string>) =>{
            state.serviceEditModal = !state.serviceEditModal;
            state.selectedService = action.payload;
        },
        toggleServiceUploadModal:(state) =>{state.serviceUploadModal = !state.serviceUploadModal},
        togglePhysicianUploadModal:(state) => {state.physcianUploadModal = !state.physcianUploadModal},
        toggleProcedureUploadModal:(state)=>{state.procedureUploadModal = !state.procedureUploadModal},
        toggleRefundApproverEditDialog:(state)=>{state.refundApproverEditDialog = !state.refundApproverEditDialog},
        toggleRefundApproverAddDialog:(state)=>{state.refundApproverAddDialog = !state.refundApproverAddDialog},
        toggleIncidentConfigDepartmentAddDialog:(state)=>{state.incidentConfigDepartmentAddDialog = !state.incidentConfigDepartmentAddDialog},
        toggleIncidentConfigEventAddDialog:(state)=>{state.incidentConfigEventAddDialog = !state.incidentConfigEventAddDialog},
        toggleIncidentConfigUserLookupModal:(state)=>{state.incidentConfigLookupUserModal = !state.incidentConfigLookupUserModal},
        toggleIncidentConfigUserProfile:(state)=>{state.incidentConfigUserProfile = !state.incidentConfigUserProfile}
    }
})

export const {toggleCenterEditModal, toggleCenterDetailTab, 
    togglePhysicianEditModal, togglePayerEditModal, toggleProcedureAddModal,
    toggleProcedureEditModal, togglePayerUpload, toggleMedicareRateModal,
    toggleServiceAddModal, toggleServiceEditModal, toggleServiceUploadModal,
    togglePhysicianUploadModal, toggleProcedureUploadModal,
    toggleRefundApproverAddDialog, toggleRefundApproverEditDialog, 
    toggleIncidentConfigDepartmentAddDialog,
    toggleIncidentConfigEventAddDialog, toggleIncidentConfigUserLookupModal, 
    toggleIncidentConfigUserProfile} = orgModuleSlice.actions;
    
export default orgModuleSlice.reducer;