import React from "react"
import { Schedule } from "../features/schedule/Schedule"
import { Button, Stack, Typography } from "@mui/material"
import { Create } from "@mui/icons-material"
import { useNavigate, useParams } from "react-router"
import { useAppDispatch } from "../../../app/hooks"
import { resetSchedule } from "../../../services/scheduleService/model/ScheduleSlice"
import { useCenterFindQuery } from "../../../services/orgService/org-api"

function ScheduleMain(){
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const {data} = useCenterFindQuery(id!)
    return <React.Fragment>
        <Stack direction='row' marginBottom='5px' justifyContent='space-between' alignItems='center'>
            <Typography variant='h5'>{data?.shortName}</Typography>

            {data?.allowManualScheduling ?
            <Button variant="outlined" 
                onClick={()=> {dispatch(resetSchedule()); navigate('/ScheduleCreate/' + id)}}  startIcon={<Create/>}>New Visit</Button>:
            <Typography variant="h6" color='red'>Read Only Mode</Typography>}
        </Stack>
        <Schedule/>
    </React.Fragment>
}

export {ScheduleMain}