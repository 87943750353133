import { Stack, TextField, Typography } from "@mui/material"
import React from "react"
import { useAppSelector } from "../../../../../app/hooks";
import { formatDate } from "@progress/kendo-intl";

function EAR_Procedures(){
    const verification = useAppSelector((state)=>state.verification.verification);
    return <React.Fragment>
        <Typography className="psn-green" variant='body1'>Procedures</Typography>
        <Stack gap={.25}>
        {verification?.waystarAuthStatus?.procedureAuthStatuses?.map((item,index)=>{
            return <Stack direction='row' key={index} gap={.5} marginBottom='10px'>
                <TextField label='Procedure' value={item.cptCode} sx={{width:'125px'}}/>
                <TextField label='Required' value={item.authRequired} sx={{width:'125px'}}/>
                <TextField label='Auth Number' value={item.authNumber}/>
                <TextField label='Status' value={item.procedureAuthStatus} sx={{width:'300px'}}/>
                <TextField label='Effective Date' value={item.effectiveDate===null?'': formatDate(new Date(item.effectiveDate!),"MM/dd/yyyy")}/>
                <TextField label='Expiration Date' value={item.expirationDate===null?'': formatDate(new Date(item.expirationDate??''),'MM/dd/yyyy')}/>
            </Stack>
        })}
        </Stack>
    </React.Fragment>
}
export {EAR_Procedures}