import { Box, Stack } from "@mui/material";
import React from "react";
import { AdverseDrugTypes } from "./AdverseDrugTypes";
import { AdverseDrugDetails } from "./AdverseDrugDetails";

function AdverseDrug(props: {canEdit: boolean}) {
  return (
    <React.Fragment>
      <Box mt={2} mb={2} sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
        <Stack
          direction="row"
          spacing={3}
          alignItems="baseline"
        >
            <AdverseDrugTypes canEdit={props.canEdit}/>
            <AdverseDrugDetails canEdit={props.canEdit}/>
        </Stack>
      </Box>
    </React.Fragment>
  );
}

export { AdverseDrug };