import React from "react"
import { useAppSelector } from "../../../../../app/hooks";
import { TextField } from "@mui/material";
import { formatNumber } from "@progress/kendo-intl";

function AccountChargesView(){
    const account = useAppSelector((state) => state.account.account);
    return <React.Fragment>
        <TextField label='Charges' 
            value={formatNumber(account.transactionHeader.charges,'c')} disabled
            />
    </React.Fragment>
}
export {AccountChargesView}