export const PatientSafetyOptions = [
    {
        description: "Consent Issue",
        name: "ConsentIssue",
    },
    {
        description: "Patient Incorrect",
        name: "PatientIncorrect",
    },
    {
        description: "Surgery Site not Marked",
        name: "SurgerySiteNotMarked",
    },
    {
        description: "Time Out Not Completed",
        name: "TimeOutNotCompleted",
    },
    {
        description: "Time Out Not Completed Appropriately",
        name: "TimeOutNotCompletedAppropriately",
    },
];