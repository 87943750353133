import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useEffect } from "react"
import { useParams } from "react-router";
import { useCaseManSearchQuery } from "../../../../services/caseManService/caseManage-api";
import { Loading } from "../../../shared";
import { CaseManSearchRequest } from "../../../../services/caseManService/commands";
import { CaseManSearchResultsGrid } from "./CaseManSearchResultsGrid";

type ownProps = {
    searchText: string;
    skip: boolean;
    open: boolean;
    setOpen: Function;
}

function CaseManSearchResults(props: ownProps){
    const [skip, setSkip] = React.useState(props.skip)
    useEffect(()=>setSkip(props.skip),[props.skip]);
    const {center} = useParams();
    const {data, isFetching} = useCaseManSearchQuery(new CaseManSearchRequest(center!,props.searchText),{skip,});

    return <React.Fragment>
        <Dialog open={props.open} maxWidth='xl'>
            <DialogContent>
                {(isFetching === false && data?.length === 0) &&
                    <Loading/>
                }
                {isFetching === false ?
                    <CaseManSearchResultsGrid data={data ?? []}/>:
                    <Loading/>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{props.setOpen(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}
export {CaseManSearchResults}