import { Grid, Stack } from "@mui/material"
import React from "react"
import { HistoryAndPhysicalRequest } from "./HistoryAndPhysicalRequest"
import { ImagingReportRequest } from "./ImagingReportRequest"
import { ClinicalRecordsRequest } from "./ClinicalRecordsRequest"
import { PtNotesRequest } from "./PtNotesRequest"
import { PathologyRequest } from "./PathologyRequest"
import { PsychEvalRequest } from "./PsychEvalRequest"
import { OtherDocumentRequest } from "./OtherDocumentRequest"
import { CaseManDocRequestNote } from "./CaseManDocRequestNote"
import { CaseManDocRequestHeader } from "./CaseManDocRequestHeader"

function DocumentRequest(){
    return <React.Fragment>
        <CaseManDocRequestHeader/>

        <Grid container margin='10px'>
            <Grid item sm={1.25}>
                <HistoryAndPhysicalRequest/>
            </Grid>
            <Grid item sm={1.25}>
                <ImagingReportRequest/>
            </Grid>
            <Grid item sm={1.25}>
                <ClinicalRecordsRequest/> 
            </Grid>
            <Grid item sm={1.25}>
                <OtherDocumentRequest/>
            </Grid>
        </Grid>
        <Grid container margin='10px'>
            <Grid item sm={1.25}>
                <PtNotesRequest/>
            </Grid>
            <Grid item sm={1.25}>
                <PathologyRequest/>
            </Grid>
            <Grid item sm={1.25}>
                <PsychEvalRequest/>
            </Grid>
        </Grid>

        <Stack margin='10px' marginTop='40px'>
            <CaseManDocRequestNote/>
        </Stack>

    </React.Fragment>
}
export {DocumentRequest}