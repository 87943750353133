import { TextField } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { editPolicyDetails } from "../../../../services/caseManService/model/policy/PolicySlice";
import { ReduxUpdate } from "../../../../utils/ReduxUpdateCommands";

function PolicyNameEdit(){
    const dispatch = useAppDispatch();
    const policy = useAppSelector((state)=>state.policy.policy);
    return <React.Fragment>
        <TextField label='Policy Name' value={policy.policyName} sx={{width:'500px'}}
            onChange={(e)=>dispatch(editPolicyDetails(new ReduxUpdate('policyName',e.target.value)))}/>
    </React.Fragment>
}
export {PolicyNameEdit}