import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { toggleDetailTab } from "../../../app/slices/VerificationModuleSlice";
import { useCaseFindQuery } from "../../../services/scheduleService/schedule-api";
import { useVerificationFindQuery } from "../../../services/verifyService/verify-api";
import { Authorization, Benefits, BenefitsAttributes, BenefitsStatus, Estimate, FinancialOrientation, Header, Insurances, Notes, PatientOutOfPocket, SingleCaseAgreement, VerifyDocuments } from "../features"
import { CallInfo } from "../features/detail/benefits/CallInfo"
import { Loading } from "../../shared/features/Loading"
import {Verification as Verify} from '../../../services/verifyService/model';
import { AuthorizationStatus } from "../features/detail/authorizationStatus/AuthorizationStatus"
import { Grid, Stack, Table } from "@mui/material";
import { CenterNameChipView } from "../../../components/orgComponents/CenterNameChipView";
import { PhysicianChipView } from "../../../components/encounterItems";
import { CommandBar } from "../features/detail/commandBar/CommandBar";

function Verification(){
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const tabIndex = useAppSelector((state)=> state.verificationModule.DetailTab);
    const verify = useAppSelector((state) => state.verification.verification);
    const toggleTab = (e: TabStripSelectEventArguments) => dispatch(toggleDetailTab(e.selected));
    const schedule = useCaseFindQuery(id!);
    const {data, isLoading} = useVerificationFindQuery(id!);
    const [verification, setVerification] = useState(new Verify());
    useEffect(() => {setVerification(data!)});
    const handleChange = (e:any) =>{
        const {name, value} = e.target;
        setVerification({...verification,[name]:value});
    }

    return <React.Fragment>
        {(schedule.isLoading) ? <Loading/> :
        <div>
        <Grid container>
            <Grid item sm={4.75}><CenterNameChipView centerId={schedule.data?.center!}/></Grid>
            <Grid item sm={5}><PhysicianChipView/></Grid>
            <Grid item sm={2.25}justifyContent='flex-end'><CommandBar/></Grid>
        </Grid>
        <Header centerId="IEB" visitId="1"/>
        <Insurances centerId="IEB" visitId="1"/>

        <TabStrip selected={tabIndex} onSelect={toggleTab}>
            <TabStripTab title='Coverage'>
                <Table/>
                <Grid container marginLeft={'10px'} marginRight={'20px'}>
                    <Grid item sm={8}><Benefits/><hr/><CallInfo/></Grid>
                    <Grid item sm={4}><BenefitsStatus/><BenefitsAttributes/></Grid>
                </Grid>
            </TabStripTab>
            <TabStripTab title='Estimate'>
                <Table/>
                <Grid container>
                    <Grid item sm={12}><Estimate/></Grid>
                </Grid>
            </TabStripTab>
            <TabStripTab title='Authorization'>
                <Table/>
                <Grid container marginTop={'5px'} marginLeft={'10px'}>
                    <Grid item sm={8}><Authorization/></Grid>
                    <Grid item sm={4} ><AuthorizationStatus/></Grid>
                </Grid>
            </TabStripTab>
            <TabStripTab title='Financial Orientation'>
                <Table/>
                <FinancialOrientation/>
            </TabStripTab>
            <TabStripTab title='Notes'>
                <Table/>
                <Notes/>
            </TabStripTab>
            <TabStripTab title='Documents'>
                <Table/>
                <VerifyDocuments/>
            </TabStripTab>
            {verify?.singleCaseAgreement?.agreementRequired === true &&
            <TabStripTab title='Single Case Agreement'>
                <Table/>
                <SingleCaseAgreement/>
            </TabStripTab>
            }
        </TabStrip>
        </div>
        }
    </React.Fragment>
}

export {Verification}