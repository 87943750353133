import { Create } from "@mui/icons-material"
import { Button } from "@mui/material"
import React from "react"
import { useAppDispatch } from "../../../../../../app/hooks"
import { toggleRefundNoteDialog } from "../../../../../../app/slices/RefundModuleSlice";
import { NoteCreateDialog } from "./NoteCreateDialog";

function NoteCreateBtn(){
    const dispatch = useAppDispatch();


    return <React.Fragment>
        <Button variant='outlined' startIcon={<Create/>}
            onClick={()=>dispatch(toggleRefundNoteDialog())}>Create Note</Button>

        <NoteCreateDialog/>
    </React.Fragment>
}

export {NoteCreateBtn}