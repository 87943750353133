import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useNavigate } from "react-router";
import { GridButton } from "../../../../../components";

function FeeScheduleCommandCell(props:GridCellProps){
    const navigate = useNavigate();
    
    return <React.Fragment>
        <GridButton onClick={()=>navigate('/FeeSchedule/'+ props.dataItem.contractId + '/'  + props.dataItem.id)}>View</GridButton>
    </React.Fragment>
}

export {FeeScheduleCommandCell}