export const DocumentationOptions = [
    {
        description: "Count Discrepancy",
        name: "CountDiscrepancy",
    },
    {
        description: "Count Incomplete/Not Performed",
        name: "CountIncompleteNotPerformed",
    },
    {
        description: "ID Missing/Incorrect",
        name: "IDMissingIncorrect",
    },
];