import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react"
import { useNavigate } from "react-router"
import { useAppDispatch } from "../../../../app/hooks";
import { GridButton } from "../../../../components";
import { contractApi } from "../../../../services/contractService/contract-api";

function ContractSelectCommandCell(props:GridCellProps){
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    return <React.Fragment>
        <GridButton size='small' color="primary" variant="outlined"
             onClick={()=>{dispatch(contractApi.util.invalidateTags(['contract']));navigate('/contractDetail/' + props.dataItem.id)}}>View</GridButton>
    </React.Fragment>
}

export {ContractSelectCommandCell}