import { Stack } from '@mui/material';
import {process, State } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import React, { useState } from "react"
import { usePayerListQuery } from "../../../../services/orgService/org-api";
import { ColumnMenu } from '../../../../utils/ColumnMenu';
import { PayerEdit } from '../payerEdit/PayerEdit';
import { PayerExport } from './PayerExport';
import { PayerImport } from './PayerImport';
import { ViewCommandCell } from './ViewCommandCell';

type ownProps = {
    center:string
}

function PayerList(props:ownProps){
    const {data} = usePayerListQuery(props.center);
    const [dataState, setDataState] = useState({});

    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };

      return <React.Fragment>
            <Stack direction='row' justifyContent='space-between' margin='5px'>
                <Stack direction='row' gap={1}>
                <PayerExport center={props.center}/>
                <PayerImport center={props.center}/>
                </Stack>
            </Stack>
          <Grid className='center-list-grid card-body-grid' data={process(data === undefined ? [] : data.slice(0),dataState)}
          style={{height:'500px'}} onDataStateChange={dataStateChange}>
              <Column cell={ViewCommandCell} width='75px'/>
              <Column title= 'Payer Code' field = 'code' columnMenu={ColumnMenu} width='150px'/>
              <Column title='Calc Type' field='financialClass' columnMenu={ColumnMenu} width='200px'/>
              <Column title='Payer Name' field='name' columnMenu={ColumnMenu} width='500px'/>
              <Column title='Waystar Eligibility' field='ecsPayerId' columnMenu={ColumnMenu} width='200px'/>
              <Column title='Waystar Auth' field='waystarAuthPayer' columnMenu={ColumnMenu} width='200px'/>
          </Grid>
          <PayerEdit/>
      </React.Fragment>
}

export {PayerList}