import React from "react"
import { useMyProfileQuery } from "../../../../services/profileService/profile-api"
import { Card, CardActions, CardContent, Grid } from "@mui/material";
import CaseOptimizationIcon from '../../../../images/appicons/CaseOptimizationIcon.png';

function CaseOptimizationCard(){
    const {data} = useMyProfileQuery();
    const canAccess=():boolean=>{
        if(data?.role === 'Admin'){
            return true;
        }
        return false;
    }

    return <React.Fragment>
        {canAccess() &&
            <Grid item sm={3}>
                <Card className='card-img-body center-align'>
                    <CardContent className='card-img-main'>
                        <a className='btn img-button' href={'/CaseOptimization'}><img className='card-img' src={CaseOptimizationIcon} alt=''/></a>
                    </CardContent>
                    <CardActions className='card-img-footer'><h6>Case Optimization</h6></CardActions>
                </Card>
            </Grid>
        }
    </React.Fragment>
}
export {CaseOptimizationCard}