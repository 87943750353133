import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useAppDispatch } from "../../../../app/hooks"
import { GridButton } from "../../../../components";
import { toggleServiceEditModal } from "../../../../app/slices/OrgModuleSlice";

function EditServiceCell(props:GridCellProps){
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <GridButton size='small' onClick={()=>dispatch(toggleServiceEditModal(props.dataItem.id))}>
            View
        </GridButton>
    </React.Fragment>
}

export {EditServiceCell}