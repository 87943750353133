import dayjs from "dayjs";

export class ReduxUpdate{
    name:string;
    value:string |Date |Number|boolean| null|dayjs.Dayjs|string[];

    constructor(name:string, value:string|Date|Number|boolean|null|dayjs.Dayjs|string[]){
        this.name = name;
        this.value = value;
    }
}

export class ReduxNestedUpdate{
    index:string;
    name:string;
    value:string |Date |Number|null|dayjs.Dayjs|boolean|string[];

    constructor(index:string,name:string,value:string|Date|Number|null|dayjs.Dayjs|boolean|string[]){
        this.index = index; this.name = name; this.value = value;
    }
}