import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { InputAdornment, TextField } from "@mui/material";
import { editBenefits } from "../../../../../../services/verifyService/model/verification/VerificationSlice";

function UncoveredAmount(){
    const dispatch = useAppDispatch();
    const verification = useAppSelector((state) => state.verification.verification);
    return <React.Fragment>
        <TextField label='Additional Uncovered Amount' name='uncoveredAmount'
             InputProps={{startAdornment:<InputAdornment position='start'>$</InputAdornment>}}
             value={verification.benefits.uncoveredAmount}
             onChange={(e)=>dispatch(editBenefits(e))}/>
    </React.Fragment>
}

export {UncoveredAmount}