import React from "react"
import { useAppSelector } from "../../../../../app/hooks";
import { TextField } from "@mui/material";
import { formatNumber } from "@progress/kendo-intl";

function AccountTotalNonSurgCharges(){
    const account = useAppSelector((state)=>state.account.account);
    const totalCharges = account.nonSurgical.reduce((acc, item) => acc + (item.chargeAmount ?? 0), 0);

    return <React.Fragment>
        <TextField label='Total Non-Surgical Charges' value={formatNumber(totalCharges,'c')} disabled/>
    </React.Fragment>
}
export {AccountTotalNonSurgCharges}