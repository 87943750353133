import { Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react"
import { useParams } from "react-router";
import { ExternalService } from "../../../../../utils/ServiceLocator";
import { Close } from "@mui/icons-material";
import { Loading } from "../../../../shared";

type ownProps = {
    pointer:string;
    index:number;
}
function EAI_Dialog(props:ownProps){
    const [open, setopen] = React.useState(false);
    const{id} = useParams();
    const [image,setimage] = React.useState<string>('');
    let prepareImage = "data:image/png;base64," + image;

    const handleDownload=()=>{
        fetch(ExternalService+'AuthImageLookupEndpoint/ImageDownload/'+props.pointer+'/' + id!.split('-')[0],{method:'GET'})
            .then((response)=>response.text())
            .then(((data)=>setimage(data)));
    }

    return <React.Fragment>
        <Button variant='text' size='small' color='primary' onClick={()=>{setopen(!open);handleDownload()}}>{"image-" + props.index}</Button>
        
        <Dialog open={open} onClose={()=>setopen(false)} maxWidth='xl'>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}> {"Image-" + props.index}
            <IconButton sx={{ ml: 'auto' }} onClick={()=>setopen(false)}>
                <Close/>
            </IconButton>
        </DialogTitle>
            <DialogContent>
                {image===''?<Loading/>:
                <img src={prepareImage} style={{width:'100%'}}/>}
            </DialogContent>
            
        </Dialog>
    </React.Fragment>
}
export {EAI_Dialog}