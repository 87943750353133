import { Stack, TextField } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";
import { editCheckInfo } from "../../../../../services/refundService/model/refundRequest/RefundRequestSlice";
import { RefundLocked } from "../../../specs/RefundLocked";

function CheckPayAddressEdit(){
    const dispatch = useAppDispatch();
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);

    return <React.Fragment>
        <Stack gap={2}>
            <TextField label='Street Address 1' sx={{width:'475px'}}
                value={refund.checkInfo.streetAddress1}
                disabled={new RefundLocked().IsSatisfiedBy(refund) && refund.status !== 'REISSUE'}
                onChange={(e)=>dispatch(editCheckInfo(new ReduxUpdate('streetAddress1',e.target.value)))}/>
            <TextField label='Street Address 2' sx={{width:'475px'}}
                value={refund.checkInfo.streetAddress2}
                disabled={new RefundLocked().IsSatisfiedBy(refund) && refund.status !== 'REISSUE'}
                onChange={(e)=>dispatch(editCheckInfo(new ReduxUpdate('streetAddress2',e.target.value)))}/>
            <Stack direction='row' gap={2}>
                <TextField label='City' sx={{width:'240px'}}
                    value={refund.checkInfo.city}
                    disabled={new RefundLocked().IsSatisfiedBy(refund) && refund.status !== 'REISSUE'}
                    onChange={(e)=>dispatch(editCheckInfo(new ReduxUpdate('city',e.target.value)))}/>
                <TextField label='State abbr.' sx={{width:'100px'}}
                    value={refund.checkInfo.state} inputProps={{maxLength:2}}
                    disabled={new RefundLocked().IsSatisfiedBy(refund) && refund.status !== 'REISSUE'}
                    onChange={(e)=>dispatch(editCheckInfo(new ReduxUpdate('state',e.target.value.toUpperCase())))}/>
                <TextField label='Zip' sx={{width:'100px'}}
                    value={refund.checkInfo.zip} inputProps={{maxLength:5}}
                    disabled={new RefundLocked().IsSatisfiedBy(refund) && refund.status !== 'REISSUE'}
                    onChange={(e)=>dispatch(editCheckInfo(new ReduxUpdate('zip',e.target.value)))}/>
            </Stack>
        </Stack>
    </React.Fragment>
}

export {CheckPayAddressEdit}