import React from "react"
import {useAppSelector } from "../../../../../app/hooks";
import {TextField } from "@mui/material";

function AccountServiceTypeEdit(){
    const account = useAppSelector((state)=> state.account.account);

    return <React.Fragment>
        <TextField label='Accounting Service' sx={{width:'225px'}}
            value={account.accountServiceType} disabled/>

    </React.Fragment>
}
export {AccountServiceTypeEdit}