import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleGrouperEditModal } from "../../../../../app/slices/ContractModuleSlice";
import { GrouperDeleteCommand, GrouperEditCommand } from "../../../../../services/contractService/commands";
import { useGrouperDeleteMutation, useGrouperEditMutation } from "../../../../../services/contractService/contract-api";


function GrouperEdit(){
    const grouperId = useAppSelector((state)=> state.contractModule.selectedGrouper)
    const feeSchedule = useAppSelector((state)=>state.feeSchedule.feeSchedule);
    const isOpen = useAppSelector((state)=> state.contractModule.grouperEditModal);
    const dispatch = useAppDispatch();
    const [saveGrouper] = useGrouperEditMutation();
    const [removeGrouper] = useGrouperDeleteMutation();

    const [code, setCode] = useState('');
    const [rate, setRate] = useState(0);
    const [perDiem,setPerDiem] = useState(false);

    useEffect(()=>{
        let grouper = feeSchedule.groupers.find(p => p.id === grouperId);
        setCode(grouper?.code ?? '0');
        setRate(grouper?.rate ?? 0);
        setPerDiem(grouper?.isPerDiem ?? false);
    }, [grouperId, feeSchedule])
    
    const editGrouper=()=>{
        let command = new GrouperEditCommand(feeSchedule.id,grouperId,code,rate, perDiem);
        saveGrouper(command);
    }
    const deleteGrouper=()=>{
        let command = new GrouperDeleteCommand(feeSchedule.id,grouperId);
        removeGrouper(command);
    }

    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogTitle>
                <Stack direction='row' justifyContent='space-between'>
                    <Typography>Edit Grouper</Typography>
                    <Button color='error' onClick={()=>{deleteGrouper();dispatch(toggleGrouperEditModal(''));}}>Delete</Button>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack direction='row' gap={1} marginTop='10px'>
                    <TextField label='Grouper Code' name='code' size='small' value={code} onChange={(e)=> setCode(e.target.value)}
                        InputLabelProps={{shrink:true}}/>
                    <TextField label='Grouper Rate' name='rate'size='small' value={rate} onChange={(e)=> setRate(Number(e.target.value))}
                        InputLabelProps={{shrink:true}}/>
                </Stack>
                <Stack direction='row' marginTop='10px'>
                    <FormControlLabel
                        control={<Switch size='small' value={perDiem} checked={perDiem} onChange={() => setPerDiem(!perDiem)}/>}
                        label='Per Diem' labelPlacement='end'/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={()=> dispatch(toggleGrouperEditModal(''))}>Close</Button>
                <Button onClick={()=> {editGrouper(); dispatch(toggleGrouperEditModal(''))}}>Save</Button>            
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export {GrouperEdit}