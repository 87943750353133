import { Alert, Button, CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { toggleCptUploadModal } from "../../../../../app/slices/ContractModuleSlice";
import { contractApi } from "../../../../../services/contractService/contract-api";
import { ContractService } from "../../../../../utils/ServiceLocator";
import CptTemplate from '../../../../../templates/CptUploadTemplate.png';
import CptGroupedTemplate from '../../../../../templates/CptGroupedUploadTemplate.png';

function CptUpload(){
    const dispatch = useAppDispatch();
    const feeSchedule = useAppSelector((state)=>state.feeSchedule.feeSchedule);
    const isOpen = useAppSelector((state) => state.contractModule.cptUploadModal)
    const [selectedFile, setSelectedFile] = useState();
	const [uploadComplete, setUploadComplete] = useState(false);
	const [uploadInProgress, setUploadInProgress] = useState(false);

    const changeHandler = (event:any) =>{
        setSelectedFile(event.target.files[0]);
    }
    const handleSubmission = () => {
		const formData = new FormData();
		setUploadInProgress(true);
		formData.append('File', selectedFile!);
		fetch(ContractService + 'UploadCptEndpoint/UploadCpt/'+feeSchedule.id +'/' + feeSchedule.feeSubType,{method: 'POST', body: formData,})
			.then((response) => {response.json(); setUploadComplete(true);setUploadInProgress(false);dispatch(contractApi.util.invalidateTags(['cptList']));})
			.then((result) => {dispatch(contractApi.util.invalidateTags(['feeSchedule','feeScheduleItemCount','feeScheduleList']));})
			.catch((error) => {console.log('Error:', error);});
	};

    return <React.Fragment>
         <Dialog open={isOpen}>
            <DialogTitle>Upload a Cpt Fee Schedule</DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Alert color='info'>Fee Schedules must be uploaded using a specific format and file name.  Please download the template here.
                        {feeSchedule.feeSubType === 'Flat Rate' ? 
                        <a href={CptTemplate} download='CptTemplate.csv'>Download</a>:
                        <a href={CptGroupedTemplate} download='CptGroupedTemplate.csv'>Download</a>
                        }
                    </Alert>
                    {uploadInProgress &&
					    <Alert color='warning'>We're processing your request. This may take a few minutes.  You can close this window and continue working.</Alert>}
					{uploadComplete === true &&
						<Alert color="success">Upload Completed Successfully</Alert>}
                    <input type='file' name='file' onChange={changeHandler}/>
                    <Button variant='outlined' startIcon={uploadInProgress ? '' : ''} onClick={handleSubmission } disabled={selectedFile === undefined || uploadInProgress}>{!uploadInProgress ? 'Submit': <CircularProgress/>}</Button>
                    <Button variant='outlined' onClick={()=>{dispatch(contractApi.util.invalidateTags(['feeSchedule','feeScheduleItemCount'])); setUploadComplete(false); dispatch(toggleCptUploadModal()); }}>Close</Button>
                </Stack>
            </DialogContent>
         </Dialog>       
    </React.Fragment>
}
export{CptUpload}