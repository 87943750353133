import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { togglePayerEditModal } from "../../../../app/slices/OrgModuleSlice";
import { PayerSaveCommand } from "../../../../services/orgService/commands";
import { Payer } from "../../../../services/orgService/model";
import { usePayerQuery, useSavePayerMutation } from "../../../../services/orgService/org-api";
import {Dialog, DialogContent, DialogTitle, MenuItem, Select, SelectChangeEvent, Button as But, FormControl, InputLabel, Stack, TextField} from '@mui/material';

function PayerEdit(){
    const dispatch = useAppDispatch();
    const payerId = useAppSelector((state) => state.orgModule.selectedPayer);
    const {data} = usePayerQuery(payerId);
    const [payer, setPayer] = useState(new Payer());
    const [savePayer] = useSavePayerMutation();
    useEffect(() => {setPayer(data!)},[data,payerId]);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
        const {name, value} = e.target;
        let newPayer:Payer = payer;
        newPayer = ({...newPayer, [name]:value});
        setPayer(newPayer);
    }

    const modalOpen = useAppSelector((state) => state.orgModule.payerEditModal);

    return <React.Fragment>
        <Dialog open={modalOpen}>
            <DialogTitle>{payer?.code + ' - ' + payer?.name}</DialogTitle>
            <DialogContent>
                <Stack sx={{marginTop:'10px'}}>
                <TextField select label='Calc Payer Name' name='financialClass' value={payer?.financialClass} onChange={(e)=>handleChange(e)}>
                    <MenuItem value='Unknown'>Unknown</MenuItem>
                    <MenuItem value='Aetna'>Aetna</MenuItem>
                    <MenuItem value='Aetna Medicare'>Aetna Medicare</MenuItem>
                    <MenuItem value='Aetna Medicaid'>Aetna Medicaid</MenuItem>
                    <MenuItem value='Amerigroup'>Amerigroup</MenuItem>
                    <MenuItem value='BCBS BAV'>BCBS BAV</MenuItem>
                    <MenuItem value='BCBS Blue Essential'>BCBS Blue Essential</MenuItem>
                    <MenuItem value='BCBS HMO'>BCBS HMO</MenuItem>
                    <MenuItem value='BCBS Medicaid'>BCBS Medicaid</MenuItem>
                    <MenuItem value='BCBS Medicare'>BCBS Medicare</MenuItem>
                    <MenuItem value='BCBS NWB'>BCBS NWB</MenuItem>
                    <MenuItem value='BCBS PPO'>BCBS PPO</MenuItem>
                    <MenuItem value='BCBS Trad'>BCBS Trad</MenuItem>
                    <MenuItem value='Bundled'>Bundled</MenuItem>
                    <MenuItem value='Care N Care'>Care N Care</MenuItem>
                    <MenuItem value='Cigna'>Cigna</MenuItem>
                    <MenuItem value='Cigna Connect'>Cigna Connect</MenuItem>
                    <MenuItem value='Cigna Healthspring'>Cigna Healthspring</MenuItem>
                    <MenuItem value='Cigna Medicare'>Cigna Medicare</MenuItem>
                    <MenuItem value='Corvel'>Corvel</MenuItem>
                    <MenuItem value='Department of Labor'>Department of Labor</MenuItem>
                    <MenuItem value='Endeavor'>Endeavor</MenuItem>
                    <MenuItem value='GMMI'>GMMI</MenuItem>
                    <MenuItem value='HD Research'>HD Research</MenuItem>
                    <MenuItem value='Humana'>Humana</MenuItem>
                    <MenuItem value='Humana Medicare'>Humana Medicare</MenuItem>
                    <MenuItem value='LOP'>LOP</MenuItem>
                    <MenuItem value='Medicaid'>Medicaid</MenuItem>
                    <MenuItem value='Medicare'>Medicare</MenuItem>
                    <MenuItem value='MPower'>MPower/Triple AIM</MenuItem>
                    <MenuItem value='Multiplan'>Multiplan</MenuItem>
                    <MenuItem value='Multiplan Comm'>Mulitplan Comm</MenuItem>
                    <MenuItem value='Multiplan WC'>Multiplan WC</MenuItem>
                    <MenuItem value='Oscar'>Oscar</MenuItem>
                    <MenuItem value='Other Commercial'>Other Commercial</MenuItem>
                    <MenuItem value='Research Source'>Research Source</MenuItem>
                    <MenuItem value='Superior Health Plan'>Superior Health Plan</MenuItem>
                    <MenuItem value='Surgery Plus'>Surgery Plus</MenuItem>
                    <MenuItem value='Self-Pay'>Self-Pay</MenuItem>
                    <MenuItem value='SWHP Comm'>SWHP Comm</MenuItem>
                    <MenuItem value='SWHP Managed Medicare'>SWHP Managed Medicare</MenuItem>
                    <MenuItem value ='SWHR'>SWHR</MenuItem>
                    <MenuItem value='Three Rivers'>Three Rivers</MenuItem>
                    <MenuItem value='Tricare'>Tricare</MenuItem>
                    <MenuItem value='TriWest'>TriWest</MenuItem>
                    <MenuItem value='UHC'>UHC</MenuItem>
                    <MenuItem value='UHC Medicaid'>UHC Medicaid</MenuItem>
                    <MenuItem value='UHC Medicare'>UHC Medicare</MenuItem>
                    <MenuItem value='Wellcare'>WellCare</MenuItem>
                    <MenuItem value='Wellmed'>Wellmed</MenuItem>
                    <MenuItem value='Workers Comp'>Workers Comp</MenuItem>
                </TextField>

                <Stack direction='row' gap={3} marginTop='15px'>
                    <TextField label='Portal URL' name='portalUrl' value={payer?.portalUrl} onChange={(e)=>handleChange(e)} size='small' InputLabelProps={{shrink:true}}/>
                    <TextField label='Phone' name='phone' value={payer?.phone} onChange={(e)=> handleChange(e)} size='small' InputLabelProps={{shrink:true}}/>
                </Stack>
                <Stack direction='row' gap={3} marginTop='15px'>
                    <TextField label='Waystar Eligibility Code' name='ecsPayerId' value={payer?.ecsPayerId} onChange={(e)=>handleChange(e)} size='small'
                        InputLabelProps={{shrink:true}}/>
                    <TextField label='Waystar Auth Code' name='waystarAuthPayer' value={payer?.waystarAuthPayer} onChange={(e)=>handleChange(e)} size='small'/>
                </Stack>
                <Stack direction='row' gap={3} justifyContent='flex-end' sx={{marginTop:'5px'}}>
                    <But onClick={()=>dispatch(togglePayerEditModal(''))} color='secondary'>Close</But>
                    <But onClick={(e)=>{savePayer(new PayerSaveCommand(payer!));dispatch(togglePayerEditModal(''));}}>Save</But>
                </Stack>
                

                </Stack>
            </DialogContent>
        </Dialog>
{/*         <Modal isOpen={modalOpen} size='lg'>
            <ModalHeader toggle={()=> dispatch(togglePayerEditModal(''))}>{payer?.code + ' - ' + payer?.name}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm={{size:4}}>
                        <Label className="mb-0">Calc Payer Name</Label>
                        <Input name='financialClass' type='select' value={payer?.financialClass} onChange={(e) => handleChange(e)}>
                            <option>Unknown</option>
                            <option>Aetna</option>
                            <option>BCBS Blue Essential</option>
                            <option>BCBS PPO</option>
                            <option>Bundled</option>
                            <option>Cigna</option>
                            <option>Humana</option>
                            <option>Kara Golden</option>
                            <option>LOP</option>
                            <option>Medicaid</option>
                            <option>Medicare</option>
                            <option>Other Commercial</option>
                            <option>Self-Pay</option>
                            <option>UHC</option>
                            <option>Workers Comp</option>
                        </Input>
                    </Col>
                </Row>
                <Row>
                    <Col sm={{size:6}}>
                        <Label className="mb-0 mt-3">Portal Url</Label>
                        <InputGroup>
                            <InputGroupText>http://</InputGroupText>
                            <Input name='portalUrl' value={payer?.portalUrl} onChange={(e) =>handleChange(e)}/>
                        </InputGroup>
                    </Col>
                    <Col sm={{size:4}}>
                        <Label className="mb-0 mt-3">Phone</Label>
                        <Input name='phone' value={payer?.phone} onChange={(e) => handleChange(e)}/>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col sm={{size:12}} className='right-align'>
                        <Button className="me-2" outline color='primary' onClick={()=>{savePayer(new PayerSaveCommand(payer!));dispatch(togglePayerEditModal(''));}}>Save</Button>
                        <Button outline color='secondary' onClick={()=>dispatch(togglePayerEditModal(''))}>Close</Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal> */}
    </React.Fragment>
}

export {PayerEdit}