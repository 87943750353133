import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { Button, Card, CardActions, CardContent, CardHeader, MenuItem, Popper, Stack, TextField } from "@mui/material";
import { QuestionMark } from "@mui/icons-material";
import { editCaseManClinicalReview } from "../../../../../services/caseManService/model/caseMan/CaseManagementSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";

function ClinicalRecordsEdit(){
    const dispatch = useAppDispatch();
    const caseManage = useAppSelector((state)=>state.caseManagement.caseManagement);
    const[popout, setPopout] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    
    return <React.Fragment>
        <Stack direction='row' alignSelf='center'>
        <TextField label='Clinical Records/Office Notes' select defaultValue='Incomplete' sx={{width:'200px'}}
            value={caseManage.clinicalReview?.clinicalRecords} onChange={(e)=>dispatch(editCaseManClinicalReview(new ReduxUpdate('clinicalRecords',e.target.value)))}>
            <MenuItem value='Incomplete'>Incomplete</MenuItem>
            <MenuItem value='Pending'>Pending</MenuItem>
            <MenuItem value='Complete'>Complete</MenuItem>
            <MenuItem value='Not Required'>Not Required</MenuItem>
            <MenuItem value='Does Not Support'>Does Not Support</MenuItem>
        </TextField>
        <Button name='questions' startIcon={<QuestionMark/>} variant='text' onClick={(e)=>{setPopout(true);setAnchorEl(e.currentTarget)}}/>
        </Stack>
        <Popper open={popout} placement='right'  sx={{zIndex:100}} anchorEl={anchorEl}>
            <Card>
                <CardHeader title='Clinical Records/Office Notes'/>
                <CardContent>
                <ul>
                    <li>Outcome of physical examination</li>
                    <li>Effect on ADLs (Activities of Daily Living)</li>
                    <li>Pain level using VAS (Visual Analog Scale)</li>
                    <li>Any functional disability</li>
                    <li>Any use of adjunct medications, analgesics, anti-inflammatory, or muscle relaxants</li>
                    <li>Any alternative therapies</li>
                    <li>Any other conservative care, including duration</li>
                </ul>
                </CardContent>
                <CardActions sx={{justifyContent:'center'}}><Button onClick={()=>setPopout(false)}>Close</Button></CardActions>
            </Card>
        </Popper>    
    </React.Fragment>
}
export {ClinicalRecordsEdit}