import React from "react"

function IncidentSummary(){
    return <React.Fragment>
        <iframe title="Incident Summary" width="1400" height="800" src="https://app.powerbi.com/reportEmbed?reportId=ac96c91c-38f5-44f6-bbde-b026c30990a5&autoAuth=true&ctid=49423d5d-8d49-4128-b53d-59537808f6ac" allowFullScreen={true}></iframe>
    </React.Fragment>
}

export{IncidentSummary}

