import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {getToken} from '../../utils/AdalConfig';
import { ExternalService } from '../../utils/ServiceLocator';
import { AuthStatusImageRequest } from './commands/AuthStatusImageRequest';

export const externalApi = createApi({
    reducerPath:'externalApi',
    baseQuery: fetchBaseQuery({
        baseUrl: ExternalService,
        prepareHeaders:(headers,{getState}) => {
            const token = getToken();
            headers.set('Authorization', 'Bearer ' + token);
            headers.set('content-type', 'application/json')
            return headers;
        }
    }),
    tagTypes:['authImage'],
    endpoints:(builder) => ({
        authStatusImageFind: builder.query<string,AuthStatusImageRequest>({
            query:(request:AuthStatusImageRequest) => 'AuthImageLookup?imageLocator=' + request.imageLocator + '&facilityId=' + request.facilityId,
            providesTags: ['authImage']
        })
    })
})

export const {useAuthStatusImageFindQuery} = externalApi;