import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { Grid, TextField, Typography } from "@mui/material";
import { editAccountFinalProcedures } from "../../../../../services/accountService/model/account/AccountSlice";
import { ReduxNestedUpdate } from "../../../../../utils/ReduxUpdateCommands";

function AccountFinalReimbursementList() {
    const dispatch = useAppDispatch();
    const account = useAppSelector((state) => state.account.account);
    const sortedFinalProcedures = [...account.finalProcedures].sort((a, b) => a.priority - b.priority);
    return <React.Fragment>
    {account.finalProcedures.length > 0 && <div>
            <Grid container spacing={1} marginLeft='5px'>
                <Grid item sm={1}><h6>Priority</h6></Grid>
                <Grid item sm={1}><h6>Units</h6></Grid>
                <Grid item sm={1}><h6>Code</h6></Grid>
                <Grid item sm={4}><h6>Description</h6></Grid>
                <Grid item sm={1}><h6>Modifiers</h6></Grid>
                <Grid item sm={1}><h6>Bill Amount</h6></Grid>
                <Grid item sm={1}><h6>Reimbursement</h6></Grid>
                <Grid item sm={2}></Grid>
            </Grid>
            {sortedFinalProcedures.map(i=>(
                <Grid container spacing={1} sx={{mb:1}} marginLeft='5px'>
                    <Grid item sm={1}><TextField value={i.priority} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                    <Grid item sm={1}><TextField value={i.units} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                    <Grid item sm={1}><TextField value={i.procedureCode} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                    <Grid item sm={4}><TextField value={i.procedureDescription} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                    <Grid item sm={1}><TextField value={i.mod1 + ',' + i.mod2 + ',' + i.mod3 + ',' + i.mod4} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                    <Grid item sm={1}><TextField value={i.billAmount} onChange={(e)=>dispatch(editAccountFinalProcedures(new ReduxNestedUpdate(i.id,'billAmount',e.target.value)))} 
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                    <Grid item sm={1}><TextField value={i.reimbursement} onChange={(e)=>dispatch(editAccountFinalProcedures(new ReduxNestedUpdate(i.id,'reimbursement',e.target.value)))}
                             sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                    <Grid item sm={2}><Typography alignItems='center' color='orangered' variant='h6'>{i.id==='PLACEHOLDER'?'PROJECTED':''}</Typography></Grid>
                </Grid>
            ))}
            </div>}
  </React.Fragment>
}
export { AccountFinalReimbursementList }