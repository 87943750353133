import React from "react"

type ownProps={
    file:File|null;
    setFile:(file:File|null)=>void;
}
function DocumentSelector(props:ownProps){
    const changeHandler=(e:any)=>{
        props.setFile(e.target.files[0]);
    }
    return <React.Fragment>
        <input type='file' onChange={changeHandler}/>
    </React.Fragment>
}

export {DocumentSelector}