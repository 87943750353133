import { Alert, Stack } from "@mui/material";
import { Link } from "react-router-dom"
import { useContractLookupQuery } from "../services/contractService/contract-api";

type ownProps={
    queryParam:string;
}

function ContractAlert(props:ownProps){
    const Contracts = useContractLookupQuery(props.queryParam);
    const contractCount = Contracts.data?.length ?? 0;    

    return <div>
        {contractCount > 0 &&
            <Stack gap={1} marginBottom='10px'>
                <Alert color="error">{'Alert: There are ' + contractCount + ' contract(s) with ' + props.queryParam + ' '} 
                    <Link style={{color:'green'}} to={"/AlertList/" + props.queryParam}>View Contracts</Link>
                </Alert>
            </Stack>
        }
    </div>
}

export {ContractAlert}