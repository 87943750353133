export class RulePostCommand{
    contractId: string;
    ruleId:string;
    ruleType:string
    priority:number;
    ruleString:string;
    description:string;

    constructor(contractId:string, ruleId:string, ruleType:string, priority:number, rule:string, description:string){
        this.contractId = contractId;
        this.ruleId = ruleId;
        this.priority = priority;
        this.ruleString = rule;
        this.ruleType = ruleType;
        this.description = description;
    }
}