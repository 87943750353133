// A section that displays the witnesses of a variance. It has a button to add a new witness and a list of witnesses in the header.
// The List of witnesses is displayed by repeating the WitnessDetails component for each witness in the list.
// The WitnessDetails component displays the details of a witness.

import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { WitnessDetails } from "./WitnessDetails";
import { editVarianceIndividual } from "../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";
function WitnessSection() {
  const dispatch = useAppDispatch();
  const individuals = useAppSelector(
    (state) => state.varianceRequest.varianceRequest.individual
  );

  return (<React.Fragment>
   <WitnessDetails/>
  </React.Fragment>);
}
export { WitnessSection };
