import React, { useState } from "react"
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { ColumnMenu } from "../../../../../utils/ColumnMenu";
import { FeeScheduleCommandCell } from "./FeeScheduleCommandCell";
import { useFeeScheduleListFindQuery } from "../../../../../services/contractService/contract-api";
import { useParams } from "react-router";
import { FeeScheduleMessageCell } from "./FeeScheduleMessageCell";
import { process, State } from "@progress/kendo-data-query";

type ownProps={
    feeScheduleType:string;
}
function FeeScheduleList(props:ownProps){
    const {id} = useParams();
    const {data} = useFeeScheduleListFindQuery(id!);
    const [dataState, setDataState] = useState({});
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };
    const mapFeeSchedules=()=>{
         return data?.filter(p => p.feeType === props.feeScheduleType).map(i =>({...i, effectiveDate: new Date(i.effectiveDate ?? ''), endDate: new Date(i.endDate ?? '')}
        ))
    }

    return <React.Fragment>
        <Grid data={process(mapFeeSchedules()===undefined ? [] : mapFeeSchedules()!.slice(0),dataState)} 
            style={{height:'500px'}} onDataStateChange={dataStateChange}>
            <Column cell={FeeScheduleCommandCell} width='75px'/>
            <Column title='Description' field='description' columnMenu={ColumnMenu} width='500px'/>
            <Column title='Effective Date' field='effectiveDate' columnMenu={ColumnMenu} format='{0:MM/dd/yyyy}' width='200px'/>
            <Column title='End Date' field="endDate" columnMenu={ColumnMenu} format='{0:MM/dd/yyyy}' width='200px'/>
            <Column title='Category' field='category' columnMenu={ColumnMenu} width='200px'/>
            <Column title='Message' cell={FeeScheduleMessageCell}/>
        </Grid>
    </React.Fragment>
}

export {FeeScheduleList}