import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { Delete, Download } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useParams } from "react-router";
import { VarianceService } from "../../../../../../utils/ServiceLocator";
import { GridButton } from "../../../../../../components";
import { useDocumentRemoveMutation } from "../../../../../../services/varianceService/variance-api";
import { VarianceDocRemoveCommand } from "../../../../../../services/varianceService/commands/VarianceDocRemoveCommand";

function VarianceDocumentCommands(props:GridCellProps){
    const {Id} = useParams();
    const [remove]=useDocumentRemoveMutation();
    const handleDownload=()=>{
        fetch(VarianceService + 'DocumentDownloadEndoint/DocumentDownload/'+props.dataItem.id,{method:'GET'})
            .then((response)=>response.blob())
            .then((blob)=>{
                if(props.dataItem.extension==='.pdf'){
                    const file = new Blob([blob], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(file);
                    window.open(url);
                }
                else{
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download',props.dataItem.fileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
                }
            });
    };

    return<React.Fragment>
        <Stack direction='row' spacing={'-20px'}>
        <GridButton
            onClick={()=>handleDownload()}>
                {<Download/>}
        </GridButton>
        <GridButton color='error'
            onClick={()=>remove(new VarianceDocRemoveCommand(Id!,props.dataItem.id))}>
            {<Delete/>}
        </GridButton>
        </Stack>

    </React.Fragment>
}

export {VarianceDocumentCommands}