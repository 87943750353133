export const FallOtherPriorDetailOptions = [
    {
        description: "Inadequate lighting",
        name: "InadequateLighting",
    },
    {
        description: "Seizure activity",
        name: "SeizureActivity",
    },
    {
        description: "Syncopal episode",
        name: "SyncopalEpisode",
    },
    {
        description: "Visitor assisted patient in ambulating",
        name: "VisitorAssistedPatientInAmbulating",
    },
    {
        description: "Was assistive device involved",
        name: "WasAssistiveDeviceInvolved",
    },
];