import { Alert, Button, TextField } from "@mui/material"
import { Stack } from "@mui/system"
import { formatDate } from "@progress/kendo-intl";
import React, { useState } from "react"
import { useAppSelector } from "../../../../../app/hooks"
import { useFeeScheduleItemCountQuery } from "../../../../../services/contractService/contract-api";
import { DeleteConfirmation } from "./DeleteConfirmation";

function FeeSummary(){
    const feeSchedule = useAppSelector((state)=> state.feeSchedule.feeSchedule);
    const feeSummary = useAppSelector((state)=> state.feeSchedule.feeSchedule.feeSummary);
    const items = useFeeScheduleItemCountQuery(feeSchedule.id);
    const [confirmDelete,setConfirmDelete] = useState(false);

    return <React.Fragment>
        <Stack direction='row' marginTop='10px' marginLeft='10px' gap={1} justifyContent='space-between'>
            <Stack direction='row' gap={1}>
                <TextField label='Create Date' value={formatDate(new Date(feeSummary.createDate ?? new Date('01/01/1900')),'MM/dd/yyyy')} InputLabelProps={{shrink:true}} size='small'/>
                <TextField label='Last Update' value={formatDate(new Date(feeSummary.lastUpdate ?? new Date('01/01/1900')),'MM/dd/yyyy')} InputLabelProps={{shrink:true}} size='small'/>
                <TextField label='Total # Codes Loaded'value={items.data} InputLabelProps={{shrink:true}} size='small'/>
            </Stack>
            <Stack marginRight='10px'>
                <Button variant='outlined' color='error' onClick={()=>setConfirmDelete(true)}>Delete</Button>
            </Stack>
        </Stack>

        <Stack marginTop='10px' marginLeft='10px' marginRight='10px' gap={2}>
            {feeSummary.uploadInProgress === true &&
                <Alert color='warning'>A fee schedule upload is currently in progress.  Refresh this page for updates.</Alert>
            }
            {feeSummary.isLastUploadSuccess === false &&
                <Alert color='error'>An error occured during the last fee schedule upload. Please check your upload file and resubmit.<br/> {feeSummary.uploadErrorMessage}</Alert>
            }
            {feeSummary.isLastUploadSuccess === true &&
                <Alert color='success'>Last fee schedule upload successful.</Alert>
            }
        </Stack>
            <DeleteConfirmation isOpen={confirmDelete} toggleModal={setConfirmDelete}/>
    </React.Fragment>
}

export {FeeSummary}