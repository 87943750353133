export const InfectionOptions = [
    {
        description: "Hand Hygiene Compliance Issue",
        name: "HandHygieneComplianceIssue",
    },
    {
        description: "Isolation - Breach",
        name: "IsolationBreach",
    },
    {
        description: "Isolation - Failure to Follow Protocol",
        name: "IsolationFailureToFollowProtocol",
    },
    {
        description: "Isolation Not Implemented when required",
        name: "IsolationNotImplementedWhenRequired",
    },
    {
        description: "Patient Exposure - Blood/Body Fluid",
        name: "PatientExposureBloodBodyFluid",
    },
    {
        description: "Patient Exposure - Communicable Disease",
        name: "PatientExposureCommunicableDisease",
    },
    {
        description: "Patient Exposure - Other Contaminated Material",
        name: "PatientExposureOtherContaminatedMaterial",
    },
    {
        description: "Patient Exposure - Sharps Issue",
        name: "PatientExposureSharpsIssue",
    },
    {
        description: "Single-Use Item Submitted for Reprocessing Sterilization/Disinfection Issue",
        name: "SingleUseItem",
    },
    {
        description: "Suspected Infection",
        name: "SuspectedInfection",
    },
];