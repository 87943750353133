import React, { useState } from "react"
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { useParams } from "react-router"
import { process, State } from "@progress/kendo-data-query";
import { ColumnMenu } from "../../../../utils/ColumnMenu";
import { RadWorklist as worklist } from "../../../../services/radiologyService/model/radWorklist/RadWorklist";
import { RadWorklistCommandCell } from "./RadWorklistCommandCell";
import { useFindRadWorklistQuery } from "../../../../services/radiologyService/radiology-api";

function RadWorklist(){
    const {center} = useParams();
    const {data} = useFindRadWorklistQuery(center!);
    const [dataState, setDataState] = useState({});
    const mapCases =()=>{
        let cases:worklist[]=data===undefined ? [] : data?.map(i => ({...i, requestedBy:new Date(i.requestedBy ?? new Date('01/01/1900'))}))
        return cases;
    }
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };

    return <React.Fragment>
        <Grid data={process(mapCases() === undefined ? [] : mapCases().slice(0),dataState)}
        style={{height:'500px'}} onDataStateChange={dataStateChange}>
            <Column cell={RadWorklistCommandCell} width='100px'/>
            <Column title="Last Name" field="lastName" columnMenu={ColumnMenu}/>
            <Column title='First Name' field="firstName" columnMenu={ColumnMenu}/>
            <Column title='Request By Date' field='requestedBy' columnMenu={ColumnMenu} format='{0:MM/dd/yyyy}' filter="date"/>
        </Grid>
    </React.Fragment>
}

export {RadWorklist}