import { Details } from "@mui/icons-material"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleCaseManMedNecDetailModal } from "../../../../../app/slices/CaseManModuleSlice";
import { MedNecAlertDetail } from "./MedNecAlertDetail";
import { error } from "console";

type ownProps = {
    priority:number;
    status:string;
}
function MedNecProcDetailModal(props:ownProps){
    const dispatch = useAppDispatch();
    const open = useAppSelector((state)=>state.caseManModule.CaseManMedNecDetailModal);

    return <React.Fragment>
        <Button size='large' variant="outlined" startIcon={<Details/>}
            onClick={()=>dispatch(toggleCaseManMedNecDetailModal(props.priority))}
            color={props.status==='Fail'?'error':props.status==='Pass Info'?'warning': props.status==='Pass'?'success':'info'}></Button>

        <Dialog open={open} onClose={()=>dispatch(toggleCaseManMedNecDetailModal(props.priority))}>
            <DialogTitle>Medical Necessity Detail</DialogTitle>
            <DialogContent>
                <MedNecAlertDetail/>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>dispatch(toggleCaseManMedNecDetailModal(props.priority))}>Close</Button>
            </DialogActions>
        </Dialog>
        
    </React.Fragment>
}

export {MedNecProcDetailModal}