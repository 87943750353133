export class RefundStatusPostCommand{
    caseId:string;
    refundId:string;
    nextStatus:string;

    constructor(caseId:string, refundId:string, nextStatus:string){
        this.caseId = caseId;
        this.refundId = refundId;
        this.nextStatus = nextStatus;
    }
}