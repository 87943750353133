import { Stack, Typography } from "@mui/material"
import React from "react"
import { AccountSummaryRequest } from "../../../../services/accountService/commands";
import { useFindAccountByPeriodQuery } from "../../../../services/accountService/account-api";
import { useParams } from "react-router";

function AccountCountCard(){
    const {center, month, year} = useParams();
    const {data} = useFindAccountByPeriodQuery(new AccountSummaryRequest(center!, Number(month!), Number(year!)));
    const count = data === undefined ? 0 : data.length;
    return <React.Fragment>
        <Stack alignItems='center'>
            <Typography variant='subtitle1' className='psn-green'>Case Count</Typography>
            <Typography variant='subtitle1' className='psn-green'>{count}</Typography>
        </Stack>
    </React.Fragment>
}
export {AccountCountCard}