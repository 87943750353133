import { Box, Container, ThemeProvider } from '@mui/material';
import * as React from 'react';
import NavMenu from './NavMenu'
import {LocalizationProvider} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { psnTheme } from '../../../components/PsnTheme';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <Box>
            <NavMenu/>
            <br/>
            <Container maxWidth='xl'>
                <ThemeProvider theme={psnTheme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {props.children}
                </LocalizationProvider>
                </ThemeProvider>
            </Container>
        </Box>
    </React.Fragment>
);
