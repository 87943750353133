import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useNavigate } from "react-router";
import { GridButton } from "../../../../components";
import { accountApi } from "../../../../services/accountService/account-api";
import { useAppDispatch } from "../../../../app/hooks";

function AccountGridCommandCell(props:GridCellProps){
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <GridButton variant='outlined' size='small' onClick={()=>
            {navigate('/RevRecDetail/'+props.dataItem.id);
            dispatch(accountApi.util.invalidateTags(['Account']))
            }}>View</GridButton>
        
    </React.Fragment>
}
export {AccountGridCommandCell}