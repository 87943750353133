import React from "react"
import { useAppSelector } from "../../../../../app/hooks";
import { Alert } from "@mui/material";

function NotRequiredAlert(){
    const caseManage = useAppSelector((state)=>state.caseManagement.caseManagement);
    return<React.Fragment>
        {caseManage?.policyLinks?.length === 0 &&
            <Alert color='info'>No Policies found.  Clinical Review not required.</Alert>
        }
    </React.Fragment>
}
export{NotRequiredAlert}
