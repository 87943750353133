import { Stack } from "@mui/system"
import React from "react"
import { Implants } from "./Implants"
import { Multiples } from "./Multiples"
import { UnspecifiedRates } from "./UnspecifiedRates"

function Rates(){
    return <React.Fragment>
        {/* <FeeSchedule/> */}
        <Stack direction='row' gap={2} marginBottom='20px' marginLeft='10px' marginTop='10px'>
            <Multiples/>
            <UnspecifiedRates/>
        </Stack>
        <Stack direction='row'  gap={2} marginBottom='20px' marginLeft='10px'>
            <Implants/>
        </Stack>
    </React.Fragment>
}

export {Rates}