import { Alert } from "@mui/material";
import { useParams } from "react-router";
import {useCaseFindQuery } from "../../../../../services/scheduleService/schedule-api";

function ApptNote(){
    const {id} = useParams();
    const schedule = useCaseFindQuery(id!);
    const note = schedule.data?.visitNotes.find(i => i.code == 'ApptNote')?.description;
    const trimNote = note?.trimRight().replace("\n$", "TEST");
        
    return <Alert sx={{marginTop:'10px', marginBottom:'10px', paddingBottom:'0px'}} color="info">
        Appointment Note:
        <pre style={{marginBottom:'0px', paddingBottom:'0px'}}>{note?.trim()}</pre>
    </Alert>
}

export {ApptNote}