import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { GridButton } from "../../../../components";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../../../app/hooks";
import { varianceApi } from "../../../../services/varianceService/variance-api";

function VarianceWorklistCommandCell(props: GridCellProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <React.Fragment>
      <GridButton
        onClick={() => {
          navigate("/VarianceRequestDetail/" + props.dataItem.id);
          dispatch(varianceApi.util.invalidateTags(["variance"]));
        }}
      >
        Select
      </GridButton>
    </React.Fragment>
  );
}

export { VarianceWorklistCommandCell };
