import React from "react"
import { useMyProfileQuery } from '../../../../services/profileService/profile-api';
import RefundIcon from '../../../../images/appicons/Refunds.png';
import { Card, CardActions, CardContent, Grid } from "@mui/material";

function RefundsCard(){
    const {data} = useMyProfileQuery();
    const canAccess=():boolean=>{
        if(data?.role === 'Admin'){
            return true;
        }
        if(data?.claimsAccess.find(p => p.code === 'RefundEdit') != undefined){
            return true;
        }
        return false;
    }

    return <React.Fragment>
        {canAccess() &&
            <Grid item sm={3}>
               <Card className='card-img-body center-align'>
                <CardContent className='card-img-main'>
                    <a className='btn img-button' href={'/Refunds'}><img className='card-img' src={RefundIcon} alt=''/></a>
                </CardContent>
                <CardActions className='card-img-footer'><h6>Refunds</h6></CardActions>
               </Card>
            </Grid>
        }
    </React.Fragment>
}

export {RefundsCard}