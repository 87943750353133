import { FeeSummary } from "./FeeSummary";
import { Groupers } from "./Groupers";

export class FeeSchedule{
    id:string='';
    feeScheduleId:string = '';
    contractId:string = '';
    feeType:string = '';
    feeSubType:string = '';
    category:string = '';
    description:string = '';
    effectiveDate?:Date = new Date();
    endDate?:Date = new Date();
    groupers:Groupers[] = [];
    feeSummary:FeeSummary = new FeeSummary();
    revCodeRestrictions:string[] = [];
}