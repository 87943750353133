export class VariancePendingApprovalPostCommand{
    varianceId:string;
    user:string;
    userEmail:string;

    constructor(varianceId:string, user:string, userEmail:string){
        this.varianceId = varianceId;
        this.user = user;
        this.userEmail = userEmail;
    }
}