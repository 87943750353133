import React, { useEffect, useState } from "react"
import { RuleDeleteCommand, RulePostCommand } from "../../../../../../services/contractService/commands";
import { contractApi, useContractsForCalculatorQuery, useRuleDeleteMutation, useRuleSaveMutation } from "../../../../../../services/contractService/contract-api";
import { useAppDispatch } from "../../../../../../app/hooks";
import { useParams } from "react-router";
import { RuleCommands } from "./RuleCommands";
import { Stack } from "@mui/system";
import { MenuItem, TextField } from "@mui/material";

type ownProps={
    ruleId:string;
    ruleString?:string;
    ruleDescription?:string;
}

function MedicarePercentByFeeType(props:ownProps){
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const [rule,setRule] = useState({contract:'',category:'',rate:0})
    const medicareContracts = useContractsForCalculatorQuery("Medicare");
    const [saveRule] = useRuleSaveMutation();
    const [deleteRule] = useRuleDeleteMutation();
    const save=()=>{
        let command = new RulePostCommand(id!,props.ruleId,'MedicarePercentByFeeType',0, JSON.stringify(rule),props.ruleDescription ?? '');
        saveRule(command).unwrap().then(()=>{dispatch(contractApi.util.invalidateTags(['ruleList']))});
    }
    const ruleDelete=()=>{deleteRule(new RuleDeleteCommand(props.ruleId))};
    useEffect(() => {
        if(props.ruleString !== ''){
            let r:{contract:string,category:string,rate:number} = JSON.parse(props.ruleString!);
            setRule(r);
        }},[props.ruleString, props.ruleId]
    )
    return <React.Fragment>
        <Stack direction='row' gap={1}>
            <TextField label='Contract' select size='small' sx={{width:'250px'}} InputLabelProps={{shrink:true}}
                value={rule.contract} onChange={(e)=>{setRule({...rule,contract:e.target.value})}}> 
                <MenuItem value=''>Unknown</MenuItem>
                {medicareContracts.data?.map(i => (<MenuItem value={i.id}>{i.payer}</MenuItem>))}
            </TextField>
            <TextField label='Fee Schedule Category' value={rule.category} sx={{width:'250px'}}
                onChange={(e)=>setRule({...rule,category:e.target.value})}/>
            <TextField type='number' label='Rate %' value={rule.rate} sx={{width:'100px'}}
                onChange={(e)=>setRule({...rule, rate:Number(e.target.value)})}/>
        </Stack>
        <RuleCommands saveCommand={save} deleteCommand={ruleDelete} isNewRule={props.ruleId === '0' ? true:false}/>
    </React.Fragment>
}

export {MedicarePercentByFeeType}