import { Alert } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import React from "react";

function ResponsibilityNote() {
  const verification = useAppSelector(
    (state) => state.verification.verification);
  const note = verification.patientResponsibility.changeNote;
  const category = verification.patientResponsibility.changeNoteCategory;

  return (
    <React.Fragment>
      {note && note.trim() !== '' && (
        <Alert
          severity="info"
          sx={{ marginTop: "10px", marginBottom: "10px", paddingBottom: "0px" }}
          color="warning"
        >
          Patient Responsibility Change Note:
          <pre style={{ marginBottom: "0px", paddingBottom: "0px" }}>{category}: {note}</pre>
        </Alert>
      )}
    </React.Fragment>
  );
}

export { ResponsibilityNote };
