import { Alert, Button, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMyProfileQuery } from "../../../../../../services/profileService/profile-api";
import { VariancePostCommand } from "../../../../../../services/varianceService/commands/VariancePostCommand";
import { useAppSelector } from "../../../../../../app/hooks";
import { useVarianceSaveMutation } from "../../../../../../services/varianceService/variance-api";

type ownProps={
    setNotify:Function;
    setMessage:Function;}

function VarianceDetailsSave(props:ownProps){
    const variance = useAppSelector(
        (state) => state.varianceRequest.varianceRequest
      );
      const [varianceSave, status] = useVarianceSaveMutation();
    const myProfile = useMyProfileQuery();
    const canEdit =():boolean=>{
      if(variance.status === "Complete"){return false;}
      if(myProfile.data?.role === 'Admin'){return true;}
      if(myProfile.data?.role === 'Quality Team'){return true;}
      if(myProfile.data?.claimsAccess.find(p => p.code === 'IncidentQualityTeam') != undefined){return true;}
      if(myProfile.data?.claimsAccess.find(p => p.code === 'IncidentAdmin') != undefined){return true;}
      if(myProfile.data?.claimsAccess.find(p => p.code === 'IncidentEdit' && variance.status === "Started") != undefined){return true;}
      if(myProfile.data?.upn === variance?.assignedTo?.upn && variance.status === "Assigned"){return true;}
      return false;
  }
    const [notify, setNotify] = useState(false);
    useEffect(() => {
      setNotify(status.isSuccess);
    }, [status.isSuccess]);
    const handleSave = () => {
      let command = new VariancePostCommand(variance);
      varianceSave(command)
        .unwrap()
        .then(() => {});
    };
  return <React.Fragment>
   {canEdit()  && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleSave()}
              >
                Save
              </Button>
            )}
             <Snackbar
        open={notify}
        onClose={() => setNotify(false)}
        color="success"
        autoHideDuration={6000}
        message="Incident Saved Successfully!"
      />
      <Snackbar open={status.isError}>
        <Alert color="error">
          {"An error has occured: " + JSON.stringify(status.error)}
        </Alert>
      </Snackbar>
 </React.Fragment>
}

export {VarianceDetailsSave}