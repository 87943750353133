import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { useMyProfileQuery } from "../../../../../../services/profileService/profile-api";
import { Alert, Button, Snackbar } from "@mui/material";
import { VariancePostCommand } from "../../../../../../services/varianceService/commands/VariancePostCommand";
import { Block } from "@mui/icons-material";
import {
  useVarianceRejectMutation,
  useVarianceSaveMutation,
} from "../../../../../../services/varianceService/variance-api";
import { VarianceRejectPostCommand } from "../../../../../../services/varianceService/commands/VarianceRejectPostCommand";
import { toggleIncidentRejectCommentModal } from "../../../../../../app/slices/VarianceModuleSlice";
import { IncidentRejectCommentDialog } from "../../comments/IncidentRejectCommentDialog";


type ownProps = {
  setNotify: Function;
  setMessage: Function;
};

function VarianceRejectBtn(props: ownProps) {
  const incident = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  const dispatch = useAppDispatch();
  const [varianceSave] = useVarianceSaveMutation();
  const [saveStatus, result] = useVarianceRejectMutation();
  const profile = useMyProfileQuery();
  const command = new VarianceRejectPostCommand(incident.id, profile.data?.identity!, profile.data?.email!);
  const handleReject = async () => {
    dispatch(toggleIncidentRejectCommentModal());
    await varianceSave(new VariancePostCommand(incident));
    await saveStatus(command).unwrap()
    .then(()=>{props.setNotify(true);
    props.setMessage('Incident Rejected')});    
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="error"
        startIcon={<Block />}
        onClick={() => handleReject()}
      >
        Reject
      </Button>
      <Snackbar open={result.isError}>
        <Alert color="error">
          {"An error has occured: " + JSON.stringify(result.error)}
        </Alert>
      </Snackbar>
      <IncidentRejectCommentDialog />
    </React.Fragment>
  );
}

export { VarianceRejectBtn };
