import { MenuItem, Stack, TextField } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { setAccountPeriod, setAccountYear } from "../../../../../app/slices/AccountModuleSlice";

function PeriodSelector(){
    const dispatch = useAppDispatch();
    const period = useAppSelector((state) => state.accountModule.Period);
    const year = useAppSelector((state) => state.accountModule.Year);

    return <React.Fragment>
        <Stack direction='row' gap={1} marginBottom={'10px'} marginTop='10px'>
            <TextField label='Period' select value={period} sx={{width:'150px', backgroundColor:'white'}}
                 onChange={(e) => dispatch(setAccountPeriod(e.target.value))}>
                <MenuItem value='1'>January</MenuItem>
                <MenuItem value='2'>February</MenuItem>
                <MenuItem value='3'>March</MenuItem>
                <MenuItem value='4'>April</MenuItem>
                <MenuItem value='5'>May</MenuItem>
                <MenuItem value='6'>June</MenuItem>
                <MenuItem value='7'>July</MenuItem>
                <MenuItem value='8'>August</MenuItem>
                <MenuItem value='9'>September</MenuItem>
                <MenuItem value='10'>October</MenuItem>
                <MenuItem value='11'>November</MenuItem>
                <MenuItem value='12'>December</MenuItem>
            </TextField> 
            <TextField label='Year' sx={{width:'100px', backgroundColor:'white'}}
                value={year} onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                 dispatch(setAccountYear(e.target.value));
                }
                }}
                inputProps={{maxLength:4}} />
        </Stack>
    </React.Fragment>
}
export {PeriodSelector}