import { Stack, Typography } from "@mui/material"
import { formatNumber } from "@progress/kendo-intl"
import React from "react"
import { useFindAdjustmentsByPeriodQuery } from "../../../../services/accountService/account-api"
import { useParams } from "react-router"
import { AccountSummaryRequest } from "../../../../services/accountService/commands"

function OutOfPeriodAdjCard(){

    return <React.Fragment>
        <Stack alignItems='center'>
            <Typography variant='subtitle1' className='psn-green'>Out of Period Adjustments</Typography>
            <Typography variant='subtitle1' className='psn-green'>{formatNumber(0,'c')}</Typography>
        </Stack>
    </React.Fragment>
}
export {OutOfPeriodAdjCard}