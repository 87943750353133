import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack,  TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleFeeScheduleItemEditModal } from "../../../../../app/slices/ContractModuleSlice";
import { DrgEditCommand, DrgRequest } from "../../../../../services/contractService/commands";
import { Drg } from "../../../../../services/contractService/model";
import { useDrgEditMutation, useDrgFindQuery } from "../../../../../services/contractService/contract-api";

function DrgItemEdit(){
    const dispatch = useAppDispatch();
    const {feeScheduleId} = useParams();
    const isOpen = useAppSelector((state) => state.contractModule.feeScheduleItemEditModal)
    const selectedCode = useAppSelector((state) => state.contractModule.selectedCode)
    const skip = !useAppSelector((state) => state.contractModule.feeScheduleItemEditModal);
    const [editDrg] = useDrgEditMutation();
    const {data} = useDrgFindQuery(new DrgRequest(feeScheduleId!,selectedCode),{skip,});
    const [drg,setDrg] = useState(new Drg());
    const handleChange=(e:React.ChangeEvent<HTMLTextAreaElement|HTMLInputElement>)=>{
        const {name,value} = e.target
        let newDrg:Drg = data!;
        newDrg = ({...newDrg,[name]:value});
        setDrg(newDrg);
    }
    useEffect(()=>{setDrg(data??new Drg())},[data]);
    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogTitle>{'Edit Code: ' +data?.code}</DialogTitle>
            <DialogContent>
                <Stack direction='row' marginTop='10px'>
                    <TextField label='Description' name='title' value={drg.title} onChange={(e)=>handleChange(e)} 
                        size='small' InputLabelProps={{shrink:true}} fullWidth multiline/>
                </Stack>
                <Stack direction='row' gap={1} marginTop='10px'>
                    <TextField label='Weight' name='weight' value={drg.weight} onChange={(e)=>handleChange(e)}
                        size='small' InputLabelProps={{shrink:true}}/>
                    <TextField label='ALOS' name='arithmeticMeanLOS' value={drg.arithmeticMeanLOS} onChange={(e)=>handleChange(e)}
                        size='small' InputLabelProps={{shrink:true}}/>
                    <TextField label='Grouper' name='grouperCode' value={drg.grouperCode} onChange={(e)=>handleChange(e)}
                        size='small' InputLabelProps={{shrink:true}}/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={()=>dispatch(toggleFeeScheduleItemEditModal(''))}>Close</Button>
                <Button
                    onClick={()=> {editDrg(new DrgEditCommand(feeScheduleId!,drg.code,'',drg.title,drg.weight,drg.arithmeticMeanLOS,drg.grouperCode));
                                    dispatch(toggleFeeScheduleItemEditModal(''));}}
                >Save</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export {DrgItemEdit}