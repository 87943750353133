import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import React, { useState } from "react";

function DirectorResponseInstructions() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="text" color="primary" onClick={handleOpen}>
        Instructions
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Instructions</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <ul>
              <li>Identification of all employees involved. (Witness Tab)</li>
              <li>Measures implemented to prevent recurrence.</li>
              <li>Has this event previously occurred within the department? If so, department-wide education required.</li>
              <li>Has the employee experienced this event more than once? If yes, and if previously addressed, what is the updated action plan beyond education?</li>
              <li>Impact on the patient: Was there any harm?</li>
              <li>Additional treatment provided to the patient as a result of the event.</li>
              <li>Was there a deviation from the standard of care?</li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export { DirectorResponseInstructions };
