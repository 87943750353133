export const TreatmentOptions = [
    {
        description: "Adverse Reaction (Non Med)",
        name: "AdverseReaction",
    },
    {
        description: "Allergic Reaction (Non Med)",
        name: "AllergicReaction",
    },
    {
        description: "Delayed Diagnosis",
        name: "DelayedDiagnosis",
    },
    {
        description: "Delayed Treatment",
        name: "DelayedTreatment",
    },
    {
        description: "Failure to Assess Patient by Hospital Staff",
        name: "FailureToAssessPatientByHospitalStaff",
    },
    {
        description: "Failure to Assess Patient by Medical Staff",
        name: "FailureToAssessPatientByMedicalStaff",
    },
    {
        description: "Inappropriate Treatment",
        name: "InappropriateTreatment",
    },
    {
        description: "Incorrect Diagnosis",
        name: "IncorrectDiagnosis",
    },
    {
        description: "Isolation – Failure to Follow Protocol",
        name: "IsolationFailureToFollowProtocol",
    },
    {
        description: "Treatment provided without Order",
        name: "TreatmentProvidedWithoutOrder",
    },
    {
        description: "Wrong Patient Treated",
        name: "WrongPatientTreated",
    },
    ];