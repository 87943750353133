export class DirectorResponseFallAction {
    foreignSubstance:boolean = false;
    foreignSubstanceDetails:string = "";
    hazardEnviroment:boolean = false;
    hazardEnviromentDetails:string = "";
    patientFallRisk:boolean = false;
    patientFallRiskDetails:string = "";
    interventionDocumented:boolean = false;
    toiletingNeeds:boolean = false;
    medsImagingOrdered:boolean = false;
    medsImagingOrderedDetails:string = "";
    injuriesIdentified:boolean = false;
    injuriesIdentifiedDetails:string = "";
}

export function DefaultDirectorResponseFallAction(): DirectorResponseFallAction{
    return {foreignSubstance:false, foreignSubstanceDetails:"", hazardEnviroment:false, hazardEnviromentDetails:"", patientFallRisk:false, patientFallRiskDetails:"", interventionDocumented:false, toiletingNeeds:false, medsImagingOrdered:false, medsImagingOrderedDetails:"", injuriesIdentified:false, injuriesIdentifiedDetails:""};
}