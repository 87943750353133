import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { Profile } from './model/profile/Profile';
import { getToken } from '../../utils/AdalConfig';
import { ProfileService } from '../../utils/ServiceLocator';
import { AccessAddCommand, AccessRemoveCommand, ClaimAddCommand, ClaimRemoveCommand, RoleChangeCommand } from './commands';
import { Claims } from './model/Claims';
import { Roles } from './model/Roles';
import { ProfileAttrSaveCommand } from './commands/ProfileAttrSaveCommand';

export const profileApi = createApi({
    reducerPath:'profileApi',
    baseQuery: fetchBaseQuery({
        baseUrl: ProfileService,
        prepareHeaders:(headers,{getState}) => {
            const token = getToken();
            headers.set('Authorization', 'Bearer ' + token);
            headers.set('content-type', 'application/json')
            return headers;
        }
    }),
    tagTypes:['myProfile','roles','claims','profileLookup','userProfile'],
    endpoints:(builder) =>({
        myProfile:builder.query<Profile,void>({
            query:() =>'myprofile',
            providesTags:['myProfile']
        }),
        profileLookup:builder.query<Profile[],string>({
            query:(term)=>'profileLookup?SearchTerm='+term,
            providesTags:['profileLookup']
        }),
        userProfile:builder.query<Profile,string>({
            query:(id) => 'userProfile?request=' + id,
            providesTags:['userProfile']
        }),
        allProfiles:builder.query<Profile[],void>({
            query:()=>'allProfiles'
        }),
        roles:builder.query<Roles[],void>({
            query:()=>'allRoles',
            providesTags:['roles']
        }),
        claims:builder.query<Claims[],void>({
            query:()=>'allClaims',
            providesTags:['claims']
        }),
        roleChange:builder.mutation<void, RoleChangeCommand>({
            query:(body) => {
                return {url:'RoleChange', method:'POST', body:body,}
            },
            invalidatesTags:['myProfile','userProfile']
        }),
        addAccess:builder.mutation<void, AccessAddCommand>({
            query:(body) =>{
                return {url:'AddAccess',method:'POST',body:body,}
            },
            invalidatesTags:['myProfile','userProfile']
        }),
        removeAccess:builder.mutation<void,AccessRemoveCommand>({
            query:(body) =>{
                return {url:'RemoveAccess',method:'POST',body:body,}
            },
            invalidatesTags:['myProfile','userProfile']
        }),
        addClaim:builder.mutation<void,ClaimAddCommand>({
            query:(body)=>{
                return {url:'ClaimAdd',method:'POST',body:body,}
            },
            invalidatesTags:['myProfile','userProfile']
        }),
        removeClaim:builder.mutation<void,ClaimRemoveCommand>({
            query:(body) =>{
                return {url:'ClaimRemove',method:'POST',body:body,}
            },
            invalidatesTags:['myProfile','userProfile']
        }),
        profileAttributeSave:builder.mutation<Profile,ProfileAttrSaveCommand>({
            query:(body) =>{
                return {url:'profileAttributesSave',method:'POST',body:body,}
            },
            invalidatesTags:['myProfile','userProfile']
        
        })
    })
})

export const {useMyProfileQuery, useProfileLookupQuery, useRolesQuery, useClaimsQuery,
    useRoleChangeMutation,useUserProfileQuery, useAddAccessMutation,
    useRemoveAccessMutation, useAddClaimMutation, useRemoveClaimMutation,
    useAllProfilesQuery, useProfileAttributeSaveMutation} = profileApi;