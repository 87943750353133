import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField } from "@mui/material"
import React from "react"
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { Profile } from "../../../../services/profileService/model/profile/Profile";
import { useProfileAttributeSaveMutation } from "../../../../services/profileService/profile-api";

type ownProps={
    profile?:Profile;
}
function ProfileAttrbEdit(props:ownProps) {
    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = React.useState<string>('');
    const [saveProfile] = useProfileAttributeSaveMutation();
    React.useEffect(()=>{
        if(props.profile){
            setEmail(props.profile.email);
        }
    },[props.profile])
    const save = ()=>{
        let command = {id:props.profile?.id!,email:email!};
        saveProfile(command);
    }

    return <React.Fragment>
        <IconButton size='small' onClick={()=>setOpen(true)}><ModeEditOutlinedIcon fontSize="small"/></IconButton>

        <Dialog open={open} onClose={()=>setOpen(false)}>
            <DialogTitle>Edit Profile</DialogTitle>
            <DialogContent>
                <Stack marginTop='10px'>
                    <TextField label='Email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
                </Stack>
                <Stack direction='row' gap={1} justifyContent='flex-end' marginTop='10px'>
                    <Button variant='outlined' onClick={()=>setOpen(false)}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={()=>{setOpen(false);save();}}>Save</Button>
                </Stack>    
            </DialogContent>
        </Dialog>

    </React.Fragment>

}
export { ProfileAttrbEdit}