import {process, State } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import React, { useState } from "react"
import { useAppSelector } from "../../../../app/hooks"
import { ColumnMenu } from '../../../../utils/ColumnMenu';
import { MedicareRateEditCommandCell } from './MedicareRateEditCommandCell';

function MedicareRateList(){
    const rates = useAppSelector((state) => state.center.center.medicareRates);
    const defaultGridState:State = {sort:[{field:'year',dir:'desc'}]};
    const [dataState, setDataState] = useState(defaultGridState);

    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };


    return <React.Fragment>
        <Grid data={process(rates.slice(0),dataState)}
            style={{height:'500px'}} onDataStateChange={dataStateChange}>
            <Column cell={MedicareRateEditCommandCell} width='100px'/>
            <Column title='Year' field='year' columnMenu={ColumnMenu} width='100px'/>
            <Column title='Wage Index' field='wageIndex' columnMenu={ColumnMenu} width='150px'/>
            <Column title='LROP' field='lrop'columnMenu={ColumnMenu} width='125px'/>
            <Column title='NLOP' field='nlop' columnMenu={ColumnMenu} width='125px'/>
            <Column title='Base Rate' field='baseRate' columnMenu={ColumnMenu} width='150px' format='{0:c}'/>
             <Column title='FCW' field='federalCapitalWeight'columnMenu={ColumnMenu} width='125px'/>
            <Column title='GAF' field='geographicAdjustmentFactor' columnMenu={ColumnMenu} width='125px'/>
            <Column title='IP Hosp Ded' field='inpatientHospitalDeductible' columnMenu={ColumnMenu} width='150px'/>
        </Grid>
    </React.Fragment>
}

export {MedicareRateList}