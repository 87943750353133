import { Stack } from "@mui/material"
import React from "react"
import { NoteCreateBtn } from "./refundNoteCreate/NoteCreateBtn"
import { RefundNoteResults } from "./refundNoteResults/RefundNoteResults"

function RefundNotes(){

    return <React.Fragment>
        <Stack direction='row' justifyContent='flex-end' marginTop='5px'>
            <NoteCreateBtn/>
        </Stack>
        <RefundNoteResults/>
    </React.Fragment>
}

export {RefundNotes}