import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface RefundModuleState{
    TabStripPosition:number;
    RefundNoteDialogOpen:boolean;
    RefundDocumentDialogOpen:boolean;
    RefundInitiateDialogOpen:boolean;
}

const initialState:RefundModuleState={TabStripPosition:0, RefundNoteDialogOpen:false, RefundDocumentDialogOpen:false,
    RefundInitiateDialogOpen:false};

export const RefundModuleSlice = createSlice({
    name:'RefundModuleSlice',
    initialState,
    reducers:{
        setTabStripPosition:(state, action:PayloadAction<number>) =>{
            state.TabStripPosition = action.payload;
        },
        toggleRefundNoteDialog:(state)=>{state.RefundNoteDialogOpen = !state.RefundNoteDialogOpen},
        toggleRefundDocumentDialog:(state)=>{state.RefundDocumentDialogOpen = !state.RefundDocumentDialogOpen},
        toggleRefundInitiateDialog:(state)=>{state.RefundInitiateDialogOpen = !state.RefundInitiateDialogOpen}
    }
})

export const {setTabStripPosition,toggleRefundNoteDialog, toggleRefundDocumentDialog,
    toggleRefundInitiateDialog}= RefundModuleSlice.actions;
export default RefundModuleSlice.reducer;