import { Call } from "./Call";
import { Patient } from "./Patient";

export interface RadRequest{
    requestId: string;
    centerId:string;
    patient:Patient;
    requestByDate?: string|null;
    procedureDescription:string;
    status:string ;
    callLog:Call[];
}

export function DefaultRadReqeust():RadRequest{
    return {
        requestId:'',centerId:'',requestByDate:null,procedureDescription:'',status:'',callLog:[],
        patient:{lastName:'',firstName:'',middleName:'',homePhone:'',workPhone:'',email:''}
    }
}