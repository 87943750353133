import { Button } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router"

function PolicyHomeBtn(){
    const navigate = useNavigate()
    return <React.Fragment>
        <Button variant='contained' sx={{background:'#62BCBD'}} 
            onClick={()=> navigate("/policies")}>POLICY HOME</Button>
    </React.Fragment>
}
export {PolicyHomeBtn}