import { Button, Dialog, DialogContent, Stack } from "@mui/material";
import React, { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { toggleCaseLookup } from "../../../../app/slices/RadModuleSlice";
import { LookupResultsGrid } from "./LookupResultsGrid";
import { useSearchRadRequestQuery } from "../../../../services/radiologyService/radiology-api";

type ownProps = {
    searchText:string;
    skip:boolean;
}

function LookupResults(props:ownProps){
    const [skip, setSkip] = React.useState(true)
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.radModule.CaseLookupDialog);
    const {data, isFetching} = useSearchRadRequestQuery(props.searchText,{skip,})
    useEffect(()=>setSkip(props.skip),[props.skip]);

    return <React.Fragment>
        <Dialog open={isOpen} maxWidth='lg'>
            <DialogContent>
                <LookupResultsGrid searchResults={data??[]}/>
                <Stack direction='row' justifyContent='flex-end' marginTop='10px'>
                    <Button variant='outlined' onClick={()=>dispatch(toggleCaseLookup())}>Close</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    </React.Fragment>
}

export {LookupResults}