import React, { useState } from "react"
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { ColumnMenu } from "../../../../../utils/ColumnMenu";
import { process, State } from "@progress/kendo-data-query";
import { useAppSelector } from "../../../../../app/hooks";
import { RefundDownloadCommand } from "./RefundDownloadCommand";

function RefundDocumentList(){
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const [dataState, setDataState] = useState<State>({});
    const mapDocuments=()=>{
        return refund.documents.map(i => ({...i, uploadDate: new Date(i.uploadDate ?? '')}));
    }
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };

    return<React.Fragment>
        <Grid data={process(mapDocuments().filter(p => p.isDeleted!=true)??[].slice(0),dataState)}{...dataState}
            onDataStateChange={dataStateChange} style={{height:'500px'}}>
                <Column cell={RefundDownloadCommand} width='100px'/>
                <Column title='File Name' field='fileName' columnMenu={ColumnMenu} width='450px'/>
                <Column title='Upload By' field='uploadBy' columnMenu={ColumnMenu} width='250px'/>
                <Column title='Upload Date' field='uploadDate' columnMenu={ColumnMenu} width='250px' format='{0:MM/dd/yyyy}'/>
        </Grid>
    </React.Fragment>
}

export {RefundDocumentList}