export const PropertyLossOptions = [
    {
        description: "Property Damaged",
        name: "PropertyDamaged",
    },
    {
        description: "Property Stolen",
        name: "PropertyStolen",
    },
    {
        description: "Vehicle Break-In",
        name: "VehicleBreakIn",
    },
    {
        description: "Vehicle Damage",
        name: "VehicleDamage",
    },
    {
        description: "Vehicle Stolen",
        name: "VehicleStolen",
    },
];