export class AccountApproveAdjustmentCommand {
    accountNumber: string;
    adjustmentId: string;
    isApproved: boolean;

    constructor(accountNumber: string, adjustmentId: string, isApproved: boolean) {
        this.accountNumber = accountNumber;
        this.adjustmentId = adjustmentId;
        this.isApproved = isApproved;
    }
}