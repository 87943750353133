import React from "react"
import { useAppSelector } from "../../../../../app/hooks"
import { AccountNonSurgProcList } from "./AccountNonSurgProcList";
import { Stack } from "@mui/material";
import { AccountTotalNonSurgCharges } from "./AccountTotalNonSurgeCharges";

function NonSurgicalProcs(){
    const account = useAppSelector((state) => state.account.account);

    return<React.Fragment>
        <br/>
        <Stack direction='row' marginBottom='10px' marginLeft='10px'>
            <AccountTotalNonSurgCharges/>
        </Stack>
        <AccountNonSurgProcList/>
    </React.Fragment>
}
export {NonSurgicalProcs}