import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import React from "react"
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { toggleEncounterTab } from "../../../app/slices/ScheduleModuleSlice";
import { Header } from "../features/detail/Header"
import { Table } from "@mui/material";
import { Procedures } from "../features/detail/procedures/Procedures";
import { Insurances } from "../features/detail/insurances/Insurances";
import { ContactInfo } from "../features/detail/contact/ContactInfo";
import { ProcedureDetail } from "../features/detail/procedures/ProcedureDetail";
import { InsuranceDetail } from "../features/detail/insurances/InsuranceDetail";
import { InsuranceCreate } from "../features/detail/insurances/InsuranceCreate";
import { ApptNote } from "../features/detail/apptNote/ApptNote";


function ScheduleCreate(){
    const dispatch = useAppDispatch();
    const {center} = useParams();
    const tabLocation = useAppSelector((state) => state.scheduleModule.EncounterTab);
    const toggleTab = (e: TabStripSelectEventArguments) => dispatch(toggleEncounterTab(e.selected));

    return <React.Fragment>
        <Header center={center!} isCreate={true}/>
        <TabStrip selected={tabLocation} onSelect={toggleTab}>
        <TabStripTab title='Services'>
            <Table/>
            <Procedures center={center!}/>
        </TabStripTab>
        <TabStripTab title='Insurance'>
            <Table/>
            <Insurances center={center!}/>
        </TabStripTab>
        <TabStripTab title='Contact'>
            <Table/>
            <ContactInfo center={center!}/>
        </TabStripTab>
        <TabStripTab title='Notes'>
            <Table/>
            <ApptNote center={center!}/>
        </TabStripTab>
        </TabStrip>

        <ProcedureDetail center={center!}/>
        <InsuranceDetail center={center!}/>
        <InsuranceCreate center={center!}/>
    </React.Fragment>
}

export {ScheduleCreate}