import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useRefundApproverRemoveMutation } from "../../../../services/orgService/org-api"
import { Stack } from "@mui/material";
import { GridButton } from "../../../../components";
import { Delete } from "@mui/icons-material";
import { RefundApproverEdit } from "./RefundApproverEdit";
import { RefundApproverPostCommand } from "../../../../services/orgService/commands";

function RefundApproverCommands(props:GridCellProps){
    const [remove] = useRefundApproverRemoveMutation();
    
    return <React.Fragment>
        <Stack direction = 'row' spacing={'-20px'}>
            <RefundApproverEdit selectedApprover={props.dataItem.id}/>
            <GridButton color='error'
                 onClick={()=>remove(new RefundApproverPostCommand(props.dataItem))}>
                    {<Delete/>}
            </GridButton>
        </Stack>
    </React.Fragment>
}

export{RefundApproverCommands}