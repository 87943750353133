import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DefaultRefundRequest, RefundRequest } from "./RefundRequest";
import { ReduxUpdate } from "../../../../utils/ReduxUpdateCommands";
import { refundApi } from "../../refund-api";
import { RefundDocument } from "./RefundDocuments";

interface RefundRequestState {refundRequest:RefundRequest}

const initialState: RefundRequestState = {refundRequest: DefaultRefundRequest()};
export const RefundRequestSlice = createSlice({
    name:'refundRequest',
    initialState,
    reducers:{
        editRefund:(state,action:PayloadAction<ReduxUpdate>)=>{
            //@ts-ignore
            state.refundRequest[action.payload.name] = action.payload.value;
        },
        editRefundDetails:(state,action:PayloadAction<ReduxUpdate>)=>{
            //@ts-ignore
            state.refundRequest.refundDetail[action.payload.name] = action.payload.value;
        },
        editCheckInfo:(state,action:PayloadAction<ReduxUpdate>)=>{
            //@ts-ignore
            state.refundRequest.checkInfo[action.payload.name] = action.payload.value;
        },
        addRefundDocument:(state, action:PayloadAction<RefundDocument>)=>{
            state.refundRequest.documents.push(action.payload);
        }
    },
    extraReducers:(builder)=>{
        builder.addMatcher(
            refundApi.endpoints.findRefundRequest.matchFulfilled,
            (state,{payload})=>{state.refundRequest = payload}
        )
        .addMatcher(refundApi.endpoints.noteCreate.matchFulfilled,
            (state,{payload})=>{state.refundRequest.notes.push(payload)}    
        )
        .addMatcher(refundApi.endpoints.documentRemove.matchFulfilled,
            (state,{payload})=>{state.refundRequest.documents = state.refundRequest.documents.filter(x=>x.id != payload.id)})
        .addMatcher(refundApi.endpoints.refundStatusUpdate.matchFulfilled,
            (state,{payload})=> {state.refundRequest = payload})
        .addMatcher(refundApi.endpoints.refundApprove.matchFulfilled,
            (state,{payload})=>
            {state.refundRequest.approvals.map(x=>{if(x.approver == payload.approver){x.isApproved = true; x.isRejected = false}});
            state.refundRequest.status = payload.currentStatus; })
        .addMatcher(refundApi.endpoints.refundReject.matchFulfilled,
            (state,{payload})=>
            {state.refundRequest.approvals.map(x=>{if(x.approver == payload.rejecter){x.isApproved = false; x.isRejected = true}});
            state.refundRequest.status = payload.currentStatus;})
        .addMatcher(refundApi.endpoints.refundResubmit.matchFulfilled,
            (state,{payload})=>{state.refundRequest.status = payload.status;
            state.refundRequest.approvals = payload.approvals;})
    }
})

export const {editRefund, editRefundDetails, editCheckInfo,addRefundDocument} = RefundRequestSlice.actions;
export default RefundRequestSlice.reducer;